import InterviewQuestions from "./InterviewQues";

const Mockinterview = () => {
  return (
    <>
      <InterviewQuestions />
    </>
  );
};

export default Mockinterview;
