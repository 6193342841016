import { Table as TableView, TableContainer, Card } from "@mui/material";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";
import { REFERRALS_TABLE_HEADER } from "settings/constants/members";
import PropTypes from "prop-types";
import TableBody from "./TableBody";
import TableHeader from "./TableHeader";
import { handleError } from "utils/error";
import { PAGINATION } from "settings/constants/pagination";
import { useEffect, useReducer } from "react";
import { useLazyGetEmpWithPrevExpQuery } from "api/Referrals/getEmpWithPrevExp";
import { get, noop } from "lodash";
import { MISCurrentUser } from "utils/validations";
import T from "T";
import { useLazyGetEmpsByCompanyQuery } from "api/Referrals/getEmpsByCompany";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Table = ({ prevCompany, userId, id, handleAddRecommendation = noop }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    tableData: [],
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
  });
  const { page, size, tableData, totalTableRowsCount, totalPageCount } = localState;

  const [getEmpWithPrevExp] = useLazyGetEmpWithPrevExpQuery();
  const { user } = MISCurrentUser();
  const role = get(user, "role", null);
  const [getEmpsByCompany] = useLazyGetEmpsByCompanyQuery();
  useEffect(() => {
    if (id) {
      getEmpsByCompany({ page, size, id })
        .unwrap()
        .then((res) => {
          const results = get(res, "results", []);
          setLocalState({
            tableData: results,
            totalTableRowsCount: get(res, "numberOfRecords", 0),
            totalPageCount: get(res, "numberOfPages", 0),
          });
        })
        .catch(handleError);
    } else {
      getEmpWithPrevExp({ page, size, companyName: prevCompany, userId })
        .unwrap()
        .then((res) => {
          const results = get(res, "results", []);
          setLocalState({
            tableData: results,
            totalTableRowsCount: get(res, "numberOfRecords", 0),
            totalPageCount: get(res, "numberOfPages", 0),
          });
        })
        .catch(handleError);
    }
  }, [page, size]);

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <TableContainer
        sx={{
          height: "calc(100vh - 245px)",
          overflow: "auto",
        }}
      >
        {tableData.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            {role === T.TA ? (
              <TableHeader columns={REFERRALS_TABLE_HEADER.filter((column) => column.label !== "Previous Company")} />
            ) : (
              <TableHeader
                columns={REFERRALS_TABLE_HEADER.filter((column) => column.label !== "Previous Company" && column.label !== "")}
              />
            )}
            <TableBody
              allTableRows={tableData}
              handleAddRecommendation={handleAddRecommendation}
              page={page}
              id={id}
              size={size}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
      <MasterPagination
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={size}
        onRowsPerPageChange={handleRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};
Table.propTypes = {
  handleAddRecommendation: PropTypes.func,
  prevCompany: PropTypes.string,
  userId: PropTypes.number,
  id: PropTypes.number,
};
export default Table;
