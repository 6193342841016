import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFeedbackTraining: builder.query({
      query: ({ feedbackName }) => ({
        url: `/training/get/feedbackByName?feedbackName=${feedbackName}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetFeedbackTrainingQuery } = extendedApi;
