import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadContract: build.mutation({
      query: ({ payload, licenseId }) => ({
        url: `/tools/license/upload/contract?toolUserMappingId=${licenseId}`,
        body: payload,
        method: "POST",
      }),
    }),
  }),
});

export const { useUploadContractMutation } = extendedApi;
