import { api } from "api";

const extendedApi = api.injectEndpoints({
    endpoints: (build) => ({
        updateToolType: build.mutation({
            query: (body) => ({
                url: "/toolType/update",
                method: "PUT",
                body,
            }),
        }),
    }),
});

export const { useUpdateToolTypeMutation } = extendedApi;
