import PropTypes from "prop-types";
import { noop } from "lodash";
import { Box, Card, Table as TableView, TableContainer } from "@mui/material";
import MISTableBody from "./TableBody";
import TableHeader from "./TableHeader";

const Table = ({
  columns = [],
  jdId = "",
  lockedColumns = [],
  shortListedChecks = [],
  allTableRows,
  selectAllCheck = false,
  handleShortListCheckBoxChange = noop,
  refreshMemberTable = noop,
  handleExport = noop,
  handleScheduleInterview = noop,
  handleCancelInterview = noop,
  handleCodeAssessment = noop,
  handleSubmitAssessmentDialog = noop,
  handleViewAssessmentDialog = noop,
  handleInterviewChecklist = noop,
  navigate = noop,
  handleTrackStatusDialog = noop,
  handlePreInterviewDialog = noop,
  handleScheduleMockInterview = noop,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        maxHeight: "calc(100vh - 260px)",
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 290px)",
            minHeight: "calc(100vh - 500px)",
            overflow: "auto",
          }}
        >
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader
              selectAllCheck={selectAllCheck}
              handleShortListCheckBoxChange={handleShortListCheckBoxChange}
              columns={columns}
              lockedColumns={lockedColumns}
            />
            <MISTableBody
              columns={columns}
              jdId={jdId}
              lockedColumns={lockedColumns}
              records={allTableRows}
              refreshMemberTable={refreshMemberTable}
              shortListedChecks={shortListedChecks}
              handleShortListCheckBoxChange={handleShortListCheckBoxChange}
              handleExport={handleExport}
              handleScheduleInterview={handleScheduleInterview}
              handleScheduleMockInterview={handleScheduleMockInterview}
              handleSubmitAssessmentDialog={handleSubmitAssessmentDialog}
              handleViewAssessmentDialog={handleViewAssessmentDialog}
              handleCodeAssessment={handleCodeAssessment}
              handleCancelInterview={handleCancelInterview}
              handleInterviewChecklist={handleInterviewChecklist}
              navigate={navigate}
              handleTrackStatusDialog={handleTrackStatusDialog}
              handlePreInterviewDialog={handlePreInterviewDialog}
            />
          </TableView>
        </TableContainer>
      </Box>
    </Card>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  jdId: PropTypes.number,
  allTableRows: PropTypes.array,
  lockedColumns: PropTypes.array,
  shortListedChecks: PropTypes.array,
  refreshMemberTable: PropTypes.func,
  handleExport: PropTypes.func,
  handleScheduleInterview: PropTypes.func,
  handleCodeAssessment: PropTypes.func,
  handleCancelInterview: PropTypes.func,
  navigate: PropTypes.func,
  selectAllCheck: PropTypes.bool,
  handleShortListCheckBoxChange: PropTypes.func,
  handleSubmitAssessmentDialog: PropTypes.func,
  handleViewAssessmentDialog: PropTypes.func,
  handleInterviewChecklist: PropTypes.func,
  handlePreInterviewDialog: PropTypes.func,
  handleScheduleMockInterview: PropTypes.func,
  handleTrackStatusDialog: PropTypes.func,
};

export default Table;
