import T from "T";
import { format } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";

export const YES_NO_VALUES = [
  { label: T.YES, value: true },
  { label: T.NO, value: false },
];

export const tenureList = [
  { tenureName: T.MONTHLY, label: T.MONTHLY },
  { tenureName: T.QUARTERLY, label: T.QUARTERLY },
  { tenureName: T.BI_ANNUALLY, label: T.BI_ANNUALLY },
  { tenureName: T.YEARLY, label: T.YEARLY },
  { tenureName: T.ONE_TIME, label: T.ONE_TIME },
];

export const SORTING_KEYS = [
  { key: "tenure", value: "tenure" },
  { key: "toolsName", value: "toolsId.toolsName" },
  { key: "department", value: "profitCenterId.profitCenterName" },
  { key: "renewalDate", value: "renewalDate" },
];

export const CONFIG_TYPE = [
  { id: "boolean", labelName: "Boolean" },
  { id: "number", labelName: "Number" },
  { id: "string", labelName: "String" },
];

export const downloadContractFile = async (response) => {
  const date = format(new Date(), BACKEND_DATE_FORMAT);
  const blob = await response.blob();
  const downloadUrl = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = downloadUrl;
  a.download = `MIS-${date}`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(downloadUrl);
};

const convertBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result.split(",")[1]);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const formatFileForBE = async (event) => {
  const file = event.target.files[0];
  const fileName = event.target.files[0].name;
  const base64 = await convertBase64(file);
  return { fileName, base64 };
};