import PropTypes from "prop-types";
import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import { USER_DELETED_NC_HEADER } from "settings/constants/members";
import DeletedNcTableBody from "./DeletedNcTableBody";
import { useEffect, useState } from "react";
import { handleError } from "utils/error";
import { get } from "lodash";
import { useLazyGetDeletedJiraByDateQuery } from "api/Jira/getDeletedJiraByDate";
import MISNoData from "components/common/MISNoData";
import TableHeader from "./TableHeader";
const deletedNcStatus = [1, 2];

const DeletedNcTable = ({ userId = "", selectedMonth = "", endDate = "", startDate = "", typeOfNc = "" }) => {
  const [getDeletedJiraByDate] = useLazyGetDeletedJiraByDateQuery();
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    getUserJiraData();
  }, []);
  const getUserJiraData = () => {
    const payload = { id: userId, month: selectedMonth, endDate, startDate, status: deletedNcStatus, type: typeOfNc };
    getDeletedJiraByDate(payload)
      .unwrap()
      .then((res) => {
        setTableData(get(res, "results", ""));
      })
      .catch(handleError);
  };
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          component={Paper}
          sx={{ height: "calc(100vh - 290px)", width: "80vw", overflowY: "auto", overflowX: "auto" }}
        >
          {tableData.length > 0 ? (
            <TableView>
              <TableHeader columns={USER_DELETED_NC_HEADER} />
              <DeletedNcTableBody tableData={tableData} typeOfNc={typeOfNc} />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
    </Card>
  );
};

DeletedNcTable.propTypes = {
  userId: PropTypes.number,
  month: PropTypes.number,
  typeOfNc: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  selectedMonth: PropTypes.string,
};
export default DeletedNcTable;
