import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateMail: builder.query({
      query: ({ id }) => ({
        url: `training/offlineTrainingAssignmentUploadReminder?trainingAssignmentId=${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyGenerateMailQuery } = extendedApi;