import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getPitchedUser: builder.query({
      query: ({ preSalesMappingId }) => ({
        url: `/selected/user/getPitchedUser?preSalesMappingId=${preSalesMappingId}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetPitchedUserQuery } = extendedApi;
