import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import T from "T";
import { Box } from "@mui/system";
import { noop } from "lodash";

const FeedbackTableHeader = ({ columns = [], selectAllCheck = false, handleDeleteCheckBoxChange = noop }) => (
  <TableHead>
    <TableRow sx={{ height: 30 }}>
      {Children.toArray(
        columns.map((column, index) => (
          <TableCell
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.header,
              padding: "0px 8px 0px 24px",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography
                fontSize={14}
                noWrap
                fontWeight={600}
                style={{ width: "190px" }}
                variant="body1"
                textTransform={"uppercase"}
              >
                {column.label}
              </Typography>
            </Box>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

FeedbackTableHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(FeedbackTableHeader);
