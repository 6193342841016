import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    finalSubmission: builder.query({
      query: (id) => ({
        url: `/videoInterview/final/submission?participantId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyFinalSubmissionQuery } = extendedApi;
