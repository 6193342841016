import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { get } from "utils/lodash";
import memo from "utils/react";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  paddingBottom: 0,
  overflow: "hidden",
  fontSize: 13,
}));
const InterviewTableBody = ({ records }) => {
  return (
    <TableBody>
      {Children.toArray(
        records.map((record, index) => (
          <TableRow>
            <StyledTableCell>{index + 1}.</StyledTableCell>
            <StyledTableCell>{get(record, "clientName", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "startDate", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "endDate", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "platform", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "status", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "rejectedResponse", "-")}</StyledTableCell>
            <StyledTableCell>{get(record, "bytManager", "-")}</StyledTableCell>
          </TableRow>
        )),
      )}
    </TableBody>
  );
};

InterviewTableBody.propTypes = {
  records: PropTypes.array,
};

export default memo(InterviewTableBody);
