import { FormControlLabel, Grid, Radio, RadioGroup, Tooltip, Typography, CircularProgress } from "@mui/material";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISTextField from "components/common/MISTextField";
import { noop, sortBy } from "lodash";
import { get } from "utils/lodash";
import { ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { isEmail, isMobileNo, isUrl } from "utils/validations";
import PropTypes from "prop-types";
import WithInputLabel from "components/common/WithInputLabel";
import { useEffect } from "react";
import MISDragDrop from "components/common/MISDragDrop";

const CreateReferral = ({
  referralName = "",
  phoneNumber = "",
  referralEmail = "",
  skills = [],
  experience = "",
  comment = "",
  skillList = [],
  companyList = [],
  // companyName = "",
  previousCompany = "",
  linkedInURL = "",
  departmentList,
  selectedReferralAnswers,
  referralAnswers,
  referralQuestions,
  selectedDept,
  isSecondLevel,
  feedbackType,
  memberList,
  recommendedBy,
  showRecommendedBy,
  record,
  isLoadingUsers,
  files,
  onHandleChange = noop,
  onHandleAutoCompleteChange = noop,
  onHandleCompanyChange = noop,
  handleReferralAnswersChange = noop,
  handleFileUpload = noop,
}) => {
  useEffect(() => {
    if (record) {
      onHandleChange({ target: { name: "referralName", value: record.name || "" } });
      onHandleChange({ target: { name: "phoneNumber", value: record.phoneNumber || "" } });
      onHandleChange({ target: { name: "referralEmail", value: record.email || "" } });
      onHandleChange({ target: { name: "experience", value: record.experience || "" } });
      onHandleAutoCompleteChange(record.skills || [], "skills");
      onHandleAutoCompleteChange(record.department || null, "selectedDept");
      onHandleCompanyChange(record.company || null);
      onHandleChange({ target: { name: "linkedinUrl", value: record.linkedInURL || "" } });
    }
  }, [record]);
  const deptListResults = get(departmentList, "results", []);

  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.PREVIOUS_COMPANY}
          fullWidth
          listDetails={sortBy(companyList, ["companyName"])}
          value={previousCompany}
          getByLabelText={(option) => get(option, "companyName", "")}
          onHandleChange={(event, newValue) => onHandleCompanyChange(newValue)}
          required
        />
      </Grid>
      {/* {previousCompany?.companyName === T.OTHER && (
        <Grid item md={4} xs={12}>
          <MISTextField
            label={T.COMPANY_NAME}
            fullWidth
            placeholder={T.COMPANY_NAME}
            size="small"
            variant="outlined"
            name="companyName"
            value={companyName}
            onChange={onHandleChange}
            required
          />
        </Grid>
      )} */}
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.SKILLS}
          required
          multiple
          listDetails={skillList}
          disabled={skillList.length < 1}
          value={skills}
          getByLabelText={(option) => get(option, "skillName", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "skills")}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.NAME}
          required
          fullWidth
          placeholder={T.NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="referralName"
          value={referralName}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      {showRecommendedBy && (
        <Grid item md={4} xs={12}>
          <div style={{ position: "relative" }}>
            <MISAutocomplete
              label={T.RECOMMENDED_BY}
              name="reviewerName"
              listDetails={memberList}
              disabled={isLoadingUsers}
              required
              getByLabelText={(option) => get(option, "userEmailId", "")}
              value={recommendedBy}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "recommendedBy")}
            />
            {isLoadingUsers && (
              <div style={{ position: "absolute", top: "72%", right: "40px", transform: "translateY(-50%)" }}>
                <CircularProgress size={20} sx={{ color: "gray" }} />
              </div>
            )}
          </div>
        </Grid>
      )}

      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.PHONE_NUMBER}
          fullWidth
          placeholder={T.PHONE_NUMBER}
          autoComplete="on"
          size="small"
          variant="outlined"
          InputProps={{ type: "number" }}
          name="phoneNumber"
          value={phoneNumber}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: phoneNumber && `3px solid ${isMobileNo(phoneNumber) ? SUCCESS.main : ERROR.main}`,
            },
          }}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.EMAIL}
          fullWidth
          placeholder={T.EMAIL}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="referralEmail"
          value={referralEmail}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: referralEmail && `3px solid ${isEmail(referralEmail) ? SUCCESS.main : ERROR.main}`,
            },
          }}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.LINKED_IN_URL}
          fullWidth
          placeholder={T.LINKED_IN_URL}
          size="small"
          variant="outlined"
          name="linkedInURL"
          value={linkedInURL}
          onChange={(e) => onHandleChange(e)}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: linkedInURL && `3px solid ${isUrl(linkedInURL) ? SUCCESS.main : ERROR.main}`,
            },
          }}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISTextField
          label={T.EXPERIENCE + " (in years)"}
          fullWidth
          required
          placeholder={T.EXPERIENCE}
          size="small"
          variant="outlined"
          InputProps={{ type: "number" }}
          name="experience"
          value={experience}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <MISAutocomplete
          label={T.SUITABLE_DEPARTMENT}
          listDetails={deptListResults}
          value={selectedDept}
          getByLabelText={(option) => get(option, "departmentName", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "selectedDept")}
        />
      </Grid>
      {/* First three question dropdowns */}
      {referralQuestions.slice(0, 3).map((question, index) => {
        return (
          <Grid key={index} item md={4} xs={12}>
            <MISAutocomplete
              label={
                <Tooltip title={question} placement="top-start">
                  <span>{question}</span>
                </Tooltip>
              }
              selectAllOption
              multiple
              listDetails={referralAnswers[index]}
              size="small"
              placeholder={T.SELECT}
              required
              getByLabelText={(option) => option}
              value={selectedReferralAnswers[index]}
              onHandleChange={(event, newValue) => handleReferralAnswersChange(index, newValue)}
            />
          </Grid>
        );
      })}
      {/* Last two question dropdowns */}
      {referralQuestions.slice(3).map((question, index) => {
        return (
          <Grid key={index} item md={6} xs={12}>
            <MISAutocomplete
              label={
                <Tooltip title={question} placement="top-start">
                  <span>{question}</span>
                </Tooltip>
              }
              selectAllOption
              multiple
              listDetails={referralAnswers[index + 3]}
              size="small"
              placeholder={T.SELECT}
              required
              getByLabelText={(option) => option}
              value={selectedReferralAnswers[index + 3]}
              onHandleChange={(event, newValue) => handleReferralAnswersChange(index + 3, newValue)}
            />
          </Grid>
        );
      })}
      <Grid item md={6} xs={12} mb={-7}>
        <Typography variant="body1" sx={{ mb: 0.3 }} color="text.label">
          {T.UPLOAD_FILE + "s" + " (Only Image, PDF, Word allowed. Maximum 2 files)"}
        </Typography>
        <MISDragDrop
          size={"small"}
          files={files}
          setFiles={handleFileUpload}
          multiple={true}
          maxFiles={2}
          fileTypes={["pdf", "xls", "xlsx", "csv", "jpg", "jpeg", "png", "doc", "docx"]}
        />
      </Grid>
      {isSecondLevel && (
        <Grid item md={4} xs={12}>
          <WithInputLabel label={T.FEEDBACK} required />
          <RadioGroup aria-label="feedbackType" name="feedbackType" value={feedbackType} onChange={(e) => onHandleChange(e)} row>
            <FormControlLabel value="true" control={<Radio style={{ color: NETSMARTZ_THEME_COLOR }} />} label="Positive" />
            <FormControlLabel value="false" control={<Radio style={{ color: NETSMARTZ_THEME_COLOR }} />} label="Negative" />
          </RadioGroup>
        </Grid>
      )}
      {!isSecondLevel && <Grid item md={4} xs={12} />}
      <Grid item md={12} xs={12}>
        <MISTextField
          label={T.COMMENTS}
          fullWidth
          placeholder={T.COMMENTS}
          multiline
          rows={4}
          size="small"
          variant="outlined"
          name="comment"
          value={comment}
          onChange={(e) => onHandleChange(e)}
        />
      </Grid>
    </Grid>
  );
};
CreateReferral.propTypes = {
  referralName: PropTypes.string,
  phoneNumber: PropTypes.string,
  referralEmail: PropTypes.string,
  skills: PropTypes.array,
  experience: PropTypes.string,
  comment: PropTypes.string,
  skillList: PropTypes.array,
  companyList: PropTypes.array,
  companyName: PropTypes.string,
  previousCompany: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  linkedInURL: PropTypes.string,
  departmentList: PropTypes.arrayOf(PropTypes.object),
  selectedReferralAnswers: PropTypes.array,
  referralAnswers: PropTypes.array,
  referralQuestions: PropTypes.array,
  selectedDept: PropTypes.object.isRequired,
  isSecondLevel: PropTypes.bool,
  feedbackType: PropTypes.string,
  memberList: PropTypes.array,
  recommendedBy: PropTypes.string,
  showRecommendedBy: PropTypes.bool,
  record: PropTypes.object,
  onHandleChange: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  onHandleCompanyChange: PropTypes.func,
  handleReferralAnswersChange: PropTypes.func,
  handleFileUpload: PropTypes.func,
  files: PropTypes.array,
  isLoadingUsers: PropTypes.bool,
};
export default CreateReferral;
