import { Box } from "@mui/system";
import { useEffect, useReducer } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Paper, Typography } from "@mui/material";
import T from "T";
import { handleError } from "utils/error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MISLoader from "components/common/MISLoader";
import AddIcon from "@mui/icons-material/Add";
import MISButton from "components/common/MISButton";
import SIRTable from "./Table";
import AddSIRDialog from "./AddSIR";
import { useLazyGetCategoriesQuery } from "api/members/SIR/getCategories";
import { useLazyGetSpecialIncidentsQuery } from "api/members/SIR/getSpecialIncident";
import { useDeleteSpecialIncidentMutation } from "api/members/SIR/deleteSIR";
import { toast } from "react-toastify";

const SpecialIncidentRecord = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openModal: false,
    openDialog: false,
    categories: [],
    tableData: [],
    dialogData: {},
  });
  const { tableData, openDialog, categories, dialogData } = localState;

  const url = location.pathname;
  const userId = Number(url.replace(/\D+/g, ""));
  const [getCategories] = useLazyGetCategoriesQuery();
  const [getSpecialIncidents, { isFetching }] = useLazyGetSpecialIncidentsQuery();
  const [deleteSpecialIncident] = useDeleteSpecialIncidentMutation();

  useEffect(() => {
    getCategories()
      .unwrap()
      .then((res) => {
        setLocalState({ categories: res });
      })
      .catch(handleError);
    refreshPage();
  }, []);

  const refreshPage = () => {
    getSpecialIncidents({ userId })
      .unwrap()
      .then((res) => {
        setLocalState({ tableData: res });
      })
      .catch(handleError);
  };
  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteSIR = (sirId) => {
    deleteSpecialIncident({ sirId })
      .unwrap()
      .then(() => {
        setLocalState({ openModal: false });
        toast.success("Special Incident Successfully Deleted.");
        refreshPage();
      })
      .catch(handleError);
  };

  const handleAddSIRDialog = () => {
    setLocalState({ openDialog: !openDialog, dialogData: null });
  };

  const handleEditSIRDialog = (record) => {
    setLocalState({ openDialog: !openDialog, dialogData: record });
  };
  return (
    <Paper display="block" sx={{ p: 2 }}>
      {isFetching && <MISLoader />}
      <Box display="flex" mb={1} justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
          <Typography variant="h6" fontWeight={700} ml={1}>
            {T.SPECIAL_INCIDENT_REGISTER}
          </Typography>
        </Box>
        <Box display="flex" justifyContent={"flex-end"} alignItems="center">
          <MISButton size="medium" startIcon={<AddIcon />} onClick={() => handleAddSIRDialog()}>
            {"Special Incident"}
          </MISButton>
        </Box>
      </Box>
      <SIRTable tableRecord={tableData} handleDeleteSIR={handleDeleteSIR} handleEditSIRDialog={handleEditSIRDialog} />
      <AddSIRDialog
        handleAddSIRDialog={handleAddSIRDialog}
        handleEditSIRDialog={handleEditSIRDialog}
        openDialog={openDialog}
        categories={categories}
        record={dialogData}
        refreshPage={refreshPage}
      />
    </Paper>
  );
};
export default SpecialIncidentRecord;
