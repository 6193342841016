import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    startInterview: build.query({
      query: (assessmentId) => ({
        url: "technicalAssignment/start/assignment",
        method: "GET",
        params: { assessmentId },
      }),
    }),
  }),
});

export const { useLazyStartInterviewQuery } = extendedApi;
