import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getProfitCenterList: builder.query({
      query: ({ page, rowsPerPage }) => ({
        url: `/profitCenter/get/all?page=${page}&size=${rowsPerPage}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetProfitCenterListQuery } = extendedApi;
