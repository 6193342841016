import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMockCandidateRecordList: builder.query({
      query: ({ page, rowsPerPage, interviewFilterRequestDto }) => ({
        url: `/mockVideoInterview/getAllVideoInterviewParticipants?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: interviewFilterRequestDto,
      })
    }),
  }),
});

export const { useLazyGetMockCandidateRecordListQuery } = extendedApi;
