import PropTypes from "prop-types";
import { get } from "lodash";
import { PAGINATION } from "settings/constants/pagination";
import { useEffect, useReducer } from "react";
import { Table as TableView, TableContainer, Card } from "@mui/material";
import { COMPANIES_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import ActivityLog from "./ActivityLog";
import usePMFetch from "hooks/usePMFetch";
import routes from "router/routes";

import { Box } from "@mui/system";
import MISButton from "components/common/MISButton";
import { Add } from "@mui/icons-material";
import Form from "./Form";
import { handleError } from "utils/error";
import { useSaveCompanyDetailsMutation } from "api/Referrals/saveCompanyDetails.";
import { toast } from "react-toastify";
import { useLazyGetAllCompaniesQuery } from "api/Referrals/getAllCompanies";
import { useUpdateCompanyDetailsMutation } from "api/Referrals/updateCompanyDetails";

import MasterPagination from "components/MasterSetting/MasterPagination";
import T from "T";
import MISLoader from "components/common/MISLoader";
import { useNavigate } from "react-router-dom";
import ViewEmployees from "../RecentJoiners/ViewEmployees";
import MISNoData from "components/common/MISNoData";
import { useLazyGetCompanyActivityLogQuery } from "api/Referrals/getCompanyActivityLog";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const CompaniesTable = () => {
  const { skillList } = usePMFetch();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: INITIAL_PAGE,
    size: ROWS_PER_PAGE,
    homePage: INITIAL_PAGE,
    homeSize: ROWS_PER_PAGE,
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    totalHomePageCount: INITIAL_PAGE,
    totalHomeTableRowsCount: INITIAL_PAGE,
    openFormDialog: false,
    openLogDialog: false,
    tableData: [],
    companyName: "",
    location: "",
    minEmp: null,
    maxEmp: null,
    skills: [],
    formData: null,
    activityLog: [],
    openEmpDialog: false,
    prevCompany: "",
    id: null,
    empId: null,
  });

  const {
    page,
    size,
    totalPageCount,
    totalTableRowsCount,
    tableData,
    openFormDialog,
    openLogDialog,
    companyName,
    skills,
    formData,
    minEmp,
    maxEmp,
    activityLog,
    location,
    homePage,
    homeSize,
    totalHomePageCount,
    totalHomeTableRowsCount,
    openEmpDialog,
    prevCompany,
    id,
    empId,
  } = localState;

  const navigate = useNavigate();

  const [saveCompanyDetails] = useSaveCompanyDetailsMutation();

  const [getAllCompaniesDetails, { isLoading }] = useLazyGetAllCompaniesQuery();


  const [updateCompanyDetails] = useUpdateCompanyDetailsMutation();
  const [getCompanyActivityLog, { isFetching: isLoadingLogs }] = useLazyGetCompanyActivityLogQuery();

  const handleAskForReferrals = () => {};

  const getAllCompanies = () => {
    getAllCompaniesDetails({ page: homePage, size: homeSize })
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: res.results,
          totalHomeTableRowsCount: get(res, "numberOfRecords", 0),
          totalHomePageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  };

  const getCompanyLogById = () => {
    getCompanyActivityLog({ page: page, size: size, id: id })
      .unwrap()
      .then((res) => {
        setLocalState({
          activityLog: res.results,
          totalTableRowsCount: get(res, "numberOfRecords", 0),
          totalPageCount: get(res, "numberOfPages", 0),
        });
      })
      .catch(handleError);
  };


  useEffect(() => {
    getAllCompanies();
  }, [homePage, homeSize]);

  useEffect(() => {
    if (id !== null) {
      getCompanyLogById();
    } else {
      setLocalState({
        page: INITIAL_PAGE,
        size: ROWS_PER_PAGE,
      });
    }
  }, [id, page, size]);

  const handleSubmit = () => {
    const isFormValid = companyName && location && skills.length >= 1 && minEmp && maxEmp;

    if (!isFormValid) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return;
    }
    if (minEmp !== null && maxEmp !== null) {
      if (parseInt(minEmp) > parseInt(maxEmp)) {
        toast.error(T.MIN_MAX_ERROR);
        return;
      }
    }
    if (formData) {
      const payload = {
        id: formData.id,
        companyName: companyName ? companyName.trim() : companyName,
        location: location ? location.trim() : location,
        numberOfEmployees: `${minEmp}-${maxEmp}`,
        techStack: skills.map((skill) => skill.id.toString()),
      };

      updateCompanyDetails({ payload })
        .unwrap()
        .then(() => {
          getAllCompanies();
          handleFormToggleDialog();
          toast.success(T.COMPANY_DETAILS_UPDATED_SUCCESSFULLY);
        })
        .catch(handleError);
    } else {
      const payload = {
        companyName: companyName ? companyName.trim() : companyName,
        location: location ? location.trim() : location,
        numberOfEmployees: `${minEmp}-${maxEmp}`,
        techStack: skills.map((skill) => skill.id.toString()),
      };

      saveCompanyDetails({ payload })
        .unwrap()
        .then(() => {
          getAllCompanies();
          handleFormToggleDialog();
          toast.success(T.COMPANY_DETAILS_ADDED_SUCCESSFULLY);
        })
        .catch(handleError);
    }
  };

  const handleOpenFormDialog = (record) => {
    setLocalState({ openFormDialog: !openFormDialog, formData: record });

    setLocalState({
      companyName: record.companyName,
      skills: record.techStack,
      minEmp: record.minEmp,
      maxEmp: record.maxEmp,
      location: record.location,
    });
  };

  const handleFormToggleDialog = () => {
    setLocalState({ openFormDialog: !openFormDialog, formData: null });
    setLocalState({
      companyName: "",
      skills: [],
      minEmp: null,
      maxEmp: null,
      location: "",
    });
  };

  const handleLogDialog = (id) => {
    setLocalState({ openLogDialog: !openLogDialog, id: id });
  };

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handleHomeRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ homePage: INITIAL_PAGE, homeSize: value });
  };

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (newValue, item) => {
    setLocalState({ [item]: newValue });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleHomePageChange = (newPage) => {
    setLocalState({ homePage: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleAddRecommendation = (isSecondLevel, user_id) => {
    navigate(routes.app.referralRecommendationForm, { state: { isSecondLevel: isSecondLevel, user_id: user_id } });
  };

  const handleViewEmployees = (prevCompany, id) => {
    setLocalState({ openEmpDialog: !openEmpDialog, prevCompany: prevCompany, empId: id });
  };

  return (
    <Box>
      {isLoading && <MISLoader />}
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
      

        <MISButton
          sx={{ ml: 2 }}
          size="small"
          startIcon={<Add sx={{ width: 19 }} />}
          onClick={() => handleFormToggleDialog(null)}
        >
          {T.NEW}
        </MISButton>
      </Box>

      <Card
        elevation={0}
        sx={{
          p: "0px 8px",
          mt: 1,
          backgroundColor: "background.card",
          "& .add-shadow": {
            boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
          },
        }}
      >
        <TableContainer
          sx={{
            height: "calc(100vh - 340px)",
            overflow: "auto",
          }}
        >
          {tableData.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader columns={COMPANIES_TABLE_HEADER} />

              <TableBody
                allTableRows={tableData}
                page={homePage}
                size={homeSize}
                handleViewEmployees={handleViewEmployees}
                handleFormToggleDialog={handleOpenFormDialog}
                handleLogDialog={handleLogDialog}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>

        <Form
          openModal={openFormDialog}
          formData={formData}
          handleToggleDialog={handleFormToggleDialog}
          companyName={companyName}
          onHandleChange={onHandleChange}
          skillList={get(skillList, "results", [])}
          skills={skills}
          location={location}
          handleSubmit={handleSubmit}
          minEmp={minEmp}
          maxEmp={maxEmp}
          tableData={tableData}
          onHandleAutoCompleteChange={onHandleAutoCompleteChange}
        />

        <ViewEmployees
          openEmpDialog={openEmpDialog}
          handleViewEmployees={handleViewEmployees}
          id={empId}
          prevCompany={prevCompany}
          handleAskForReferrals={handleAskForReferrals}
          handleAddRecommendation={handleAddRecommendation}
        />

        <ActivityLog
          isLoading={isLoadingLogs}
          openModal={openLogDialog}
          handleToggleDialog={() => {
            setLocalState({ activityLog: [] });
            handleLogDialog(null);
          }}
          handlePageChange={handlePageChange}
          activityLog={activityLog}
          handleRowsPerPageChange={handleRowsPerPageChange}
          page={page}
          size={size}
          totalPageCount={totalPageCount}
          totalTableRowsCount={totalTableRowsCount}
        />

        <MasterPagination
          page={homePage}
          onPageChange={handleHomePageChange}
          rowsPerPage={homeSize}
          onRowsPerPageChange={handleHomeRowsPerPageChange}
          totalTableRowsCount={totalHomeTableRowsCount}
          totalPageCount={totalHomePageCount}
        />
      </Card>
    </Box>
  );
};

CompaniesTable.propTypes = {
  handleAskForReferrals: PropTypes.func,
};

export default CompaniesTable;
