import { Grid } from "@mui/material";
import T from "T";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ReportCardFilters from "./Filters";

const TopBar = ({
  skillList,
  status,
  startDate,
  endDate,
  pMResults,
  clientName,
  filters,
  isFilterEmpty,
  onHandleDateChange = noop,
  onhandleFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
}) => {
  return (
    <Grid container spacing={2} justifyContent="flex-end">
      <Grid item md={2.5} xs={12}>
        <ReportCardFilters
          status={status}
          clientName={clientName}
          skillList={skillList}
          pMResults={pMResults}
          filters={filters}
          isFilterEmpty={isFilterEmpty}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
        />
      </Grid>
      <Grid item md={2.5} xs={12}>
        <MISDatePicker
          placeholder={T.START_DATE}
          inputFormat="MM/DD/YYYY"
          value={startDate}
          name="startDate"
          maxDate={endDate}
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={2.5} xs={12}>
        <MISDatePicker
          placeholder={T.END_DATE}
          inputFormat="MM/DD/YYYY"
          value={endDate}
          minDate={startDate}
          name="endDate"
          sx={{ ml: 1, mr: 1 }}
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  skillList: PropTypes.object,
  filters: PropTypes.object,
  onHandleChange: PropTypes.func,
  status: PropTypes.array,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  pMResults: PropTypes.array,
  clientName: PropTypes.array,
  onHandleDateChange: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
  isFilterEmpty: PropTypes.bool,
};

export default TopBar;
