import { Box, Card, Table, TableBody, TableContainer, TableHead, TableRow, IconButton, TableCell, Tooltip } from "@mui/material";
import { Children, useEffect } from "react";
import PropTypes from "prop-types";
import { get, noop } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import MISNoData from "components/common/MISNoData";
import { shallowEqual, useSelector } from "react-redux";
import { PRE_SALES_TABLE_HEADER } from "settings/constants/members";
import { useLazyGetAllJobTitleQuery } from "api/preSales/getPreSalesJobTitle";

const PreSalesTableData = ({ handleViewClientDialog = noop, psTableData }) => {
  const navigate = useNavigate();
  const [getAllJobTitle, { data: jobTitleList }] = useLazyGetAllJobTitleQuery();

  useEffect(() => {
    getAllJobTitle();
  }, []);

  const { selectedCard } = useSelector(
    (state) => ({
      selectedCard: get(state, "PSCardSlice.selectedCard", []),
    }),
    shallowEqual,
  );

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderTop: "inherit",
    overflow: "hidden",
    padding: "8px 0px 8px 17px",
    ...theme.typography.subtitle1,
    fontSize: 12.5,
    whiteSpace: "nowrap",
  }));

  const renderTableRows = (data) => {
    return Children.toArray(
      data.map((record, index) => {
        const region = get(record, "clientRegion.regionName", "-");
        const shiftTimings = get(record, "shiftTimings.shiftTimings", "-");
        const jdList = get(record, "preSalesMapping", []);
        const requirementId = get(record, "id", "");
        const requirementFor = get(record, "requirementFor", "-");
        const requirementType = get(record, "requirementType", "-");
        const handleEditRequirement = (id) => {
          navigate(`/app/pre-sales/${id}/edit`);
        };
        const handleViewClient = (jdId, data) => {
          navigate(`/app/pre-sales/view-client`, { state: { jdId, data } });
        };

        if (jdList.length === 0) {
          return (
            <Box key={index} sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "42vh" }}>
              <MISNoData />
            </Box>
          );
        }

        return jdList.map((data, index) => {
          const preSalesJobTitleId = get(data, "preSalesJobTitleId", null);
          const preSalesSkillsMapping = get(data, "preSalesSkillsMapping", []);
          const skills = [...new Set(preSalesSkillsMapping.map((skill) => skill.tech.skillName))];
          const preSalesJobTitle = jobTitleList?.find((jobTitle) => jobTitle?.id === preSalesJobTitleId).preSalesJobTitle;
          return (
            <TableRow key={index} sx={{ "&:nth-child(even)": { backgroundColor: BACKGROUND.header } }}>
              {/* <TableCell>{index + 1}</TableCell> */}
              <StyledTableCell>{region}</StyledTableCell>
              <TableCell sx={{ minWidth: "70px" }}>{shiftTimings}</TableCell>
              <TableCell sx={{ pl: 6.5 }}>{get(data, "daysOfOperation", "-")}</TableCell>
              <TableCell sx={{ pl: 7 }}>{get(data, "daysInSales", "-")}</TableCell>
              <TableCell>
                <Tooltip placement="top" title={skills.join(", ")}>
                  <span> {preSalesJobTitle}</span>
                </Tooltip>
              </TableCell>
              <TableCell sx={{ pl: 5.5 }}>{get(data, "numberOfPosts", "-")}</TableCell>
              <StyledTableCell>
                <Box>{get(data, "createdAt", "").split(" ")[0]}</Box>
                <Box> at {get(data, "createdAt", "").split(" ")[1]}</Box>
              </StyledTableCell>
              <StyledTableCell>{get(data, "preSalesMappingStatus", "-")}</StyledTableCell>
              <StyledTableCell>{requirementType}</StyledTableCell>
              <TableCell
                onClick={() =>
                  requirementFor === "T&M" || requirementFor === "Fixed Cost"
                    ? handleViewClientDialog(record)
                    : handleViewClient(get(data, "id"), data)
                }
                sx={{ color: NETSMARTZ_THEME_COLOR, cursor: "pointer", fontWeight: "bold", whiteSpace: "nowrap" }}
              >
                {get(data, "preSalesMappingName")}
              </TableCell>
              <StyledTableCell>
                <IconButton
                  onClick={() => handleEditRequirement(requirementId)}
                  sx={{ cursor: "pointer", color: NETSMARTZ_THEME_COLOR }}
                >
                  <EditIcon sx={{ height: 17, width: 17 }} />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          );
        });
      }),
    );
  };

  return (
    <Card sx={{ borderRadius: 2, boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", p: 1, m: 1 }}>
      <TableContainer sx={{ height: "calc(100vh - 350px)", overflow: "scroll" }}>
        {(selectedCard.length > 0 ? selectedCard : psTableData) && (
          <Table stickyHeader>
            {(selectedCard.length > 0 ? selectedCard : psTableData).some(
              (record) => get(record, "preSalesMapping", []).length > 0,
            ) && (
              <TableHead>
                <TableRow>
                  {PRE_SALES_TABLE_HEADER.map((header, index) => (
                    <TableCell sx={{ fontWeight: "bold", py: 1, fontSize: 12.5, whiteSpace: "nowrap" }} key={index}>
                      {header.toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
            )}
            <TableBody>{renderTableRows(selectedCard.length > 0 ? selectedCard : psTableData)}</TableBody>
          </Table>
        )}
      </TableContainer>
    </Card>
  );
};

PreSalesTableData.propTypes = {
  handleViewClientDialog: PropTypes.func,
  psTableData: PropTypes.array,
};

export default PreSalesTableData;
