import { createSlice } from "@reduxjs/toolkit";

const mockVideoInterviewSlice = createSlice({
    name: "MockVideoInterviewSlice",
    initialState: {
        receivedTechSet1: false,
        receivedTechSet2: false,
        videoResponses: [],
        videoResponsesFormData: {},
        participantDetails: {},
        formSubmitted: false,
        videoInterviewUserRegistered: false,
        openInstructionModal: false,
        responseArr: [],
        techQuestions: [],
        startTime: null,
        timeLeft: 20 * 60,
        isVideoInterviewAssigned: null,
    },
    reducers: {
        mockVideoInterviewTechQuestionStore: (state, action) => {
            state.techQuestions = action.payload.techQuestions;
        },
        mockVideoInterviewTechSet1Recieved: (state, action) => {
            state.receivedTechSet1 = action.payload.receivedTechSet1;
        },
        mockVideoInterviewTechSet2Recieved: (state, action) => {
            state.receivedTechSet2 = action.payload.receivedTechSet2;
        },
        mockVideoInterviewStore: (state, action) => {
            state.videoResponses = action.payload.videoResponses;
        },
        mockVideoInterviewFormDataStore: (state, action) => {
            state.videoResponsesFormData = action.payload.videoResponsesFormData;
        },
        mockVideoInterviewParticipantIdStore: (state, action) => {
            state.participantDetails = action.payload.participantDetails;
        },
        mockVideoInterviewStore1: (state, action) => {
            state.formSubmitted = action.payload.formSubmitted;
        },
        mockManageVideoInterviewUserLogin: (state, action) => {
            state.videoInterviewUserRegistered = action.payload.videoInterviewUserRegistered;
        },
        mockVideoInterviewResponseArrStore: (state, action) => {
            state.responseArr = action.payload.responseArr;
        },
        mockVideoInterviewInstructionModal: (state, action) => {
            state.openInstructionModal = action.payload.openInstructionModal;
        },
        mockSetStartTime: (state, action) => {
            state.startTime = action.payload.startTime;
        },
        mockUpdateTimeLeft: (state, action) => {
            state.timeLeft = action.payload.timeLeft;
        },
        mockVideoInterviewStatus: (state, action) => {
            state.isVideoInterviewAssigned = action.payload.isVideoInterviewAssigned;
        },
    },
});
export const {
    mockVideoInterviewTechSet1Recieved,
    mockVideoInterviewTechSet2Recieved,
    mockVideoInterviewTechQuestionStore,
    mockVideoInterviewStore,
    mockVideoInterviewFormDataStore,
    mockVideoInterviewParticipantIdStore,
    mockVideoInterviewStore1,
    mockManageVideoInterviewUserLogin,
    mockVideoInterviewResponseArrStore,
    mockVideoInterviewInstructionModal,
    mockSetStartTime,
    mockUpdateTimeLeft,
    mockVideoInterviewStatus,
} = mockVideoInterviewSlice.actions;
export default mockVideoInterviewSlice.reducer;
