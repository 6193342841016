import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitCodeAssessment: build.mutation({
      query: ({ userId, formData }) => ({
        url: `/pitchedUser/codeAssessment/codeAssessment/submission`,
        method: "POST",
        body: formData,
        params: { pitchedUserId: userId },
      }),
    }),
  }),
});

export const { useSubmitCodeAssessmentMutation } = extendedApi;