import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    addMembersToGroup: build.mutation({
      query: (body) => ({
        url: "/group/add/users",
        method: "POST",
        body,
      }),
    }),
  }),
});
export const { useAddMembersToGroupMutation } = extendedApi;
