import api from "api";

const extendedApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getToolTypeById: builder.query({
			query: (id) => ({
				url: `/toolType/get/toolTypeById?toolTypeId=${id}`,
				method: "GET",
			}),
		}),
	}),
});

export const { useLazyGetToolTypeByIdQuery } = extendedApi;
