import React, { useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Typography, Paper, TextField, Button, Grid } from "@mui/material";

// import { APP_PREFIX } from "router/routes";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { usePostIntervieweeInfoMutation } from "api/VideoInterview/postVideoInterviewUserInfo";
import { handleError } from "utils/error";
import { get } from "utils/lodash";
import { useDispatch } from "react-redux";
import {
  setStartTime,
  videoInterviewParticipantIdStore,
  videoInterviewStore1,
  videoInterviewTechQuestionStore,
  videoInterviewTechSet1Recieved,
  videoInterviewTechSet2Recieved,
} from "slices/videoInterviewSlice";
import { GET_SIZE } from "utils/responsive";
import { updateTimeLeft } from "slices/videoInterviewSlice";
import { isEmail } from "utils/validations";

const IntervieweeLoginForm = () => {
  const { isLg } = GET_SIZE();
  const [postUserInfo] = usePostIntervieweeInfoMutation();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    yourName: "",
    emailId: "",
    universityName: "",
    stream: "",
  });

  const [errors, setErrors] = useState({
    userNameErr: "",
    emailIdErr: "",
    universityNameErr: "",
    streamErr: "",
  });
  const dispatch = useDispatch();
  const { yourName, emailId, universityName, stream } = localState;
  const { userNameErr, emailIdErr, universityNameErr, streamErr } = errors;

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });

    // Clear the error when user starts typing again
    setErrors({ ...errors, [`${name}Err`]: "" });
  };

  const handleSubmit = () => {
    let isValid = true;
    const newErrors = { userNameErr: "", emailIdErr: "", universityNameErr: "", streamErr: "" };

    if (!yourName) {
      newErrors.userNameErr = "Name is required.";
      isValid = false;
    }

    if (!emailId) {
      newErrors.emailIdErr = "Email id is required.";
      isValid = false;
    }
    if (!isEmail(emailId)) {
      newErrors.emailIdErr = "Please enter a valid email id";
      isValid = false;
    }

    if (!universityName) {
      newErrors.universityNameErr = "College/University Name is required.";
      isValid = false;
    }
    if (!stream) {
      newErrors.streamErr = "Stream is required.";
      isValid = false;
    }

    setErrors(newErrors);

    if (isValid) {
      // navigate(`/app/screen-test`);
      // navigate(`/app/screen-test?ques=0`)
      const userInfoPayload = {
        name: yourName,
        email: emailId,
        stream: stream,
        collegeName: universityName,
      };
      postUserInfo(userInfoPayload)
        .unwrap()
        .then((res) => {
          dispatch(
            videoInterviewParticipantIdStore({
              // videoResponses: answersListCopy,
              participantDetails: get(res, "videoInterviewParticipant", null),
            }),
          );
          dispatch(updateTimeLeft({ timeLeft: 20 * 60 }));
          dispatch(setStartTime({ startTime: null }));
          dispatch(videoInterviewTechQuestionStore({ techQuestions: [] }));
          dispatch(videoInterviewTechSet1Recieved({ receivedTechSet1: false }));
          dispatch(videoInterviewTechSet2Recieved({ receivedTechSet2: false }));
          dispatch(videoInterviewStore1({ formSubmitted: false }));

          navigate(`/app/screen-test?ques=0`, { state: get(res, "videoInterviewParticipant", {}) });
        })
        .catch(handleError);
    }
  };
  return (
    <Paper elevation={2} sx={{ p: "25px 50px", minWidth: "250px", maxWidth: "600px", width: isLg ? "600px" : "auto" }}>
      <Typography variant="h5" textAlign="center" fontSize={22} fontWeight={"bold"}>
        {T.PLEASE_ENTER_YOUR_DETAILS.toUpperCase()}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" mt={2}>
            {T.YOUR_NAME}
          </Typography>

          <TextField
            placeholder={T.ENTER_YOUR_NAME_HERE}
            variant="outlined"
            name="yourName"
            value={yourName}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9.5px 14px",
                fontSize: 14,
              },
            }}
            fullWidth
            required
            onChange={handleChange}
            error={Boolean(userNameErr)}
            helperText={userNameErr}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" mt={2}>
            {T.EMAIL_ID}
          </Typography>
          <TextField
            placeholder={T.ENTER_YOUR_EMAIL_ID_HERE}
            variant="outlined"
            name="emailId"
            value={emailId}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9.5px 14px",
                fontSize: 14,
              },
            }}
            fullWidth
            required
            onChange={handleChange}
            error={Boolean(emailIdErr)}
            helperText={emailIdErr}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" mt={2}>
            {T.STREAM}
          </Typography>
          <TextField
            placeholder={T.ENTER_YOUR_STREAM_HERE}
            variant="outlined"
            name="stream"
            value={stream}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9.5px 14px",
                fontSize: 14,
              },
            }}
            fullWidth
            required
            onChange={handleChange}
            error={Boolean(streamErr)}
            helperText={streamErr}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1" mt={2}>
            {T.COLLEGE_UNIVERSITY_NAME}
          </Typography>
          <TextField
            placeholder={T.COLLEGE_UNIVERSITY_NAME}
            variant="outlined"
            name="universityName"
            value={universityName}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "9.5px 14px",
                fontSize: 14,
              },
            }}
            fullWidth
            required
            onChange={handleChange}
            error={Boolean(universityNameErr)}
            helperText={universityNameErr}
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        sx={{
          bgcolor: NETSMARTZ_THEME_COLOR,
          mt: 3,
          // mb: 2,\
          "&:hover": {
            backgroundColor: "themeColor", // Change to your theme color
          },
        }}
        fullWidth
        onClick={handleSubmit}
      >
        {T.START}
      </Button>
    </Paper>
  );
};

export default IntervieweeLoginForm;
