import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";
import CSVersionTableBody from "./CSVersionTableBody";
import CSVersionTableHeader from "./CSVersionTableHeader";
import MISNoData from "components/common/MISNoData";
import { PAGINATION } from "settings/constants/pagination";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { VERSIONS_HISTORY_HEADER } from "../caseStudyModel";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const CSVersionTable = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows = [],
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  downloadVersionFile = noop,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
      }}
    >
      <Box overflow="hidden">
        <TableContainer component={Paper} sx={{ height: "calc(100vh - 245px)", overflowY: "auto" }}>
          {allTableRows !== 0 ? (
            <TableView stickyHeader>
              <CSVersionTableHeader columns={VERSIONS_HISTORY_HEADER} />
              <CSVersionTableBody allTableRows={allTableRows} downloadVersionFile={downloadVersionFile} />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

export default CSVersionTable;
