import { Typography, Box, DialogActions, DialogContent } from "@mui/material";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { useReducer } from "react";
import MISDatePicker from "components/common/MISDatePicker";
import MISTimePicker from "components/common/MISTimePicker";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { usePreInterviewNotifyMutation } from "api/preSales/preinterviewnotify";
import { handleError } from "utils/error";
import { toast } from "react-toastify";

const PreInterviewNotification = ({
  handlePreInterviewDialog = noop,
  presalesMappingId,
  openPreInterviewDialog = false,
  userId,
  getPitchedMembers = noop,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    date: null,
    time: null,
    instructions: "",
  });

  const [preInterviewNotify] = usePreInterviewNotifyMutation();

  const { date, time, instructions } = localState;

  const getFormattedDate = (val) => {
    if (!val) {
      return null;
    }

    const formattedDate = new Date(val);

    if (isValid(formattedDate)) {
      return format(formattedDate, BACKEND_DATE_FORMAT);
    }

    return null;
  };

  const getFormattedTime = (val) => {
    if (!val) {
      return null;
    }

    const formattedTime = new Date(val);

    if (isValid(formattedTime)) {
      const hours = formattedTime.getHours();
      const minutes = formattedTime.getMinutes();
      return `${hours}:${minutes}`;
    }

    return null;
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "date") {
      setLocalState({ [name]: getFormattedDate(value) });
    } else if (name === "time") {
      setLocalState({ [name]: value });
    } else {
      setLocalState({ [name]: value });
    }
  };

  const handleSubmit = () => {
    const formattedTime = getFormattedTime(time);
    if (date && time) {
      const formData = {
        intimationDate: date,
        intimationTime: `${formattedTime}:00`,
        comments: instructions,
      };
      preInterviewNotify({ userId, presalesMappingId, formData })
        .then((res) => {
          if (res.error) {
            handleError(res.error);
          } else {
            toast.success("Pre-Interview Notification successfully sent.");
            handleClose();
            getPitchedMembers();
          }
        })
        .catch(handleError);
    } else {
      toast.error(T.PLEASE_FILL_ALL_REQUIRED_FEILDS);
    }
  };

  const handleClose = () => {
    handlePreInterviewDialog();
    setLocalState({ time: "", date: "", instructions: "" });
  };

  return (
    <MISDialog open={openPreInterviewDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", p: 1, width: 580 }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          <Box display="flex" alignItems="center" pl={1} pt={1.5}>
            <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={() => handleClose()} />
            <Typography variant="h6" fontWeight={700} ml={1}>
              {T.PRE_INTERVIEW_NOTIFICATION}
            </Typography>
          </Box>
        </Box>

        <DialogContent
          sx={{
            p: 1.4,
            m: 1,
            mt: 1,
            backgroundColor: "background.card",
            boxShadow: "none",
            flexGrow: 1,
            borderRadius: 1,
          }}
        >
          <MISDatePicker
            placeholder="Select Date"
            inputFormat="MM/DD/YYYY"
            value={date ? new Date(date) : null}
            label="Date"
            name="date"
            disablePast
            handleChange={(newValue) => handleChange({ target: { name: "date", value: newValue } })}
            required
          />
          <MISTimePicker
            placeholder="Select Time"
            value={time}
            label="Time"
            name="time"
            disabled={!date}
            handleChange={(newValue) => setLocalState({ time: newValue })}
            required
          />
          <MISTextField
            label="Instructions"
            fullWidth
            multiline
            rows={7}
            size="small"
            variant="outlined"
            name="instructions"
            value={instructions}
            onChange={handleChange}
          />
          <DialogActions>
            <MISFooterButton handleSubmit={handleSubmit} handleClose={handleClose} />
          </DialogActions>
        </DialogContent>
      </Box>
    </MISDialog>
  );
};

PreInterviewNotification.propTypes = {
  handlePreInterviewDialog: PropTypes.func,
  getPitchedMembers: PropTypes.func,
  userId: PropTypes.number,
  presalesMappingId: PropTypes.number,
  openPreInterviewDialog: PropTypes.bool,
};
export default PreInterviewNotification;
