import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAssignedCourseFilter: build.mutation({
      query: ({ page, rowPerPage, assignedTrainingFilterDto }) => ({
        url: `/trainee/filter?page=${page}&size=${rowPerPage}`,
        method: "POST",
        body: assignedTrainingFilterDto,
      }),
    }),
  }),
});

export const { useGetAssignedCourseFilterMutation } = extendedApi;
