import FolderOffIcon from "@mui/icons-material/FolderOff";
import { Grid, Typography, styled } from "@mui/material";
import { Container } from "@mui/system";
import T from "T";

const HeaderTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}));

const SubHeaderTypography = styled(Typography)(() => ({
  fontWeight: "normal",
  opacity: 0.5,
}));

const NetsmartzFolderOffIcon = styled(FolderOffIcon)(({ theme }) => ({
  color: theme.palette.themeColor,
  fontSize: "40px",
}));

const MISNoData = () => {
  return (
    <Grid
      container
      sx={{
        height: "-webkit-fill-available",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        opacity: 0.6,
      }}
    >
      <Grid item>
        <NetsmartzFolderOffIcon />
        <HeaderTypography variant="h5">{T.NO_RECORDS}</HeaderTypography>
        <SubHeaderTypography variant="subtitle">{T.NO_RECORDS_MESSAGE.toUpperCase()}</SubHeaderTypography>
      </Grid>
    </Grid>
  );
};
export default MISNoData;
