import { useState } from "react";
import { useEffect, useReducer } from "react";
import capitalize from "lodash/capitalize";
import { Box, Paper, Typography, Grid } from "@mui/material";
import MISLoader from "components/common/MISLoader";
import { useLazyGetUserViewByIdQuery } from "api/members/getUserViewById";
import T from "T";
import Constants from "Constants";
import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { get } from "utils/lodash";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";
import { useLazyGetUserNcHistoryQuery } from "api/members/getUserNcHistory";
import { useLazyGetFeedbackByIdQuery } from "api/members/getFeedbacks";
import { useLazyGetUserInterviewHistoryQuery } from "api/members/getUserInterviewHistory";
import { useLazyGetUserTrainingHistoryQuery } from "api/members/getUserTrainingHistory";
import ChipsFilter from "./ChipsFilter";
import { useSelector } from "react-redux";
import TechnicalHistory from "./Technical/TechnicalHistory";
import InterviewHistory from "./Interview/InterviewHistory";
import FeedbackHistory from "./Feedback/FeedbackHistory";
import TrainingHistory from "./Training/TrainingHistory";
import ProjectHistory from "./Project/ProjectHistory";
import NCHistory from "./NC/NCHistory";
import styled from "@emotion/styled";
import UploadAvatar from "./UploadAvatar";
import { useLazyGetUserByIdQuery } from "api/members/getUserById";
import SWOT from "./Swot";
import { useTheme } from "@emotion/react";
import { useLazyGetExistingResponsesQuery } from "api/MockInterview/getExistingResponses";
import InterviewTable from "./MockInterview/Table";
import { useLazyGetSpecialIncidentsQuery } from "api/members/SIR/getSpecialIncident";
import SIRTable from "./SIR/Table";
import { endOfMonth, format, intervalToDuration, isValid, startOfMonth } from "date-fns";
import ActiveNcList from "./NC/NcLists/ActiveNcList";
import DeletedNcList from "./NC/NcLists/DeletedNcList";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";

const { SERVER_URL } = Constants;
const TypingAnimation = styled.span`
  overflow: hidden;
  white-space: nowrap;
  width: ${(props) => props.width};
  animation: typing ${(props) => props.typingSpeed} infinite alternate;

  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: ${(props) => props.width};
    }
  }
`;
export const TitleTypography = styled(Typography)(({ rest }) => ({
  fontWeight: 500,
  fontSize: 13.5,
  lineHeight: "22px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
  paddingRight: rest?.paddingRight,
}));
export const SubTitleTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 13.5,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));
const EmployeeProfile = () => {
  const [typedText, setTypedText] = useState("");
  const typingSpeed = 70;
  const theme = useTheme();
  const { isXs } = GET_SIZE();
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    startDate: getBEDateFormat(startOfMonth(new Date())),
    endDate: getBEDateFormat(endOfMonth(new Date())),
    startMonth: null,
    endMonth: null,
    feedbackAddition: false,
    projectId: "",
    ncId: "",
    selectedMonth: "",
    typeOfNc: "",
    assignedCourse: false,
    exportLoading: false,
    openDeleteNC: false,
    openActiveNcCountList: false,
    openDeleteNcCountList: false,
    ncHistory: [],
    interviewHistory: [],
    trainingHistory: [],
    swotData: [],
    mockData: [],
    sirData: [],
    selectedHistories: [T.INTERVIEW_HISTORY],
    avatarSrc: "",
  });

  const {
    startDate,
    endDate,
    feedbackAddition,
    selectedMonth,
    typeOfNc,
    exportLoading,
    openDeleteNC,
    openActiveNcCountList,
    openDeleteNcCountList,
    ncHistory,
    interviewHistory,
    trainingHistory,
    selectedHistories,
    avatarSrc,
    swotData,
    mockData,
    sirData,
  } = localState;

  const loginSelector = useSelector((state) => state.LoginSlice.user);

  const id = loginSelector.user.id;
  const [getUserViewById, { isFetching, data: viewData }] = useLazyGetUserViewByIdQuery();
  const [getFeedbackById, { data: DataFeed }] = useLazyGetFeedbackByIdQuery();
  const tableRecord = get(DataFeed, "results", []);
  const [getUserNcHistory] = useLazyGetUserNcHistoryQuery();
  const [getUserInterviewHistory] = useLazyGetUserInterviewHistoryQuery();
  const [getUserTrainingHistory] = useLazyGetUserTrainingHistoryQuery();
  const [getUserById] = useLazyGetUserByIdQuery();
  const [getExistingResponses] = useLazyGetExistingResponsesQuery();
  const [getSpecialIncidents] = useLazyGetSpecialIncidentsQuery();

  const refreshView = () => {
    getUserViewById({ id })
      .unwrap()
      .then((res) => {
        const userProfilePic = get(res, "results.userProfilePic", "");
        setLocalState({ avatarSrc: userProfilePic });
      })
      .catch(handleError);
  };
  useEffect(() => {
    refreshView();
  }, []);

  useEffect(() => {
    getUserById({ id })
      .unwrap()
      .then((res) => {
        const results = get(res, "results", []);
        const swotData = get(results, "swotAnalysisResponseDto", null);
        setLocalState({ swotData });
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    getExistingResponses({ userId: id })
      .unwrap()
      .then((res) => {
        const mockInterviewLevels = get(res, "mockInterviewLevels", []);
        const createdAt = get(res, "createdAt", []);
        setLocalState({ mockData: { mockInterviewLevels, createdAt } });
      })
      .catch(handleError);
  }, []);
  useEffect(() => {
    getSpecialIncidents({ userId: id })
      .unwrap()
      .then((res) => {
        setLocalState({ sirData: res });
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    if (initialNcHistory) {
      getNCHistory(id, startDate, endDate);
    }
    const userId = id;
    const pmId = "";
    getFeedbackById({ userId, pmId });
  }, []);

  const handleChipChange = (updatedHistories) => {
    setLocalState({ selectedHistories: updatedHistories });
  };

  const record = get(viewData, "results", []);
  const fullName = get(record, "userName", "");
  const fullText = "Welcome back, " + fullName.split(" ")[0];
  const empCode = get(record, "empCode", "");
  const department = get(record, "department", "");
  const designation = get(record, "designation", "");
  const reportingManager = get(record, "reportingManager", "-");
  const nonWorkingDays = get(record, "nonWorkingDays", ["-"]);
  const email = get(record, "email", "");
  const phone = get(record, "mobileNo", "");
  const experience = `${get(record, "expInYears", 0)}.${get(record, "expInMonths", 0)} ${T.YEARS.toLowerCase()}`;
  const jiraFrequency = get(record, "jiraFrequency", "-");
  const joiningDate = get(record, "joiningDate", "");
  const projectHistory = get(record, "projectHistory", []);
  const technicalHistory = get(record, "technicalHistory", []);
  const initialNcHistory = get(record, "ncHistory", []);

  useEffect(() => {
    if (startDate && endDate) {
      getNCHistory(id, startDate, endDate);
    } else {
      setLocalState({ ncHistory: initialNcHistory });
    }
  }, [startDate, endDate]);
  const getNCHistory = (id, startDate, endDate) => {
    getUserNcHistory({ id, startDate, endDate })
      .unwrap()
      .then((res) => {
        setLocalState({ ncHistory: get(res, "results", []) });
      })
      .catch(handleError);
  };

  useEffect(() => {
    let currentIndex = 0;
    const intervalId = setInterval(() => {
      if (currentIndex <= fullText.length) {
        setTypedText(fullText.substring(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, typingSpeed);

    return () => clearInterval(intervalId);
  }, [fullName, fullText, typingSpeed]);

  useEffect(() => {
    if (email) {
      getUserInterviewHistory({ email })
        .unwrap()
        .then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            setLocalState({ interviewHistory: res });
          }
        })
        .catch(handleError);
    }
    if (id) {
      getUserTrainingHistory({ id })
        .unwrap()
        .then((res) => {
          setLocalState({ trainingHistory: res });
        })
        .catch(handleError);
    }
  }, [email, id]);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const { sessionToken } = MISCurrentUser();
  const handleExport = async (type) => {
    setLocalState({ exportLoading: true });
    fetch(`${SERVER_URL}/feedback/project/export?id=${id}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, type);

        setLocalState({ exportLoading: false });
      })
      .catch(handleError);
  };

  const handleDeleteNCDialog = (id = "") => {
    setLocalState({
      openDeleteNC: !openDeleteNC,
      ncId: id,
    });
  };

  // Active user NC list open handler
  const handleActiveNcCountDialog = (selectedMonth = "", typeOfNc = "") => {
    setLocalState({
      openActiveNcCountList: !openActiveNcCountList,
      selectedMonth,
      typeOfNc,
    });
  };

  const handleDeleteNcCountDialog = (selectedMonth = "", typeOfNc = "") => {
    setLocalState({
      openDeleteNcCountList: !openDeleteNcCountList,
      selectedMonth,
      typeOfNc,
    });
  };

  const handleAddFeedbackDialog = (id) => {
    setLocalState({ feedbackAddition: !feedbackAddition, projectId: id });
  };

  const handleAddFeedback = (id) => {
    handleAddFeedbackDialog(id);
  };

  const totalDuration = joiningDate
    ? intervalToDuration({
        start: new Date(joiningDate.split(" ")[0]),
        end: new Date(),
      })
    : "";
  const onHandleDateChange = (newValue, type) => {
    const validDate = new Date(newValue);
    if (isValid(validDate)) {
      setLocalState({ [type]: format(validDate, BACKEND_DATE_FORMAT) });
    }
  };
  return (
    <Paper sx={{ p: 3, px: 4, height: "calc(100vh - 177px)", borderRadius: 3, overflow: "auto" }}>
      {(isFetching || exportLoading) && <MISLoader />}
      <Box
        mb={3}
        sx={{
          mx: {
            md: -1.8,
            xs: 0,
          },
        }}
        display={"flex"}
        justifyContent={"space-between"}
      >
        <ChipsFilter onChange={handleChipChange} selectedHistories={selectedHistories} />
      </Box>

      <Grid container>
        <Grid item md={9} xs={12}>
          <Grid container direction="column">
            <Grid container mb={2} alignItems="center" spacing={1.5}>
              <Grid
                item
                sx={{
                  [theme.breakpoints.only("xs")]: {
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  },
                  [theme.breakpoints.only("md")]: {},
                }}
              >
                <UploadAvatar alt={fullName} image={avatarSrc} userId={id} refreshView={refreshView} isFetching={isFetching} />
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 22,
                      md: 24,
                    },
                  }}
                  fontWeight={700}
                >
                  <TypingAnimation width={fullText.length * 1.2 + "ch"} typingSpeed={typingSpeed * fullText.length}>
                    {typedText}
                  </TypingAnimation>
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box display="flex">
                  <SubTitleTypography>{T.EMP_CODE}:</SubTitleTypography>
                  <TitleTypography ml={1}>{empCode}</TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.DEPARTMENT}:</SubTitleTypography>
                  <TitleTypography ml={1}>{department}</TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.DESIGNATION}:</SubTitleTypography>
                  <TitleTypography ml={1}>{designation}</TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.REPORTING_MANAGER}:</SubTitleTypography>
                  <TitleTypography ml={1}>{reportingManager}</TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.NON_WORKING_DAYS}:</SubTitleTypography>
                  <TitleTypography ml={1}>{nonWorkingDays.map((item) => capitalize(item)).join(", ")}</TitleTypography>
                </Box>
              </Grid>

              <Grid item md={6} xs={12}>
                <Box display="flex">
                  <SubTitleTypography>{T.EMAIL_ID}:</SubTitleTypography>
                  <TitleTypography ml={1} noWrap maxWidth={isXs ? 205 : "inherit"}>
                    {email}
                  </TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{`${T.PHONE} ${T.NUMBER}`}:</SubTitleTypography>
                  <TitleTypography ml={1}>{phone}</TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.TENURE_WITH_NETSMARTZ}:</SubTitleTypography>
                  <TitleTypography ml={1} noWrap maxWidth={isXs ? 100 : "inherit"}>
                    {`${get(totalDuration, "years", "")}.${get(totalDuration, "months", "")} years`}
                  </TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.TOTAL_EXPERIENCE}:</SubTitleTypography>
                  <TitleTypography ml={1}>{experience}</TitleTypography>
                </Box>

                <Box display="flex" mt={0.5}>
                  <SubTitleTypography>{T.FREQUENCY_OF_JIRA}:</SubTitleTypography>
                  <TitleTypography ml={1}>{jiraFrequency}</TitleTypography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={3} xs={12} display={"flex"} justifyContent={{ md: "flex-end" }} mt={2}>
          <TechnicalHistory results={technicalHistory} />
        </Grid>
      </Grid>
      {selectedHistories.map((history) => {
        switch (history) {
          case T.INTERVIEW_HISTORY:
            return (
              <InterviewHistory
                allTableRows={interviewHistory}
                handleAddFeedback={handleAddFeedback}
                handleExport={handleExport}
              />
            );

          case T.FEEDBACK_HISTORY:
            return <FeedbackHistory tableRecord={tableRecord} />;

          case T.TRAINING_HISTORY:
            return <TrainingHistory tableRecord={trainingHistory} />;
          case T.PROJECT_HISTORY:
            return (
              <ProjectHistory allTableRows={projectHistory} handleAddFeedback={handleAddFeedback} handleExport={handleExport} />
            );
          case T.NC_HISTORY:
            return (
              <>
                <NCHistory
                  startDate={startDate}
                  endDate={endDate}
                  allTableRows={ncHistory}
                  refreshNCTable={refreshView}
                  handleDeleteNCDialog={handleDeleteNCDialog}
                  handleActiveNcCountDialog={handleActiveNcCountDialog}
                  handleDeleteNcCountDialog={handleDeleteNcCountDialog}
                  onHandleChange={onHandleChange}
                  handleExport={handleExport}
                  onHandleDateChange={onHandleDateChange}
                />

                <ActiveNcList
                  openActiveNcCountList={openActiveNcCountList}
                  selectedUserName={fullName}
                  userId={id}
                  endDate={endDate}
                  startDate={startDate}
                  selectedMonth={selectedMonth}
                  typeOfNc={typeOfNc}
                  refreshView={refreshView}
                  handleActiveNcCountDialog={handleActiveNcCountDialog}
                />
                <DeletedNcList
                  openDeleteNcCountList={openDeleteNcCountList}
                  selectedUserName={fullName}
                  userId={id}
                  endDate={endDate}
                  startDate={startDate}
                  selectedMonth={selectedMonth}
                  typeOfNc={typeOfNc}
                  refreshView={refreshView}
                  handleDeleteNcCountDialog={handleDeleteNcCountDialog}
                />
              </>
            );
          case T.SWOT_ANALYSIS:
            return <SWOT swotData={swotData} />;
          case T.MOCK_ASSESSMENT:
            return <InterviewTable mockData={mockData} />;
          case T.SIR:
            return <SIRTable sirData={sirData} />;
          default:
            return (
              <InterviewHistory
                allTableRows={interviewHistory}
                handleAddFeedback={handleAddFeedback}
                handleExport={handleExport}
              />
            );
        }
      })}
    </Paper>
  );
};

export default EmployeeProfile;
