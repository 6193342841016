import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { MISCurrentUser } from "utils/validations";
import UploadIcon from "@mui/icons-material/Upload";
import AddLinkIcon from "@mui/icons-material/AddLink";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DownloadIcon from "@mui/icons-material/Download";
import { TableRow, TableCell, Typography, TableBody, styled, Button, Tooltip, Grid } from "@mui/material";

import IconButton from "@mui/material/IconButton";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import T from "T";
import { canEditCaseStudy } from "utils/permissions";
import { useIsPublishedCaseStudyMutation } from "api/CaseStudy/IsPublishedCaseStudy";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import MISNoData from "components/common/MISNoData";
import { StyledBox } from "utils/members";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import { useNavigate } from "react-router-dom";
import { downloadCaseStudyFile } from "../caseStudyModel";
import Constants from "Constants";
const { SERVER_URL } = Constants;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 10px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));
const CaseStudyTableBody = ({
  allTableRows = [],
  onClickReminder = noop,
  refreshTable = noop,
  handleCaseStudyUploadLinkDialog = noop,
  handleOpenCaseStudyUpload = noop,
  handleRejectCaseStudyDialog = noop,
}) => {
  const navigate = useNavigate();
  const [IsPublishedCaseStudy] = useIsPublishedCaseStudyMutation();
  const { user, sessionToken } = MISCurrentUser();

  const userRole = get(user, "role", "");
  // const disableCondition = (record) => !["Pending", "Rejected"].includes(get(record, "caseStudyStatus", ""));

  const publishCaseStudy = (csId) => {
    const payload = {
      isPublished: true,
    };
    IsPublishedCaseStudy({ payload, csId })
      .unwrap()
      .then((res) => {
        if (res.status === "OK") {
          refreshTable();
          toast.success(T.CASE_STUDY_PUBLISHED);
        } else {
          toast.error(T.REQUEST_NOT_FULFILLED);
        }
      })
      .catch(handleError);
  };
  const downloadCaseStudy = async (id, role) => {
    const url = `${SERVER_URL}/caseStudy/download?caseStudyId=${id}`;
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      downloadCaseStudyFile(response);
      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenCSVersions = (id) => {
    navigate(`/app/case-study/${id}/versions`);
  };

  const openPublishLink = (publishedLink) => {
    if (publishedLink !== null) {
      window.open(publishedLink, "_blank");
      return;
    }
    return toast.error("Link is not published yet");
  };
  const getColor = (status) => {
    if ([T.PUBLISHED, T.PMO_APPROVED].includes(status)) return "green";
    else if ([T.SUBMITTED_BY_PM, T.SUBMITTED_BY_MT].includes(status)) return NETSMARTZ_THEME_COLOR;
    else if ([T.PENDING].includes(status)) return "#E7AE2B";
    else return "red";
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const { caseStudyStatus } = record;
          const publishedLink = get(record, "publishedLink", null);
          const disableCondition = () => {
            if (userRole === T.PM && [T.PENDING, T.PMO_REJECTED].includes(caseStudyStatus)) {
              return false;
            } else if (userRole === T.MT && [T.SUBMITTED_BY_PM, T.PMO_APPROVED].includes(caseStudyStatus)) {
              return false;
            } else {
              return true;
            }
          };
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} key={index}>
              <StyledTableCell>{index + 1}.</StyledTableCell>
              <StyledTableCell>{get(record, "project.name", "")}</StyledTableCell>
              {/* {userRole === T.PMO && (
              <StyledTableCell>
                <Tooltip title={T.REMINDER_BUTTON} placement="top">
                  <IconButton
                    size="small"
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                      "&:hover": {
                        color: "#460000",
                      },
                    }}
                    disabled={disableCondition(record)}
                    onClick={() => onClickReminder(get(record, "id", ""))}
                  >
                    <AddAlertIcon />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
            )} */}
              <StyledTableCell>{get(record, "projectManagerId.name", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "projectStartDate", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "caseStudyDueDate", "")}</StyledTableCell>
              <StyledTableCell align="center">
                <Tooltip title={T.OPEN_PUBLISHED_LINK} placement="top">
                  <IconButton disabled={caseStudyStatus !== T.PUBLISHED} onClick={() => openPublishLink(publishedLink)}>
                    <AddLinkIcon
                      sx={{
                        mr: 1,
                        // height: 17,
                        cursor: "pointer",
                        color: caseStudyStatus !== T.PUBLISHED ? "grey" : NETSMARTZ_THEME_COLOR,
                      }}
                    />
                  </IconButton>
                </Tooltip>
              </StyledTableCell>
              <StyledTableCell align="center">
                <label htmlFor={`fileOutput_${record.id}`}>
                  <Tooltip title={T.DOWNLOAD_FILE} placement="top">
                    <IconButton disabled={[T.PM].includes(userRole) || [T.PENDING].includes(caseStudyStatus)}>
                      <DownloadIcon
                        onClick={() => downloadCaseStudy(record.id, userRole.toLowerCase())}
                        fontSize="small"
                        sx={{
                          mr: 1,
                          cursor: "pointer",
                          color:
                            [T.PM].includes(userRole) || [T.PENDING].includes(caseStudyStatus) ? "grey" : NETSMARTZ_THEME_COLOR,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </label>
              </StyledTableCell>
              <StyledTableCell align="center">
                {caseStudyStatus === T.PMO_REJECTED ? (
                  <Tooltip title={get(record, "pmoComment", "")} placement="top">
                    <Typography sx={{ color: getColor(caseStudyStatus), fontSize: 14, cursor: "pointer" }}>
                      {get(record, "caseStudyStatus", "")}
                    </Typography>
                  </Tooltip>
                ) : (
                  <Typography sx={{ color: getColor(caseStudyStatus), fontSize: 14 }}>
                    {get(record, "caseStudyStatus", "")}
                  </Typography>
                )}
              </StyledTableCell>
              {/* <StyledTableCell align="center">
                {get(record, "published", false) ? (
                  <CheckIcon></CheckIcon>
                ) : userRole === T.PMKRA ? (
                  <Button
                    variant="outlined"
                    onClick={() => publishCaseStudy(get(record, "id", ""))}
                    sx={{
                      ml: 2,
                      borderColor: BACKGROUND.black,
                      color: BACKGROUND.black,
                      "&:hover": {
                        borderColor: NETSMARTZ_THEME_COLOR,
                        color: NETSMARTZ_THEME_COLOR,
                      },
                    }}
                    disabled={get(record, "caseStudyStatus", "") !== T.COMPLETED}
                  >
                    {T.MARK_AS_PUBLISHED}
                  </Button>
                ) : (
                  <ClearIcon></ClearIcon>
                )}
              </StyledTableCell> */}
              <StyledTableCell>{get(record, "createdBy", "")}</StyledTableCell>
              <StyledTableCell>{get(record, "updatedBy", "")}</StyledTableCell>
              <StyledTableCell align="center">
                <Grid sx={{ display: "flex" }}>
                  {
                    <Tooltip title={T.VERSIONS_HISTORY} placement="top">
                      <IconButton
                        disabled={!get(record, "isHistoryPresent", false)}
                        onClick={() => handleOpenCSVersions(record.id)}
                      >
                        <BackupTableIcon
                          sx={{
                            cursor: "pointer",
                            color: !get(record, "isHistoryPresent", false) ? "grey" : NETSMARTZ_THEME_COLOR,
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  }
                  {canEditCaseStudy() ? (
                    <>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleRejectCaseStudyDialog(get(record, "id", ""), "accept")}
                        sx={{
                          ml: 2,
                          bgcolor: NETSMARTZ_THEME_COLOR,
                          color: "white",
                          "&:hover": {
                            background: NETSMARTZ_THEME_COLOR,
                          },
                        }}
                        disabled={get(record, "caseStudyStatus", "") !== T.SUBMITTED_BY_MT}
                      >
                        {T.ACCEPT}
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleOpenCaseStudyUpload(record.id)}
                        sx={{
                          ml: 1,
                          bgcolor: NETSMARTZ_THEME_COLOR,
                          color: "white",
                          "&:hover": {
                            background: NETSMARTZ_THEME_COLOR,
                          },
                        }}
                        disabled={get(record, "caseStudyStatus", "") !== T.SUBMITTED_BY_MT}
                      >
                        {T.REJECT}
                      </Button>
                    </>
                  ) : (
                    <label htmlFor={`fileInput_${record.id}`}>
                      <Tooltip
                        sx={{ display: "flex" }}
                        title={
                          userRole === T.MT && get(record, "caseStudyStatus", "") === T.PMO_APPROVED
                            ? T.PUBLISH_CASE_STUDY
                            : T.UPLOAD_FILE
                        }
                        placement="top"
                      >
                        <IconButton
                          disabled={disableCondition()}
                          onClick={() =>
                            handleOpenCaseStudyUpload(
                              record.id,
                              userRole === T.MT && get(record, "caseStudyStatus", "") === T.PMO_APPROVED,
                            )
                          }
                        >
                          <UploadIcon
                            fontSize="small"
                            sx={{
                              pt: 0.5,
                              mx: 1,
                              cursor: "pointer",
                              color: disableCondition() ? "grey" : NETSMARTZ_THEME_COLOR,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </label>
                  )}
                </Grid>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

CaseStudyTableBody.propTypes = {
  allTableRows: PropTypes.array,
  pName: PropTypes.string,
  refreshTable: PropTypes.func,
  handleRejectCaseStudyDialog: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  onClickReminder: PropTypes.func,
};

export default memo(CaseStudyTableBody);
