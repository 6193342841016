import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
// import ProfitCenterMutation from "./ProfitCenterMutation";
import ContractHistoryTable from "./ContractHistoryTable";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import T from "T";

const ContractHistory = ({
  contractHistoryData = [],
  isOpenContractHistory = bool,
  isOpenContractDelete = bool,
  licenseData = {},
  handleUploadContract = noop,
  handleClose = noop,
  handleOpenConfirmDelete = noop,
  handleCloseConfirmDelete = noop,
  handleContractDelete = noop,
}) => {
  const columns = [
    { label: "S.no", key: "id" },
    { label: "File Name", key: "contractPath" },
    { label: "Created By", key: "createdBy" },
    { label: "Created At", key: "createdAt" },
    { label: "Actions", key: "actions" },
  ];
  return (
    <>
      <ContractHistoryTable
        columns={columns}
        contractHistoryData={contractHistoryData}
        isOpenContractHistory={isOpenContractHistory}
        licenseData={licenseData}
        handleClose={handleClose}
        handleUploadContract={handleUploadContract}
        handleOpenConfirmDelete={handleOpenConfirmDelete}
      />
      <ConfirmSubmit
        proceedButtonText={T.DELETE}
        openConfirmSubmit={isOpenContractDelete}
        handleClose={() => handleCloseConfirmDelete()}
        handleSubmit={() => handleContractDelete()}
      />
    </>
  );
};

ContractHistory.propTypes = {
  contractHistoryData: PropTypes.arrayOf(PropTypes.object),
  isOpenContractHistory: PropTypes.bool,
  isOpenContractDelete: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpenConfirmDelete: PropTypes.func,
  handleCloseConfirmDelete: PropTypes.func,
  handleContractDelete: PropTypes.func,
};

export default ContractHistory;
