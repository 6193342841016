import { Box } from "@mui/system";
import { useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Paper, Tooltip, Typography } from "@mui/material";
import T from "T";
import { handleError } from "utils/error";
import MISLoader from "components/common/MISLoader";
import { toast } from "react-toastify";
import MockCommentsDialog from "./MockCommentsDialog";
import MISTable from "./Table";
import { useLazyGetAllMockInterviewsQuery } from "api/MockAssessment/getAllMockInterviews";
import Constants from "Constants";
import { useDeleteRecordingsMutation } from "api/MockAssessment/deleteRecordings";
import CSV from "assets/CSV.png";
import { MISCurrentUser } from "utils/validations";
const { SERVER_URL } = Constants;

const MockAssessment = () => {
  const { sessionToken } = MISCurrentUser();
  const navigate = useNavigate();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openDialog: false,
    tableData: [],
    mock_id: null,
    comments: "",
    openDeleteModal: false,
  });
  const { tableData, openDialog, mock_id, comments, openDeleteModal } = localState;

  const [getAllMockInterviews, { isFetching }] = useLazyGetAllMockInterviewsQuery();
  const [deleteRecordings] = useDeleteRecordingsMutation();

  useEffect(() => {
    refreshPage();
  }, []);

  const refreshPage = () => {
    getAllMockInterviews()
      .unwrap()
      .then((res) => {
        setLocalState({ tableData: res });
      })
      .catch(handleError);
  };

  const handleExport = async () => {
    try {
      const currentDate = new Date().toISOString().slice(0, 10);
      const response = await fetch(`${SERVER_URL}/ai/mock/interview/download/csv`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "content-type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `mock_interviews_${currentDate}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      toast.success("CSV Downloaded Successfully.");
    } catch (error) {
      handleError(error);
    }
  };

  const handleDeleteRecordings = () => {
    deleteRecordings(mock_id)
      .unwrap()
      .then(() => {
        toast.success("Recordings Deleted Successfully.");
        refreshPage();
        handleDeleteModal();
      })
      .catch(handleError);
  };
  const handleDeleteModal = (mock_id) => {
    setLocalState({ openDeleteModal: !openDeleteModal, mock_id: mock_id });
  };

  const handleAddComment = (mock_id, comments) => {
    setLocalState({ openDialog: !openDialog, mock_id: mock_id, comments: comments });
  };

  const handleDownloadRecordings = (mock_id) => {
    const url = `${SERVER_URL}/ai/mock/interview/download/audioFiles?mock_id=${mock_id}`;
    try {
      window.location.href = url;
      toast.success("Recordings Downloaded Successfully.");
    } catch (error) {
      handleError(error);
    }
  };

  const handleViewAssessmentResults = (mock_id) => {
    navigate("assessment-result", { state: mock_id });
  };

  return (
    <Paper display="block" sx={{ p: 2 }}>
      {isFetching && <MISLoader />}
      <Box display="flex" mb={1} justifyContent={"space-between"}>
        <Box display="flex" alignItems="center">
          <Typography variant="h5" fontWeight={600} mb={1}>
            {T.MOCK_ASSESSMENT}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Tooltip title={T.CSV} placement="top">
            <Box sx={{ height: 45, cursor: "pointer" }} component="img" src={CSV} onClick={handleExport} />
          </Tooltip>
        </Box>
      </Box>
      <MISTable
        tableRecord={tableData}
        handleDeleteRecordings={handleDeleteRecordings}
        handleDownloadRecordings={handleDownloadRecordings}
        handleViewAssessmentResults={handleViewAssessmentResults}
        handleAddComment={handleAddComment}
        handleDeleteModal={handleDeleteModal}
        openDeleteModal={openDeleteModal}
      />
      <MockCommentsDialog
        handleAddComment={handleAddComment}
        openDialog={openDialog}
        mock_id={mock_id}
        existingComment={comments}
        refreshPage={refreshPage}
      />
    </Paper>
  );
};
export default MockAssessment;
