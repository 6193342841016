import { Children, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  TextareaAutosize,
  Rating,
  Checkbox,
  FormControlLabel,
  TableRow,
  DialogTitle,
  TableCell,
  TableBody,
  TableHead,
  IconButton,
  DialogContent,
  DialogActions,
  Table,
  Radio,
  FormLabel,
  RadioGroup,
  Button,
  TextField,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import { MISCurrentUser } from "utils/validations";
import T from "T";
import MISDialog from "components/common/MISDialog";
import { TitleTypography } from "utils/members";
import { get } from "lodash";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import styled from "@emotion/styled";
import MISFooterButton from "components/common/MISFooterButton";
import Constants from "Constants";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useLazyGenerateMailQuery } from "api/training/assignCourse/generateMail";
import LocalPostOfficeIcon from "@mui/icons-material/LocalPostOffice";
import { attendanceTypeList } from "components/Training/trainingModal";
import { orange } from "@mui/material/colors";
import { useLazyGenerateCertificateQuery } from "api/training/assignCourse/generateCertificate";
import { useReviewCourseMutation } from "api/training/assignCourse/assignmentReview";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
// import { generate_Certificate } from "utils/commonFunction";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  ...theme.typography.subtitle1,
}));
export const KeyTypography = styled(Typography)(() => ({
  fontWeight: 600,
  fontSize: 16,
  lineHeight: "22px",
  whiteSpace: "nowrap",
}));

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));
const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const grey = {
  50: "#f6f8fa",
  100: "#eaeef2",
  200: "#d0d7de",
  300: "#afb8c1",
  400: "#8c959f",
  500: "#6e7781",
  600: "#57606a",
  700: "#424a53",
  800: "#32383f",
  900: "#24292f",
};

const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 320px;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 12px;
    border-radius: 12px 12px 0 12px;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
    background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
    box-shadow: 0px 2px 24px ${theme.palette.mode === "dark" ? blue[900] : blue[100]};

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
);
const { SERVER_URL } = Constants;

const ReviewModal = ({
  openReview = false,
  assignData = {},
  onHandleClose = noop,
  handleEmptyTask = noop,
  refreshTable = noop,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    attendanceType: "",
    assignmentReview: { subCourseIds: [], rating: "", comment: "" },
    reviewData: [],
  });

  const { attendanceType, assignmentReview, reviewData } = localState;

  const { sessionToken } = MISCurrentUser();

  const [generateMail] = useLazyGenerateMailQuery();

  const [generateCertificate] = useLazyGenerateCertificateQuery();

  const [reviewCourse] = useReviewCourseMutation();

  const courseTypeName = get(assignData, "trainingCourse.assignmentType.courseAssignmentType", "");
  const courseName = get(assignData, "trainingCourse.courseName", "");
  const assignCourseId = get(assignData, "id", "");
  const subCourseName = get(assignData, "subsCourses", []);
  let offlineCompletionLink = null;
  let offlineCompletionFile = null;
  const userName = get(assignData, "userId.userName", "");

  const generate_Certificate = (id) => {
    generateCertificate({ id })
      .unwrap()
      .then(() => {
        toast.success(T.CERTIFICATE_GENERATED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const handleAttendanceRadioChange = (event) => {
    setLocalState({ attendanceType: event.target.value });
  };

  const generate_Mail = (id) => {
    generateMail({ id })
      .unwrap()
      .then(() => {
        toast.success(T.MAIL_GENERATED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  const handleBack = () => {
    setLocalState({ assignmentReview: { subCourseIds: [], rating: "", comment: "" } });
    onHandleClose();
    handleEmptyTask();
  };

  const handleSaveReviewDialog = () => {
    const updatedSubCourseIds = assignData.subsCourses.filter((value) => value.status === T.COMPLETED).map((value) => value.id);

    const updatedAssignmentReview = {
      ...assignmentReview,
      subCourseIds: [...assignmentReview.subCourseIds, ...updatedSubCourseIds],
    };

    setLocalState({
      ...localState,
      assignmentReview: updatedAssignmentReview,
    });

    const payload = {
      subCourseAssignmentMappingIds: assignmentReview.subCourseIds,
      trainingAssignmentId: assignData.id,
      offlineTrainingReview: attendanceType,
      skillRate: assignmentReview.rating ? parseFloat(assignmentReview.rating) : 0,
      comments: assignmentReview.comment,
      isSaved: true,
    };

    reviewCourse(payload)
      .unwrap()
      .then(() => {
        setLocalState({
          assignmentReview: {
            subCourseIds: [],
            rating: "",
            comment: "",
          },
          attendanceType: "",
        });
        toast.success(T.COURSE_REVIEWED_SUCCESSFULLY);

        handleReview();
      })
      .catch(handleError);
  };

  const handleComplete = (index, value, reviewData, type) => {
    const currSubStatus = reviewData[index].subStatus;

    const updatedSubStatus =
      type === "accept" ? ([0, -1].includes(currSubStatus) ? 1 : -1) : [-1, 1].includes(currSubStatus) ? 0 : -1;

    const updatedReviewData = [...reviewData];
    updatedReviewData[index].subStatus = updatedSubStatus;
    updatedReviewData[index].rejComment = updatedSubStatus !== 0 ? "" : reviewData[index].rejComment;

    const id = reviewData[index].id;

    const updatedSubCourseIds =
      value || updatedSubStatus !== -1
        ? assignmentReview.subCourseIds.includes(id)
          ? assignmentReview.subCourseIds
          : [...assignmentReview.subCourseIds, id]
        : assignmentReview.subCourseIds.filter((subId) => subId !== id);

    const updatedAssignmentReview = { ...assignmentReview, subCourseIds: updatedSubCourseIds };

    setLocalState({
      ...localState,
      reviewData: updatedReviewData,
      assignmentReview: updatedAssignmentReview,
    });
  };

  const handleRejectComment = (e, index) => {
    const { value } = e.target;
    const updatedReviewData = [...reviewData];
    updatedReviewData[index].rejComment = value;
    setLocalState({ reviewData: updatedReviewData });
  };

  const handleReview = () => {
    const labelList = attendanceTypeList.map((attendanceType) => attendanceType.label);
    const attendanceStatus = labelList.includes(get(assignData, "attendance", ""));
    const comments = get(assignData, "comments", "");
    const skillRate = get(assignData, "skillRate", 0);
    const attendanceTypeName = attendanceStatus ? get(assignData, "attendance", "") : "";
    const data = assignData?.subsCourses?.map((value) => {
      return {
        id: value?.id,
        // checked: false,
        subStatus: -1,
        rejComment: "",
      };
    });
    assignData &&
      setLocalState({
        attendanceType: attendanceTypeName,
        reviewData: data,
        assignmentReview: {
          ...assignmentReview,
          rating: skillRate,
          comment: comments,
        },
      });
  };

  const handleCompleteReview = () => {
    const updatedSubCourseIds = assignData.subsCourses.filter((value) => value.status === T.COMPLETED).map((value) => value.id);

    const updatedAssignmentReview = {
      ...assignmentReview,
      subCourseIds: [...assignmentReview.subCourseIds, ...updatedSubCourseIds],
    };

    setLocalState({
      ...localState,
      assignmentReview: updatedAssignmentReview,
    });

    // const transformSubCoursesIds = assignmentReview.subCourseIds.reduce((acc, value) => {
    //   const subStatus = reviewData.find((item) => item.id === value)?.subStatus;
    //   acc[value.toString()] = Boolean(subStatus);
    //   return acc;
    // }, {});

    const transformSubCoursesIds = assignmentReview.subCourseIds.map((value) => {
      const data = reviewData.find((item) => item.id === value);
      const subStatus = data?.subStatus;
      const rejectionComment = data?.rejComment ? data?.rejComment : null;
      return {
        mappingId: value,
        rejectionComment: rejectionComment,
        status: Boolean(subStatus),
      };
    });

    const payload = {
      subCourseAssignmentMappingIds: transformSubCoursesIds,
      trainingAssignmentId: assignData.id,
      offlineTrainingReview: attendanceType,
      skillRate: assignmentReview.rating ? parseFloat(assignmentReview.rating) : 0,
      comments: assignmentReview.comment,
      isSaved: false,
    };

    reviewCourse(payload)
      .unwrap()
      .then(() => {
        setLocalState({
          assignmentReview: {
            subCourseIds: [],
            rating: "",
            comment: "",
          },
          attendanceType: "",
        });
        toast.success(T.COURSE_REVIEWED_SUCCESSFULLY);
        handleReview();
        refreshTable();
      })
      .catch(handleError)
      .finally(() => {
        handleBack();
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({
      ...localState,
      assignmentReview: { ...assignmentReview, [name]: value },
    });
  };

  const handleExport = async (Id) => {
    try {
      const response = await fetch(
        `${SERVER_URL}/subCourse/get/subCourseAssignmentCompletionFileLink?subCourseAssignmentMappingId=${Id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${sessionToken}`,
          },
        },
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      toast.success(T.ASSESSMENT_FILE_DOWNLOAD_SUCCESSFULLY);
      window.location.href = responseData.subCourseFileLink;
    } catch (error) {
      handleError();
    }
  };

  const isAssessmentRequired = courseTypeName === T.OFFLINE_TRAINING && subCourseName.length > 0;
  const isReviewButtonDisabled = () => {
    if ([T.OFFLINE_TRAINING].includes(courseTypeName)) {
      if (isAssessmentRequired) {
        if (attendanceType === T.ABSENT) {
          return false;
        } else {
          return !(
            attendanceType &&
            subCourseName.every((value) => value.status === T.COMPLETED || assignmentReview.subCourseIds.includes(value.id))
          );
        }
      } else {
        return !attendanceType;
      }
    } else {
      return (
        !assignmentReview.rating ||
        !subCourseName.every((value) => value.status === T.COMPLETED || assignmentReview.subCourseIds.includes(value.id))
      );
    }
  };

  useEffect(() => {
    if (!_.isEmpty(assignData)) {
      handleReview();
    }
  }, [assignData]);

  //  add rejection comment in payload //todo
  return (
    <MISDialog open={openReview}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <IconButton onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        {`${userName}'s Review`.toUpperCase()}
      </DialogTitle>

      <DialogContent>
        <Grid item display={"inline-flex"}>
          <KeyTypography>{T.COURSE_NAME} : </KeyTypography>
          <Typography ml={1}>{courseName}</Typography>
        </Grid>

        {courseTypeName !== T.OFFLINE_TRAINING ? (
          <Table stickyHeader mt={1}>
            <TableHead>
              <TableRow sx={{ height: 30 }}>
                <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.SUB_COURSE_NAME}</KeyTypography>
                </TableCell>
                <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.ASSESSMENT}</KeyTypography>
                </TableCell>
                <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.DOWNLOAD}</KeyTypography>
                </TableCell>
                <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                  <KeyTypography>{T.REVIEW}</KeyTypography>
                </TableCell>
                {reviewData.some((value) => value.subStatus === 0) && (
                  <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                    <KeyTypography>{T.REJECTION_COMMENT}</KeyTypography>
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {Children.toArray(
                subCourseName.map((value, index) => {
                  const subCompletionProofLink = value.completionProofLink;
                  const subCompletionProofFile = value.completionProofFile;
                  return (
                    <TableRow sx={{ background: BACKGROUND.white }} key={index}>
                      <StyledTableCell>
                        <TitleTypography>{value.subCourse.subsCourseName}</TitleTypography>
                      </StyledTableCell>
                      <StyledTableCell>
                        <TitleTypography>
                          {subCompletionProofLink === null ? (
                            <TitleTypography sx={{ paddingRight: 2 }}>{value.status}</TitleTypography>
                          ) : (
                            <Tooltip title={subCompletionProofLink} placement="top">
                              <TitleTypography textOverflow="" ml={1}>
                                <a href={subCompletionProofLink} target="_blank" rel="noreferrer">
                                  {subCompletionProofLink}
                                </a>
                              </TitleTypography>
                            </Tooltip>
                          )}
                        </TitleTypography>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        <Tooltip title={T.DOWNLOAD_FILE} placement="top">
                          <IconButton
                            disabled={!subCompletionProofFile}
                            onClick={() => handleExport(value.id)}
                            sx={{ color: NETSMARTZ_THEME_COLOR }}
                          >
                            <FileDownloadOutlinedIcon />
                          </IconButton>
                        </Tooltip>
                      </StyledTableCell>
                      <StyledTableCell>
                        {/* <Checkbox
                          checked={value.status === T.COMPLETED ? true : reviewData[index].checked}
                          onChange={(e) => handleComplete(index, e.target.checked, reviewData)}
                          name={`checkedCol${index}`}
                          disabled={!(subCompletionProofLink || subCompletionProofFile)}
                        /> */}
                        <Tooltip title={T.ACCEPT} placement="top">
                          <IconButton
                            aria-label="accept"
                            sx={{
                              color: "green",
                              opacity: value.status !== T.COMPLETED && [0, -1].includes(reviewData[index]?.subStatus) ? 0.3 : 1,
                            }}
                            onClick={() => handleComplete(index, false, reviewData, "accept")}
                            disabled={!(subCompletionProofLink || subCompletionProofFile) || value.status === T.IN_PROGRESS}
                          >
                            <CheckIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {value.status === T.REVIEW_PENDING && (
                          <Tooltip title={T.REJECT} placement="top">
                            <IconButton
                              aria-label="reject"
                              sx={{ color: "red", opacity: [1, -1].includes(reviewData[index]?.subStatus) ? 0.3 : 1 }}
                              onClick={() => handleComplete(index, false, reviewData, "reject")}
                              disabled={!(subCompletionProofLink || subCompletionProofFile)}
                            >
                              <CloseIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </StyledTableCell>
                      {reviewData[index]?.subStatus === 0 && (
                        <StyledTableCell>
                          <TextField
                            name="rejComment"
                            aria-label="rejComment"
                            placeholder="Rejection Comment"
                            value={reviewData[index]?.rejComment}
                            onChange={(e) => handleRejectComment(e, index)}
                            disabled={!subCompletionProofLink && !subCompletionProofFile && reviewData[index]?.subStatus === 0}
                          />
                        </StyledTableCell>
                      )}
                    </TableRow>
                  );
                }),
              )}
            </TableBody>
          </Table>
        ) : (
          <Grid item xs={12}>
            {subCourseName.length > 0 && (attendanceType ? attendanceType !== T.ABSENT : true) ? (
              <Table stickyHeader mt={1}>
                <TableHead>
                  <TableRow sx={{ height: 30 }}>
                    <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                      <KeyTypography>{T.ASSESSMENT}</KeyTypography>
                    </TableCell>
                    <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                      <KeyTypography>{T.DOWNLOAD}</KeyTypography>
                    </TableCell>
                    <TableCell sx={{ border: "none", backgroundColor: BACKGROUND.header }}>
                      <KeyTypography>{T.REVIEW}</KeyTypography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Children.toArray(
                    subCourseName.map((value, index) => {
                      const subCompletionProofLink = value.completionProofLink;
                      const subCompletionProofFile = value.completionProofFile;
                      offlineCompletionLink = subCompletionProofLink;
                      offlineCompletionFile = subCompletionProofFile;
                      return (
                        <TableRow sx={{ background: BACKGROUND.white }} key={index}>
                          <StyledTableCell>
                            <TitleTypography>
                              {subCompletionProofLink === null ? (
                                <TitleTypography sx={{ paddingRight: 2 }}>{value.status}</TitleTypography>
                              ) : (
                                <Tooltip title={subCompletionProofLink} placement="top">
                                  <TitleTypography textOverflow="" ml={1}>
                                    <a href={subCompletionProofLink} target="_blank" rel="noreferrer">
                                      {subCompletionProofLink}
                                    </a>
                                  </TitleTypography>
                                </Tooltip>
                              )}
                            </TitleTypography>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Tooltip title={T.DOWNLOAD_FILE} placement="top">
                              <IconButton
                                disabled={!subCompletionProofFile}
                                onClick={() => handleExport(value.id)}
                                sx={{ color: NETSMARTZ_THEME_COLOR }}
                              >
                                <FileDownloadOutlinedIcon />
                              </IconButton>
                            </Tooltip>
                          </StyledTableCell>
                          <StyledTableCell>
                            {/* <Checkbox
                              checked={value.status === T.COMPLETED ? true : reviewData[index]?.checked}
                              onChange={(e) => handleComplete(index, e.target.checked, reviewData)}
                              name={`checkedCol${index}`}
                              disabled={!(offlineCompletionLink || offlineCompletionFile)}
                            /> */}
                            <Tooltip title={T.ACCEPT} placement="top">
                              <IconButton
                                aria-label="accept"
                                sx={{
                                  color: "green",
                                  opacity:
                                    value.status !== T.COMPLETED && [0, -1].includes(reviewData[index]?.subStatus) ? 0.3 : 1,
                                }}
                                onClick={() => handleComplete(index, false, reviewData, "accept")}
                                disabled={!(offlineCompletionLink || offlineCompletionFile) || value.status === T.IN_PROGRESS}
                              >
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            {value.status === T.REVIEW_PENDING && (
                              <Tooltip title={T.REJECT} placement="top">
                                <IconButton
                                  aria-label="reject"
                                  sx={{ color: "red", opacity: [1, -1].includes(reviewData[index]?.subStatus) ? 0.3 : 1 }}
                                  onClick={() => handleComplete(index, false, reviewData, "reject")}
                                  disabled={!(offlineCompletionLink || offlineCompletionFile)}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                          </StyledTableCell>
                          {reviewData[index]?.subStatus === 0 && (
                            <StyledTableCell>
                              <TextField
                                name="rejComment"
                                aria-label="rejComment"
                                placeholder="Rejection Comment"
                                value={reviewData[index]?.rejComment}
                                onChange={(e) => handleRejectComment(e, index)}
                                disabled={
                                  !subCompletionProofLink && !subCompletionProofFile && reviewData[index]?.subStatus === 0
                                }
                              />
                            </StyledTableCell>
                          )}
                        </TableRow>
                      );
                    }),
                  )}
                </TableBody>
              </Table>
            ) : null}
            <Grid container mt={2}>
              <Grid item>
                <KeyTypography>{T.ATTENDANCE} :</KeyTypography>
                <RadioGroup value={attendanceType} onChange={handleAttendanceRadioChange}>
                  {attendanceTypeList.map((val, index) => (
                    <FormControlLabel
                      key={index}
                      size="small"
                      name={val.label}
                      disabled={
                        courseTypeName === T.OFFLINE_TRAINING &&
                        (offlineCompletionLink || offlineCompletionFile) &&
                        !subCourseName.every(
                          (value) => value.status === T.COMPLETED || assignmentReview.subCourseIds.includes(value.id),
                        )
                      }
                      value={val.label}
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: orange[700],
                            },
                          }}
                        />
                      }
                      label={val.label}
                      labelPlacement="end"
                    />
                  ))}
                </RadioGroup>
              </Grid>
              {courseTypeName === T.OFFLINE_TRAINING && subCourseName.length > 0 ? (
                <Grid item textAlign={"center"}>
                  <KeyTypography>{"Assignment not uploaded"}</KeyTypography>
                  <IconButton
                    onClick={() => generate_Mail(assignCourseId)}
                    sx={{
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                    disabled={
                      !(
                        courseTypeName === T.OFFLINE_TRAINING &&
                        subCourseName.length > 0 &&
                        !(offlineCompletionLink || offlineCompletionFile)
                      )
                    }
                  >
                    <Tooltip title={"Remind via email"} placement="top">
                      <LocalPostOfficeIcon />
                    </Tooltip>
                  </IconButton>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}

        <Grid container mt={2} justifyContent={"space-around"}>
          <Grid item>
            <KeyTypography>{T.COURSE_COMMENT} : </KeyTypography>
            <StyledTextarea
              name="comment"
              aria-label="empty textarea"
              placeholder="Course Feedback..."
              value={assignmentReview.comment}
              onChange={handleChange}
              minRows={3}
              disabled={
                !subCourseName.every((value) => value.status === T.COMPLETED || assignmentReview.subCourseIds.includes(value.id))
              }
            />
          </Grid>
          <Grid item textAlign={"center"}>
            <KeyTypography>{T.APPROVE_CERTIFICATE}</KeyTypography>
            <IconButton
              onClick={() => generate_Certificate(assignCourseId)}
              sx={{
                color: NETSMARTZ_THEME_COLOR,
              }}
              disabled={
                !subCourseName.every((value) => value.status === T.COMPLETED || assignmentReview.subCourseIds.includes(value.id))
              }
            >
              <CardMembershipIcon />
            </IconButton>
          </Grid>
        </Grid>

        {courseTypeName !== T.OFFLINE_TRAINING ? (
          <Grid mt={1}>
            <KeyTypography>{T.RATING} : </KeyTypography>
            <Rating
              name="rating"
              precision={0.5}
              defaultValue={0}
              value={Number(assignmentReview.rating)}
              onChange={handleChange}
              disabled={
                !subCourseName.every((value) => value.status === T.COMPLETED || assignmentReview.subCourseIds.includes(value.id))
              }
            />
          </Grid>
        ) : null}
      </DialogContent>

      <DialogActions>
        {courseTypeName === T.OFFLINE_TRAINING &&
        subCourseName.length > 0 &&
        !(offlineCompletionLink || offlineCompletionFile) &&
        !(attendanceType === T.ABSENT) ? (
          <Button
            variant="contained"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              whiteSpace: "nowrap",
              color: BACKGROUND.white,
              "& .MuiButton-startIcon": {
                mr: 0,
              },
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
                color: BACKGROUND.white,
              },
            }}
            onClick={handleSaveReviewDialog}
          >
            {T.SAVE}
          </Button>
        ) : null}
        <MISFooterButton
          proceedButtonText={T.COMPLETE_REVIEW}
          justify="center"
          sx={{ p: 1.5 }}
          size="medium"
          disableProceed={isReviewButtonDisabled()}
          handleClose={() => handleBack()}
          handleSubmit={handleCompleteReview}
        />
      </DialogActions>
    </MISDialog>
  );
};

ReviewModal.propTypes = {
  openReview: PropTypes.bool,
  assignData: PropTypes.object,
  onHandleClose: PropTypes.func,
};

export default ReviewModal;
