import PropTypes from "prop-types";
import { noop } from "lodash";

import { Box, Card, Table as TableView, TableContainer, Button } from "@mui/material";

import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import MISTableBody from "./TableBody";
import TableHeader from "./TableHeader";

const Table = ({
  columns = [],
  jdId = "",
  lockedColumns = [],
  allTableRows,
  handleUnshortlist = noop,
  handleFileUpload = noop,
  refreshMemberTable = noop,
  handlePitchCandidate = noop,
  pitchedUsersCount,
}) => {
  const shouldShowButton = allTableRows.length > 0 && allTableRows.length !== pitchedUsersCount.length;

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        backgroundColor: "background.card",
        maxHeight: "calc(100vh - 260px)",

        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            maxHeight: "calc(100vh - 310px)",
            minHeight: "calc(100vh - 500px)",
            overflow: "auto",
          }}
        >
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader columns={columns} lockedColumns={lockedColumns} />
            <MISTableBody
              columns={columns}
              jdId={jdId}
              lockedColumns={lockedColumns}
              records={allTableRows}
              refreshMemberTable={refreshMemberTable}
              handleUnshortlist={handleUnshortlist}
              handleFileUpload={handleFileUpload}
              handlePitchCandidate={handlePitchCandidate}
            />
          </TableView>
        </TableContainer>
      </Box>

      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {shouldShowButton && (
          <Button
            variant="contained"
            size="small"
            sx={{
              m: 1,
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
              textTransform: "none",
            }}
            onClick={handlePitchCandidate}
          >
            {T.PITCH_CANDIDATES}
          </Button>
        )}
      </Box>
    </Card>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  jdId: PropTypes.number,
  pitchedUsersCount: PropTypes.number,
  lockedColumns: PropTypes.array,
  refreshMemberTable: PropTypes.func,
  handleUnshortlist: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handlePitchCandidate: PropTypes.func,
  allTableRows: PropTypes.array,
};

export default Table;
