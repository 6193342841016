import { createSlice } from "@reduxjs/toolkit";

const reportCardFilterSlice = createSlice({
  name: "ReportCardFilterSlice",
  initialState: {
    storedFilters: {
      clientName: "",
      bytmanager: null,
      techStack: "",
      status: "",
    },
  },
  reducers: {
    reportCardFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
    },
  },
});
export const { reportCardFilterStore } = reportCardFilterSlice.actions;

export default reportCardFilterSlice.reducer;
