import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    bulkUpload: build.mutation({
      query: ({ bulkMemberFile, modFileType }) => ({
        url: `/user/bulk/add?type=${modFileType}`,
        method: "POST",
        body: bulkMemberFile,
      }),
    }),
  }),
});

export const { useBulkUploadMutation } = extendedApi;
