import { createSlice } from "@reduxjs/toolkit";

const tabsSlice = createSlice({
  name: "TabsSlice",
  initialState: {
    storedTab: "",
  },

  reducers: {
    tabsStore: (state, action) => {
      state.storedTab = action.payload.storedTab;
    },
  },
});

export const { tabsStore } = tabsSlice.actions;
export default tabsSlice.reducer;
