import { Grid, IconButton, InputAdornment, Tooltip, Typography, Input, Chip, Box, Button } from "@mui/material";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import { noop } from "lodash";
import { get } from "utils/lodash";
import { YES_NO_VALUES, tenureList } from "components/ISTools/toolsModel";
import { Children } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import ConstructionIcon from "@mui/icons-material/Construction";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { isDecimal, isEmail, isNumber } from "utils/validations";
import PropTypes from "prop-types";
import WithInputLabel from "components/common/WithInputLabel";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadIcon from "@mui/icons-material/Upload";
import PlumbingIcon from '@mui/icons-material/Plumbing';

const CreateLicense = ({
  id = "",
  toolsId = "",
  profitCenterId = "",
  toolTypeId = "",
  purchaseDate = "",
  costPrice = "",
  licenseHolder = "",
  licenseHolderEmail = "",
  licenseHolderArray = [],
  remarks = "",
  tenure = "",
  renewalDate = "",
  contractFile = "",
  contractFileName = "",
  autoRenewal = {},
  costCenterMap = [],
  savedCostCenters = [],
  toolsList = [],
  profitCenterList = [],
  costCenterList = [],
  contractHistoryList = [],
  toolTypeList = [],
  onHandleOpenConfirmDelete = noop,
  disableField = noop,
  onHandleReqAddMore = noop,
  onHandleReqRemove = noop,
  handleFileUpload = noop,
  handleDeleteFile = noop,
  onHandleChange = noop,
  handleKeyPress = noop,
  handleButtonClick = noop,
  handleDeleteChip = noop,
  onHandleDateChange = noop,
  onHandleAutoCompleteChange = noop,
  handleOpenToolsDialog = noop,
  handleOpenProfitCenterDialog = noop,
  handleOpenCostCenterDialog = noop,
  handleOpenToolTypeDialog = noop,
}) => {
  const selectedTool = toolsList.find((tool) => tool.toolsId === toolsId) || null;
  const selectedProfitCenter = profitCenterList.find((pc) => pc.profitCenterId === profitCenterId) || null;
  const selectedToolType = toolTypeList.find((toolType) => toolType.toolTypeId === toolTypeId) || null;

  const renderIcons = (index) => (
    <>
      {/* savedCostCenters is the data coming from the BE */}
      {((id && index >= savedCostCenters.length && index !== 0) ||
        (!id && index > 0) ||
        (costCenterMap.length > 1 && index >= 0)) && (
          <RemoveCircleOutlineIcon
            onClick={() => {
              onHandleReqRemove(index);
            }}
            fontSize="small"
            sx={{
              cursor: "pointer",
              color: NETSMARTZ_THEME_COLOR,
            }}
          />
        )}
      {index === costCenterMap.length - 1 && (
        <AddCircleOutlineIcon
          onClick={() => {
            onHandleReqAddMore();
          }}
          fontSize="small"
          sx={{
            cursor: "pointer",
            color: NETSMARTZ_THEME_COLOR,
          }}
        />
      )}
    </>
  );
  const extractFilename = (path) => {
    const regex = /([^/\\]+)_[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}\.(doc|pdf|docx)$/;
    const match = path.match(regex);
    return match ? match[1] + "." + match[2] : null;
  };
  return (
    <Grid container spacing={2}>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.TOOLS_NAME}
          required
          listDetails={toolsList}
          value={selectedTool}
          disabled={disableField()}
          getByLabelText={(option) => get(option, "toolsName", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("toolsId", get(newValue, "toolsId", ""), null)}
          inputAdornment={
            <InputAdornment>
              <Tooltip title={T.ADD_TOOLS} placement="top">
                <IconButton disabled={disableField()} onClick={() => handleOpenToolsDialog()}>
                  <ConstructionIcon
                    sx={{
                      color: disableField() ? "grey" : NETSMARTZ_THEME_COLOR,
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          andormentPosition={"endAdornment"}
        />
      </Grid>
      {/* <Grid item md={1} xs={12}></Grid> */}
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.DEPARTMENT}
          required
          disabled={disableField()}
          listDetails={profitCenterList}
          value={selectedProfitCenter}
          getByLabelText={(option) => get(option, "profitCenterName", "")}
          onHandleChange={(event, newValue) =>
            onHandleAutoCompleteChange("profitCenterId", get(newValue, "profitCenterId", ""), null)
          }
          inputAdornment={
            <InputAdornment>
              <Tooltip title={T.ADD_NEW_DEPARTMENT} placement="top">
                <IconButton disabled={disableField()} onClick={() => handleOpenProfitCenterDialog()}>
                  <AccountBalanceIcon
                    sx={{
                      color: disableField() ? "grey" : NETSMARTZ_THEME_COLOR,
                      width: 20,
                      height: 20,
                    }}
                  />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          }
          andormentPosition={"endAdornment"}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.COST_PRICE}
          required
          fullWidth
          disabled={disableField()}
          placeholder={T.COST_PRICE}
          autoComplete="on"
          size="small"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: costPrice && `3px solid ${isDecimal(costPrice) ? SUCCESS.main : ERROR.main}`,
            },
          }}
          variant="outlined"
          name="costPrice"
          value={costPrice}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISDatePicker
          label={T.PURCHASE_DATE}
          placeholder={T.PURCHASE_DATE}
          inputFormat="DD/MM/YYYY"
          value={purchaseDate}
          maxDate={renewalDate}
          required
          disabled={disableField()}
          name="purchaseDate"
          handleChange={onHandleDateChange}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.LICENSE_HOLDER_NAME}
          required
          fullWidth
          placeholder={T.LICENSE_HOLDER_NAME}
          autoComplete="on"
          size="small"
          disabled={disableField()}
          variant="outlined"
          name="licenseHolder"
          value={licenseHolder}
          onChange={(e) => onHandleChange(e, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.AUTO_RENEWAL}
          fullWidth
          required
          disabled={disableField()}
          listDetails={YES_NO_VALUES}
          value={autoRenewal}
          getByLabelText={(option) => get(option, "label", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("autoRenewal", newValue, null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.TENURE}
          fullWidth
          required
          disabled={disableField()}
          listDetails={tenureList}
          value={tenureList.find((ten) => ten.tenureName === tenure)}
          getByLabelText={(option) => get(option, "label", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("tenure", get(newValue, "tenureName", ""), null)}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISDatePicker
          label={T.RENEWAL_DATE}
          placeholder={T.RENEWAL_DATE}
          inputFormat="DD/MM/YYYY"
          value={renewalDate}
          minDate={purchaseDate}
          required
          name="renewalDate"
          handleChange={onHandleDateChange}
          disabled={disableField()}
          renderInput={(params) => <MISTextField {...params} />}
        />
      </Grid>
      <Grid container md={12} xs={12} mt={2}>
        <Grid container md={5} xs={12}>
          <Grid item md={12} xs={12} ml={2}>
            <MISTextField
              label={T.LICENSE_HOLDER_EMAIL}
              fullWidth
              placeholder={T.LICENSE_HOLDER_EMAIL}
              autoComplete="on"
              size="small"
              disabled={disableField()}
              variant="outlined"
              name="licenseHolderEmail"
              value={licenseHolderEmail}
              onChange={(e) => onHandleChange(e, null)}
              // sx={{
              //   "& .MuiOutlinedInput-notchedOutline": {
              //     borderBottom: licenseHolderEmail && `3px solid ${isEmail(licenseHolderEmail) ? SUCCESS.main : ERROR.main}`,
              //   },
              // }}
              onBlur={(e) => handleButtonClick(e, "licenseHolderArray")}
              onKeyDown={(e) => handleKeyPress(e, "licenseHolderArray")}
            />
            <div>
              {licenseHolderArray && licenseHolderArray.length > 0
                ? licenseHolderArray.map((email, emailIndex) => (
                  <Chip
                    key={emailIndex}
                    label={email}
                    onDelete={() => handleDeleteChip(emailIndex, "licenseHolderArray")}
                    style={{ margin: 5 }}
                  />
                ))
                : null}
            </div>
          </Grid>
          <Grid item md={12} xs={12} ml={2} mt={1.5}>
            <WithInputLabel label={T.UPLOAD_CONTARCT}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={"auto"}>
                  <input
                    name="contractFileName"
                    style={{
                      display: "none",
                    }}
                    type="file"
                    key={contractFileName}
                    onChange={(e) => handleFileUpload(e)}
                    id="contractFileInput"
                  />
                  <label htmlFor="contractFileInput">
                    <Button
                      variant="contained"
                      component="span"
                      style={{
                        backgroundColor: contractFileName ? "transparent" : NETSMARTZ_THEME_COLOR,
                        color: contractFileName ? NETSMARTZ_THEME_COLOR : "white",
                        maxWidth: contractFileName ? "100%" : "",
                        marginTop: 10,
                        textTransform: "none",
                        borderRadius: "5px",
                      }}
                      startIcon={<UploadIcon />}
                    >
                      {contractFileName ? "Replace File" : "Upload File"}
                    </Button>
                  </label>
                </Grid>
                {contractFileName && (
                  <Grid item xs={12} md={"auto"}>
                    <div style={{ display: "flex", alignItems: "center", color: NETSMARTZ_THEME_COLOR }}>
                      <span style={{ fontSize: 13, margin: 10 }}>{`Chosen File: ${contractFileName}`}</span>
                      <IconButton onClick={(e) => handleDeleteFile(e)} style={{ color: NETSMARTZ_THEME_COLOR }}>
                        <CancelIcon />
                      </IconButton>
                    </div>
                  </Grid>
                )}
                <Grid item xs={12}>
                  {contractHistoryList.length > 0 && (
                    <Box
                      width="100%"
                      sx={{
                        display: "flex",
                        overflowX: "auto",
                        "&::-webkit-scrollbar": {
                          height: "4px",
                        },
                        "&::-webkit-scrollbar-thumb": {
                          backgroundColor: NETSMARTZ_THEME_COLOR,
                          borderRadius: "3px",
                        },
                      }}
                    >
                      {contractHistoryList.map((contract) => (
                        <Chip
                          key={get(contract, "id", "")}
                          size="small"
                          label={extractFilename(get(contract, "contractPath", ""))}
                          onDelete={() => onHandleOpenConfirmDelete(get(contract, "id", ""))}
                          sx={{ mr: 1, mt: 1 }}
                        />
                      ))}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </WithInputLabel>
          </Grid>
        </Grid>
        <Grid container md={5} xs={12}>
          <Grid item md={12} xs={12} ml={2}>
            <MISAutocomplete
              label={T.TOOL_TYPE}
              required
              listDetails={toolTypeList}
              value={selectedToolType}
              disabled={disableField()}
              getByLabelText={(option) => get(option, "toolTypeName", "")}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange("toolTypeId", get(newValue, "toolTypeId", ""), null)}
              inputAdornment={
                <InputAdornment>
                  <Tooltip title={T.ADD_NEW_TOOL_TYPE} placement="top">
                    <IconButton disabled={disableField()} onClick={() => handleOpenToolTypeDialog()}>
                      <PlumbingIcon
                        sx={{
                          color: disableField() ? "grey" : NETSMARTZ_THEME_COLOR,
                          width: 20,
                          height: 20,
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              }
              andormentPosition={"endAdornment"}
            />
          </Grid>
          <Grid item md={12} xs={12} m={2}>
            <MISTextField
              label={T.REMARKS}
              fullWidth
              placeholder={T.REMARKS}
              autoComplete="on"
              multiline
              rows={3}
              size="small"
              disabled={disableField()}
              variant="outlined"
              name="remarks"
              value={remarks}
              onChange={(e) => onHandleChange(e, null)}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography
          fontSize={16}
          fontWeight={600}
          mt={2}
        >{`${T.COST_CENTER.toUpperCase()} ${T.DETAILS.toUpperCase()}`}</Typography>
      </Grid>
      <Grid item xs={12}>
        {Children.toArray(
          costCenterMap.map((data, index) => {
            return (
              <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                <Grid item md={5} xs={12}>
                  <MISAutocomplete
                    label={T.COST_CENTER}
                    fullWidth
                    required
                    disabled={disableField()}
                    listDetails={costCenterList.filter(
                      (costCenter) => !costCenterMap.some((costmap) => costCenter.costCenterId === costmap.costCenterId),
                    )}
                    value={
                      costCenterList.find((costCenter) => costCenter.costCenterId === costCenterMap[index].costCenterId) || null
                    }
                    getByLabelText={(option) => get(option, "costCenterName", "")}
                    onHandleChange={(event, newValue) =>
                      onHandleAutoCompleteChange("costCenterId", get(newValue, "costCenterId", ""), index)
                    }
                    inputAdornment={
                      index === costCenterMap.length - 1 && (
                        <InputAdornment>
                          <Tooltip title={T.ADD_NEW_COST_CENTER} placement="top">
                            <IconButton disabled={disableField()} onClick={() => handleOpenCostCenterDialog()}>
                              <MonetizationOnIcon
                                sx={{
                                  color: disableField() ? "grey" : NETSMARTZ_THEME_COLOR,
                                  width: 20,
                                  height: 20,
                                }}
                              />
                            </IconButton>
                          </Tooltip>
                        </InputAdornment>
                      )
                    }
                    andormentPosition={"endAdornment"}
                  />
                </Grid>
                <Grid item md={2} xs={12}>
                  <MISTextField
                    label={T.PERCENTAGE}
                    required
                    fullWidth
                    disabled={disableField()}
                    placeholder={T.PERCENTAGE}
                    autoComplete="on"
                    size="small"
                    type="number"
                    variant="outlined"
                    name="percentage"
                    InputProps={{
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    }}
                    value={costCenterMap[index].percentage}
                    onChange={(e) => onHandleChange(e, index)}
                  />
                </Grid>
                <Grid item md={2} xs={12} mt={4}>
                  {renderIcons(index)}
                </Grid>
              </Grid>
            );
          }),
        )}
      </Grid>
    </Grid>
  );
};

CreateLicense.propTypes = {
  id: PropTypes.string,
  toolsId: PropTypes.string,
  profitCenterId: PropTypes.string,
  purchaseDate: PropTypes.string,
  costPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  licenseHolder: PropTypes.string,
  licenseHolderEmail: PropTypes.string,
  remarks: PropTypes.string,
  tenure: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  renewalDate: PropTypes.string,
  autoRenewal: PropTypes.object,
  costCenterMap: PropTypes.array,
  savedCostCenters: PropTypes.array,
  toolsList: PropTypes.array,
  profitCenterList: PropTypes.array,
  costCenterList: PropTypes.array,
  disableField: PropTypes.func,
  onHandleReqAddMore: PropTypes.func,
  onHandleReqRemove: PropTypes.func,
  onHandleChange: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  handleOpenToolsDialog: PropTypes.func,
  handleOpenProfitCenterDialog: PropTypes.func,
  handleOpenCostCenterDialog: PropTypes.func,
  handleAddToolsInfoDialog: PropTypes.func,
  handleAddProfitCenterInfoDialog: PropTypes.func,
  onHandleOpenConfirmDelete: PropTypes.func,
};

export default CreateLicense;
