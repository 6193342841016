import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveInterviewResult: build.mutation({
      query: ({ assessmentId, savePayload }) => ({
        url: `technicalAssignment/submit/assignment`,
        method: "POST",
        body: savePayload,
        params: { assessmentId },
      }),
    }),
  }),
});

export const { useSaveInterviewResultMutation } = extendedApi;
