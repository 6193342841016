
import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    uploadProfilePic: builder.query({
      query: ({ userId, fileType, file }) => ({
        url: `user/upload/profilePicture`,
        method: "POST",
        body: { fileType, file },
        params: { userId },
      }),
    }),
  }),
});

export const { useLazyUploadProfilePicQuery } = extendedApi;
