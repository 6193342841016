import { api } from "api";

const extendedApi = api.injectEndpoints({
	endpoints: (build) => ({
		saveToolType: build.mutation({
			query: (body) => ({
				url: "/toolType/save",
				method: "POST",
				body,
			}),
		}),
	}),
});

export const { useSaveToolTypeMutation } = extendedApi;
