import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSpecialIncidents: builder.query({
      query: ({ userId }) => ({
        url: `/specialIncidentRegister/get/all`,
        method: "GET",
        params: { userId },
      }),
    }),
  }),
});

export const { useLazyGetSpecialIncidentsQuery } = extendedApi;
