import PropTypes from "prop-types";
import { Table as TableView, TableContainer } from "@mui/material";
import { ASSIGNED_COURSE_TABLE_HEADER } from "settings/constants/members";
import TrainingHistoryHeader from "./TrainingHistoryHeader";
import TrainingHistoryBody from "./TrainingHistoryBody";

const TrainingTable = ({ tableRecord = [] }) => {
  const TRAINING_HISTORY_TABLE_HEADER = ASSIGNED_COURSE_TABLE_HEADER.slice(0, -1);
  return (
    <TableContainer>
      <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
        <TrainingHistoryHeader columns={TRAINING_HISTORY_TABLE_HEADER} />
        <TrainingHistoryBody tableRecord={tableRecord} />
      </TableView>
    </TableContainer>
  );
};

TrainingTable.propTypes = {
  tableRecord: PropTypes.array,
};

export default TrainingTable;
