import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    addSwotAnalysis: build.mutation({
      query: ({ id, formData }) => ({
        url: `swotAnalysis/employee/save`,
        method: "POST",
        body: formData,
        params: { userId: id },
      }),
    }),
  }),
});

export const { useAddSwotAnalysisMutation } = extendedApi;
