import { Edit, ExpandMore, Remove } from "@mui/icons-material";
import Add from "@mui/icons-material/Add";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  TableBody,
  AccordionActions,
  IconButton,
  Unstable_Grid2,
  Checkbox,
  Button,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import MISNoData from "components/common/MISNoData";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";

const GroupsList = ({
  columns,
  groupsList,
  expandedGroup,
  allSelected,
  selectedMembers,
  handleExpand,
  handleOpenConfDialog,
  toggleAddSingleMember,
  handleEditGroup,
  handleAssignTraining,
  handleSelectMember,
  handleSelectAll,
}) => {
  const { isXs } = GET_SIZE();
  return (
    <Paper sx={{ mt: 2, overflow: "auto", height: "inherit", maxHeight: isXs ? "calc(100vh - 305px)" : "calc(100vh - 270px)" }}>
      {groupsList && groupsList.length ? (
        groupsList.map((item, groupIndex) => (
          <Accordion key={item.groupId} expanded={item.groupId === expandedGroup} onChange={handleExpand(item.groupId)}>
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls={`group-${item.groupId}-content`}
              id={`group-${item.groupId}-header`}
            >
              <Unstable_Grid2 container sx={{ width: "95%", justifyContent: "space-between", alignItems: "center" }}>
                <Unstable_Grid2 container sm={"auto"} xs={12} sx={{ justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="subtitle1">{item.groupName}</Typography>
                </Unstable_Grid2>
                <Unstable_Grid2 sm={"auto"} xs={12}>
                  <Typography variant="body2">Created By: {item.groupCreatedBy.name}</Typography>
                </Unstable_Grid2>
              </Unstable_Grid2>
            </AccordionSummary>
            <AccordionDetails>
              {item.groupId === expandedGroup && (
                <TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 450px)", overflow: "auto" }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                    <TableHead>
                      <TableRow>
                        {columns &&
                          columns.length &&
                          columns.map((column, index) => {
                            return column.key === "checkBox" ? (
                              <TableCell key={column.key}>
                                <Checkbox checked={allSelected} onClick={(e) => handleSelectAll(e, "Groups")} />
                              </TableCell>
                            ) : column.key === "actions" ? (
                              <TableCell key={column.key}>
                                <IconButton
                                  sx={item.groupId !== expandedGroup ? { display: "none" } : {}}
                                  color="warning"
                                  onClick={toggleAddSingleMember}
                                >
                                  <Add />
                                </IconButton>
                              </TableCell>
                            ) : (
                              <TableCell key={column.key}>{column.label}</TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.usersList?.map((member, memberIndex) => (
                        <TableRow key={member.user.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                          {columns.map((column) => (
                            <TableCell key={`${item.groupId}-${member.user.id}-${column.key}`}>
                              {column.key === "checkBox" ? (
                                <Checkbox
                                  checked={selectedMembers.includes(member.user.id)}
                                  onChange={(e) => handleSelectMember(e, member.user.id, "Groups")}
                                  name={`${item.groupId}-${member.user.id}-checkbox`}
                                  id={`${item.groupId}-${member.user.id}`}
                                />
                              ) : column.key === "actions" && selectedMembers.length === 0 ? (
                                <IconButton
                                  color="error"
                                  onClick={() => {
                                    handleOpenConfDialog(member.user.id);
                                  }}
                                >
                                  <Remove />
                                </IconButton>
                              ) : (
                                member.user[column.key]
                              )}
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </AccordionDetails>
            <AccordionActions>
              {selectedMembers.length !== 0 && (
                <Button
                  color="error"
                  variant="outlined"
                  size="small"
                  startIcon={<Remove />}
                  onClick={() => {
                    handleOpenConfDialog();
                  }}
                >
                  {"Remove Selected"}
                </Button>
              )}
              <MISButton variant="contained" onClick={handleEditGroup} startIcon={<Edit />}>
                {T.ADD_MEMBERS}
              </MISButton>
              <MISButton variant="contained" onClick={handleAssignTraining}>
                {T.ASSIGN_GROUP_TRAINING}
              </MISButton>
            </AccordionActions>
          </Accordion>
        ))
      ) : (
        <MISNoData />
      )}
    </Paper>
  );
};

GroupsList.propTypes = {
  columns: PropTypes.array.isRequired,
  groupsList: PropTypes.array.isRequired,
  groupDetails: PropTypes.object,
  expandedGroup: PropTypes.number,
  allSelected: PropTypes.bool,
  selectedMembers: PropTypes.array.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleOpenConfDialog: PropTypes.func.isRequired,
  toggleAddSingleMember: PropTypes.func.isRequired,
  handleEditGroup: PropTypes.func.isRequired,
  handleAssignTraining: PropTypes.func.isRequired,
  handleSelectMember: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
};

export default GroupsList;
