import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import MISNoData from "components/common/MISNoData";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Table = ({allTableRows = []}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mx: 1.4,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 430px)",
            overflowY: "scroll",
          }}
        >
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader />
              <TableBody allTableRows={allTableRows} />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
    </Card>
  );
};

export default Table;
