import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { get } from "utils/lodash";
import memo from "utils/react";
import { getFEDateFormat } from "utils/members";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 13,
  paddingBottom: 0,
}));

const HistoryTableBody = ({ records = [] }) => (
  <TableBody>
    {Children.toArray(
      records.map((record, index) => (
        <TableRow>
          <StyledTableCell>{index + 1}.</StyledTableCell>
          <StyledTableCell>{get(record, "projectName", "")}</StyledTableCell>
          <StyledTableCell>{getFEDateFormat(get(record, "startDate", null))}</StyledTableCell>
          <StyledTableCell>{getFEDateFormat(get(record, "endDate", null))}</StyledTableCell>
          <StyledTableCell>{get(record, "projectManager", "")}</StyledTableCell>
          <StyledTableCell>{get(record, "functionalManager", "")}</StyledTableCell>
          <StyledTableCell sx={{ pl: 5 }}>{get(record, "projectFeedbacks", []).length}</StyledTableCell>
        </TableRow>
      )),
    )}
  </TableBody>
);

HistoryTableBody.propTypes = {
  records: PropTypes.array,
  handleAddFeedback: PropTypes.func,
};

export default memo(HistoryTableBody);
