import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: (params) => ({
        url: "/group/get/all",
        params,
      }),
    }),
  }),
});

export const { useLazyGetAllGroupsQuery } = extendedApi;
