import PropTypes from "prop-types";
import { noop } from "lodash";
import { Box, Card, Table as TableView, TableContainer } from "@mui/material";
import MISTableBody from "./TableBody";
import TableHeader from "./TableHeader";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { PAGINATION } from "settings/constants/pagination";
import MISNoData from "components/common/MISNoData";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const Table = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  columns = [],
  jdId = "",
  lockedColumns = [],
  shortListedChecks = [],
  allTableRows = [],
  selectAllCheck = false,
  handleShortListCheckBoxChange = noop,
  handleEditAllPitched = noop,
  refreshMemberTable = noop,
  handleExport = noop,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            height: "calc(100vh - 310px)",
            overflow: "auto",
          }}
        >
          {allTableRows.length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              <TableHeader
                selectAllCheck={selectAllCheck}
                handleShortListCheckBoxChange={handleShortListCheckBoxChange}
                columns={columns}
                lockedColumns={lockedColumns}
              />
              <MISTableBody
                columns={columns}
                jdId={jdId}
                lockedColumns={lockedColumns}
                records={allTableRows}
                refreshMemberTable={refreshMemberTable}
                shortListedChecks={shortListedChecks}
                handleShortListCheckBoxChange={handleShortListCheckBoxChange}
                handleEditAllPitched={handleEditAllPitched}
                page={page}
                rowsPerPage={rowsPerPage}
                handleExport={handleExport}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>
      </Box>
      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

Table.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  columns: PropTypes.array,
  jdId: PropTypes.string,
  allTableRows: PropTypes.array,
  lockedColumns: PropTypes.array,
  shortListedChecks: PropTypes.array,
  refreshMemberTable: PropTypes.func,
  selectAllCheck: PropTypes.bool,
  handleShortListCheckBoxChange: PropTypes.func,
  handleEditAllPitched: PropTypes.func,
  handleExport: PropTypes.func,
};

export default Table;
