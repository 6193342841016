import Typography from "@mui/material/Typography";
import T from "T";
import { Container, styled } from "@mui/material";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";

const HeaderTypography = styled(Typography)(() => ({
  fontWeight: "bold",
}));

const SubHeaderTypography = styled(Typography)(() => ({
  fontWeight: "normal",
  opacity: 0.5,
}));

const NetsmartzWarningAmberRoundedIcon = styled(WarningAmberRoundedIcon)(({ theme }) => ({
  color: theme.palette.themeColor,
  fontSize: "40px",
}));

const ErrorPage = () => (
  <Container
    sx={{
      textAlign: "center",
      position: "absolute",
      top: "45%",
      left: "50%",
      transform: "translate(-50%, -45%)",
      opacity: 0.6,
    }}
  >
    <NetsmartzWarningAmberRoundedIcon />
    <HeaderTypography variant="h4">{T.ERROR_PAGE}</HeaderTypography>
    <SubHeaderTypography variant="subtitle">{T.SOMETHING_WENT_WRONG.toUpperCase()}</SubHeaderTypography>
  </Container>
);

export default ErrorPage;
