import PropTypes from "prop-types";
import { Table as TableView, TableContainer } from "@mui/material";
import { INTERVIEW_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "./TableHeader";
import InterviewTableBody from "./InterviewTableBody";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";

const InterviewTable = ({ allTableRows = [] }) => {
  return (
    <TableContainer
      sx={{
        overflow: "auto",
        height: 220,
      }}
    >
      {allTableRows.length > 0 ? (
        <TableView stickyHeader>
          <TableHeader columns={INTERVIEW_TABLE_HEADER} />
          <InterviewTableBody records={allTableRows} />
        </TableView>
      ) : (
        <Box>
          <MISNoData />
        </Box>
      )}
    </TableContainer>
  );
};

InterviewTable.propTypes = {
  allTableRows: PropTypes.array,
};

export default InterviewTable;
