import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    subCourseStarted: build.mutation({
      query: ({ id }) => ({
        url: `/trainee/start/subCourse?subCourseAssignmentMappingId=${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useSubCourseStartedMutation } = extendedApi;
