import { useReducer } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Grid, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import ConfirmCancel from "components/ISTools/ConfirmCancel";
import Form from "./Form";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

const AddToolsLicence = () => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openConfirmCancel: false,
    file: {},
    fileName: "",
  });

  const { openConfirmCancel, file, fileName } = localState;

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const getBinaryFile = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const formData = new FormData();
    formData.append("file", file);
    return { fileName, formData };
  };

  const handleLicenseUpload = async (event) => {
    const { name } = event.target;
    const { fileName, formData } = await getBinaryFile(event);
    setLocalState({ file: formData, [name]: fileName });
  };

  const handleDeleteFile = (event) => {
    const { name } = event.target;
    setLocalState({ file: {}, [name]: "" });
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };
  return (
    <Paper sx={{ p: "16px 16px 0" }}>
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={confrmCancelModal} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {T.CREATE_NEW_TOOLS_LICENCE}
        </Typography>
        <Grid container md={4} xs={12} style={{ marginLeft: "auto" }}>
          <Grid item mr={2}>
            <Typography>{T.UPLOAD_LICENSE_XL}</Typography>
          </Grid>
          <Grid item>
            <input
              name="fileName"
              style={{ color: fileName ? "transparent" : NETSMARTZ_THEME_COLOR }}
              type="file"
              onChange={(e) => handleLicenseUpload(e)}
              onClick={(e) => handleDeleteFile(e)}
            />
            {fileName && <div style={{ color: NETSMARTZ_THEME_COLOR, fontSize: "12px" }}>Chosen File: {fileName}</div>}
          </Grid>
        </Grid>
      </Box>
      <Form handleClose={handleClose} file={file} fileName={fileName} />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
    </Paper>
  );
};

export default AddToolsLicence;
