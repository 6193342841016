import { useEffect, useReducer } from "react";
import { Box, Tab, Paper, styled, Typography } from "@mui/material";
import T from "T";
import { handleError } from "utils/error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { get } from "lodash";
import { useLazyGetUserViewByIdQuery } from "api/members/getUserViewById";
import { useLocation, useNavigate } from "react-router-dom";
import usePMFetch from "hooks/usePMFetch";
import AssignmentForm from "./AssignmentForm";
import MISFooterButton from "components/common/MISFooterButton";
import ConfirmCancel from "./ConfirmCancel";
import { toast } from "react-toastify";
import { MISCurrentUser, isEmptyString, isNumberNegative } from "utils/validations";
import { useSaveAssignmentMutation } from "api/Assignment/saveAssignmentData";
import { useGetAssignmentQuestionMutation } from "api/Assignment/getAssignmentQuestion";
import { canAddTechAssignment } from "utils/permissions";
import MISLoader from "components/common/MISLoader";
import { useAssignTechAssessmentMutation } from "api/MockAssessment/assignTechAssessment";

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.background.black,
  minHeight: "unset",
  border: `1px solid ${theme.palette.border.tabsGrey}`,
}));

const AddAssignment = () => {
  const emptyTopicDetails = { topicName: "" };
  const { user } = MISCurrentUser();
  const userId = get(user, "user.id", "");
  const [saveAssignment, { isLoading: isSavingAssignment }] = useSaveAssignmentMutation();
  const [getAssignmentQuestion, { isLoading: isGettingAssignmentQuestion }] = useGetAssignmentQuestionMutation();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    skills: {},
    topicsList: [emptyTopicDetails],
    assignedDuration: "",
    experience: "",
    openConfirmCancel: false,
  });

  const { skills, topicsList, assignedDuration, experience, openConfirmCancel } = localState;
  const { skillList } = usePMFetch();
  const skillListResults = get(skillList, "results", []);
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));
  const [getUserViewById, { data: viewData }] = useLazyGetUserViewByIdQuery();
  const [assignTechAssessment] = useAssignTechAssessmentMutation();

  const refreshView = () => {
    getUserViewById({ id }).unwrap().catch(handleError);
  };
  useEffect(() => {
    refreshView();
  }, []);
  const record = get(viewData, "results", []);
  const fullName = get(record, "userName", "");
  const userName = get(record, "email", "");
  const empId = get(record, "empCode", "");

  const handleBack = () => {
    navigate(-1);
  };
  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setLocalState({ [name]: value });
  };
  const onHandleAutoCompleteChange = (type, value) => {
    const skillFilter = skillListResults.find((res) => res.id === value);
    setLocalState({ [type]: skillFilter });
  };

  const onHandleTopicsChange = (e, index) => {
    const { name, value } = e.target;
    topicsList[index][name] = value;
    setLocalState({ topicsList });
  };

  const onHandleTopicsAddMore = () => {
    topicsList.push(emptyTopicDetails);
    setLocalState({ topicsList });
  };

  const onHandleTopicsRemove = (index) => {
    topicsList.splice(index, 1);
    setLocalState({ topicsList });
  };

  const handleAddAssignment = () => {
    if (handleFormValidations()) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
    } else if (checkIfNumberIsNegative()) {
      toast.error(T.EXPERIENCE_OR_ASSIGNED_DURATION_CAN_NOT_BE_NEGATIVE);
    } else {
      const topicsToBeAssigned = topicsList.map((data) => data.topicName);
      const payload = {
        emp_id: empId,
        username: userName,
        Name: fullName,
        Tech_stack: get(skills, "skillName", ""),
        Topics_of_interest: topicsToBeAssigned,
        Total_time_minutes: assignedDuration,
        YOE: experience,
      };
      getAssignmentQuestion(payload)
        .unwrap()
        .then((res) => {
          const problemStatement = res[T.GENERATED_CODING_QUESTIONS];
          const mappedTopicList = topicsList.map((topic) => topic.topicName);
          const saveAssignmentPayload = {
            userId: !canAddTechAssignment() ? userId : id,
            technology: get(skills, "skillName", ""),
            assignedDuration: assignedDuration,
            experience: experience,
            topicAssigned: mappedTopicList,
            question: problemStatement,
          };
          saveAssignment(saveAssignmentPayload)
            .then(() => {
              assignTechAssessment({ id }).unwrap().catch(handleError);
              navigate(-1);
              setTimeout(() => {
                toast.success(T.ADDED_ASSIGNMENT_SUCCESSFULLY);
              }, 300);
            })
            .catch(handleError);
        })

        .catch(handleError);
    }
  };

  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };

  const handleClose = () => {
    navigate(-1);
  };

  const handleFormValidations = () => !skills || topicsList.some((topic) => !topic.topicName || isEmptyString(topic.topicName));
  const checkIfNumberIsNegative = () => isNumberNegative(assignedDuration) || isNumberNegative(experience);
  return (
    <Paper display="block" justifycontent="flex-start" sx={{ borderRadius: 2, p: 3 }}>
      {(isSavingAssignment || isGettingAssignmentQuestion) && <MISLoader />}
      <Box display="flex" alignItems={"center"} mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h5" fontWeight={600} ml={1}>
          {`${T.TECHNICAL_ASSIGNMENT_FOR} ${fullName}`}
        </Typography>
      </Box>
      <Box
        sx={{
          height: "calc(100vh - 245px)",
          overflowY: "auto",
        }}
      >
        <AssignmentForm
          skills={skills}
          assignedDuration={assignedDuration}
          experience={experience}
          topicsList={topicsList}
          onHandleChange={onHandleChange}
          onHandleTopicsChange={onHandleTopicsChange}
          onHandleTopicsAddMore={onHandleTopicsAddMore}
          onHandleTopicsRemove={onHandleTopicsRemove}
          onHandleAutoCompleteChange={onHandleAutoCompleteChange}
        />
      </Box>
      <MISFooterButton
        id={id}
        proceedButtonText={T.ASSIGN}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleSubmit={handleAddAssignment}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
    </Paper>
  );
};

export default AddAssignment;
