import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import { Box } from "@mui/system";
import T from "T";

const TrainingHistoryHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          border: "none",
          backgroundColor: BACKGROUND.cardDefault,
          padding: "5px",
        }}
      >
        <Typography fontSize={13.5} noWrap fontWeight={600}>
          {T.S_NO}
        </Typography>
      </TableCell>
      {Children.toArray(
        columns.map((column, ind) => (
          <TableCell
            sx={{
              border: "none",
              backgroundColor: BACKGROUND.cardDefault,
              paddingY: "5px",
            }}
          >
            <Typography fontSize={13.5} noWrap fontWeight={600}>
              {column.label}
            </Typography>
          </TableCell>
        )),
      )}
    </TableRow>
  </TableHead>
);

TrainingHistoryHeader.propTypes = {
  columns: PropTypes.array,
};

export default TrainingHistoryHeader;
