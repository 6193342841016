import { api } from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getReportCardStats: build.mutation({
      query: ({ payload }) => ({
        url: `/preSalesMapping/get/reportCardAnalysis/records`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetReportCardStatsMutation } = extendedApi;
