import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getToolsList: builder.mutation({
      query: ({ page, rowsPerPage, search }) => ({
        url: `/tools/get/all?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: {
          toolName: search ? search : "",
        },
      }),
    }),
  }),
});

export const { useGetToolsListMutation } = extendedApi;
