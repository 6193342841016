import PropTypes from "prop-types";
import { DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";
import T from "T";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get, noop, sortBy } from "lodash";

const AssignDialog = ({
  onClose = noop,
  experience,
  topics,
  techStack,
  techStackList,
  handleValidate = noop,
  onHandleAutoCompleteChange = noop,
  onHandleChange = noop,
  handleSubmit = noop,
}) => {
  return (
    <>
      <DialogTitle>
        Please Complete The Following Fields to Generate Technical Questions
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <MISAutocomplete
          label={T.TECH_STACK}
          listDetails={sortBy(techStackList)}
          value={techStack}
          required
          sx={{ mb: 1 }}
          getByLabelText={(option) => get(option, "skillName", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("techStack", newValue)}
        />

        <MISTextField
          label={T.EXPERIENCE}
          fullWidth
          placeholder={T.EXPERIENCE}
          autoComplete="on"
          size="small"
          InputProps={{
            inputProps: {
              type: "number",
              pattern: "[0-9]*",
            },
          }}
          variant="outlined"
          required
          sx={{ mb: 1 }}
          name="experience"
          value={experience}
          onChange={(e) => onHandleChange(e)}
        />
        <MISTextField
          label={"Topics"}
          fullWidth
          placeholder={"Topics"}
          autoComplete="on"
          size="small"
          required
          sx={{ mb: 1 }}
          variant="outlined"
          name="topics"
          multiline
          rows={3}
          value={topics}
          onChange={(e) => onHandleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          disableProceed={!handleValidate()}
          proceedButtonText={T.SUBMIT}
          handleClose={onClose}
          handleSubmit={handleSubmit}
        />
      </DialogActions>
    </>
  );
};

AssignDialog.propTypes = {
  onClose: PropTypes.func,
  experience: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  topics: PropTypes.string.isRequired,
  techStack: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  techStackList: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleValidate: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
};

export default AssignDialog;
