import { useState } from "react";
import { TabContext, TabList, TabPanel } from "@mui/lab";

import { Grid, Paper, Tooltip } from "@mui/material";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { StyledTab } from "components/MasterSetting";
import { Box } from "@mui/system";
import T from "T";
import RecentJoinersTable from "./RecentJoiners/Table";
import ReferralsTable from "./Recommendations/Table";
import { handleError } from "utils/error";
import MISLoader from "components/common/MISLoader";
import { useAskForReferralsMutation } from "api/Referrals/askForReferrals";
import { toast } from "react-toastify";
import { canViewRecommendations, canViewRecommendationsTab } from "utils/permissions";
import CompaniesTable from "./Companies/Table";
import { shallowEqual, useSelector } from "react-redux";
import { get } from "lodash";
import { recommendationsTabStore } from "slices/recommendationsTabSlice";
import { useDispatch } from "react-redux";
import { FactCheckOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";

const Referrals = () => {
  const [askForReferrals, { isLoading: loading }] = useAskForReferralsMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { storedTab } = useSelector(
    (state) => ({
      storedTab: get(state, "RecommendationsTabSlice.storedTab", ""),
    }),
    shallowEqual,
  );

  const [tabValue, setTabValue] = useState(
    storedTab ? storedTab : canViewRecommendationsTab() && canViewRecommendations() ? "1" : "2",
  );

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    dispatch(recommendationsTabStore({ storedTab: newValue }));
  };

  const handleAskForReferrals = (user_id) => {
    askForReferrals({ user_id })
      .unwrap()
      .then(() => {
        toast.success("Referral request sent successfully!");
      })
      .catch(handleError);
  };

  return (
    <>
      <Box sx={{ display: "flex", flex: 1, flexDirection: "row-reverse", marginRight: 2.5, marginLeft: 2.5 }}>
        <Box item>
          <Tooltip title={T.REPORT_CARD} placement="top">
            <FactCheckOutlined
              onClick={() => {
                navigate(routes.app.recommendationReportCard);
              }}
              sx={{
                cursor: "pointer",
                color: "themeColor",
                margin: "auto",
                maxWidth: "inherit",
                width: 30,
                height: 30,
                m: 1,
                mr: 0,
              }}
            />
          </Tooltip>
        </Box>
      </Box>
      <Paper display={"block"} sx={{ borderRadius: 2 }}>
        {loading && <MISLoader />}

        <TabContext value={tabValue}>
          <TabList
            variant="fullWidth"
            indicatorColor="false"
            onChange={handleTabChange}
            aria-label="lab API tabs example"
            sx={{
              "& .MuiTab-root.Mui-selected": {
                color: BACKGROUND.white,
                backgroundColor: NETSMARTZ_THEME_COLOR,
              },
              "& .MuiTab-root": {
                fontWeight: 600,
              },
            }}
          >
            {canViewRecommendations() && (
              <StyledTab sx={{ borderTopLeftRadius: "10px" }} label={T.RECENT_JOINERS.toUpperCase()} value="1" />
            )}
            <StyledTab label={T.RECOMMENDATIONS.toUpperCase()} value="2" />
            {canViewRecommendations() && <StyledTab sx={{ borderTopRightRadius: "10px" }} label={"COMPANIES"} value="3" />}
          </TabList>
          <Box
            sx={{
              "& .MuiTabPanel-root": {
                p: 2,
                pt: 0,
              },
            }}
          >
            {canViewRecommendations() && (
              <TabPanel value="1">
                <RecentJoinersTable handleAskForReferrals={handleAskForReferrals} />
              </TabPanel>
            )}

            <TabPanel value="2">
              <ReferralsTable />
            </TabPanel>
            {canViewRecommendations() && (
              <TabPanel value="3">
                <CompaniesTable />
              </TabPanel>
            )}
          </Box>
        </TabContext>
      </Paper>
    </>
  );
};

export default Referrals;
