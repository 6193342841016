import PropTypes from "prop-types";
import { noop } from "lodash";
import { PAGINATION } from "settings/constants/pagination";
import AssignTable from "./AssignTable";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const AssignTab = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  allTableRows = [],
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  handleReview = noop,
  generate_Certificate = noop,
  handleDeleteCourse = noop,
}) => {
  return (
    <AssignTable
      page={page}
      onPageChange={onPageChange}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={onRowsPerPageChange}
      totalTableRowsCount={totalTableRowsCount}
      totalPageCount={totalPageCount}
      allTableRows={allTableRows}
      handleReview={handleReview}
      generate_Certificate={generate_Certificate}
      handleDeleteCourse={handleDeleteCourse}
    />
  );
};

AssignTab.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  generate_Certificate: PropTypes.func,
  handleSubCourse: PropTypes.func,
  handleReview: PropTypes.func,
};

export default AssignTab;
