import PropTypes from "prop-types";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from "@mui/material";

import T from "T";
import MISButton from "components/common/MISButton";
import MISDialog from "components/common/MISDialog";

const RemoveConfirmation = ({ isDialogOpen, handleClose, handleSubmit }) => {
  return (
    <MISDialog open={isDialogOpen} handleClose={handleClose}>
      <DialogTitle>
        <IconButton onClick={handleClose}>
          <ArrowBack />
        </IconButton>
        {"Are you sure ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color={"red"}>{"You are about to remove the selected user/s."}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <MISButton variant="outlined" onClick={handleClose}>
          {T.CANCEL}
        </MISButton>
        <MISButton variant="contained" onClick={handleSubmit}>
          {T.CONFIRM}
        </MISButton>
      </DialogActions>
    </MISDialog>
  );
};

RemoveConfirmation.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default RemoveConfirmation;
