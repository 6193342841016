import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getContractHistory: builder.mutation({
      query: (id) => ({
        url: `/tools/license/contract/history?toolsUserMappingId=${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetContractHistoryMutation } = extendedApi;
