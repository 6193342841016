import { IconButton, Paper, Typography } from "@mui/material";
import T from "T";
import Form from "./Form";
import PropTypes from "prop-types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useLazyGetAllDepartmentQuery } from "api/members/getDepartmentList";
import { handleError } from "utils/error";
import { PAGINATION } from "settings/constants/pagination";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const AddRecommendation = () => {
  const [getAllDepartment, { data: departmentList }] = useLazyGetAllDepartmentQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const isSecondLevel = location?.state?.isSecondLevel;
  const record = location?.state?.record;
  const user_id = location?.state?.user_id ? location?.state?.user_id : record?.user_id;
  const showRecommendedBy = location?.state?.recommendedBy;
  const handleBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    getAllDepartment({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE }).unwrap().catch(handleError);
  }, []);

  return (
    <Paper sx={{ p: 3 }}>
      <Box display="flex" alignItems="center" mb={1.5}>
        <IconButton fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h5" fontWeight={600}>
          {T.EMPLOYEE_RECOMMENDATION_FORM}
        </Typography>
      </Box>
      <Form
        departmentList={departmentList}
        isSecondLevel={isSecondLevel}
        user_id={user_id}
        showRecommendedBy={showRecommendedBy}
        record={record}
      />
    </Paper>
  );
};
AddRecommendation.propTypes = {
  redirectPath: PropTypes.string,
};
export default AddRecommendation;
