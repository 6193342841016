import { Box } from "@mui/material";
import { keyframes } from "@emotion/react";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

const bounce = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
  } 
  40% {
    transform: scale(1);
  }
`;

const Dot = ({ delay }) => (
  <Box
    sx={{
      width: 8,
      height: 8,
      backgroundColor: NETSMARTZ_THEME_COLOR,
      borderRadius: "50%",
      display: "inline-block",
      mr: 0.5,
      animation: `${bounce} 1.4s infinite ease-in-out both`,
      animationDelay: delay,
    }}
  />
);

const ThreeDotsLoader = () => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Dot delay="0s" />
      <Dot delay="0.16s" />
      <Dot delay="0.32s" />
    </Box>
  );
};

export default ThreeDotsLoader;
