import { Typography, Divider, Grid, Card, Box, Stack, Tooltip } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import T from "T";
import { ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import { useLocation, useNavigate } from "react-router-dom";
import MISButton from "components/common/MISButton";
import { shallowEqual, useDispatch } from "react-redux";
import {
  manageMockInterviewUserLogin,
  mockInterviewResponseArrStore,
  mockInterviewStatus,
  mockInterviewStore,
  mockInterviewStore1,
} from "slices/mockInterviewSlice";
import { useLazyGetExistingResponsesQuery } from "api/MockInterview/getExistingResponses";
import { get } from "lodash";
import { MISCurrentUser } from "utils/validations";
import { handleError } from "utils/error";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const APP_PREFIX = "/app";
const InterviewResponses = () => {
  const dispatch = useDispatch();
  const { user } = MISCurrentUser();
  const userId = get(user, "user.id", "");
  const { isMockInterviewAssigned } = useSelector(
    (state) => ({
      isMockInterviewAssigned: get(state, "MockInterviewSlice.isMockInterviewAssigned", null),
    }),
    shallowEqual,
  );
  const [getExistingResponses] = useLazyGetExistingResponsesQuery();
  const { isXs } = GET_SIZE();
  const [createdAt, setCreatedAt] = useState(null);
  const navigate = useNavigate();
  function convertToTitleCase(str) {
    return str.replace(/_/g, " ").replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }
  useEffect(() => {
    getExistingResponses({ userId })
      .unwrap()
      .then((res) => {
        const createdAt = get(res, "createdAt", []);
        const isMockInterviewAssigned = get(res, "isMockInterviewAssigned", false);
        dispatch(mockInterviewStatus({ isMockInterviewAssigned: isMockInterviewAssigned }));
        setCreatedAt(createdAt);
      })
      .catch(handleError);
  }, []);
  const handleReAttempt = () => {
    getExistingResponses({ userId })
      .unwrap()
      .then((res) => {
        const responseArr = get(res, "mockInterviewLevels", []);
        dispatch(mockInterviewResponseArrStore({ responseArr: responseArr }));
        const resLength = responseArr.length;
        const isMockInterviewAssigned = get(res, "isMockInterviewAssigned", null);
        if (resLength > 0) {
          navigate(`${APP_PREFIX}/mock-interview`, { state: { isMockInterviewAssigned: isMockInterviewAssigned } });
        }
      })
      .catch(handleError);
    dispatch(mockInterviewStore({ mockResponses: [] }));
    dispatch(mockInterviewStore1({ formSubmitted: false }));
    dispatch(manageMockInterviewUserLogin({ mockInterviewUserRegistered: false }));
  };

  const location = useLocation();
  const coloredCard = (item) => {
    return (
      <Box
        sx={{
          minHeight: "106px",
          minWidth: "170px",
        }}
      >
        <Card
          sx={{
            background: "background.white",
            height: "45%",
            margin: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "10px 10px 30px -10px rgba(0,0,0,0.2)",
            minHeight: "inherit",
            minWidth: "inherit",
            alignItems: "center",
            position: "relative",
            padding: "10px",
            borderRadius: "8px",
          }}
        >
          <Stack display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Typography variant="h6" margin="auto" textAlign={"center"} sx={{ fontWeight: "bold", fontSize: "20px" }}>
              {convertToTitleCase(item.title)}
            </Typography>
          </Stack>
          <Box width={"100%"}>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.8 }}>
              {item.level < 3 ? (
                <Typography sx={{ fontWeight: "bold" }} color={ERROR.main}>
                  Level {item.level}
                </Typography>
              ) : item.level === 3 ? (
                <Typography sx={{ color: NETSMARTZ_THEME_COLOR, fontWeight: "bold" }}>Level {item.level}</Typography>
              ) : (
                <Typography sx={{ fontWeight: "bold" }} color={SUCCESS.main}>
                  Level {item.level}
                </Typography>
              )}
              <Tooltip title={<span style={{ width: "50px", fontSize: "13px" }}>{item.desc}</span>}>
                <InfoIcon className="info_ic" fontSize="medium" sx={{ ml: 1, cursor: "pointer", color: "gray" }} />
              </Tooltip>
            </Box>
          </Box>
        </Card>
      </Box>
    );
  };

  return (
    <Box p="10px 20px">
      <Box ml={3} mt={3}>
        <Typography variant="subtitle2" sx={{ fontSize: 14 }}>
          Last Updated: {createdAt}
        </Typography>
      </Box>
      <Grid container p={3} pt={1} spacing={3} sx={{ mt: isXs && "30px" }}>
        {location?.state?.responseArr?.map((item, index) => (
          <Grid item sm={6} lg={3} xs={12} key={index}>
            {coloredCard(item)}
          </Grid>
        ))}
        {isMockInterviewAssigned && (
          <Grid item xs={12} textAlign={"center"}>
            <MISButton sx={{ padding: "8px 24px", fontSize: "18px" }} onClick={handleReAttempt}>
              {T.RE_ATTEMPT}
            </MISButton>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
export default InterviewResponses;
