import { useEffect } from "react";

import { useLazyGetProjectManagerQuery } from "api/projects/getProjectManager";
import { useLazyGetFunctionalManagerQuery } from "api/members/getFunctionalHead";

import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetWorkLocationQuery } from "api/members/getWorkLocation";
import { useLazyGetSkillListQuery } from "api/skills/getSkillList";
import { useLazyGetProjectListQuery } from "api/projects/getProjectList";
import { useLazyGetAllDepartmentQuery } from "api/members/getDepartmentList";
import { useLazyGetCourseListQuery } from "api/training/courseDetail/getCourseList";
import { useLazyGetRoleListQuery } from "api/roles/getRoleList";
import { shallowEqual, useDispatch } from "react-redux";
import { filtersStore } from "slices/filtersSlice";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { useLazyGetBYTManagerQuery } from "api/getBYTManager";
import { useLazyGetCompanyListQuery } from "api/members/getCompanyList";

const { INITIAL_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;

const usePMFetch = () => {
  const dispatch = useDispatch();
  const [getProjectManager] = useLazyGetProjectManagerQuery();
  const [getFunctionalManager] = useLazyGetFunctionalManagerQuery();
  const [getWorkLocation] = useLazyGetWorkLocationQuery();
  const [getAllDepartment] = useLazyGetAllDepartmentQuery();
  const [getSkillList] = useLazyGetSkillListQuery();
  const [getProjectList] = useLazyGetProjectListQuery();
  const [getCourseList] = useLazyGetCourseListQuery();
  const [getRoleList] = useLazyGetRoleListQuery();
  const [getBYTManager] = useLazyGetBYTManagerQuery();
  const [getCompanyList] = useLazyGetCompanyListQuery();

  const { storedFilterData } = useSelector(
    (state) => ({
      storedFilterData: get(state, "FiltersSlice", {}),
    }),
    shallowEqual,
  );

  useEffect(() => {
    if (!storedFilterData.workLocationList) {
      getWorkLocation({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ workLocationList: res }));
        });
    }
    if (!storedFilterData.projectManagers) {
      getProjectManager({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ projectManagers: res }));
        });
    }
    if (!storedFilterData.functionalManagers) {
      getFunctionalManager({
        page: INITIAL_PAGE,
        rowPerPage: FULL_ROWS_PER_PAGE,
      })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ functionalManagers: res }));
        });
    }
    if (!storedFilterData.skillList) {
      getSkillList({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ skillList: res }));
        });
    }
    if (!storedFilterData.projectList) {
      getProjectList({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ projectList: res }));
        });
    }
    if (!storedFilterData.departmentList) {
      getAllDepartment({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ departmentList: res }));
        });
    }
    if (!storedFilterData.courseList) {
      getCourseList({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ courseList: res }));
        });
    }
    if (!storedFilterData.roleList) {
      getRoleList({ page: INITIAL_PAGE, rowsPerPage: FULL_ROWS_PER_PAGE })
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ roleList: res }));
        });
    }
    if (!storedFilterData.roleList) {
      getBYTManager()
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ bytManagers: res }));
        });
    }
    if (!storedFilterData.companyList) {
      getCompanyList()
        .unwrap()
        .then((res) => {
          dispatch(filtersStore({ companyList: res }));
        });
    }
  }, []);

  return storedFilterData;
};

export default usePMFetch;
