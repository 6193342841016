import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInterviewDetails: builder.query({
      query: ({ preSalesMappingId, userId }) => ({
        url: `/selected/user/getPitchedUser/interviewDetails`,
        method: "GET",
        params: { preSalesMappingId, userId },
      }),
    }),
  }),
});

export const { useLazyGetInterviewDetailsQuery } = extendedApi;
