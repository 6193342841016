import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Table as TableView, TableContainer, Card } from "@mui/material";
import { INSIDE_SALES_COLUMNS } from "settings/constants/members";
import MasterPagination from "components/MasterSetting/MasterPagination";
import InsideSalesTableHeader from "./InsideSalesTableHeader";
import MISNoData from "components/common/MISNoData";
import InsideSalesTableBody from "./InsideSalesTableBody";

const InsideSalesTable = ({
  totalTableRowsCount,
  totalPageCount,
  page,
  rowsPerPage,
  onPageChange,
  onRowsPerPageChange,
  allTableRows = [],
  handleExport = noop,
}) => {
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <TableContainer
        sx={{
          height: "calc(100vh - 303px)",
          overflow: "auto",
        }}
      >
        {allTableRows.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <InsideSalesTableHeader columns={INSIDE_SALES_COLUMNS} />
            <InsideSalesTableBody
              page={page}
              rowsPerPage={rowsPerPage}
              records={allTableRows}
              columns={INSIDE_SALES_COLUMNS}
              handleExport={handleExport}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

InsideSalesTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleExport: PropTypes.func,
};
export default InsideSalesTable;
