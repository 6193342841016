import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    deleteContract: builder.mutation({
      query: ({ id, licenseId }) => ({
        url: `/tools/license/delete/contract/history?toolsUserMappingId=${licenseId}&contractId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useDeleteContractMutation } = extendedApi;
