import { useRef, useEffect, Children } from "react";
import { Box, Button, Card, Grid, Typography } from "@mui/material";
import T from "T";
import { get, noop } from "lodash";
import PropTypes from "prop-types";
import { SubTitleTypography, getFEDateFormat } from "utils/members";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const TaskboxLabels = ({ allTableRows, labelId, handleLabelClick = noop }) => {
  return (
    <Box
      sx={{
        overflowY: "scroll",
        height: "100%",
        pt: 1,
        px: 1,
      }}
    >
      {Children.toArray(
        allTableRows.map((label, index) => {
          const isSelectedLabel = label.id === labelId;
          return (
            <>
              <Card
                key={index}
                onClick={() => handleLabelClick(label.id)}
                sx={{
                  px: 2,
                  py: 1,
                  mb: index !== allTableRows.length - 1 ? 2 : 0,
                  borderRadius: 2,
                  borderLeft: isSelectedLabel ? `3px solid ${NETSMARTZ_THEME_COLOR}` : "",
                }}
              >
                <Box display="flex" whiteSpace="nowrap" width="100%" alignItems="center">
                  <Typography variant="subtitle2" style={{ fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}>
                    {label.labelType}
                  </Typography>
                  <SubTitleTypography ml={1} sx={{ flexGrow: 1, textAlign: "end" }}>
                    {label.count}
                  </SubTitleTypography>
                </Box>
              </Card>
            </>
          );
        }),
      )}
    </Box>
  );
};

TaskboxLabels.propTypes = {
  allTableRows: PropTypes.array.isRequired,
  handleLabelClick: PropTypes.func,
};

export default TaskboxLabels;
