import PropTypes from "prop-types";
import { Table as TableView, TableContainer } from "@mui/material";
import { INTERVIEW_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "./TableHeader";
import InterviewTableBody from "./InterviewTableBody";

const InterviewTable = ({ allTableRows = [] }) => {
  return (
    <TableContainer>
      <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
        <TableHeader columns={INTERVIEW_TABLE_HEADER} />
        <InterviewTableBody records={allTableRows} />
      </TableView>
    </TableContainer>
  );
};

InterviewTable.propTypes = {
  allTableRows: PropTypes.array,
};

export default InterviewTable;
