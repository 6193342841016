import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCostCenterById: builder.query({
      query: (id) => ({
        url: `/costCenter/get/costCenterById?costCenterId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetCostCenterByIdQuery } = extendedApi;
