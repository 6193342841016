import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    assignCourse: build.mutation({
      query: (body) => ({
        url: "/training/assignTraining",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { useAssignCourseMutation } = extendedApi;
