import { useEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Grid, MenuItem, Typography, Rating, Box, DialogTitle, IconButton, Tooltip, Card, CardActions } from "@mui/material";
import MISTextField from "components/common/MISTextField";
import T from "T";
import { isEmptyString } from "utils/validations";
import SelectCheck from "@mui/icons-material/Check";
import MISFooterButton from "components/common/MISFooterButton";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useLazyGetUserViewByIdQuery } from "api/members/getUserViewById";
import { toast } from "react-toastify";
import { get } from "lodash";
import MISDatePicker from "components/common/MISDatePicker";
import { useLazySubmitFeedbackByIdQuery } from "api/members/submitFeedback";
import WithInputLabel from "components/common/WithInputLabel";
import { format, isValid } from "date-fns";
import { handleError } from "utils/error";
import { ERROR, NETSMARTZ_THEME_COLOR } from "theme/colors";
import InfoIcon from "@mui/icons-material/Info";
import { RATING_VALUES, YES_NO_VALUES } from "../memberModel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ConfirmSubmit from "../ConfirmSubmit";

const FeedbackForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);
  const CHARACTER_LIMIT = 250;

  const refreshView = () => {
    getUserViewById({ id }).unwrap().catch(handleError);
  };

  useEffect(() => {
    refreshView();
    handleValidation() && setLocalState({ isError: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reSelectedPM = location.state && location.state.reSelectedPM;
  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));

  const requiredFields = {
    employeeOnEDP: true,
    comments: true,
    technicalFeedback: true,
    techComment: true,
    // clientFeedback: false,
    // clientComment: false,
    communicationSkill: true,
    communicationComment: true,
    availability: true,
    teamPlayer: false,
    // enhancingSkill: true,
    // enhancingSkillComment: true,
    takingInitiative: false,
    takingInitiativeComment: false,
    discipline: true,
    disciplineComment: true,
    jiraFilling: true,
    jiraFillingComment: true,
  };

  const [getUserViewById, { data: viewData }] = useLazyGetUserViewByIdQuery();
  const [submitFeedbackById] = useLazySubmitFeedbackByIdQuery();
  const { previousLocation } = location.state || {};
  const record = get(viewData, "results", []);
  const fullName = get(record, "userName", "");
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    employeeOnEDP: false,
    comments: "",
    technicalFeedback: 0,
    techComment: "",
    clientFeedback: "",
    clientComment: "",
    communicationSkill: "",
    communicationComment: "",
    availability: 0,
    teamPlayer: 0,
    enhancingSkill: 0,
    enhancingSkillComment: "",
    takingInitiative: 0,
    takingInitiativeComment: "",
    discipline: 0,
    disciplineComment: "",
    jiraFilling: 0,
    jiraFillingComment: "",
    technologyDetails: [],
    primarySkill: "",
    secondarySkill: "",
    startDate: null,
    endDate: null,
    openModal: false,
    isError: false,
  });
  const {
    employeeOnEDP,
    comments,
    technicalFeedback,
    techComment,
    clientFeedback,
    clientComment,
    communicationSkill,
    communicationComment,
    availability,
    teamPlayer,
    enhancingSkill,
    enhancingSkillComment,
    takingInitiative,
    takingInitiativeComment,
    jiraFilling,
    jiraFillingComment,
    discipline,
    disciplineComment,
    technologyDetails,
    primarySkill,
    secondarySkill,
    startDate,
    endDate,
    openModal,
    isError,
  } = localState;

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const handleRatingChange = (event) => {
    const { name, value } = event.target;
    const floatValue = parseFloat(value);
    setLocalState({
      [name]: !requiredFields[name] ? (localState[name] === floatValue ? 0 : floatValue) : floatValue,
    });
  };

  const handleValidation = () =>
    comments &&
    !isEmptyString(comments) &&
    technicalFeedback !== 0 &&
    techComment &&
    !isEmptyString(techComment) &&
    // enhancingSkill !== 0 && //Training
    // enhancingSkillComment !== "" &&
    !isNaN(parseFloat(communicationSkill)) &&
    (parseFloat(communicationSkill) < 3 ? communicationComment && !isEmptyString(communicationComment) : true) &&
    availability !== 0 &&
    (jiraFilling < 3 ? jiraFillingComment && !isEmptyString(jiraFillingComment) : true) && //Process Compliance
    (discipline < 3 ? disciplineComment && !isEmptyString(disciplineComment) : true) &&
    (employeeOnEDP ? isValid(new Date(startDate)) && isValid(new Date(endDate)) : true);

  const disableCondition = () => employeeOnEDP === false;

  const handleAdd = (payload) => {
    submitFeedbackById({ payload, id })
      .unwrap()
      .then(() => {
        toast.success(T.Feedback_CREATED_SUCCESSFULLY);
        if (previousLocation) {
          navigate(-1);
        } else {
          navigate(`/app/members/${id}/edit`, { state: { reSelectedPM: reSelectedPM ? reSelectedPM : null } });
        }
      })
      .catch(handleError);
  };
  const getPayload = () => {
    const payload = {
      employeeOnEDP,
      comments,
      technicalFeedback,
      techComment,
      clientFeedback,
      clientComment,
      communicationSkill,
      communicationComment,
      availability,
      teamPlayer,
      enhancingSkill,
      enhancingSkillComment,
      takingInitiative,
      takingInitiativeComment,
      jiraFilling,
      jiraFillingComment,
      discipline,
      disciplineComment,
      technologyDetails,
      primarySkill,
      secondarySkill,
      startDate: startDate ? getBEDateFormat(startDate) : null,
      endDate: endDate ? getBEDateFormat(endDate) : null,
    };
    return payload;
  };

  const handleSubmitModal = () => {
    if (handleValidation()) {
      setLocalState({ openModal: true });
    } else {
      toast.error("Please fill all the Required Fields");
      setLocalState({ isError: true });
    }
  };

  const handleCloseModal = () => {
    setLocalState({ openModal: false });
  };
  const handleSubmit = () => {
    const payload = getPayload();
    handleAdd(payload);
  };

  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? validDate : null,
    });
  };

  const handleClose = () => {
    if (previousLocation) {
      navigate(-1);
    } else {
      navigate(`/app/members/${id}/edit`, { state: { reSelectedPM: null } });
    }
  };
  const infoContent = (
    <>
      <Typography variant="body1">Communication Skill Rating Guidelines</Typography>
      <Grid md={12} sm={12}>
        <Typography variant="body">
          1 Star ⭐:Unacceptable Communication
          <br />2 Stars ⭐⭐: Below Average Communication
          <br />3 Stars ⭐⭐⭐:Average Communication
          <br />4 Stars ⭐⭐⭐⭐:Above Average Communication
          <br />5 Stars ⭐⭐⭐⭐⭐: Exceptional Communication
        </Typography>
      </Grid>
    </>
  );

  return (
    <Card>
      <Box sx={{ p: "20px", pb: "10px" }}>
        <Box display="flex" alignItems="center" mb={1}>
          <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleClose} />
          <Typography variant="h6" fontWeight={700} ml={1}>
            {`${fullName}'s ${T.FEEDBACK} `}
          </Typography>
        </Box>
        <Grid item md={12} sx={{ mb: "15px" }}>
          {/* <TopBar showActive={true} fullName={fullName} id={id} /> */}
        </Grid>
        <Box
          mb={2}
          sx={{
            maxHeight: "calc(100vh - 267px)",
            overflowY: "auto",
          }}
        >
          <Grid container spacing={2}>
            <ConfirmSubmit
              cancelButtonText={T.NO}
              proceedButtonText={T.YES}
              dialogMessageText={T.CONFIRM_SUBMIT}
              openConfirmSubmit={openModal}
              handleClose={handleCloseModal}
              handleSubmit={handleSubmit}
            />
            <Grid item md={4} xs={12}>
              <MISTextField
                label={T.EDP_CONFORMATION}
                fullWidth
                select
                required
                size="small"
                variant="outlined"
                name="employeeOnEDP"
                value={[employeeOnEDP]}
                onChange={(event) => handleChange(event)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderBottom: isError && employeeOnEDP === "" ? `3px solid ${ERROR.main}` : "",
                  },
                }}
              >
                {Object.keys(YES_NO_VALUES).map((key) => (
                  <MenuItem key={key} value={YES_NO_VALUES[key]}>
                    <Typography noWrap>{key}</Typography>
                    <SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />
                  </MenuItem>
                ))}
              </MISTextField>
            </Grid>
            <Grid item md={4} xs={12} sx={{ marginTop: "3px" }}>
              <MISDatePicker
                placeholder={T.START_DATE}
                inputFormat="MM/DD/YYYY"
                disabled={disableCondition()}
                value={startDate}
                label={T.START_DATE}
                maxDate={endDate}
                name="startDate"
                handleChange={onHandleDateChange}
                renderInput={(params) => <MISTextField {...params} />}
              />
            </Grid>
            <Grid item md={4} xs={12} sx={{ marginTop: "3px" }}>
              <MISDatePicker
                placeholder={T.END_DATE}
                inputFormat="MM/DD/YYYY"
                disabled={disableCondition()}
                label={T.END_DATE}
                value={endDate}
                name="endDate"
                minDate={startDate}
                // sx={{ ml: 1, mr: 1 }}
                handleChange={onHandleDateChange}
                renderInput={(params) => <MISTextField {...params} />}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "normal",
                }}
              >
                <WithInputLabel label={T.TECHNICAL_FEEDBACK} required={requiredFields.technicalFeedback} />
                <Rating
                  name="technicalFeedback"
                  value={technicalFeedback}
                  required={requiredFields.technicalFeedback}
                  precision={0.5}
                  onChange={(event) => handleRatingChange(event)}
                  sx={{
                    ml: 3,
                    "& .MuiRating-iconEmpty": {
                      "& path": {
                        color: isError && !technicalFeedback ? "red" : "",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <MISTextField
                  // label={T.COMMENTS}
                  fullWidth
                  required
                  placeholder={T.COMMENTS + "*"}
                  size="small"
                  variant="outlined"
                  name="techComment"
                  onChange={(event) => handleChange(event)}
                  inputProps={{
                    maxlength: CHARACTER_LIMIT,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderBottom: isError && !techComment ? `3px solid ${ERROR.main}` : "",
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "normal",
                }}
              >
                <WithInputLabel
                  label={T.TRAINING}
                  // required={requiredFields["enhancingSkill"]}
                  fontSize={15}
                  mt={2}
                />
                <Rating
                  name="enhancingSkill"
                  value={enhancingSkill}
                  required={requiredFields.enhancingSkill}
                  precision={0.5}
                  sx={{ ml: 3 }}
                  onChange={(event) => handleRatingChange(event)}
                />
              </Grid>

              <Grid item xs={12} sx={{ height: "2px" }}>
                <MISTextField
                  fullWidth
                  required
                  placeholder={T.COMMENTS}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    maxlength: CHARACTER_LIMIT,
                  }}
                  name="enhancingSkillComment"
                  onChange={(event) => handleChange(event)}
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "normal",
                }}
              >
                <WithInputLabel label={T.TAKING_INITIATIVES} required={requiredFields.takingInitiative} fontSize={15} mt={2} />
                <Rating
                  name="takingInitiative"
                  value={takingInitiative}
                  required={requiredFields.takingInitiative}
                  precision={0.5}
                  sx={{ ml: 3 }}
                  onChange={(event) => handleRatingChange(event)}
                />
              </Grid>
              <Grid item xs={15}>
                <MISTextField
                  // label={T.COMMENTS}
                  fullWidth
                  placeholder={T.COMMENTS}
                  size="small"
                  variant="outlined"
                  inputProps={{
                    maxlength: CHARACTER_LIMIT,
                  }}
                  name="takingInitiativeComment"
                  onChange={(event) => handleChange(event)}
                />
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid item xs={15}>
                <Grid sx={{ mb: 1 }}>
                  <MISTextField
                    label={T.COMMUNICATION_SKILLS}
                    required
                    fullWidth
                    select
                    size="small"
                    variant="outlined"
                    name="communicationSkill"
                    placeholder="Select"
                    onChange={(event) => handleChange(event)}
                    value={[communicationSkill]}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isError && communicationSkill === "" ? `3px solid ${ERROR.main}` : "",
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      {T.SELECT_OPTION}
                    </MenuItem>
                    {RATING_VALUES.map((key, index) => (
                      <MenuItem key={index} value={key}>
                        <Typography noWrap>{key}</Typography>

                        <SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />
                      </MenuItem>
                    ))}
                  </MISTextField>
                </Grid>

                {communicationSkill && communicationSkill < 4 && (
                  <MISTextField
                    fullWidth
                    placeholder={T.COMMENTS}
                    size="small"
                    variant="outlined"
                    name="communicationComment"
                    onChange={(event) => handleChange(event)}
                    inputProps={{
                      maxlength: CHARACTER_LIMIT,
                    }}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isError && communicationComment === "" ? `3px solid ${ERROR.main}` : "",
                      },
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid mt={5}>
              <Tooltip title={infoContent} arrow>
                <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "normal",
                }}
              >
                <WithInputLabel label={T.PROCESS_COMPLIANCE} required={requiredFields.jiraFilling} />
                <Rating
                  name="jiraFilling"
                  value={jiraFilling}
                  required={requiredFields.jiraFilling}
                  precision={0.5}
                  onChange={(event) => handleRatingChange(event)}
                  sx={{
                    ml: 3,
                    "& .MuiRating-iconEmpty": {
                      "& path": {
                        color: isError && !jiraFilling ? "red" : "",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {jiraFilling && jiraFilling < 3 ? (
                  <MISTextField
                    fullWidth
                    required={requiredFields.jiraFilling}
                    placeholder={T.COMMENTS}
                    size="small"
                    variant="outlined"
                    name="jiraFillingComment"
                    inputProps={{
                      maxlength: CHARACTER_LIMIT,
                    }}
                    onChange={(event) => handleChange(event)}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isError && !jiraFillingComment ? `3px solid ${ERROR.main}` : "",
                      },
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: "normal",
                }}
              >
                <WithInputLabel label={T.DISCIPLINE} required={requiredFields.discipline} />
                <Rating
                  name="discipline"
                  value={discipline}
                  required={requiredFields.discipline}
                  precision={0.5}
                  onChange={(event) => handleRatingChange(event)}
                  sx={{
                    ml: 3,
                    "& .MuiRating-iconEmpty": {
                      "& path": {
                        color: isError && !discipline ? "red" : "",
                      },
                    },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                {discipline && discipline < 3 ? (
                  <MISTextField
                    fullWidth
                    required={requiredFields.discipline}
                    placeholder={T.COMMENTS}
                    size="small"
                    variant="outlined"
                    name="disciplineComment"
                    inputProps={{
                      maxlength: CHARACTER_LIMIT,
                    }}
                    onChange={(event) => handleChange(event)}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderBottom: isError && !disciplineComment ? `3px solid ${ERROR.main}` : "",
                      },
                    }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  mt: 3,
                }}
              >
                <Box>
                  <WithInputLabel label={T.AVAILABILITY_FEEDBACK} required={requiredFields.availability} />
                </Box>
                <Box>
                  <Rating
                    name="availability"
                    value={availability}
                    required={requiredFields.availability}
                    precision={0.5}
                    onChange={(event) => handleRatingChange(event)}
                    sx={{
                      ml: 2.5,
                      "& .MuiRating-iconEmpty": {
                        "& path": {
                          color: isError && !availability ? "red" : "",
                        },
                      },
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item md={4} xs={12}>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "25px",
                }}
              >
                <Box>
                  <WithInputLabel label={T.TEAM_PLAYER} required={requiredFields.teamPlayer} />
                </Box>
                <Box>
                  <Rating
                    name="teamPlayer"
                    value={teamPlayer}
                    required={requiredFields.teamPlayer}
                    precision={0.5}
                    sx={{ ml: 2.5 }}
                    onChange={(event) => handleRatingChange(event)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <MISTextField
                label={T.COMMENTS}
                fullWidth
                required={requiredFields.comments}
                placeholder={T.COMMENTS}
                size="small"
                variant="outlined"
                name="comments"
                inputProps={{
                  maxlength: CHARACTER_LIMIT,
                }}
                helperText={`${comments.length}/${CHARACTER_LIMIT}`}
                multiline
                onChange={(event) => handleChange(event)}
                sx={{
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderBottom: isError && comments === "" ? `3px solid ${ERROR.main}` : "",
                  },
                }}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {/* <Grid sx={{ mb: 1 }}>
            <MISTextField
              label={T.CLIENT_FEEDBACK}
              fullWidth
              select
              size="small"
              variant="outlined"
              name="clientFeedback"
              onChange={(event) => handleChange(event)}
              value={[clientFeedback]}
            >
              <MenuItem value="">{T.SELECT_OPTION}</MenuItem>
              {RATING_VALUES.map((key, index) => (
                <MenuItem key={index} value={key}>
                  <Typography noWrap>{key}</Typography>
                  {<SelectCheck sx={{ width: "20px", height: "20px", display: "none" }} />}
                </MenuItem>
              ))}
            </MISTextField>
          </Grid> */}
              {/* {clientFeedback && clientFeedback < 3 && ( */}
              {/* <MISTextField
            label={T.COMMENTS}
            fullWidth
            required={requiredFields["comments"]}
            placeholder={T.COMMENTS}
            size="small"
            variant="outlined"
            name="comments"
            multiline
            onChange={(event) => handleChange(event)}
          /> */}
              <MISTextField
                fullWidth
                label={T.CLIENT_FEEDBACK}
                placeholder={T.COMMENTS}
                size="small"
                variant="outlined"
                name="clientComment"
                inputProps={{
                  maxlength: CHARACTER_LIMIT,
                }}
                helperText={`${clientComment.length}/${CHARACTER_LIMIT}`}
                multiline
                onChange={(event) => handleChange(event)}
              />
              {/* )} */}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <CardActions sx={{ justifyContent: "flex-end", pb: 2, pr: 3 }}>
        <MISFooterButton justify="end" handleSubmit={handleSubmitModal} handleClose={handleClose} />
      </CardActions>
    </Card>
  );
};

export default FeedbackForm;
