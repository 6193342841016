import { createSlice } from "@reduxjs/toolkit";

const communicationAssessmentFilterSlice = createSlice({
	name: "CommunicationAssessmentFilterSlice",
	initialState: {
		storedFilters: {
			//   minMockAverage: null,
			//   maxMockAverage: null,
			//   minQuestionEngagementScore: null,
			//   maxQuestionEngagementScore: null,
			//   minLogicalLevelScore: null,
			//   maxLogicalLevelScore: null,
			text: "",
			//   isNaUser: null,
		},
		// storedSearch: "",
	},
	reducers: {
		communicationAssessmentFilterStore: (state, action) => {
			state.storedFilters = action.payload.storedFilters;
			//   state.storedSearch = action.payload.storedSearch;
		},
	},
});
export const { communicationAssessmentFilterStore } = communicationAssessmentFilterSlice.actions;

export default communicationAssessmentFilterSlice.reducer;
