import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Paper, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import Form from "./Form";

const EditToolsLicence = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const handleClose = () => {
    navigate(-1);
  };

  return (
    <Paper sx={{ p: "16px 16px 0" }}>
      <Box display="flex" alignItems="center" mb={1}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleClose} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {`Edit ${state.toolName} License for ${state.licenseHolder}`}
        </Typography>
      </Box>
      <Form handleClose={handleClose} />
    </Paper>
  );
};

export default EditToolsLicence;
