import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    generateCertificate: builder.query({
      query: ({ id }) => ({
        url: `trainee/generate/certificate?trainingAssignmentId=${id}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useLazyGenerateCertificateQuery } = extendedApi;