import { Grid, Box, Typography, Checkbox, FormControl, FormControlLabel, DialogActions, DialogContent } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { noop } from "lodash";
import PropTypes from "prop-types";
import MISFooterButton from "components/common/MISFooterButton";
import T from "T";
import MISTextField from "components/common/MISTextField";
import { get } from "utils/lodash";
import MisSubCourseConfigurator from "./MisSubCourseConfigurator";

import MISDatePicker from "components/common/MISDatePicker";
import dayjs from "dayjs";

// const minDate = "2023-08-01";

const Form = ({
  courseId = "",
  reviewerId = "",
  priority,
  startDate = null,
  filtered = [],
  department,
  allTableRows = [],
  usersList = [],
  departmentList = [],
  subCourseData,
  handleChange = noop,
  handleReviwer = noop,
  handleDepartment = noop,
  handleAssign = noop,
  handlePriority = noop,
  onHandleDateChange = noop,
  handleOnDragEnd = noop,
  handleConfChange = noop,
  onHandleClose = noop,
}) => {
  const selectedCourse = allTableRows.find((course) => course.trainingCourseId === courseId);
  const courseType = get(selectedCourse, "assignmentType.courseAssignmentType", "");
  const courseDuration = selectedCourse?.courseDuration ? selectedCourse?.courseDuration : 0;
  const practicalDuration = selectedCourse?.practicalDuration ? selectedCourse?.practicalDuration : 0;
  const trainingDuration = courseDuration + practicalDuration;
  const minDate = new Date();
  const BufferDate = trainingDuration % 8 > 4 ? Math.ceil(trainingDuration / 8) : Math.floor(trainingDuration / 8);
  minDate.setDate(minDate.getDate() + BufferDate + 1);
  return (
    <>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MISAutocomplete
              label={T.DEPARTMENT}
              listDetails={departmentList}
              onReset={onHandleClose}
              getByLabelText={(option) => get(option, "departmentName", "")}
              value={department}
              sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
              onHandleChange={(event, newValue) => handleDepartment(event, newValue)}
            />
            <MISAutocomplete
              label={T.TRAINING_NAME}
              name="courseName"
              listDetails={
                department ? filtered.filter((item) => get(item, "department.id", "") === get(department, "id", "")) : filtered
              }
              required
              onReset={onHandleClose}
              getByLabelText={(option) => get(option, "label", "")}
              value={filtered.find((course) => course.id === courseId)}
              sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
              onHandleChange={(event, newValue) => handleChange(event, newValue)}
            />
          </Grid>
          {subCourseData.length > 0 && courseType !== T.OFFLINE_TRAINING ? (
            <Grid item xs={12}>
              <Box>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={priority}
                        onChange={(e) => {
                          handlePriority(e.target.checked);
                        }}
                        name="priority"
                        sx={{
                          paddingLeft: 3,
                        }}
                      />
                    }
                    label={"Priority"}
                  />
                </FormControl>
                <Typography ml={1}>{T.COURSE_NAME}</Typography>
                <MisSubCourseConfigurator
                  configData={subCourseData}
                  handleOnDragEnd={(result) => handleOnDragEnd(result)}
                  handleConfChange={(index, type, confData) => handleConfChange(index, type, confData)}
                />
              </Box>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <MISDatePicker
              label={T.COMPLETION_DATE}
              placeholder={T.COMPLETION_DATE}
              inputFormat="MM/DD/YYYY"
              value={startDate}
              minDate={dayjs(minDate).toISOString()}
              name="startDate"
              required
              disabled={!selectedCourse}
              // maxDate={endDate}
              handleChange={(event) => onHandleDateChange(event)}
            />
          </Grid>
          <Grid item xs={12}>
            <MISAutocomplete
              label={T.REVIEWER_NAME}
              name="reviewerName"
              listDetails={usersList}
              // required
              onReset={onHandleClose}
              getByLabelText={(option) => get(option, "userEmailId", "")}
              value={usersList.find((user) => user.id === reviewerId) || {}}
              sx={{
                // padding: "10px",
                ".MuiAutocomplete-popper": {
                  zIndex: 1100,
                },
              }}
              onHandleChange={(event, newValue) => {
                handleReviwer(event, newValue);
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <MISTextField
              fullWidth
              label={`${T.TRAINING_DURATION} ${T.IN_HRS}`}
              readOnly
              autoComplete="on"
              size="small"
              disabled={true}
              variant="outlined"
              name="courseDuration"
              value={trainingDuration}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={T.ASSIGN}
          disableProceed={!courseId || !startDate}
          handleClose={onHandleClose}
          handleSubmit={handleAssign}
        />
      </DialogActions>
    </>
  );
};

Form.propTypes = {
  courseId: PropTypes.number,
  priority: PropTypes.bool,
  startDate: PropTypes.object,
  filtered: PropTypes.array,
  allTableRows: PropTypes.array,
  handleChange: PropTypes.func,
  handleAssign: PropTypes.func,
  onHandleChange: PropTypes.func,
  handlePriority: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  handleOnDragEnd: PropTypes.func,
  handleConfChange: PropTypes.func,
  onHandleClose: PropTypes.func,
  subCourseData: PropTypes.array,
};
export default Form;
