import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    submitFeedbackById: builder.query({
      query: (data) => {
        const { payload, id } = data;
        return {
          url: `/feedback/employee/save?id=${id}`,
          method: "POST",
          body: payload,
        };
      },
    }),
  }),
});

export const { useLazySubmitFeedbackByIdQuery } = extendedApi;
