import { useState } from "react";
import { Box, Typography, Divider, Button, IconButton, Grid } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import ClearIcon from "@mui/icons-material/Clear";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

const MISDragDrop = ({ files, setFiles, size, multiple = false, fileName = null, fileTypes, maxFiles }) => {
  const [highlight, setHighlight] = useState(false);

  const handleDragOver = (e) => {
    e.preventDefault();
    setHighlight(true);
  };

  const handleFileDrop = (e) => {
    e.preventDefault();
    setHighlight(false);

    const droppedFiles = [...e.dataTransfer.files];
    if (maxFiles && files.length + droppedFiles.length > maxFiles) {
      toast.error(`Maximum ${maxFiles} files allowed.`);
    } else {
      setFiles([...files, ...droppedFiles.slice(0, maxFiles - files.length)]);
    }
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = files.filter((_, i) => i !== index);
    setFiles(updatedFiles);

    // Reset the file input element
    const fileInput = document.getElementById("file");
    fileInput.value = "";
  };

  const handleChange = (e) => {
    const selectedFiles = [...e.target.files];

    const zeroSizeFiles = selectedFiles.filter((file) => file.size === 0);

    if (zeroSizeFiles.length > 0) {
      toast.error("Selected file(s) have size 0. Please select valid file(s).");
      return;
    }

    if (maxFiles && files.length + selectedFiles.length > maxFiles) {
      toast.error(`Maximum ${maxFiles} files allowed.`);
      return;
    }

    if (fileTypes && fileTypes.length > 0) {
      const filteredFiles = selectedFiles.filter((file) => {
        const fileType = file.name.split(".")[1];
        return fileTypes.includes(fileType);
      });
      if (filteredFiles?.length > 0) {
        const remainingSlots = maxFiles ? maxFiles - files.length : Infinity;
        setFiles([...files, ...filteredFiles.slice(0, remainingSlots)]);
      } else {
        if (fileTypes.length === 1) {
          toast.error(`Only ${fileTypes[0]} format is allowed`);
        } else {
          toast.error(`Only ${fileTypes.join(", ")} formats are allowed`);
        }
      }
    } else {
      const remainingSlots = maxFiles ? maxFiles - files.length : Infinity;
      setFiles([...files, ...selectedFiles.slice(0, remainingSlots)]);
    }
  };

  return (
    <Grid
      container
      sx={{
        border: `2px dashed ${highlight ? NETSMARTZ_THEME_COLOR : "#ccc"}`,
        alignItems: "center",
        transformOrigin: "top left",
        ...(size === "small" && { transform: "scale(0.8)" }),
      }}
    >
      <Grid item xs={12} md={6}>
        <Box
          onDrop={handleFileDrop}
          onDragOver={handleDragOver}
          sx={{
            px: "20px",
            pt: "25px",
            pb: "0px",
            textAlign: "center",
            cursor: "pointer",
            borderRadius: "4px",
            minHeight: "230px",
          }}
        >
          <CloudUploadIcon style={{ fontSize: "40px", color: NETSMARTZ_THEME_COLOR }} />
          <Typography variant="body1" mt={2}>
            Drag & Drop files here
            <Box sx={{ display: "flex", alignItems: "center", my: 3 }}>
              <Divider sx={{ flex: 1, borderColor: "#ccc" }} />
              <Typography variant="body1" sx={{ mx: 2 }}>
                or
              </Typography>
              <Divider sx={{ flex: 1, borderColor: "#ccc" }} />
            </Box>
            <input id="file" name="file" type="file" multiple={multiple} onChange={handleChange} style={{ display: "none" }} />
            <label style={{ display: "flex", justifyContent: "center" }} htmlFor="file">
              <Button
                variant="contained"
                component="span"
                style={{ backgroundColor: NETSMARTZ_THEME_COLOR, fontSize: "13px", color: "#fff" }}
              >
                Browse Files
              </Button>
            </label>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box
          sx={{
            backgroundColor: "#f5f5f5",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "24px",
            minHeight: "230px",
          }}
        >
          {files?.length === 0 && fileName === null ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: "12px" }}>
              <Typography variant="body1" color="textSecondary">
                Files will appear here
              </Typography>
              <FolderCopyIcon style={{ fontSize: "48px", color: "gray" }} />
            </Box>
          ) : files?.length === 0 && fileName !== null ? (
            <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
              <Typography
                variant="body1"
                sx={{
                  color: NETSMARTZ_THEME_COLOR,
                  fontSize: "14px",
                  wordBreak: "break-all",
                  maxWidth: "calc(100% - 24px)",
                }}
              >
                <span style={{ color: "gray" }}>Uploaded File: </span>
                {fileName}
              </Typography>
            </Box>
          ) : (
            files &&
            files.map((file, index) => (
              <Box key={index} style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Typography
                  variant="body1"
                  sx={{
                    color: NETSMARTZ_THEME_COLOR,
                    fontSize: "14px",
                    wordBreak: "break-all",
                    maxWidth: "calc(100% - 24px)",
                    marginBottom: "4px",
                  }}
                >
                  {file.name}
                </Typography>
                <IconButton size="small" onClick={() => handleRemoveFile(index)}>
                  <ClearIcon fontSize="small" />
                </IconButton>
              </Box>
            ))
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

MISDragDrop.propTypes = {
  files: PropTypes.array,
  fileTypes: PropTypes.array,
  setFiles: PropTypes.func,
  size: PropTypes.oneOf(["small", "large"]),
  fileName: PropTypes.string,
  multiple: PropTypes.bool,
  maxFiles: PropTypes.number,
};

export default MISDragDrop;
