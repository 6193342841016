import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";
const ToolTypeMutation = ({
	addEditToolType = {},
	isAddEditToolTypeOpen = bool,
	onHandleChange = noop,
	handleClose = noop,
	handleSubmitToolType = noop,
}) => {
	return (
		<Dialog onClose={handleClose} open={isAddEditToolTypeOpen}>
			<DialogTitle display={"inline-flex"} alignItems={"center"}>
				<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
					<Box display="inline-flex" alignItems="center">
						<IconButton onClick={handleClose}>
							<ArrowBack />
						</IconButton>
						{get(addEditToolType, "toolTypeHeadName", "")
							? `${T.EDIT} ${get(addEditToolType, "toolTypeHeadName", "")}`
							: `${T.ADD} ${T.NEW} ${T.TOOL_TYPE}`}
					</Box>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ height: "inherit" }}>
				<MISTextField
					label={T.TOOL_TYPE}
					required
					placeholder={T.TOOL_TYPE}
					autoComplete="on"
					size="small"
					variant="outlined"
					name="toolTypeName"
					value={get(addEditToolType, "toolTypeName", "")}
					onChange={(e) => onHandleChange(e)}
				/>
			</DialogContent>
			<DialogActions>
				<MISFooterButton
					proceedButtonText={addEditToolType.toolTypeId ? T.UPDATE : T.SUBMIT}
					justify="end"
					sx={{ pb: 0.5 }}
					handleClose={handleClose}
					handleSubmit={
						addEditToolType.toolTypeId
							? () => handleSubmitToolType(addEditToolType.toolTypeId)
							: () => handleSubmitToolType()
					}
				/>
			</DialogActions>
		</Dialog>
	);
};

ToolTypeMutation.propTypes = {
	addEditToolType: PropTypes.object,
	isAddEditToolTypeOpen: PropTypes.bool,
	onHandleChange: PropTypes.func,
	handleClose: PropTypes.func,
	handleSubmitToolType: PropTypes.func,
};

export default ToolTypeMutation;
