import { Children, useState } from "react";
import { Grid, Typography, Chip, FormControlLabel, Checkbox } from "@mui/material";
import PropTypes from "prop-types";
import { noop } from "lodash";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import T from "T";
import MISTextField from "components/common/MISTextField";
import { BACKGROUND, ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { isAsstUrl, isNumber, isUrl } from "utils/validations";
import { get } from "utils/lodash";
import WithInputLabel from "components/common/WithInputLabel";
import MISAutocomplete from "components/common/MISAutocomplete";

const CreateCourse = ({
  id = "",
  courseName = "",
  typeId = "",
  courseTypeName = "",
  meetingLink = "",
  subCourses = [],
  courseDuration = {},
  practicalDuration = {},
  offlineAssignment = {},
  courseTypesListing = [],
  completionTypeListing = [],
  savedSubCourses = [],
  selectOfflineAssignment = false,
  department = null,
  departmentList = [],
  departmentLoading = false,
  handleDeleteChip = noop,
  handleSubCourseFileUpload = noop,
  handleSelectOfflineAssignment = noop,
  onHandleReqAddMore = noop,
  onHandleReqRemove = noop,
  onHandleResetCourse = noop,
  onHandleChange = noop,
  handleOfflineLink = noop,
  handleOfflineCompletionType = noop,
  handleOfflineFileUpload = noop,
  handleDeleteFile,
  handleCourseType = noop,
  handleCompletionType = noop,
  onHandleRequirementsChange = noop,
  handleSubCourseAssessmentFileUpload = noop,
  handleButtonClick = noop,
  handleKeyPress = noop,
}) => {
  // const [reqCount, setReqCount] = useState(0);

  // const handleCount = (count) => {
  //   setReqCount(count);
  // };

  const renderIcons = (index) => (
    <>
      {/* savedSubCourses is the data coming from the BE */}
      {
        // ((id && index >= savedSubCourses.length && index !== 0) || (!id && index > 0))
        <RemoveCircleOutlineIcon
          onClick={() => (subCourses.length === 1 ? onHandleResetCourse() : onHandleReqRemove(index))}
          fontSize="small"
          sx={{
            cursor: "pointer",
            color: NETSMARTZ_THEME_COLOR,
          }}
        />
      }
      {index === subCourses.length - 1 && (
        <AddCircleOutlineIcon
          onClick={() => onHandleReqAddMore()}
          fontSize="small"
          sx={{
            cursor: "pointer",
            color: NETSMARTZ_THEME_COLOR,
          }}
        />
      )}
    </>
  );
  const offlineFileName =
    offlineAssignment["showOfflineFileName"] && offlineAssignment["offlineAssignmentFileName"]
      ? offlineAssignment["offlineAssignmentFileName"]
      : offlineAssignment["showOfflineFileName"];
  return (
    <Grid container spacing={2}>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.COURSE_TYPE}
          name="courseType"
          listDetails={courseTypesListing}
          required
          getByLabelText={(option) => get(option, "courseAssignmentType", "")}
          value={courseTypesListing.find((courseType) => courseType.courseAssignmentTypeId === typeId)}
          sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
          onHandleChange={(event, newValue) => handleCourseType(event, newValue, "courseType")}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISAutocomplete
          label={T.DEPARTMENT}
          name="department"
          listDetails={departmentList}
          required
          getByLabelText={(option) => get(option, "departmentName", "")}
          value={department}
          sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
          onHandleChange={(event, newValue) => handleCourseType(event, newValue, "department")}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={T.TRAINING_NAME}
          required
          fullWidth
          placeholder={T.TRAINING_NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="courseName"
          value={courseName}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item md={5} xs={12}>
        <MISTextField
          label={`${T.TRAINING_DURATION} ${T.IN_HRS}`}
          required
          fullWidth
          placeholder={`${T.TRAINING_DURATION} ${T.IN_HRS}`}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="courseDuration"
          value={courseDuration}
          sx={{
            "& .MuiOutlinedInput-notchedOutline": {
              borderBottom: courseDuration && `3px solid ${isNumber(courseDuration) ? SUCCESS.main : ERROR.main}`,
            },
          }}
          onChange={onHandleChange}
        />
      </Grid>
      {/* check to be implemented for only Offline course type */}
      {courseTypeName === T.OFFLINE_TRAINING && (
        <Grid item md={5} xs={12}>
          <MISTextField
            label={T.MEETING_LINK}
            fullWidth
            placeholder={T.MEETING_LINK}
            autoComplete="on"
            size="small"
            variant="outlined"
            name="meetingLink"
            value={meetingLink}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderBottom: meetingLink && `3px solid ${isUrl(meetingLink) ? SUCCESS.main : ERROR.main}`,
              },
            }}
            onChange={onHandleChange}
          />
        </Grid>
      )}
      {/* check to be implemented for only technical course type */}
      {courseTypeName === T.TECHNICAL && (
        <Grid item md={5} xs={12}>
          <MISTextField
            label={T.PRACTICAL_DURATION}
            fullWidth
            placeholder={`${T.PRACTICAL_DURATION} ${T.IN_HRS}`}
            autoComplete="on"
            size="small"
            variant="outlined"
            name="practicalDuration"
            value={practicalDuration}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderBottom: practicalDuration && `3px solid ${isNumber(practicalDuration) ? SUCCESS.main : ERROR.main}`,
              },
            }}
            onChange={onHandleChange}
          />
        </Grid>
      )}
      {/* check to be implemented for only Offline course type */}
      {courseTypeName === T.OFFLINE_TRAINING && (
        <Grid item md={12} xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectOfflineAssignment}
                onChange={handleSelectOfflineAssignment}
                name="selectOfflineAssignment"
              />
            }
            label={"Want to add Assignment?"}
            sx={{ color: NETSMARTZ_THEME_COLOR, mt: 3 }}
          />
        </Grid>
      )}
      {selectOfflineAssignment && (
        <>
          <Grid item md={5} xs={12}>
            <MISAutocomplete
              label={T.COMPLETION_TYPE}
              name="offlineCompletionTypeId"
              listDetails={completionTypeListing}
              getByLabelText={(option) => get(option, "completionType", "")}
              value={completionTypeListing.find(
                (completionType) => completionType.completionTypeId === offlineAssignment["offlineCompletionTypeId"],
              )}
              sx={{
                ".MuiAutocomplete-popper": {
                  zIndex: 1100,
                },
              }}
              required
              onHandleChange={(event, newValue) => {
                handleOfflineCompletionType(event, newValue);
              }}
            />
          </Grid>
          <Grid item md={5} xs={12}>
            <MISTextField
              placeholder={T.ASSESSMENT_LINK}
              label={T.ASSESSMENT_LINK}
              value={offlineAssignment["offlineAssignmentLink"]}
              fullWidth
              name="offlineAssignmentLink"
              autoComplete="on"
              size="small"
              variant="outlined"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": {
                  borderBottom:
                    offlineAssignment["offlineAssignmentLink"] &&
                    `3px solid ${isUrl(offlineAssignment["offlineAssignmentLink"]) ? SUCCESS.main : ERROR.main}`,
                },
              }}
              onChange={handleOfflineLink}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <WithInputLabel label={T.UPLOAD_FILE}>
              {/* <input
                name="offlineAssignmentFileName"
                style={{ color: NETSMARTZ_THEME_COLOR, marginTop: 10 }}
                type="file"
                onChange={(e) => handleOfflineFileUpload(e)}
                onClick={(e) => handleDeleteFile(e)}
              /> */}
              <>
                <input
                  name="offlineAssignmentFileName"
                  style={{
                    color: offlineFileName ? "transparent" : NETSMARTZ_THEME_COLOR,
                    marginTop: 10,
                  }}
                  type="file"
                  onChange={(e) => handleOfflineFileUpload(e)}
                  onClick={(e) => handleDeleteFile(e)}
                />
                {offlineFileName && <div style={{ color: NETSMARTZ_THEME_COLOR }}>Chosen File: {offlineFileName}</div>}
              </>
            </WithInputLabel>
          </Grid>
        </>
      )}
      {courseTypeName !== T.OFFLINE_TRAINING && (
        <Grid item xs={12} mt={1}>
          {Children.toArray(
            subCourses.map((data, index) => {
              const courseFileName =
                subCourses[index]["showSubCourseFileName"] && subCourses[index]["fileName"]
                  ? subCourses[index]["fileName"]
                  : subCourses[index]["showSubCourseFileName"];
              const assessmentFileName =
                subCourses[index]["showAssessmentFileName"] && subCourses[index]["assessmentFileName"]
                  ? subCourses[index]["assessmentFileName"]
                  : subCourses[index]["showAssessmentFileName"];
              return (
                <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
                  <Grid item xs={12}>
                    <Grid item sx={{ display: "flex", alignItems: "center", backgroundColor: BACKGROUND.header }}>
                      <Typography fontSize={16} fontWeight={600}>
                        {`${T.COURSE_DESCRIPTION} ${index + 1}`}
                      </Typography>
                      <Grid item sx={{ display: "inline-flex", alignItems: "center", pl: 2 }}>
                        {renderIcons(index)}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <MISTextField
                      label={T.COURSE_NAME}
                      required
                      fullWidth
                      placeholder={T.COURSE_NAME}
                      autoComplete="on"
                      size="small"
                      variant="outlined"
                      name="subCourseName"
                      value={subCourses[index]["subCourseName"]}
                      onChange={(e) => onHandleRequirementsChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={3} xs={12}>
                    <MISAutocomplete
                      label={T.COMPLETION_TYPE}
                      name="completionType"
                      listDetails={completionTypeListing}
                      required
                      getByLabelText={(option) => get(option, "completionType", "")}
                      value={completionTypeListing.find(
                        (completionType) => completionType.completionTypeId === subCourses[index]["completionTypeId"],
                      )}
                      sx={{
                        ".MuiAutocomplete-popper": {
                          zIndex: 1100,
                        },
                      }}
                      onHandleChange={(event, newValue) => {
                        handleCompletionType(event, newValue, index);
                      }}
                    />
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <MISTextField
                      label={T.COURSE_DESCRIPTION}
                      fullWidth
                      placeholder={T.COURSE_DESCRIPTION}
                      autoComplete="on"
                      multiline
                      rows={3}
                      size="small"
                      variant="outlined"
                      name="subCourseDescription"
                      value={subCourses[index]["subCourseDescription"]}
                      onChange={(e) => onHandleRequirementsChange(e, index)}
                    />
                  </Grid>

                  <Grid item md={8} xs={12}>
                    <MISTextField
                      placeholder={`${T.COURSE_LINKS} ${T.PRESS_ENTER_TO_ADD_LINK}`}
                      label={T.COURSE_LINKS}
                      value={subCourses[index]["courseLinks"]}
                      fullWidth
                      name="courseLinks"
                      autoComplete="on"
                      size="small"
                      variant="outlined"
                      onChange={(e) => onHandleRequirementsChange(e, index)}
                      onBlur={() => handleButtonClick(subCourses[index]["courseLinks"], index)}
                      onKeyDown={(e) => handleKeyPress(e, index)}
                    />
                    <div>
                      {subCourses[index]["courseLinksArray"] && subCourses[index]["courseLinksArray"].length > 0
                        ? subCourses[index]["courseLinksArray"].map((courseLink, linkIndex) => (
                            <Chip
                              key={linkIndex}
                              label={courseLink}
                              onClick={() => window.open(courseLink, "_blank")}
                              onDelete={() => handleDeleteChip(index, linkIndex)}
                              style={{ margin: 5 }}
                            />
                          ))
                        : ""}
                    </div>
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <WithInputLabel label={T.UPLOAD_FILE}>
                      <>
                        <input
                          name="fileName"
                          style={{
                            color: courseFileName ? "transparent" : NETSMARTZ_THEME_COLOR,
                            marginTop: 10,
                          }}
                          type="file"
                          onChange={(e) => handleSubCourseFileUpload(e, index)}
                          // onClick={(e) => handleDeleteFile(e, index)}
                        />
                        {courseFileName && <div style={{ color: NETSMARTZ_THEME_COLOR }}>Chosen File: {courseFileName}</div>}
                      </>
                    </WithInputLabel>
                  </Grid>
                  <Grid item md={8} xs={12}>
                    <MISTextField
                      label={T.ASSESSMENT_LINK}
                      fullWidth
                      placeholder={T.ASSESSMENT_LINK}
                      autoComplete="on"
                      size="small"
                      variant="outlined"
                      name="subCourseAssessmentLink"
                      value={subCourses[index]["subCourseAssessmentLink"]}
                      sx={{
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderBottom:
                            subCourses[index]["subCourseAssessmentLink"] &&
                            `3px solid ${isUrl(subCourses[index]["subCourseAssessmentLink"]) ? SUCCESS.main : ERROR.main}`,
                        },
                      }}
                      onChange={(e) => onHandleRequirementsChange(e, index)}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <WithInputLabel label={T.UPLOAD_ASSESSMENT_FILE}>
                      {/* <input
                        name="assessmentFileName"
                        style={{ color: NETSMARTZ_THEME_COLOR, marginTop: 10 }}
                        type="file"
                        onChange={(e) => handleSubCourseAssessmentFileUpload(e, index)}
                        // onClick={(e) => handleDeleteFile(e, index)}
                      /> */}
                      <>
                        <input
                          name="assessmentFileName"
                          style={{
                            color: assessmentFileName ? "transparent" : NETSMARTZ_THEME_COLOR,
                            marginTop: 10,
                          }}
                          type="file"
                          onChange={(e) => handleSubCourseAssessmentFileUpload(e, index)}
                        />
                        {assessmentFileName && (
                          <div style={{ color: NETSMARTZ_THEME_COLOR }}>Chosen File: {assessmentFileName}</div>
                        )}
                      </>
                    </WithInputLabel>
                  </Grid>
                </Grid>
              );
            }),
          )}
        </Grid>
      )}
    </Grid>
  );
};

CreateCourse.propTypes = {
  id: PropTypes.string,
  typeId: PropTypes.string,
  subCourses: PropTypes.array,
  courseDuration: PropTypes.object,
  practicalDuration: PropTypes.object,
  courseTypesListing: PropTypes.array,
  savedSubCourses: PropTypes.array,
  handleDeleteChip: PropTypes.func,
  handleSubCourseFileUpload: PropTypes.func,
  onHandleReqAddMore: PropTypes.func,
  onHandleReqRemove: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleCourseType: PropTypes.func,
  onHandleRequirementsChange: PropTypes.func,
  handleSubCourseAssessmentFileUpload: PropTypes.func,
  handleButtonClick: PropTypes.func,
  handleKeyPress: PropTypes.func,
};

export default CreateCourse;
