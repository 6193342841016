import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Grid, Box, styled } from "@mui/material";
import InsideSalesFilters from "../InsideSalesFilters";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  filters = {},
  isFilterEmpty,
  skillList = {},
  onhandleFilterChange = noop,
  handleFilterSubmit = noop,
  handleFilterClose = noop,
}) => {
  return (
    <Grid
      container
      alignItems="flex-start"
      justifyContent="flex-start"
      sx={{
        mt: 1,
        p: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3.2} xs={12}>
        <InsideSalesFilters
          filters={filters}
          skillList={skillList}
          isFilterEmpty={isFilterEmpty}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
        />
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  filters: PropTypes.object,
  skillList: PropTypes.object,
  isFilterEmpty: PropTypes.bool,
  onhandleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterClose: PropTypes.func,
};

export default TopBar;
