import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCaseStudyDoc: build.mutation({
      query: ({ id, document, role }) => ({
        url: `/caseStudy/${role}/update?id=${id}`,
        method: "POST",
        body: document,
      }),
    }),
  }),
});

export const { useUpdateCaseStudyDocMutation } = extendedApi;
