import { ArrowBack } from "@mui/icons-material";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import UserTable from "./UserTable";
import MISTextField from "components/common/MISTextField";
import GroupsFilters from "./Filters/GroupsFilters";
import { isEqual } from "lodash";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { useState } from "react";
import { MISCurrentUser } from "utils/validations";
import WithInputLabel from "components/common/WithInputLabel";
const GroupsModal = ({
  filters,
  appliedFilters,
  columns,
  memberList,
  selectedMembers,
  allSelected,
  addAllReportees,
  isModalOpen,
  isUserFetching,
  dialogTitle,
  groupName,
  fileType,
  excelFile,
  filterData,
  handleChange,
  handleXLFileUpload,
  handleDeleteFile,
  handleCloseModal,
  handleSubmit,
  handleAddMembers,
  handleSelectMember,
  handleSelectAll,
  handleSelectAllReportees,
  handleFilterReset,
  handleFilterSubmit,
  handleFilterChange,
}) => {
  const { isMd, isLg } = GET_SIZE();
  const [filterOpen, setFilterOpen] = useState(false);
  const { user } = MISCurrentUser();
  const getFilterProps = () => ({
    filterOpen,
    setFilterOpen,
    filters,
    filterData,
    handleFilterReset,
    handleFilterChange,
    handleFilterSubmit,
  });

  const getUserTableProps = () => ({
    memberList,
    selectedMembers,
    columns,
    allSelected,
    isUserFetching,
    handleSelectMember,
    handleSelectAll,
  });

  return (
    <Dialog onClose={handleCloseModal} open={isModalOpen} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"} fullWidth>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <IconButton onClick={handleCloseModal} pl={0}>
          <ArrowBack />
        </IconButton>
        {dialogTitle}
      </DialogTitle>
      <DialogContent sx={{ minHeight: filterOpen ? "500px" : "inherit" }}>
        <Grid container spacing={2} display={"flex"} justifyContent={"space-between"} alignItems={"flex-end"}>
          <Grid item lg={3} md={4} xs={12}>
            <MISTextField
              fullWidth
              label={T.GROUP_NAME}
              name={T.GROUP_NAME}
              value={groupName}
              onChange={handleChange}
              required={true}
              size={"small"}
            />
          </Grid>
          <Grid item md={2} xs={12}>
            <WithInputLabel label={T.UPLOAD_FILE}>
              <input
                name="fileType"
                style={{
                  color: fileType ? "transparent" : NETSMARTZ_THEME_COLOR,
                  marginTop: 10,
                }}
                type="file"
                onChange={(e) => handleXLFileUpload(e)}
                onClick={(e) => handleDeleteFile(e)}
              />
              {fileType && <div style={{ color: NETSMARTZ_THEME_COLOR, fontSize: "12px" }}>Chosen File: {fileType}</div>}
            </WithInputLabel>
          </Grid>
          <Grid item md={2}>
            {dialogTitle === T.CREATE_GROUP && (
              <FormControlLabel
                control={<Checkbox checked={addAllReportees} onChange={handleSelectAllReportees} name="addAllReportees" />}
                label={user.role === T.PM ? T.SELECT_YOUR_REPORTEES : T.SELECT_ALL_MEMBERS}
              />
            )}
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            <Typography sx={{ color: NETSMARTZ_THEME_COLOR, display: isEqual(appliedFilters, {}) ? "" : "none" }}>
              {"Apply a Filter for Data"}
            </Typography>
            <GroupsFilters {...getFilterProps()} />
          </Grid>
          <Grid item lg={12} md={12} sm={12}>
            {!isEqual(appliedFilters, {}) && <UserTable {...getUserTableProps()} />}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <MISButton variant="outlined" onClick={handleCloseModal}>
          {T.CANCEL}
        </MISButton>
        {dialogTitle === T.CREATE_GROUP ? (
          <MISButton variant="contained" onClick={handleSubmit}>
            {T.SUBMIT}
          </MISButton>
        ) : (
          <MISButton variant="contained" onClick={handleAddMembers}>
            {T.UPDATE}
          </MISButton>
        )}
      </DialogActions>
    </Dialog>
  );
};

GroupsModal.propTypes = {
  filters: PropTypes.object.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  columns: PropTypes.array.isRequired,
  memberList: PropTypes.array.isRequired,
  filterData: PropTypes.object,
  selectedMembers: PropTypes.array.isRequired,
  allSelected: PropTypes.bool.isRequired,
  addAllReportees: PropTypes.bool.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
  isUserFetching: PropTypes.bool.isRequired,
  dialogTitle: PropTypes.string.isRequired,
  groupName: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleAddMembers: PropTypes.func.isRequired,
  handleSelectMember: PropTypes.func.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  handleSelectAllReportees: PropTypes.func.isRequired,
  handleFilterReset: PropTypes.func.isRequired,
  handleFilterSubmit: PropTypes.func.isRequired,
  handleFilterChange: PropTypes.func.isRequired,
};

export default GroupsModal;
