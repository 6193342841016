import { createSlice } from "@reduxjs/toolkit";

const assignTrainingFilterSlice = createSlice({
  name: "AssignTrainingFilterSlice",
  initialState: {
    storedFilters: {
      isOfflineSelected: false,
      isReviewerSelected: false,
      status: null,
      assignedBy: null,
      department: null,
      reportingManager: null,
      trainingType: null,
    },
  },
  reducers: {
    assignTrainingFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      // state.storedStartDate = action.payload.storedStartDate;
      // state.storeEndDate = action.payload.storeEndDate;
    },
  },
});
export const { assignTrainingFilterStore } = assignTrainingFilterSlice.actions;

export default assignTrainingFilterSlice.reducer;
