import { useReducer, useState } from "react";
import { Grid, Paper, Box, Typography } from "@mui/material";
import MISTextField from "components/common/MISTextField";
import { useNavigate } from "react-router-dom";
import MISDatePicker from "components/common/MISDatePicker";
import MISTimePicker from "components/common/MISTimePicker";
import { toast } from "react-toastify";
import MISFooterButton from "components/common/MISFooterButton";
import { format, isValid } from "date-fns";
import ConfirmCancel from "components/Training/ConfirmCancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom";
import T from "T";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { handleError } from "utils/error";
import { isUrl } from "utils/validations";
import MISAutocomplete from "components/common/MISAutocomplete";
import { useScheduleMockInterviewMutation } from "api/preSales/scheduleMockInterview";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { ERROR, SUCCESS } from "theme/colors";
const platformOptions = ["Zoom", "Teams", "Skype", "Google Meet", "Others"];

const MockInterviewForm = () => {
  const navigate = useNavigate();
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [scheduleMockInterview] = useScheduleMockInterviewMutation();
  const location = useLocation();
  const jdId = location.state ? location.state.jdId : null;
  const userId = location.state ? location.state.userId : null;

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    date: null,
    time: null,
    platform: "",
    link: "",
    instructions: "",
    openModal: false,
  });

  const { date, time, platform, link, instructions, openModal } = localState;

  const getFormattedDate = (val) => {
    if (!val) {
      return null;
    }

    const formattedDate = new Date(val);

    if (isValid(formattedDate)) {
      return format(formattedDate, BACKEND_DATE_FORMAT);
    }

    return null;
  };

  const getFormattedTime = (val) => {
    if (!val) {
      return null;
    }

    const formattedTime = new Date(val);

    if (isValid(formattedTime)) {
      const hours = formattedTime.getHours();
      const minutes = formattedTime.getMinutes();
      return `${hours}:${minutes}`;
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "date") {
      setLocalState({ [name]: getFormattedDate(value) });
    } else if (name === "time") {
      setLocalState({ [name]: value });
    } else if (name === "link") {
      setLocalState({ [name]: value.trim() });
    } else {
      setLocalState({ [name]: value });
    }
  };

  const handleCloseConfirmCancel = (proceed) => {
    setOpenConfirmCancel(false);
    if (proceed) {
      handleBack();
    }
  };
  const handleCancel = () => {
    setOpenConfirmCancel(true);
  };

  const handleSubmit = () => {
    const formattedTime = getFormattedTime(time);

    const formData = {
      interviewDate: date,
      interviewTime: `${formattedTime}:00`,
      medium: platform === "others" ? "Other" : platform,
      interviewlink: link,
      instructions: instructions,
    };

    scheduleMockInterview({
      userId: userId,
      presalesMappingId: jdId,
      formData,
    })
      .then((res) => {
        if (res.error) {
          handleError(res.error);
          navigate(-1);
        } else {
          toast.success("Mock Interview successfully scheduled");
          navigate(-1);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleSubmitModal = () => {
    if (!isUrl(link)) {
      toast.error("Please enter a valid URL!");
      return;
    }
    if (date && time && platform) {
      setLocalState({ openModal: true });
    } else {
      toast.error("Please fill all the Required Fields");
    }
  };

  const handleCloseModal = () => {
    setLocalState({ openModal: false });
  };

  return (
    <Paper sx={{ p: 3, minHeight: "calc(100vh - 170px)" }}>
      <Box display="flex" alignItems="center" mb={2}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer", mr: 1 }} onClick={handleBack} />
        <Typography variant="h5" fontWeight={600}>
          {T.SCHEDULE_MOCK_INTERVIEW}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <MISDatePicker
            placeholder="Select Date"
            inputFormat="MM/DD/YYYY"
            value={date ? new Date(date) : null}
            label="Date"
            name="date"
            disablePast
            handleChange={(newValue) => handleChange({ target: { name: "date", value: newValue } })}
            required
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <MISTimePicker
            placeholder="Select Time"
            value={time}
            label="Time"
            name="time"
            disabled={!date}
            handleChange={(newValue) => setLocalState({ time: newValue })}
            required
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <MISAutocomplete
            label="Platform"
            options={platformOptions}
            value={platform}
            placeholder="Platform"
            required
            getByLabelText={(option) => option || ""}
            onChange={(event, newValue) =>
              handleChange({
                target: { name: "platform", value: newValue },
              })
            }
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <MISTextField
            label="Link"
            fullWidth
            size="small"
            variant="outlined"
            name="link"
            value={link}
            placeholder="Enter link URL"
            onChange={handleChange}
            required
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderBottom: link && `3px solid ${isUrl(link) ? SUCCESS.main : ERROR.main}`,
              },
            }}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <MISTextField
            label="Instructions"
            fullWidth
            multiline
            rows={7}
            size="small"
            variant="outlined"
            name="instructions"
            value={instructions}
            onChange={handleChange}
          />
        </Grid>
        <Grid display="flex" sx={{ mt: 2 }} justifyContent="flex-end" item md={12} xs={12}>
          <MISFooterButton handleSubmit={handleSubmitModal} handleClose={handleCancel} />
        </Grid>
        <ConfirmSubmit
          cancelButtonText={T.NO}
          proceedButtonText={T.YES}
          dialogMessageText={T.CONFIRM_SUBMIT}
          openConfirmSubmit={openModal}
          handleClose={handleCloseModal}
          handleSubmit={handleSubmit}
        />
        <ConfirmCancel
          openConfirmCancel={openConfirmCancel}
          confrmCancelModal={() => handleCloseConfirmCancel(false)}
          handleClose={() => handleCloseConfirmCancel(true)}
        />
      </Grid>
    </Paper>
  );
};

export default MockInterviewForm;
