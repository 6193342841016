import { ArrowBack } from "@mui/icons-material";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	Box,
	Paper,
	IconButton,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { noop } from "lodash";
import { get } from "utils/lodash";

const ToolTypeTable = ({
	columns = [],
	toolTypeList = [],
	isOpenToolTypeDialog = bool,
	openAddEditToolTypeMutation = noop,
	handleClose = noop,
	handleDeleteToolType = noop,
}) => {
	const { isMd, isLg } = GET_SIZE();
	return (
		<Dialog onClose={handleClose} open={isOpenToolTypeDialog} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
			<DialogTitle display={"inline-flex"} alignItems={"center"}>
				<Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
					<Box display="inline-flex" alignItems="center">
						<IconButton onClick={handleClose}>
							<ArrowBack />
						</IconButton>
						{T.ADD_EDIT_TOOL_TYPE}
					</Box>
					<MISButton onClick={() => openAddEditToolTypeMutation()} startIcon={<AddIcon sx={{ width: 19 }} />}>
						{T.NEW}
					</MISButton>
				</Box>
			</DialogTitle>
			<DialogContent sx={{ height: "inherit" }}>
				<TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 350px)", overflow: "auto" }}>
					<Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
						<TableHead>
							<TableRow>
								{columns &&
									columns.length &&
									columns.map((column) => {
										return <TableCell key={column.key}>{column.label}</TableCell>;
									})}
							</TableRow>
						</TableHead>
						<TableBody>
							{toolTypeList.map((toolType, index) => {
								return (
									<TableRow key={toolType.toolTypeId} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
										{columns.map((column) => (
											<TableCell key={`${toolType.toolTypeId}-${column.key}`}>
												{column.key === "actions" ? (
													<>
														<IconButton
															sx={{ color: NETSMARTZ_THEME_COLOR }}
															onClick={() => {
																openAddEditToolTypeMutation(toolType.toolTypeId);
															}}
														>
															<EditIcon />
														</IconButton>
														<IconButton
															sx={{ color: NETSMARTZ_THEME_COLOR }}
															onClick={() => {
																handleDeleteToolType(toolType.toolTypeId);
															}}
														>
															<DeleteIcon />
														</IconButton>
													</>
												) : column.key === "id" ? (
													index + 1
												) : column.key === "createdBy" ? (
													get(toolType[column.key], "userName", "")
												) : (
													toolType[column.key]
												)}
											</TableCell>
										))}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</DialogContent>
		</Dialog>
	);
};

ToolTypeTable.propTypes = {
	columns: PropTypes.arrayOf(PropTypes.object),
	toolTypeList: PropTypes.arrayOf(PropTypes.object),
	isOpenToolTypeDialog: PropTypes.bool,
	openAddEditToolTypeMutation: PropTypes.func,
	handleClose: PropTypes.func,
	handleDeleteToolType: PropTypes.func,
};

export default ToolTypeTable;
