import { Skeleton, TableCell, TableRow, styled } from "@mui/material";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  padding: "8px 0px 30px 24px",
}));

const MISTableLoader = ({ colsNum, rowsNum, hasCheckList }) => {
  return [...Array(rowsNum)].map((row, rowIndex) => (
    <TableRow key={rowIndex}>
      {hasCheckList && (
        <StyledTableCell>
          <Skeleton animation="wave" variant="rectangular" width={20} height={20} sx={{ m: 1 }} />
        </StyledTableCell>
      )}
      {[...Array(colsNum)].map((col, colIndex) => (
        <StyledTableCell key={colIndex}>
          <Skeleton animation="wave" variant="text" />
        </StyledTableCell>
      ))}
    </TableRow>
  ));
};

export default MISTableLoader;
