import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getPreSalesByFilter: build.mutation({
      query: ({ page, rowPerPage, preSalesFilterRequestDto }) => ({
        url: `/preSales/filter?page=${page}&size=${rowPerPage}`,
        method: "POST",
        body: preSalesFilterRequestDto,
      }),
    }),
  }),
});

export const { useGetPreSalesByFilterMutation } = extendedApi;
