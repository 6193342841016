import React from "react";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ToolTypeTable from "./ToolTypeTable";
import ToolTypeMutation from "./ToolTypeMutation";

const ToolTypeDialog = ({
	toolTypeList = [],
	isOpenToolTypeDialog = bool,
	isAddEditToolTypeOpen = bool,
	addEditToolType = {},
	openAddEditToolTypeMutation = noop,
	closeAddEditToolTypeMutation = noop,
	handleClose = noop,
	onHandleChange = noop,
	handleSubmitToolType = noop,
	handleDeleteToolType = noop,
}) => {
	const columns = [
		{ label: "S.no", key: "id" },
		{ label: "Tool Type", key: "toolTypeName" },
		{ label: "Created By", key: "createdBy" },
		{ label: "Actions", key: "actions" },
	];
	return (
		<>
			<ToolTypeTable
				columns={columns}
				toolTypeList={toolTypeList}
				isOpenToolTypeDialog={isOpenToolTypeDialog}
				openAddEditToolTypeMutation={openAddEditToolTypeMutation}
				handleClose={handleClose}
				handleDeleteToolType={handleDeleteToolType}
			/>
			<ToolTypeMutation
				isAddEditToolTypeOpen={isAddEditToolTypeOpen}
				addEditToolType={addEditToolType}
				handleClose={closeAddEditToolTypeMutation}
				handleSubmitToolType={handleSubmitToolType}
				onHandleChange={onHandleChange}
			/>
		</>
	);
};

ToolTypeDialog.propTypes = {
	toolTypeList: PropTypes.arrayOf(PropTypes.object),
	isOpenToolTypeDialog: PropTypes.bool,
	isAddEditToolTypeOpen: PropTypes.bool,
	addEditToolType: PropTypes.object,
	openAddEditToolTypeMutation: PropTypes.func,
	closeAddEditToolTypeMutation: PropTypes.func,
	handleClose: PropTypes.func,
	onHandleChange: PropTypes.func,
	handleSubmitToolType: PropTypes.func,
	handleDeleteToolType: PropTypes.func,
};

export default ToolTypeDialog;
