import { Typography, Box, Button, Card, Paper, Grid, DialogActions } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISDialog from "components/common/MISDialog";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WithInputLabel from "components/common/WithInputLabel";
import MISTextField from "components/common/MISTextField";
import T from "T";
import PropTypes from "prop-types";

const SubmitAssessment = ({
  handleFileChange,
  selectedFile,
  handleSubmitAssessment,
  handleClose,
  openDialog = false,
  comments,
  updateComments,
  userId,
}) => {
  return (
    <MISDialog open={openDialog}>
      <Box sx={{ display: "flex", flexDirection: "column", p: 1 }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
            padding: "5px",
          }}
        >
          <Box display="flex" alignItems="center" pl={1} pt={1.5} minWidth={600}>
            <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleClose} />
            <Typography variant="h6" fontWeight={700} ml={1}>
              {T.CODE_ASSESSMENT_SUBMISSION}
            </Typography>
          </Box>
        </Box>
        <Paper
          sx={{
            p: 1.5,
            m: 1,
            mt: 1,
            backgroundColor: "background.card",
            boxShadow: "none",
            flexGrow: 1,
          }}
        >
          <Card
            sx={{
              padding: 2,
              mb: 3,
              borderRadius: 0,
            }}
          >
            <WithInputLabel label="Upload a ZIP file" gutterBottom>
              <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <input type="file" style={{ display: "none" }} id="file-input" onChange={handleFileChange} />
                <label htmlFor="file-input">
                  <Button
                    sx={{
                      mt: 3,
                      mb: 1,
                      bgcolor: NETSMARTZ_THEME_COLOR,

                      "&:hover": {
                        bgcolor: NETSMARTZ_THEME_COLOR,
                      },
                    }}
                    variant="contained"
                    size="small"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                  >
                    {T.BROWSE_FILES}
                  </Button>
                </label>
                <div>
                  <Box mt={3}>
                    {selectedFile && (
                      <Grid item>
                        <Box variant="outlined" sx={{ padding: 1, display: "flex", alignItems: "center" }}>
                          <Box fontSize={"14px"}>{selectedFile.name}</Box>
                        </Box>
                      </Grid>
                    )}
                  </Box>
                </div>
              </Box>
            </WithInputLabel>
          </Card>
          <MISTextField
            fullWidth
            placeholder="Enter your comments here"
            required
            variant="outlined"
            multiline
            rows={4}
            value={comments}
            sx={{ backgroundColor: "white", mt: 1 }}
            onChange={(event) => {
              updateComments(event.target.value);
            }}
          />
        </Paper>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
            size="small"
            onClick={() => handleSubmitAssessment(userId)}
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,

              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
          >
            {T.SUBMIT_ASSESSMENT}
          </Button>
        </DialogActions>
      </Box>
    </MISDialog>
  );
};

SubmitAssessment.propTypes = {
  handleFileChange: PropTypes.func,
  selectedFile: PropTypes.object,
  handleSubmitAssessment: PropTypes.func,
  handleClose: PropTypes.func,
  openDialog: PropTypes.bool,
  comments: PropTypes.string,
  updateComments: PropTypes.func,
  userId: PropTypes.number,
};
export default SubmitAssessment;
