import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getRejectionReasons: build.mutation({
      query: () => ({
        url: "/pitchedUser/reasonOfRejections/get/all",
        method: "GET",
      }),
    }),
  }),
});

export const { useGetRejectionReasonsMutation } = extendedApi;
