import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, camelCase } from "lodash";

import { Typography, Divider, Card, Button, Box } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { TESTIMONIAL_FILTER_OPTIONS } from "settings/constants/filter";

import { NETSMARTZ_THEME_COLOR, BACKGROUND } from "theme/colors";
import { GET_SIZE } from "utils/responsive";
import T from "T";
import { get } from "utils/lodash";

const TestimonialFilterOptions = ({
  filters = {},
  projectManagers = [],
  handleFilterClose = noop,
  handleFilterApply = noop,
  onhandleFilterChange = noop,
  handleClose = noop,
}) => {
  const TESTIMONIAL_STATUS_LIST = [T.PENDING, T.SUBMITTED];

  const { isXs } = GET_SIZE();

  const getFilterRecords = (val) => {
    switch (val) {
      case T.PROJECT_MANAGER:
        return projectManagers;
      case T.STATUS:
        return TESTIMONIAL_STATUS_LIST;
      default:
    }
  };

  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;
    switch (index) {
      case 0:
        return get(option, "name", "");
      case 1:
        return optionVal;
      default:
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          mt: 1,
          position: "absolute",
          width: isXs ? "80%" : "22%",
          zIndex: 400,
        }}
      >
        <Box
          sx={{
            maxHeight: "calc(100vh - 250px)",
            overflowY: "auto",
          }}
        >
          <Typography variant="subtitle1" p={1} textAlign="center">
            {T.FILTER}
          </Typography>
          <Divider />

          {Children.toArray(
            TESTIMONIAL_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);
              const key = camelCase(item);

              return (
                <Box display="flex" p="14px 20px" justifyContent="space-between" pb="15px">
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>

                  <MISAutocomplete
                    listDetails={lists}
                    size="small"
                    placeholder={T.SELECT}
                    sx={{ width: 185 }}
                    value={filters[key]}
                    getByLabelText={(option) => getLabelData(option, index)}
                    onHandleChange={(event, newValue) => {
                      onhandleFilterChange(newValue, key);
                    }}
                  />
                </Box>
              );
            }),
          )}
        </Box>
        <Divider />
        <Box display="flex" justifyContent="space-between" p={2}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              borderColor: BACKGROUND.black,
              color: BACKGROUND.black,

              "&:hover": {
                borderColor: NETSMARTZ_THEME_COLOR,
                color: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterClose}
          >
            {T.RESET}
          </Button>
          <Button
            variant="contained"
            size="small"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
              },
            }}
            onClick={handleFilterApply}
          >
            {T.APPLY}
          </Button>
        </Box>
      </Card>
    </ClickAwayListener>
  );
};

TestimonialFilterOptions.propTypes = {
  projectManagers: PropTypes.array,
  filters: PropTypes.object,
  onhandleFilterChange: PropTypes.func,
  handleFilterApply: PropTypes.func,
  handleFilterClose: PropTypes.func,
  handleClose: PropTypes.func,
};

export default TestimonialFilterOptions;
