import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, Tooltip } from "@mui/material";
import memo from "utils/react";
import { get } from "lodash";

const StyledTableCell = styled(TableCell)(({}) => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "10px 8px 10px 24px",
  height: "30px",
  fontSize: "13px",
  maxWidth: "150px",
}));
const MISTableBody = ({ allTableRows }) => {
  return (
    <TableBody>
      {allTableRows.map((record, index) => (
        <TableRow key={index}>
          <StyledTableCell
            sx={{
              position: "sticky",
              left: 0,
              zIndex: 3,
              // borderRight: "1px solid #eee",
            }}
          >
            {get(record, "clientName", "-")}
          </StyledTableCell>
          <StyledTableCell>{get(record, "requirementType", "-")}</StyledTableCell>
          {/* <StyledTableCell sx={{ textAlign: "center" }}>{record.daysOfOperation}</StyledTableCell>
<StyledTableCell sx={{ textAlign: "center" }}>{record.daysOfSales}</StyledTableCell> */}
          <StyledTableCell>{get(record, "techs", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "empMode", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "employeeName", "-")}</StyledTableCell>
          {/* <StyledTableCell>{get(record, "selectionProcess", "-")}</StyledTableCell> */}
          <StyledTableCell>{get(record, "status", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "reasonForRejection", "-")}</StyledTableCell>
          <Tooltip placement="top-start" title={get(record, "feedback", "-")}>
            <StyledTableCell sx={{ whiteSpace: "nowrap", textOverflow: "ellipsis" }}>
              {get(record, "feedback", "-")}
            </StyledTableCell>
          </Tooltip>
        </TableRow>
      ))}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  allTableRows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      clientName: PropTypes.string,
      projectManager: PropTypes.string,
      employeeName: PropTypes.string,
      startedDate: PropTypes.string,
      endDate: PropTypes.string,
      status: PropTypes.string,
    }),
  ),
};

export default memo(MISTableBody);
