import PropTypes from "prop-types";
import noop from "lodash/noop";
import dayjs from "dayjs";

import { Grid, Box, styled, Tooltip, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import SearchBar from "components/Members/SearchBar";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import XLS from "assets/XLS.png";
import CSV from "assets/CSV.png";
// import TrainingFilters from "../TrainingFilters";
import { GET_SIZE } from "utils/responsive";
import {
  canAddTraining,
  canReviewTraining,
  canViewCampusPlacement,
  canViewTraining,
  mockVideoAssessmentView,
} from "utils/permissions";
import T from "T";
import MISButton from "components/common/MISButton";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import GroupsIcon from "@mui/icons-material/Groups";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import CampusPlacementFilters from "../CampusPlacementFilters";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const minDate = "2023-08-01";

const TopBar = ({
  isFilterEmpty = false,
  filters = {},
  storedFilters = {},
  searchTableData = {},
  showOptions = false,
  showCancelIcon = false,
  handleChange = noop,
  onClickOutside = noop,
  onClick = noop,
  handleKeyChange = noop,
  handleExport = noop,
  onhandleFilterChange = noop,
  handleFilterSubmit = noop,
  handleCheckboxChange = noop,
  handleFilterClose = noop,
  reset = noop,
}) => {
  const { isLg } = GET_SIZE();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      spacing={1}
      sx={{
        mt: 1,
        mb: 1,
        pl: 1.5,
        pr: 1.5,
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3} xs={12}>
        <SearchBar
          records={searchTableData}
          searchInput={filters?.text}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          reset={reset}
          onClickOutside={onClickOutside}
        />
      </Grid>
      {/* <Grid item md={3} xs={12}>
        <CampusPlacementFilters
          isFilterEmpty={isFilterEmpty}
          handleCheckboxChange={handleCheckboxChange}
          filters={filters}
          storedFilters={storedFilters}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
        />
      </Grid> */}
      <Grid item md={5} />
      {mockVideoAssessmentView() && (
        <Grid item display="flex" justifyContent="flex-end">
          <Tooltip title={T.CSV} placement="top">
            <StyledBox component="img" src={CSV} onClick={() => handleExport(T.CSV)} />
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

TopBar.propTypes = {
  value: PropTypes.string,
  searchInput: PropTypes.string,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  isFilterEmpty: PropTypes.bool,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  filters: PropTypes.object,
  searchTableData: PropTypes.object,
  projectManagerList: PropTypes.array,
  departmentList: PropTypes.array,
  courseList: PropTypes.array,
  subCourseList: PropTypes.array,
  userList: PropTypes.array,
  handleExport: PropTypes.func,
  onClick: PropTypes.func,
  handleKeyChange: PropTypes.func,
  handleChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  handleCourseAdd: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  handleFilterClose: PropTypes.func,
  setTrainingState: PropTypes.func,
};

export default TopBar;
