import { useEffect, useRef, useState } from "react";
import InterviewQuestions from "./InterviewQues";
import VideoInterviewHeader from "./VideoInterviewHeader";

const MockVideointerview = () => {
  const boxRef = useRef(null);
  const [boxHeight, setBoxHeight] = useState(0);

  // Function to update the height of the Box
  const updateBoxHeight = () => {
    if (boxRef?.current) {
      const height = boxRef?.current.offsetHeight;
      setBoxHeight(height); // Update state with the current box height
    }
  };

  // Add event listener for window resize to track box height dynamically
  useEffect(() => {
    updateBoxHeight();

    // Add window resize listener
    window.addEventListener("resize", updateBoxHeight);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateBoxHeight);
    };
  }, [boxHeight]);
  return (
    <>
      <VideoInterviewHeader boxRef={boxRef} />
      <InterviewQuestions boxHeight={boxHeight} />
    </>
  );
};

export default MockVideointerview;
