import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFilterToolsDashboard: builder.mutation({
      query: ({ search }) => ({
        url: `/toolsDashboard/search?toolName=${search}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useGetFilterToolsDashboardMutation } = extendedApi;
