import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadTestimonialLink: build.mutation({
      query: ({ payload, pId }) => ({
        url: `testimonial/update?id=${pId}`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});
export const { useUploadTestimonialLinkMutation } = extendedApi;
