import { createSlice } from "@reduxjs/toolkit";

const insideSalesFilterSlice = createSlice({
  name: "InsideSalesFilterSlice",
  initialState: {
    storedFilters: {},
  },
  reducers: {
    insideSalesFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
      // state.storedStartDate = action.payload.storedStartDate;
      // state.storeEndDate = action.payload.storeEndDate;
    },
  },
});
export const { insideSalesFilterStore } = insideSalesFilterSlice.actions;

export default insideSalesFilterSlice.reducer;
