import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveAssignment: build.mutation({
      query: (saveAssignmentPayload) => ({
        url: "/ai/assessment/save ",
        method: "POST",
        body: saveAssignmentPayload,
      }),
    }),
  }),
});

export const { useSaveAssignmentMutation } = extendedApi;
