import { useRef, useEffect, Children } from "react";
import { Box, Card, Typography } from "@mui/material";
import T from "T";
import { get, noop } from "lodash";
import PropTypes from "prop-types";
import { SubTitleTypography, getFEDateFormat } from "utils/members";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import { shallowEqual } from "react-redux";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const PreSalesTableCards = ({
  allTableRows,
  handleShowDetails = noop,
  scrollPosition,
  handleSetScrollPosition = noop,
  psTableData,
}) => {
  const recordId = get(psTableData[0], "id", "");

  const scrollRef = useRef();
  const { selectedCardId } = useSelector(
    (state) => ({
      selectedCardId: get(state, "PSCardSlice.selectedCardId", null),
    }),
    shallowEqual,
  );

  useEffect(() => {
    scrollRef.current.scrollTop = scrollPosition;
  }, [scrollPosition]);

  const handleCardClick = (recordId) => {
    handleShowDetails(recordId, scrollPosition);
  };
  // Debounce the handleScroll function
  const handleScroll = debounce(() => {
    const position = scrollRef.current.scrollTop;
    handleSetScrollPosition(position);
  }, 100); // Adjust debounce time as needed

  return (
    <Box
      ref={scrollRef}
      onScroll={handleScroll}
      sx={{
        overflowY: "scroll",
        height: "calc(100vh - 335px)",
        pt: 1,
        px: 1,
      }}
    >
      {Children.toArray(
        allTableRows.map((record, index) => {
          const companyName = get(record, "companyName", "");
          const assignedTo = get(record, "projectManager.name", "");
          const salesManager = get(record, "salesManagers.name", "");
          const createdBy = get(record, "createdBy", "");
          const startDate = get(record, "startDate", null);
          const endDate = get(record, "endDate", null);

          return (
            <Card
              key={index}
              onClick={() => handleCardClick(record.id)}
              sx={{
                p: 2,
                mb: 2,
                borderRadius: 2,
                transition: "transform 0.3s, box-shadow 0.3s",
                transform:
                  selectedCardId === null
                    ? record.id === recordId
                      ? "scale(1.02)"
                      : "scale(1)"
                    : selectedCardId === record.id
                    ? "scale(1.02)"
                    : "scale(1)",
                boxShadow:
                  selectedCardId === null
                    ? record.id === recordId
                      ? "0px 8px 16px rgba(0, 0, 0, 0.2)"
                      : "0px 4px 8px rgba(0, 0, 0, 0.1)"
                    : selectedCardId === record.id
                    ? "0px 8px 16px rgba(0, 0, 0, 0.2)"
                    : "0px 4px 8px rgba(0, 0, 0, 0.1)",
                borderLeft:
                  selectedCardId === null
                    ? record.id === recordId
                      ? `3px solid ${NETSMARTZ_THEME_COLOR}`
                      : ""
                    : selectedCardId === record.id
                    ? `3px solid ${NETSMARTZ_THEME_COLOR}`
                    : "",
              }}
            >
              <Box display="flex" whiteSpace={"nowrap"}>
                <Typography variant="subtitle2" gutterBottom style={{ fontWeight: "bold", color: NETSMARTZ_THEME_COLOR }}>
                  {companyName}
                </Typography>
              </Box>
              <Box display="flex" whiteSpace={"nowrap"}>
                <Typography variant="subtitle1" fontWeight={"bold"}>
                  {T.ASSIGNED_TO}: <span style={{ fontWeight: "normal" }}>{assignedTo}</span>
                </Typography>
              </Box>
              <Box>
                <Box display="flex" flexDirection="column">
                  <Typography variant="subtitle1" fontWeight={"bold"}>
                    {T.SALES_MANAGER}: <span style={{ fontWeight: "normal" }}>{salesManager}</span>
                  </Typography>
                  <Typography variant="subtitle1" fontWeight={"bold"}>
                    {T.CREATED_BY}: <span style={{ fontWeight: "normal" }}>{createdBy}</span>
                  </Typography>
                </Box>
              </Box>
              <Box>
                <SubTitleTypography>{T.LEAD_DATE}: </SubTitleTypography>
                {endDate ? (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="subtitle1">
                      {T.FROM}: <span style={{ fontWeight: "normal" }}>{getFEDateFormat(startDate)}</span>
                    </Typography>
                    <Typography variant="subtitle1">
                      {T.TO}: <span style={{ fontWeight: "normal" }}>{getFEDateFormat(endDate)}</span>
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="subtitle1">{getFEDateFormat(startDate)}</Typography>
                )}
              </Box>
            </Card>
          );
        }),
      )}
    </Box>
  );
};

PreSalesTableCards.propTypes = {
  allTableRows: PropTypes.array.isRequired,
  psTableData: PropTypes.array,
  handleShowDetails: PropTypes.func,
  scrollPosition: PropTypes.number.isRequired,
  handleSetScrollPosition: PropTypes.func,
};

export default PreSalesTableCards;
