import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Tooltip, Typography, styled } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";
import T from "T";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { savedFilterStore } from "slices/savedFilterSlice";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
// import FilterOptions from "./FilterOptions";
import { isEqual } from "lodash";
import UploadCard from "./UploadCard";
import Bulk_Upload from "assets/Bulk_Upload.png";
const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const UploadingTool = ({
  bulkMember = "",
  handleBulkFileUpload = noop,
  handleDeleteFile = noop,
  handleSubmitUpload = noop,
  handleBulkUploadClose = noop,
}) => {
  const dispatch = useDispatch();
  const [cardOpen, setCardOpen] = useState(false);

  const handleCardClick = () => {
    setCardOpen(!cardOpen);
  };

  const onCardClose = () => {
    setCardOpen(false);
    handleBulkUploadClose("bulkMember");
  };

  const onCardSubmit = (bulkType) => {
    handleSubmitUpload(bulkType);
    setCardOpen(false);
    handleBulkUploadClose("bulkMember");
  };
  return (
    <>
      <Tooltip title={T.UPLOADING_TOOL} placement="top">
        <StyledBox sx={{ height: 35, width: 35 }} component="img" src={Bulk_Upload} pr={1} onClick={() => handleCardClick()} />
      </Tooltip>
      {cardOpen && (
        <UploadCard
          bulkMember={bulkMember}
          handleBulkFileUpload={handleBulkFileUpload}
          handleDeleteFile={handleDeleteFile}
          handleSubmitUpload={onCardSubmit}
          handleClose={() => onCardClose()}
        />
      )}
    </>
  );
};

UploadingTool.propTypes = {};

export default UploadingTool;
