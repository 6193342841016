import PropTypes from "prop-types";
import { noop } from "lodash";
import { Box, Table as TableView, TableContainer, Button } from "@mui/material";
import "./index.scss";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { useShortListMembersMutation } from "api/preSales/shortListMembers";
import { handleError } from "utils/error";
import { toast } from "react-toastify";
import MISTableBody from "./TableBody";
import TableHeader from "./TableHeader";

const Table = ({
  columns = [],
  jdId = null,
  lockedColumns = [],
  shortListedChecks = [],
  recommendedUsers = [],
  selectedMembers = [],
  allTableRows,
  selectAllCheck = false,
  handleShortListCheckBoxChange = noop,
  getRecommendedMembers = noop,
  getSelectedMembers = noop,
}) => {
  const [shortListMembers] = useShortListMembersMutation();

  const handleShortlist = (jdId, selectedMembers) => {
    const payload = {
      presalesMappingId: jdId,
      userId: selectedMembers,
    };
    shortListMembers({ recommendedUsersRequestDto: payload })
      .unwrap()
      .then(() => {
        getRecommendedMembers();
        getSelectedMembers();
        toast.success(T.SELECTED_MEMBERS_SHORTLISTED);
      })
      .catch(handleError);
  };

  return (
    <Box
      elevation={0}
      sx={{
        p: "0px 8px",
        height: "calc(100vh - 260px)",
        backgroundColor: "background.card",
      }}
    >
      <Box overflow="hidden">
        <TableContainer
          sx={{
            overflow: "auto",
            maxHeight: "calc(100vh - 310px)",
          }}
        >
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader
              selectAllCheck={selectAllCheck}
              handleShortListCheckBoxChange={handleShortListCheckBoxChange}
              columns={columns}
              records={allTableRows}
              lockedColumns={lockedColumns}
            />
            <MISTableBody
              columns={columns}
              jdId={jdId}
              lockedColumns={lockedColumns}
              records={allTableRows}
              shortListedChecks={shortListedChecks}
              handleShortListCheckBoxChange={handleShortListCheckBoxChange}
            />
          </TableView>
        </TableContainer>
      </Box>
      {/* {selectedMembers?.length > recommendedUsers?.length && ( */}
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button
          variant="contained"
          size="small"
          sx={{
            m: 1,
            bgcolor: NETSMARTZ_THEME_COLOR,
            "&:hover": {
              bgcolor: NETSMARTZ_THEME_COLOR,
            },
            textTransform: "none",
          }}
          onClick={() => handleShortlist(jdId, selectedMembers)}
        >
          {T.SHORTLIST_CANDIDATES}
        </Button>
      </Box>
      {/* )} */}
    </Box>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  jdId: PropTypes.number,
  lockedColumns: PropTypes.array,
  shortListedChecks: PropTypes.array,
  recommendedUsers: PropTypes.array,
  selectedMembers: PropTypes.array,
  allTableRows: PropTypes.array,
  refreshMemberTable: PropTypes.func,
  selectAllCheck: PropTypes.bool,
  handleShortListCheckBoxChange: PropTypes.func,
  getSelectedMembers: PropTypes.func,
  getRecommendedMembers: PropTypes.func,
};

export default Table;
