import { createSlice } from "@reduxjs/toolkit";

const referSlice = createSlice({
  name: "ReferSlice",
  initialState: {
    referralAdded: true,
  },

  reducers: {
    referStore: (state, action) => {
      state.referralAdded = action.payload.referralAdded;
    },
  },
});

export const { referStore } = referSlice.actions;
export default referSlice.reducer;
