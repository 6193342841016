import { useReducer, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  styled,
  Typography,
} from "@mui/material";
// import { useLazyGetCourseListQuery } from "api/training/courseDetail/getCourseList";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useLazyGetUserListQuery } from "api/members/getUserList";
import T from "T";
// import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash";
import { handleError } from "utils/error";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MISAutocomplete from "components/common/MISAutocomplete";
import { AddCircle, ExpandMore, RemoveCircle } from "@mui/icons-material";
import usePMFetch from "hooks/usePMFetch";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import MISLoader from "components/common/MISLoader";
import MISButton from "components/common/MISButton";
import { format, isValid } from "date-fns";
import MISDatePicker from "components/common/MISDatePicker";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useLazyCourseTypesQuery } from "api/training/typeOfCourse/courseType";
import { useAssignCourseMutation } from "api/training/assignCourse/assignUserCourse";
import { toast } from "react-toastify";
import { useGetAssignCourseFilterMutation } from "api/training/assignCourse/filterAssignCourse";
import MISFooterButton from "components/common/MISFooterButton";
// const { INITIAL_PAGE } = PAGINATION;
// const ROWS_PER_PAGE = 10000;
const emptyCourseData = {
  courseDuration: 0,
  courseName: "",
  trainingCourseId: 0,
  subsCourses: [], //{ subsCourseName: "", training_sub_course_id: 0 }

  priority: true,
};

const StyledAccordion = styled(Accordion)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

const LnDPage = () => {
  const navigate = useNavigate();
  const { routeId } = useParams();
  const location = useLocation();
  const id = location.state.id;
  const userName = location.state.userName;
  const appraisalDueDate = location.state.appraisalDueDate;
  const mandatory = routeId === "1" ? "Appraisal Mandatory" : "New Joiners Mandatory";
  const { courseList, departmentList } = usePMFetch();
  const [getUserList] = useLazyGetUserListQuery();
  const [getCourseType, { isLoading }] = useLazyCourseTypesQuery();
  const [assignCourse] = useAssignCourseMutation();
  const [getFilterAssignCourse] = useGetAssignCourseFilterMutation();

  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    trainingList: [
      {
        courseType: mandatory,
        allocatedHours: 0,
        courseData: [],
        hoursLeft: 0,
        mentor: {
          userName: "Anubhav Lotey",
          userEmailId: "anubhav.lotey@netsmartz.com",
          id: 1548,
        },
      },
      {
        courseType: "Softskill",
        allocatedHours: 0,
        courseData: [emptyCourseData],
        hoursLeft: 0,
        mentor: {
          userName: "Anubhav Lotey",
          userEmailId: "anubhav.lotey@netsmartz.com",
          id: 1548,
        },
      },
      {
        courseType: "Domain",
        allocatedHours: 0,
        courseData: [emptyCourseData],
        hoursLeft: 0,
        mentor: {
          userName: "",
          userEmailId: "",
          id: 0,
        },
      },
    ],
    usersList: [], // List of user from BE
    courseLists: [], // list of course from BE
    expandedCourseType: "",
    completionDate: null,
    department: null,
    showDomainToNewJoiner: false,
    showDialog: false,
    loader: false,
  });

  const {
    trainingList,
    usersList,
    courseLists,
    expandedCourseType,
    completionDate,
    department,
    showDomainToNewJoiner,
    showDialog,
    loader,
  } = localState;

  useEffect(() => {
    getUsersListed();
    getDateAfter12MonthsAndSetQuarterStart();
  }, []);

  useEffect(() => {
    getCourseTypeList();
  }, [courseList]);

  const getUsersListed = () => {
    getUserList({ page: 0, size: 10000, status: T.ACTIVE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({
          usersList: allTableRows,
        });
      })
      .catch(handleError);
  };

  const getCourseTypeList = () => {
    const { allTableRows } = getCurrentTableParams(courseList);

    //change the values of course list and trainlist according to department and because mandatory is prefilled
    //the data is filled here while chaning department

    if (routeId === "2") {
      getFilterAssignCourse({
        page: 0,
        rowPerPage: 1000,
        assignTrainingFilterDto: {
          userId: id,
          isOffline: false,
          trainingType: routeId === "1" ? "Appraisal Linked Learning Path" : "New Joiner Learning Path",
        },
      })
        .unwrap()
        .then((res) => {
          const results = get(res, "results", []);
          let newTrainingList = trainingList?.filter((it) => {
            if (routeId === "2" && !(results[0]?.isNewJoinerLearningPathDateExpired ?? false)) {
              return it.courseType !== "Domain";
            } else {
              return it;
            }
          });

          setLocalState({
            trainingList: newTrainingList,
            showDomainToNewJoiner: results[0]?.isNewJoinerLearningPathDateExpired ?? false,
          });
          getCourseType()
            .unwrap()
            .then((res) => {
              if (res) {
                newTrainingList = newTrainingList.map((it) => ({
                  courseType: it?.courseType,
                  allocatedHours: res.find((i) => i.courseAssignmentType === it?.courseType).duration,
                  courseData:
                    it?.courseType === mandatory
                      ? allTableRows
                          .filter((it) => it?.assignmentType?.courseAssignmentType === mandatory)
                          .map((it) => ({
                            courseDuration: it.courseDuration,
                            courseName: it.courseName,
                            trainingCourseId: it.trainingCourseId,
                            subsCourses: it?.subsCourses, //{ subsCourseName: "", training_sub_course_id: 0 }

                            priority: true,
                          }))
                      : it?.courseData,
                  hoursLeft: it?.hoursLeft,
                  mentor: it?.mentor,
                }));
                setLocalState({
                  courseLists: allTableRows,
                  trainingList: newTrainingList,
                });
              }
            })
            .catch(handleError);
        })
        .catch(handleError);
    } else {
      let newTrainingList = trainingList?.filter((it) => {
        if (routeId === "2" && !showDomainToNewJoiner) {
          return it.courseType !== "Domain";
        } else {
          return it;
        }
      });
      setLocalState({
        trainingList: newTrainingList,
      });

      getCourseType()
        .unwrap()
        .then((res) => {
          if (res) {
            newTrainingList = newTrainingList.map((it) => ({
              courseType: it?.courseType,
              allocatedHours: res.find((i) => i.courseAssignmentType === it?.courseType).duration,
              courseData:
                it?.courseType === mandatory
                  ? allTableRows
                      .filter((it) => it?.assignmentType?.courseAssignmentType === mandatory)
                      .map((it) => ({
                        courseDuration: it.courseDuration,
                        courseName: it.courseName,
                        trainingCourseId: it.trainingCourseId,
                        subsCourses: it?.subsCourses, //{ subsCourseName: "", training_sub_course_id: 0 }

                        priority: true,
                      }))
                  : it?.courseData,
              hoursLeft: it?.hoursLeft,
              mentor: it?.mentor,
            }));
            setLocalState({
              courseLists: allTableRows,
              trainingList: newTrainingList,
            });
          }
        })
        .catch(handleError);
    }
  };

  const handleCourseRemove = (trainingIndex, index) => {
    const training = trainingList[trainingIndex];
    if (training.courseData[index]) {
      training.courseData.splice(index, 1);
      //check hours
      handleCourseHours(trainingIndex, index);

      setLocalState({ trainingList });
    } else {
      training.courseData.splice(index, 1);
      //check hours
      handleCourseHours(trainingIndex, index);
      setLocalState({ trainingList });
    }
  };

  const handleCourseHours = (trainingIndex, index) => {
    let hoursSelected = 0;
    trainingList[trainingIndex].courseData.forEach((it) => {
      hoursSelected += it?.courseDuration;
    });
    trainingList[trainingIndex].hoursLeft = hoursSelected;
    setLocalState({
      trainingList,
    });
  };

  const handleCourseAdd = (trainingIndex, index) => {
    trainingList[trainingIndex].courseData.push(emptyCourseData);
    setLocalState({ trainingList });
  };

  const courseAddDisableCondition = (trainingIndex, index) => {
    if (trainingList[trainingIndex]?.courseType === "Domain") {
      return trainingList[trainingIndex]?.courseData?.some((it) => it?.trainingCourseId === 0);
    }
    return (
      trainingList[trainingIndex]?.courseData?.some((it) => it?.trainingCourseId === 0) ||
      trainingList[trainingIndex]?.allocatedHours - trainingList[trainingIndex]?.hoursLeft <= 0
    );
  };

  // ============================Handle The state of Course and subCourse====================
  const handleChangeCourse = (event, newValue, trainingIndex, index) => {
    const selectedCourse = (trainingList[trainingIndex].courseData[index] = {
      courseDuration: get(newValue, "courseDuration", 0),
      courseName: get(newValue, "courseName", 0),
      trainingCourseId: get(newValue, "trainingCourseId", 0),
      subsCourses: get(newValue, "subsCourses", []), //{ subsCourseName: "", training_sub_course_id: 0 }

      priority: true,
    });
    //course to check hours
    if (selectedCourse) {
      handleCourseHours(trainingIndex, index);
    }

    setLocalState({
      trainingList,
    });
  };
  const handleExpand = (courseType) => (event, isExpanded) => {
    setLocalState({
      expandedCourseType: isExpanded ? courseType : NaN,
    });
  };

  const handleDepartmentChange = (event, newValue) => {
    if (newValue) {
      setLocalState({
        department: newValue,
      });
    } else {
      trainingList.forEach((it) => {
        if (it.courseType === "Domain") {
          it.courseData = [emptyCourseData];
          it.hoursLeft = 0;
        }
      });

      setLocalState({
        trainingList,
        department: null,
      });
    }
  };

  const onHandleDateChange = (newValue) => {
    const validDate = new Date(newValue);
    setLocalState({
      completionDate: newValue && isValid(validDate) ? validDate : null,
    });
  };

  const disableSubmit = () => {
    if (!showDomainToNewJoiner) {
      if (completionDate === null) {
        toast.error("Completion Date is mandatory");
        return false;
      }
      if (trainingList.filter((it) => it.courseType !== mandatory).some((it) => !(it.allocatedHours - it.hoursLeft <= 0))) {
        toast.error("Please select the minimum hours of training for each course");
        return false;
      }
      if (trainingList.some((it) => it?.mentor.id === 0)) {
        toast.error("Please select the reviewers for trainings");
        return false;
      }
      if (department === null && routeId === "1") {
        toast.error("Please select a department");
        return false;
      }
    } else {
      const newTrainingList = trainingList.filter((it) => it.courseType === "Domain");

      if (completionDate === null) {
        toast.error("Completion Date is mandatory");
        return false;
      }
      if (newTrainingList.filter((it) => it.courseType !== mandatory).some((it) => !(it.allocatedHours - it.hoursLeft <= 0))) {
        toast.error("Please select the minimum hours of training for each course");
        return false;
      }
      if (newTrainingList.some((it) => it?.mentor.id === 0)) {
        toast.error("Please select the reviewers for trainings");
        return false;
      }
      if (department === null) {
        toast.error("Please select a department");
        return false;
      }
    }

    return true;
  };

  const handleShowDialog = () => {
    setLocalState({ showDialog: !showDialog });
  };

  const handleSubmit = () => {
    if (!disableSubmit()) return;
    handleShowDialog();
  };

  const handleSubmitDialog = () => {
    setLocalState({ loader: true });
    handleShowDialog();
    let courseDataList = [];

    // Collect all course data from each training type
    for (let i of trainingList) {
      const mappedCourses = i.courseData
        .filter((it) => it?.trainingCourseId !== 0)
        .map((course) => {
          return {
            courseId: course.trainingCourseId,
            courseDuration: course.courseDuration,
            subCoursePriorityStatus: course.priority,
            reviewerId: i.mentor.id,
            subsCourses: course.subsCourses.map((subCourse) => ({
              subCourseId: subCourse.training_sub_course_id,
            })),
            completionDate:
              routeId === "2" && i?.courseType === "Softskill"
                ? getBEDateFormat(getDateAfterNMonths(3))
                : routeId === "2" && i?.courseType === mandatory
                  ? getBEDateFormat(getDateAfterNMonths(1))
                  : getBEDateFormat(completionDate),
          };
        });

      // Push the mapped courses to the courseDataList
      courseDataList.push(...mappedCourses);
    }

    // Prepare the final payload
    const payload = {
      userIds: [id],
      trainingType: routeId === "1" ? "Appraisal Linked Learning Path" : "New Joiner Learning Path",
      courseDetailsList: courseDataList,
      isDomainRelatedTraining: showDomainToNewJoiner && routeId === "2" ? true : false,
    };
    assignCourse(payload)
      .unwrap()
      .then((res) => {
        toast.success("Training assigned successfully");
        navigate(-1);
      })
      .catch(handleError)
      .finally(() => {
        setLocalState({ loader: false });
      });
  };

  const handleChangeMentor = (event, newValue, trainingIndex) => {
    if (newValue) {
      trainingList[trainingIndex].mentor = {
        userName: newValue?.userName,
        userEmailId: newValue?.userEmailId,
        id: newValue?.id,
      };
    } else {
      trainingList[trainingIndex].mentor = {
        userName: "",
        userEmailId: "",
        id: 0,
      };
    }
    setLocalState({
      trainingList,
    });
  };

  const getDateAfter12MonthsAndSetQuarterStart = () => {
    const today = new Date();
    const futureDate = new Date(today);
    // Add 12 months
    futureDate.setMonth(today.getMonth() + 12);

    // Determine the quarter start
    const quarterStartMonth = Math.floor(futureDate.getMonth() / 3) * 3; // 0 for Q1, 3 for Q2, etc.
    const quarterStartDate = new Date(futureDate.getFullYear(), quarterStartMonth, 1);

    setLocalState({
      completionDate: isValid(quarterStartDate) ? quarterStartDate : null,
    });
  };

  const getDateAfterNMonths = (months) => {
    const today = new Date();
    const futureDate = new Date(today);
    futureDate.setMonth(today.getMonth() + months);
    return futureDate;
  };

  return (
    <Paper
      sx={{
        display: "flex",
        flex: 1,
        alignItems: "center",
        height: "calc(100vh - 130px)",
        flexDirection: "column",
      }}
    >
      {loader && <MISLoader />}
      <Grid xs={12} fontSize={24} padding={2} alignSelf={"flex-start"}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        {routeId === "1"
          ? `Assign Appraisal Linked Learning Path ${T.TO} ${userName}`
          : `Assign New Joiners Learning Path ${T.TO} ${userName} `}
      </Grid>
      <Grid container spacing={2} padding={2} sx={{ overflowY: "auto" }}>
        <Grid item xs={4}>
          <MISDatePicker
            disabled={routeId === "1" ? (appraisalDueDate ? true : false) : false}
            label={T.COMPLETION_DATE}
            placeholder={T.COMPLETION_DATE}
            inputFormat="MM/DD/YYYY"
            value={completionDate}
            name="completionDate"
            required
            disablePast={true}
            handleChange={(event) => onHandleDateChange(event)}
          />
        </Grid>
        <Grid container item spacing={2}>
          {trainingList.map((training, trainingIndex) => {
            return (
              <Grid container item key={trainingIndex}>
                <StyledAccordion
                  disabled={routeId === "2" && showDomainToNewJoiner && training?.courseType !== "Domain"}
                  style={{ width: "100vw" }}
                  variant="outlined"
                  expanded={training?.courseType === expandedCourseType}
                  onChange={handleExpand(training?.courseType)}
                >
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Grid container item>
                      <Grid item xs={4}>
                        {(() => {
                          let displayText;
                          switch (training?.courseType) {
                            case "Softskill":
                              displayText = "Softskills Training";
                              break;
                            case "Domain":
                              displayText = "Domain Related Training";
                              break;
                            case mandatory:
                              displayText = "Organizational Trainings";
                              break;
                            default:
                              displayText = training?.courseType;
                          }
                          return <h4>{displayText}</h4>;
                        })()}
                      </Grid>

                      <Grid item xs={4} sx={{ textAlign: "start" }}>
                        <h5>{training?.mentor?.userName && `Reviewer:- ${training?.mentor?.userName}`}</h5>
                      </Grid>

                      {routeId === "2" && training?.courseType === mandatory && (
                        <Grid item xs={2} sx={{ textAlign: "start" }}>
                          <h5>{training?.mentor?.userName && `Completion Date:- ${getBEDateFormat(getDateAfterNMonths(1))}`}</h5>
                        </Grid>
                      )}
                      {routeId === "2" && training?.courseType === "Softskill" && (
                        <Grid item xs={2} sx={{ textAlign: "start" }}>
                          <h5>{training?.mentor?.userName && `Completion Date:- ${getBEDateFormat(getDateAfterNMonths(3))}`}</h5>
                        </Grid>
                      )}
                      {
                        <Grid
                          item
                          xs={
                            (routeId === "2" && training?.courseType === mandatory) ||
                            (routeId === "2" && training?.courseType === "Softskill")
                              ? 2
                              : 4
                          }
                          sx={{ textAlign: "end", paddingX: 2 }}
                        >
                          <h5>
                            {`Total:- ${training?.courseData.reduce(function (acc, obj) {
                              return acc + obj.courseDuration;
                            }, 0)}h`}
                          </h5>
                        </Grid>
                      }
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {/* {training?.courseType !== mandatory && training?.courseType !== "Domain" && (
                      <Grid container justifyContent={"space-between"} item xs={12}>
                        <Grid item xs={12} sx={{ textAlign: "end", paddingX: 2, marginBottom: 2 }}>
                          <h5>
                            {training?.allocatedHours - training?.hoursLeft >= 0
                              ? `${training?.allocatedHours - training?.hoursLeft} hours left`
                              : "0 hours left"}
                          </h5>
                        </Grid>
                      </Grid>
                    )} */}
                    {training?.courseType === "Domain" ? (
                      <Grid container item xs={12} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                          <Grid container alignItems="center">
                            <Grid item xs={6}>
                              <MISAutocomplete
                                label="Please filter out the trainings by choosing the Department from here"
                                listDetails={get(departmentList, "results", [])}
                                getByLabelText={(option) => get(option, "departmentName", "")}
                                value={department}
                                sx={{
                                  ".MuiAutocomplete-popper": { zIndex: 1100 },
                                }}
                                onHandleChange={(event, newValue) => handleDepartmentChange(event, newValue)}
                                required
                              />
                            </Grid>
                            <Grid item xs={6} sx={{ textAlign: "end" }}>
                              <h5>
                                {training?.allocatedHours - training?.hoursLeft >= 0
                                  ? `${training?.allocatedHours - training?.hoursLeft} hours left`
                                  : "0 hours left"}
                              </h5>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : null}
                    {training?.courseData?.map((it, index) => (
                      <div key={index}>
                        {training?.courseType === mandatory ? (
                          <Paper sx={{ p: 2, mt: 2 }}>
                            {it?.subsCourses.length > 0 && (
                              <Grid item xs={12} mt={2}>
                                <Grid item xs={12}>
                                  <Box>
                                    <Typography ml={1} variant="h6">
                                      {it?.courseName}
                                    </Typography>

                                    <Box>
                                      {it?.subsCourses?.map((it, index) => (
                                        <Typography variant="body1" ml={1}>{`(${index + 1}) ${it?.subsCourseName}`}</Typography>
                                      ))}
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            )}
                          </Paper>
                        ) : (
                          <Paper sx={{ p: 2, mt: 2 }}>
                            <Grid container item>
                              <Grid container item xs={12} justifyContent={"space-between"} alignItems={"center"}>
                                <Grid item xs={6}>
                                  <MISAutocomplete
                                    label={T.COURSE}
                                    getByLabelText={(option) => get(option, "courseName", "")}
                                    sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
                                    listDetails={
                                      training.courseType !== "Domain"
                                        ? courseLists.filter(
                                            (assignment) =>
                                              assignment.assignmentType.courseAssignmentType === training.courseType &&
                                              !training.courseData.some(
                                                (it) => it.trainingCourseId === assignment?.trainingCourseId,
                                              ),
                                          )
                                        : courseLists.filter(
                                            (assignment) =>
                                              assignment.department.id === parseInt(department?.id) &&
                                              assignment.assignmentType.courseAssignmentType === training.courseType &&
                                              !training.courseData.some(
                                                (it) => it.trainingCourseId === assignment?.trainingCourseId,
                                              ),
                                          )
                                    }
                                    value={trainingList[trainingIndex].courseData[index]}
                                    onHandleChange={(event, newValue) => {
                                      handleChangeCourse(event, newValue, trainingIndex, index);
                                    }}
                                  />
                                </Grid>
                                <Grid item>
                                  <h5>{`Duration:- ${trainingList[trainingIndex].courseData[index]?.courseDuration} hour `}</h5>
                                  {trainingList[trainingIndex]?.courseType === "Softskill" &&
                                    trainingList[trainingIndex].courseData.length - 1 === index && (
                                      <h5>
                                        {training?.allocatedHours - training?.hoursLeft >= 0
                                          ? `(Minimum ${
                                              training?.allocatedHours - training?.hoursLeft
                                            } hours of courses more left to be assigned)`
                                          : "(0 hours left)"}
                                      </h5>
                                    )}
                                </Grid>
                                <Grid item>
                                  {trainingList[trainingIndex].courseData.length - 1 === index && (
                                    <IconButton
                                      disabled={courseAddDisableCondition(trainingIndex, index)}
                                      onClick={() => {
                                        handleCourseAdd(trainingIndex, index);
                                      }}
                                    >
                                      <AddCircle />
                                    </IconButton>
                                  )}

                                  {trainingList[trainingIndex].courseData.length > 1 && (
                                    <IconButton
                                      onClick={() => {
                                        handleCourseRemove(trainingIndex, index);
                                      }}
                                    >
                                      <RemoveCircle />
                                    </IconButton>
                                  )}
                                </Grid>
                              </Grid>

                              <Grid item xs={12} mt={2}>
                                {it?.subsCourses.length > 0 && (
                                  <Grid item xs={12}>
                                    <Box>
                                      <Typography ml={1} variant="h6">
                                        {T.SUB_COURSES + ":-"}
                                      </Typography>

                                      <Box>
                                        {it?.subsCourses?.map((it, index) => (
                                          <Typography variant="body1" ml={1}>{`(${index + 1}) ${it?.subsCourseName}`}</Typography>
                                        ))}
                                      </Box>
                                    </Box>
                                  </Grid>
                                )}
                              </Grid>
                            </Grid>
                          </Paper>
                        )}
                      </div>
                    ))}
                    <Grid item xs={4} mt={2}>
                      <MISAutocomplete
                        label={T.REVIEWER_NAME}
                        getByLabelText={(option) => get(option, "userEmailId", "")}
                        sx={{ ".MuiAutocomplete-popper": { zIndex: 1100 } }}
                        listDetails={usersList}
                        value={trainingList[trainingIndex].mentor}
                        onHandleChange={(event, newValue) => {
                          handleChangeMentor(event, newValue, trainingIndex);
                        }}
                        required
                      />
                    </Grid>
                  </AccordionDetails>
                </StyledAccordion>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Grid sx={{ p: 2, alignSelf: "flex-end", mt: "auto" }}>
        <Button
          variant="outlined"
          size={"small"}
          sx={{
            borderColor: BACKGROUND.black,
            color: BACKGROUND.black,

            "&:hover": {
              borderColor: NETSMARTZ_THEME_COLOR,
              color: NETSMARTZ_THEME_COLOR,
            },
            mr: 2,
          }}
          onClick={() => navigate(-1)}
        >
          {"Cancel"}
        </Button>
        <MISButton onClick={handleSubmit}>Submit</MISButton>
      </Grid>
      <Dialog open={showDialog} handleClose={handleShowDialog} maxWidth="sm" disableEscapeKeyDown>
        <DialogTitle>
          <Typography variant="title">Confirmation</Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {
              "I hereby confirm that the allocated trainings have been discussed with the employee, and he/she has been informed about the assigned courses, along with the timeline to complete them and implement the learnings before the next appraisal cycle."
            }
          </Typography>
        </DialogContent>
        <DialogActions>
          <MISFooterButton handleSubmit={handleSubmitDialog} handleClose={handleShowDialog} />
        </DialogActions>
      </Dialog>
    </Paper>
  );
};

export default LnDPage;
