
import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveDeveloperChecklist: build.mutation({
      query: ({ userId, presalesMappingId, developerChecklist }) => ({
        url: `/pitchedUser/candidateInterviewChecklist/save`,
        method: "POST",
        body: developerChecklist,
        params: { userId, presalesMappingId },
      }),
    }),
  }),
});

export const { useSaveDeveloperChecklistMutation } = extendedApi;
