import { Table as TableView, TableContainer, Card, Typography, Tooltip } from "@mui/material";
import MasterPagination from "components/MasterSetting/MasterPagination";
import MISNoData from "components/common/MISNoData";
import { REFERRALS_LIST_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";
import { useEffect, useReducer } from "react";
import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetAllReferralsQuery } from "api/Referrals/getAllReferrals";
import { handleError } from "utils/error";
import { get } from "lodash";
import MISLoader from "components/common/MISLoader";
import { useNavigate } from "react-router-dom";
import routes from "router/routes";
import { useLazyGetVPRecommendationsQuery } from "api/Referrals/getVPRecommendations";
import { canViewRecommendations, canViewRecommendationsTab } from "utils/permissions";
import T from "T";
import { MISCurrentUser } from "utils/validations";
import { Box } from "@mui/system";
import InfoIcon from "@mui/icons-material/Info";
import { ERROR, SUCCESS } from "theme/colors";
import MISButton from "components/common/MISButton";
import StatsSidebar from "./Stats";
import { shallowEqual, useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { recommendationsTabStore } from "slices/recommendationsTabSlice";
import CSV from "assets/CSV.png";
import Constants from "Constants";
import { toast } from "react-toastify";
const { SERVER_URL } = Constants;

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const ReferralsTable = () => {
  const { currentPage, storedTab } = useSelector(
    (state) => ({
      currentPage: get(state, "RecommendationsTabSlice.currentPage", 0),
      storedTab: get(state, "RecommendationsTabSlice.storedTab", ""),
    }),
    shallowEqual,
  );
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    page: currentPage,
    size: ROWS_PER_PAGE,
    totalPageCount: INITIAL_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    tableData: {},
    statsModal: false,
    exportLoading: false,
  });
  const { page, size, tableData, totalTableRowsCount, totalPageCount, statsModal, exportLoading } = localState;

  const [getAllReferrals, { isLoading }] = useLazyGetAllReferralsQuery();
  const [getVPRecommendations, { isLoading: gettingVpRecommendations }] = useLazyGetVPRecommendationsQuery();
  const navigate = useNavigate();
  const { user, sessionToken } = MISCurrentUser();
  const dispatch = useDispatch();

  const role = get(user, "role", null);

  useEffect(() => {
    if (canViewRecommendationsTab() && canViewRecommendations()) {
      getAllReferrals({ page, size })
        .unwrap()
        .then((res) => {
          const results = get(res, "results", []);
          setLocalState({
            tableData: results,
            totalTableRowsCount: get(res, "numberOfRecords", 0),
            totalPageCount: get(res, "numberOfPages", 0),
          });
        })
        .catch(handleError);
    }
    if (canViewRecommendationsTab() && !canViewRecommendations()) {
      getVPRecommendations({ page, size })
        .unwrap()
        .then((res) => {
          const results = get(res, "results", {});
          setLocalState({
            tableData: results,
            totalTableRowsCount: get(res, "numberOfRecords", 0),
            totalPageCount: get(res, "numberOfPages", 0),
          });
        })
        .catch(handleError);
    }
  }, [canViewRecommendationsTab, page, size]);

  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, size: value });
  };

  const handleDownloadCsv = async () => {
    setLocalState({ exportLoading: true });
    const currentDate = new Date().toISOString().slice(0, 10);

    fetch(`${SERVER_URL}/talentAcquisition/download/csv`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
    })
      .then(async (res) => {
        if (!res.ok) {
          throw new Error("Network response was not ok");
        }
        const blob = await res.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `Recommendations_${currentDate}.csv`);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setLocalState({ exportLoading: false });
        toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
      })
      .catch(handleError);
  };
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    dispatch(recommendationsTabStore({ currentPage: newPage, storedTab: storedTab }));
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleDetails = (matchingRecords) => {
    navigate(routes.app.recommendationDetails, { state: matchingRecords });
  };

  const handleAddRecommendation = (isSecondLevel, recommendedBy, record) => {
    navigate(routes.app.referralRecommendationForm, {
      state: { isSecondLevel: isSecondLevel, record: record, recommendedBy: recommendedBy },
    });
  };

  const handleStats = () => {
    setLocalState({ statsModal: !statsModal });
  };

  return (
    <>
      <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.5, bgcolor: "background.white" }}>
          <InfoIcon sx={{ mr: 0.5, color: "gray" }} />
          <Typography variant="subtitle1" color="textSecondary">
            Verified recommendations are highlighted in <span style={{ color: SUCCESS.main }}>green</span>, while unverified ones
            are marked in <span style={{ color: ERROR.main }}>red</span>. Ongoing recommendations are highlighted in{" "}
            <span style={{ color: "#FFBF00" }}>amber</span>.
          </Typography>
        </Box>
        <Box display={"flex"} gap={1} alignItems={"center"}>
          <Tooltip title={T.CSV} placement="top">
            <Box width={40} height={40} sx={{ cursor: "pointer" }} component="img" src={CSV} onClick={handleDownloadCsv} />
          </Tooltip>
          {/* <MISButton variant="contained" onClick={handleStats}>
            Stats
          </MISButton> */}
        </Box>
      </Box>
      <Card
        elevation={0}
        sx={{
          p: "0px 8px",
          mt: 1,
          backgroundColor: "background.card",
          "& .add-shadow": {
            boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
          },
        }}
      >
        {(isLoading || gettingVpRecommendations || exportLoading) && <MISLoader />}
        <TableContainer
          sx={{
            height: "calc(100vh - 345px)",
            overflow: "auto",
          }}
        >
          {Object.keys(tableData).length !== 0 ? (
            <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
              {role === T.TA ? (
                <TableHeader columns={REFERRALS_LIST_TABLE_HEADER} />
              ) : (
                <TableHeader columns={REFERRALS_LIST_TABLE_HEADER.filter((column) => column.label !== "")} />
              )}
              <TableBody
                allTableRows={tableData}
                page={page}
                size={size}
                handleDetails={handleDetails}
                handleAddRecommendation={handleAddRecommendation}
              />
            </TableView>
          ) : (
            <MISNoData />
          )}
        </TableContainer>

        <MasterPagination
          page={page}
          onPageChange={handlePageChange}
          rowsPerPage={size}
          onRowsPerPageChange={handleRowsPerPageChange}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
        />
      </Card>
      <StatsSidebar open={statsModal} onClose={handleStats} />
    </>
  );
};

export default ReferralsTable;
