import { api } from "api";
import { get } from "utils/lodash";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateCompanyDetails: build.mutation({
      query: ({ payload }) => ({
        url: "/talentAcquisition/update/company",
        body: payload,
        method: "POST",
      }),
    }),
  }),
});

export const { useUpdateCompanyDetailsMutation } = extendedApi;
