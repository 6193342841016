import { createSlice } from "@reduxjs/toolkit";

const toolsLicenseSlice = createSlice({
    name: "toolsLicenseSlice",
    initialState: {
        toolLicenseTableValues: {
            activeSort: "autoRenewal",
            sortDirection: 0,
        }

    },
    reducers: {
        setToolLicenseTableValues: (state, action) => {
            state.toolLicenseTableValues = action.payload
        }
    },
});

export const { setToolLicenseTableValues } = toolsLicenseSlice.actions;
export default toolsLicenseSlice.reducer;
