import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Card, Grid, Typography } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import NCTable from "./NCTable";
import T from "T";
import MISTextField from "components/common/MISTextField";
import MISDatePicker from "components/common/MISDatePicker";

const NCHistory = ({
  allTableRows = [],
  startDate = null,
  endDate = null,
  onHandleDateChange = noop,
  handleActiveNcCountDialog = noop,
  handleDeleteNcCountDialog = noop,
  onHandleChange = noop,
}) => {
  return (
    <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
      <Grid container spacing={2} pb={1}>
        <Grid item sm={8} xs={12} display="flex" alignItems="center">
          <Typography fontSize={17} fontWeight={600}>
            {`${T.NC_HISTORY}`}
          </Typography>
        </Grid>
        <Grid item sm={4} xs={12} sx={{ display: "flex", alignItems: "center" }} columnGap={2}>
          <MISDatePicker
            bel={T.START_DATE}
            placeholder={T.START_DATE}
            inputFormat="MM/DD/YYYY"
            value={startDate}
            maxDate={new Date()}
            InputProps={{ style: { height: "30px", fontSize: "13px" } }}
            name="startDate"
            handleChange={onHandleDateChange}
            renderInput={(params) => <MISTextField {...params} />}
            PopperProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
          />
          <MISDatePicker
            bel={T.END_DATE}
            placeholder={T.END_DATE}
            inputFormat="MM/DD/YYYY"
            value={endDate}
            name="endDate"
            InputProps={{ style: { height: "30px", fontSize: "13px" } }}
            minDate={startDate}
            handleChange={onHandleDateChange}
            PopperProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
            }}
            renderInput={(params) => <MISTextField {...params} />}
          />
        </Grid>
      </Grid>

      <NCTable
        allTableRows={allTableRows}
        handleActiveNcCountDialog={handleActiveNcCountDialog}
        handleDeleteNcCountDialog={handleDeleteNcCountDialog}
        onHandleChange={onHandleChange}
      />
    </Card>
  );
};

NCHistory.propTypes = {
  startMonth: PropTypes.string,
  endMonth: PropTypes.string,
  allTableRows: PropTypes.array,
  handleActiveNcCountDialog: PropTypes.func,
  handleDeleteNcCountDialog: PropTypes.func,
  onHandleChange: PropTypes.func,
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onHandleDateChange: PropTypes.func,
};

export default NCHistory;
