import React from "react";
import ToolsTable from "./ToolsTable";
import { noop } from "lodash";
import PropTypes from "prop-types";
import ToolsMutation from "./ToolsMutation";

const ToolsDialog = ({
  toolsList = [],
  isOpenToolsDialog = bool,
  isAddEditToolsOpen = bool,
  addEditTools = {},
  toolsSearch = "",
  openAddEditToolsMutation = noop,
  closeAddEditToolsMutation = noop,
  handleClose = noop,
  onHandleChange = noop,
  handleSubmitTools = noop,
  handleDeleteTools = noop,
  handleSearchChange = noop,
  handleSearchKeyChange = noop,
}) => {
  const columns = [
    { label: "S.no", key: "id" },
    { label: "Tools Name", key: "toolsName" },
    { label: "Created By", key: "createdBy" },
    { label: "Actions", key: "actions" },
  ];
  return (
    <>
      <ToolsTable
        toolsSearch={toolsSearch}
        columns={columns}
        toolsList={toolsList}
        isOpenToolsDialog={isOpenToolsDialog}
        openAddEditToolsMutation={openAddEditToolsMutation}
        handleClose={handleClose}
        handleDeleteTools={handleDeleteTools}
        handleSearchChange={handleSearchChange}
        handleSearchKeyChange={handleSearchKeyChange}
      />
      <ToolsMutation
        isAddEditToolsOpen={isAddEditToolsOpen}
        addEditTools={addEditTools}
        handleClose={closeAddEditToolsMutation}
        handleSubmitTools={handleSubmitTools}
        onHandleChange={onHandleChange}
      />
    </>
  );
};

ToolsDialog.propTypes = {
  toolsList: PropTypes.arrayOf(PropTypes.object),
  isOpenToolsDialog: PropTypes.bool,
  isAddEditToolsOpen: PropTypes.bool,
  addEditTools: PropTypes.object,
  openAddEditToolsMutation: PropTypes.func,
  closeAddEditToolsMutation: PropTypes.func,
  handleClose: PropTypes.func,
  onHandleChange: PropTypes.func,
  handleSubmitTools: PropTypes.func,
  handleDeleteTools: PropTypes.func,
};

export default ToolsDialog;
