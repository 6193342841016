import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Grid, Box, Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { GET_SIZE } from "utils/responsive";
import { BACKGROUND } from "theme/colors";
import T from "T";
import { canAddTechAssignment } from "utils/permissions";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({ handleCreateAssignment = noop }) => {
  const { isLg } = GET_SIZE();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        p: 1,
        backgroundColor: "background.card",
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3} xs={12}></Grid>
      <Grid item md={2} xs={12}></Grid>
      <>
        <Grid item md={1.5} xs={12}></Grid>
        <Grid item md={1.5} xs={12}></Grid>
      </>

      <Grid item></Grid>
      <Grid item></Grid>

      <Grid item>
        {canAddTechAssignment() && (
          <Button
            size="medium"
            sx={{
              bgcolor: "themeColor",
              whiteSpace: "nowrap",
              color: BACKGROUND.white,

              "& .MuiButton-startIcon": {
                mr: 0,
              },
              "&:hover": {
                bgcolor: "themeColor",
                color: BACKGROUND.white,
              },
            }}
            startIcon={<AddIcon sx={{ width: 19 }} />}
            onClick={handleCreateAssignment}
          >
            {T.NEW_ASSIGNMENT}
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

TopBar.propTypes = {
  handleCreateAssignment: PropTypes.func,
};

export default TopBar;
