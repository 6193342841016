import PropTypes from "prop-types";
import { Table as TableView, TableContainer } from "@mui/material";
import { HISTORY_TABLE_HEADER } from "settings/constants/members";
import TableHeader from "../Interview/TableHeader";
import HistoryTableBody from "./HistoryTableBody";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";

const HistoryTable = ({ allTableRows }) => (
  <TableContainer
    sx={{
      overflow: "auto",
      height: 220,
    }}
  >
    {allTableRows.length > 0 ? (
      <TableView stickyHeader>
        <TableHeader columns={HISTORY_TABLE_HEADER} />
        <HistoryTableBody records={allTableRows} />
      </TableView>
    ) : (
      <Box>
        <MISNoData />
      </Box>
    )}
  </TableContainer>
);

HistoryTable.propTypes = {
  allTableRows: PropTypes.array,
};

export default HistoryTable;
