import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { TableRow, TableCell, TableBody, styled, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { GET_SIZE } from "utils/responsive";
import { getMemberColumnData, handleHeaderClass } from "utils/recommendedMembers";
import MISNoData from "components/common/MISNoData";
import { Box } from "@mui/system";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
}));

const MISTableBody = ({
  columns = [],
  lockedColumns = [],
  records = [],
  shortListedChecks = [],
  handleShortListCheckBoxChange = noop,
}) => {
  const { isXs } = GET_SIZE();
  return (
    <TableBody>
      {Children.toArray(
        records.map((record, index) => {
          const userId = get(record, "id", "");
          const isShortlisted = get(record, "shortlistedchecks", "");

          return (
            <TableRow sx={{ background: BACKGROUND.white }}>
              <Box
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 2,
                  backgroundColor: "white",
                  border: "1px soild red",
                  height: "110px",
                }}
              >
                <StyledTableCell
                  sx={{
                    border: "none",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={shortListedChecks.length !== 0 ? shortListedChecks[index]?.isChecked : false}
                          onChange={(e) => handleShortListCheckBoxChange(e, index)}
                          name={`${userId}`}
                          id={`${userId}`}
                          sx={{ opacity: isShortlisted ? 0.5 : 1 }}
                          disabled={isShortlisted}
                        />
                      }
                    />
                  </FormGroup>
                </StyledTableCell>
              </Box>
              {Children.toArray(
                columns.map((column, index) => {
                  const colKey = get(column, "columnKey", "");

                  const { columnData } = getMemberColumnData(record, isXs, colKey);
                  const isRowLocked = get(column, "locked", false);

                  const isSticky = index < 1;
                  return (
                    <StyledTableCell
                      className={handleHeaderClass(index, isRowLocked, lockedColumns)}
                      sx={{
                        position: isSticky ? "sticky" : "inherit",
                        left: 71,
                        zIndex: isSticky ? 1 : "inherit",
                        backgroundColor: "white",
                      }}
                    >
                      {columnData}
                    </StyledTableCell>
                  );
                }),
              )}
            </TableRow>
          );
        }),
      )}

      {records && records.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  column: PropTypes.array,
  lockedColumns: PropTypes.array,
  records: PropTypes.array,
  shortListedChecks: PropTypes.array,
  handleShortListCheckBoxChange: PropTypes.func,
  columns: PropTypes.array,
};

export default memo(MISTableBody);
