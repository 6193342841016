import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAllInterviews: build.query({
      query: ({ page, size, userId }) => ({
        url: `technicalAssignment/get/all`,
        method: "GET",
        params: { page, size, userId },
      }),
    }),
  }),
});

export const { useLazyGetAllInterviewsQuery } = extendedApi;
