import { api3 } from "api";

const extendedApi = api3.injectEndpoints({
  endpoints: (build) => ({
    getAssignmentQuestion: build.mutation({
      query: (assignmentPayload) => ({
        url: "/userData ",
        method: "POST",
        body: assignmentPayload,
      }),
    }),
  }),
});

export const { useGetAssignmentQuestionMutation } = extendedApi;
