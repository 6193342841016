import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Grid, Box, Button, styled } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import SearchBar from "components/Members/SearchBar";

import { canAddTestimonial, canViewTestimonial } from "utils/permissions";
import { GET_SIZE } from "utils/responsive";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { MISCurrentUser } from "utils/validations";
import { get } from "utils/lodash";
import TestimonialFilters from "../TestimonialFilters";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  searchInput = "",
  isFilterEmpty = true,
  filters = {},
  showOptions = false,
  showCancelIcon = false,
  searchTableData = {},
  projectManagers = [],
  handleExport = noop,
  onClick = noop,
  handleKeyChange = noop,
  handleChange = noop,
  reset = noop,
  onClickOutside = noop,
  handleFilterClose = noop,
  handleFilterApply = noop,
  onhandleFilterChange = noop,
  handleAddTestimonialDialog = noop,
}) => {
  const { isLg } = GET_SIZE();

  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");

  return (
    <Grid
      container
      alignItems="center"
      justifyContent={isLg ? "space-between" : "center"}
      sx={{
        mt: 1,
        mb: 1,
        pl: 3,
        pr: 3,
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
        },
      }}
    >
      <Grid item md={3} xs={12}>
        <SearchBar
          records={searchTableData}
          searchInput={searchInput}
          showOptions={showOptions}
          showCancelIcon={showCancelIcon}
          onClick={onClick}
          handleKeyChange={handleKeyChange}
          handleChange={handleChange}
          reset={reset}
          onClickOutside={onClickOutside}
        />
      </Grid>
      {canAddTestimonial() && (
        <Grid item md={2.5} xs={12} sx={{ mr: 2 }}>
          <TestimonialFilters
            filters={filters}
            projectManagers={projectManagers}
            isFilterEmpty={isFilterEmpty}
            handleFilterClose={handleFilterClose}
            handleFilterApply={handleFilterApply}
            onhandleFilterChange={onhandleFilterChange}
          />
        </Grid>
      )}
      {canAddTestimonial() && (
        <Grid item>
          <Button
            size="medium"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              whiteSpace: "nowrap",
              color: BACKGROUND.white,

              "& .MuiButton-startIcon": {
                mr: 0,
              },
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
                color: BACKGROUND.white,
              },
            }}
            disabled={!canViewTestimonial()}
            startIcon={<AddIcon sx={{ width: 19 }} />}
            onClick={() => handleAddTestimonialDialog()}
          >
            {T.ADD_TESTIMONIALS}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

TopBar.propTypes = {
  projectManagers: PropTypes.array,
  filters: PropTypes.object,
  searchInput: PropTypes.string,
  showOptions: PropTypes.bool,
  showCancelIcon: PropTypes.bool,
  searchTableData: PropTypes.object,
  handleExport: PropTypes.func,
  onClick: PropTypes.func,
  handleKeyChange: PropTypes.func,
  handleChange: PropTypes.func,
  reset: PropTypes.func,
  onClickOutside: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  handleAddTestimonialDialog: PropTypes.func,
};

export default TopBar;
