import PropTypes from "prop-types";
import { Typography, Box, Card, DialogContent, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MISDialog from "components/common/MISDialog";
import MISStepper from "components/common/MISStepper";
import T from "T";

const InterviewStatus = ({ handleTrackStatusDialog, interviewSteps, activeStep, openStepperDialog = false }) => {
  const handleClose = () => {
    if (handleTrackStatusDialog) {
      handleTrackStatusDialog([], "");
    }
  };

  return (
    <MISDialog open={openStepperDialog}>
      <Box minWidth={530} minHeight={260}>
        <Box display="flex" alignItems="center" mt={2} ml={2}>
          <IconButton sx={{ fontSize: 20 }} onClick={handleClose}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={700} ml={1}>
            {T.INTERVIEW_STATUS}
          </Typography>
        </Box>
        <DialogContent
          sx={{
            p: 1.5,
            m: 1,
            mt: 1,
            backgroundColor: "background.card",
            boxShadow: "none",
            flexGrow: 1,
          }}
        >
          <Card sx={{ pt: 6, pb: 5, borderRadius: 0 }}>
            <MISStepper steps={interviewSteps} activeStep={activeStep} alternativeLabel />
          </Card>
        </DialogContent>
      </Box>
    </MISDialog>
  );
};

InterviewStatus.propTypes = {
  handleTrackStatusDialog: PropTypes.func,
  interviewSteps: PropTypes.array,
  activeStep: PropTypes.string,
  openStepperDialog: PropTypes.bool,
};

export default InterviewStatus;
