import * as React from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import {
  Checkbox,
  Typography,
  Autocomplete,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
} from "@mui/material";

import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";

import T from "T";

import MISTextField from "./MISTextField";
import WithInputLabel from "./WithInputLabel";
import { get, isEqual } from "lodash";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MISNestedAutocomplete = ({
  multiple,
  label = "",
  listDetails = [],
  value = [],
  placeholder = T.SELECT_OPTION,
  required,
  endIcon,
  deprecatedLabel = true,
  error = false,
  dontDisableCloseOnSelect = false,
  getByLabelText = noop,
  onHandleChange = noop,
  inputAdornment = null,
  andormentPosition = "",
  ...rest
}) => {
  const [openItem, setOpenItem] = React.useState(-1);
  const [autocompleteOpen, setAutocompleteOpen] = React.useState(false);
  const [hoveredOption, setHoveredOption] = React.useState(null);

  const handleToggle = (event, obj) => {
    event.stopPropagation();

    if (openItem === obj.costCenterId) setOpenItem(-1);
    else setOpenItem(obj.costCenterId);
  };

  const handleOptionClick = (event, option) => {
    event.stopPropagation();
    if (multiple) {
      if (option.children && option.children.length > 0) {
        const allchildrenSelected = option.children.every((child) => value.some((v) => isEqual(v, child)));
        const newValues = allchildrenSelected
          ? value.filter((v) => !option.children.some((child) => isEqual(v, child)))
          : [...value, ...option.children.filter((child) => !value.some((v) => isEqual(v, child)))];

        onHandleChange(event, newValues);
      } else {
        if (value.some((v) => isEqual(v, option))) {
          onHandleChange(
            event,
            value.filter((v) => !isEqual(v, option)),
          );
        } else {
          onHandleChange(event, [...value, option]);
        }
      }
    } else {
      onHandleChange(event, option);
      setAutocompleteOpen(false);
    }
  };

  const handlechildClick = (event, child, parent) => {
    event.stopPropagation();
    if (multiple) {
      let newValues;
      if (value.some((v) => isEqual(v, child))) {
        newValues = value.filter((v) => !isEqual(v, child));
      } else {
        newValues = [...value, child];
      }

      const allchildrenSelected = parent.children.every((child) => newValues.some((v) => isEqual(v, child)));
      if (allchildrenSelected) {
        newValues = [...newValues, parent];
      } else {
        newValues = newValues.filter((v) => !isEqual(v, parent));
      }

      onHandleChange(event, newValues);
    } else {
      onHandleChange(event, child);
      setAutocompleteOpen(false);
    }
  };

  const handleMouseEnter = (option) => {
    setHoveredOption(option);
    //setOpenItems((prev) => ({ ...prev, [option.id]: true }));
  };

  const handleMouseLeave = () => {
    if (hoveredOption) {
      //setOpenItems((prev) => ({ ...prev, [hoveredOption.id]: false }));
    }
    setHoveredOption(null);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Tab" || event.code === "Tab") {
      if (!event.target.value) {
        return;
      }

      if (multiple) {
        const matchingOptions = listDetails.filter((item) =>
          getByLabelText(item).toLowerCase().includes(event.target.value.toLowerCase()),
        );

        if (matchingOptions.length === 0) {
          event.preventDefault();
        } else {
          event.preventDefault();
          const newOption = matchingOptions.find((option) => !value.some((v) => isEqual(v, option)));

          if (newOption) {
            onHandleChange(event, [...value, newOption]);
          }
        }
      } else {
        const firstOption = listDetails.find((item) =>
          getByLabelText(item).toLowerCase().includes(event.target.value.toLowerCase()),
        );

        if (firstOption) {
          onHandleChange(event, firstOption);
        }
      }
    }
  };

  const handleOnChange = (event, newValue) => {
    onHandleChange(event, newValue);
    if (!multiple) {
      setAutocompleteOpen(false);
    }
  };

  const isparentSelected = (option) => {
    if (!option.children || option.children.length === 0) return false;
    return option.children.every((child) => value.some((v) => isEqual(v, child)));
  };

  return (
    <Autocomplete
      multiple={multiple}
      options={listDetails}
      disableCloseOnSelect={dontDisableCloseOnSelect ? false : multiple}
      disableClearable={value?.length === 0}
      size="small"
      value={value}
      open={autocompleteOpen}
      onOpen={() => setAutocompleteOpen(true)}
      onClose={() => setAutocompleteOpen(false)}
      isOptionEqualToValue={(option, value) => isEqual(option, value)}
      getOptionLabel={(option) => getByLabelText(option, label)}
      onChange={handleOnChange}
      {...rest}
      renderOption={(props, option, { selected }) => (
        <div
          key={option.id}
          // onMouseEnter={() => handleMouseEnter(option)} onMouseLeave={handleMouseLeave}
        >
          <ListItemButton onClick={(event) => handleOptionClick(event, option)}>
            {multiple && (
              <Checkbox
                sx={{ p: 0, mr: 0 }}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected || isparentSelected(option)}
              />
            )}
            <ListItemText primary={getByLabelText(option, label)} />
            {option.children && option.children.length > 0 && (
              <ListItemIcon onClick={(event) => handleToggle(event, option)}>
                <IconButton>{openItem === option.costCenterId ? <ExpandLess /> : <ExpandMore />}</IconButton>
              </ListItemIcon>
            )}
          </ListItemButton>
          <Collapse in={openItem === option.costCenterId} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {option.children &&
                option.children.map((child) => (
                  <ListItemButton key={child.id} sx={{ pl: 4 }} onClick={(event) => handlechildClick(event, child, option)}>
                    {multiple && (
                      <Checkbox
                        sx={{ p: 0, mr: 0 }}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={value.some((v) => isEqual(v, child))}
                      />
                    )}
                    <ListItemText primary={child.costCenterName} />
                  </ListItemButton>
                ))}
            </List>
          </Collapse>
        </div>
      )}
      renderInput={(params) => (
        <WithInputLabel label={deprecatedLabel && label} required={required} endIcon={endIcon} error={error}>
          <MISTextField
            variant="outlined"
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            sx={{ "& .MuiOutlinedInput-root": { p: 0 } }}
            {...params}
            InputProps={{
              ...params.InputProps,
              [andormentPosition]: (
                <>
                  {inputAdornment}
                  {params.InputProps[andormentPosition]}
                </>
              ),
            }}
          />
        </WithInputLabel>
      )}
    />
  );
};

MISNestedAutocomplete.propTypes = {
  multiple: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  listDetails: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  getByLabelText: PropTypes.func,
  onHandleChange: PropTypes.func,
  required: PropTypes.bool,
  endIcon: PropTypes.node,
  deprecatedLabel: PropTypes.bool,
  error: PropTypes.bool,
  inputAdornment: PropTypes.element,
  andormentPosition: PropTypes.string,
};

export default MISNestedAutocomplete;
