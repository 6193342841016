import { api } from "api";

const extendedApi = api.injectEndpoints({
	endpoints: (builder) => ({
		getToolTypeList: builder.mutation({
			query: ({ page, rowsPerPage, search }) => ({
				url: `/toolType/get/all?page=${page}&size=${rowsPerPage}`,
				method: "POST",
				body: {
					toolTypeName: search ? search : "",
				},
			}),
		}),
	}),
});

export const { useGetToolTypeListMutation } = extendedApi;
