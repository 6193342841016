import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, Box, IconButton, Tooltip } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";

import memo from "utils/react";
import { get } from "utils/lodash";
import { MISCurrentUser } from "utils/validations";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { camelCase, noop } from "lodash";

const CourseHeader = ({ columns = [], sorting = {}, handleCourseSorting = noop }) => {
  return (
    <TableHead>
      <TableRow sx={{ height: 30 }}>
        {Children.toArray(
          columns.map((column, ind) => {
            const columnName = get(column, "label", "").toUpperCase();
            const isDisabled = get(sorting[camelCase(columnName)], 'disable', false);
            const hasSorting = [
              T.COURSE_NAME.toUpperCase(),
              T.TRAINING_DURATION.toUpperCase(),
              T.COURSE_CREATED_BY.toUpperCase(),
              T.SUB_COURSE_COUNT.toUpperCase(),
            ].includes(columnName);
            return (
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.header,
                  paddingY: 0,
                  left: ind === 0 ? "0px" : "",
                  right: ind === columns.length - 1 ? "0px" : "",
                  position: ind === columns.length - 1 || ind === 0 ? "sticky" : "",
                  zIndex: ind === columns.length - 1 || ind === 0 ? +3 : "",
                }}
              >
                {hasSorting ? (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                      {column.label.toUpperCase()}
                    </Typography>

                    <IconButton
                      onClick={() => handleCourseSorting(camelCase(columnName))}
                      aria-label="Sort"
                      size="small"
                      disabled={isDisabled}
                      sx={{ color: NETSMARTZ_THEME_COLOR }}
                    >
                      <SwapVertIcon fontSize="22" />
                    </IconButton>
                  </Box>
                ) : (
                  <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                    {columnName}
                  </Typography>
                )}
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

CourseHeader.propTypes = {
  columns: PropTypes.array,
  handleCourseSorting: PropTypes.func,
  courseNameSorting: PropTypes.bool,
  courseDurationSorting: PropTypes.bool,
  subCourseCountSorting: PropTypes.bool,
  courseCreatedBySorting: PropTypes.bool,
};

export default memo(CourseHeader);
