import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    uploadCV: build.mutation({
      query: ({ id, preSaleMappingId, cvFile }) => ({
        url: `/selected/user/users/${id}/preSalesMappings/${preSaleMappingId}/upload/cvFile`,
        method: "POST",
        body: cvFile,
      }),
    }),
  }),
});

export const { useUploadCVMutation } = extendedApi;
