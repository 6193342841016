import { createSlice } from "@reduxjs/toolkit";

const testimonialFilterSlice = createSlice({
  name: "TestimonialFilterSlice",
  initialState: {
    storedFilters: {
      projectManager: null,
      status: null,
    },
  },

  reducers: {
    testimonialFilterStore: (state, action) => {
      state.storedFilters = action.payload.storedFilters;
    },
  },
});

export const { testimonialFilterStore } = testimonialFilterSlice.actions;
export default testimonialFilterSlice.reducer;
