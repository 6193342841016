import { createSlice } from "@reduxjs/toolkit";

const mockInterviewSlice = createSlice({
  name: "MockInterviewSlice",
  initialState: {
    mockResponses: [],
    formSubmitted: false,
    mockInterviewUserRegistered: false,
    openInstructionModal: false,
    responseArr: [],
    startTime: null,
    timeLeft: 20 * 60,
    isMockInterviewAssigned: null,
  },
  reducers: {
    mockInterviewStore: (state, action) => {
      state.mockResponses = action.payload.mockResponses;
    },
    mockInterviewStore1: (state, action) => {
      state.formSubmitted = action.payload.formSubmitted;
    },
    manageMockInterviewUserLogin: (state, action) => {
      state.mockInterviewUserRegistered = action.payload.mockInterviewUserRegistered;
    },
    mockInterviewResponseArrStore: (state, action) => {
      state.responseArr = action.payload.responseArr;
    },
    mockInterviewInstructionModal: (state, action) => {
      state.openInstructionModal = action.payload.openInstructionModal;
    },
    setStartTime: (state, action) => {
      state.startTime = action.payload.startTime;
    },
    updateTimeLeft: (state, action) => {
      state.timeLeft = action.payload.timeLeft;
    },
    mockInterviewStatus: (state, action) => {
      state.isMockInterviewAssigned = action.payload.isMockInterviewAssigned;
    },
  },
});
export const {
  mockInterviewStore,
  mockInterviewStore1,
  manageMockInterviewUserLogin,
  mockInterviewResponseArrStore,
  mockInterviewInstructionModal,
  setStartTime,
  updateTimeLeft,
  mockInterviewStatus,
} = mockInterviewSlice.actions;
export default mockInterviewSlice.reducer;
