import { useCallback, useEffect, useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useSelector, shallowEqual, useDispatch } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";

import { Typography, Paper, Menu, Switch, Tooltip } from "@mui/material";

import TopBar from "components/Members/TopBar";
import Table from "components/Members/Table";
import MISLoader from "components/common/MISLoader";
import MISTableConfigurator from "components/common/MISTableConfigurator";
import T from "T";

import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { MEMBER_COLUMNS } from "settings/constants/members";
import { useLazyGetUserListQuery } from "api/members/getUserList";
import { useLazyGetUserSearchListQuery } from "api/members/getUserSearchList";
import { useApplyFiltersMutation } from "api/members/filters/applyFilters";
import { useSaveFiltersMutation } from "api/members/filters/saveFilters";
import { useDeleteFilterMutation } from "api/members/filters/deleteFilter";
import { useLazyGetFiltersByMemberIdQuery } from "api/members/filters/getFiltersByMemberId";
import { useLazyGetFilterByIdQuery } from "api/members/filters/getFilterById";
import { PAGINATION } from "settings/constants/pagination";
import { MISCurrentUser } from "utils/validations";
import usePMFetch from "hooks/usePMFetch";

import { memberStore } from "slices/memberSlice";

import { handleConfiguratorDragEnd, handleConfigurationChange } from "data/configurator/configuratorSelector";
import { handleError } from "utils/error";
import { get } from "utils/lodash";
import { downloadFile } from "utils/file";
import { toast } from "react-toastify";
import Constants from "Constants";

import useUpdateAccessFetch from "hooks/useUpdateAccessFetch";
import { debounce, isEqual } from "lodash";
import { memberFilterStore } from "slices/memberFilterSlice";
import { memberSearchStore } from "slices/memberSearchSlice";
import { savedFilterStore } from "slices/savedFilterSlice";
import { useBulkUploadMutation } from "api/members/bulkUpload";
import { memberActiveStore } from "slices/memberActiveSlice";

const RENDER_TABLE_DATA_TIME = 30;
const DEBOUNCE_TIME = 300;
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const { SERVER_URL } = Constants;
const defaultStoredFilters = {};
const SCROLL_ORIGIN = 0;
const defaultFiltervalues = {
  availability: "",
  departmentName: [],
  empMode: "",
  empStatus: "",
  page: 0,
  projectManagerName: [],
  projectMode: "",
  projectName: [],
  size: 50,
  primarySkillName: [],
  secondarySkillName: [],
  status: T.ACTIVE,
  totalExp: "",
  workLocationName: [],
};

const Members = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user, pag, rowPerPag, storedScrollPosition } = useSelector(
    (state) => ({
      user: get(state, "LoginSlice.user", null),
      pag: get(location, "state.showActive", true) ? get(state, "MemberSlice.pag", INITIAL_PAGE) : INITIAL_PAGE,
      rowPerPag: get(state, "MemberSlice.rowPerPag", ROWS_PER_PAGE),
      storedScrollPosition: get(state, "MemberSlice.storedScrollPosition", SCROLL_ORIGIN),
    }),
    shallowEqual,
  );

  const memberId = get(user, "id", "");

  const [getUserList, { isFetching }] = useLazyGetUserListQuery();
  const [getUserSearchList, { isFetching: isSearchFetching }] = useLazyGetUserSearchListQuery();

  const { projectManagers, workLocationList, skillList, projectList, departmentList } = usePMFetch();

  const [applyFilters, { isFetching: isApplyfetching }] = useApplyFiltersMutation();
  const [saveFilters, { isFetching: isSavefetching }] = useSaveFiltersMutation();
  const [deleteFilter, { isFetching: isDeleteFetching }] = useDeleteFilterMutation();
  const [getFiltersByMemberId] = useLazyGetFiltersByMemberIdQuery();

  const [getFilterById, { isFetching: isFilterFetching }] = useLazyGetFilterByIdQuery();
  const [bulkUpload, { isLoading: isFileUploading }] = useBulkUploadMutation();

  const { storedActive } = useSelector(
    (state) => ({
      storedActive: get(state, "MemberActiveSlice.storedActive", false),
    }),
    shallowEqual,
  );

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isOpenConfigurator: false,
    configuratorColumns: cloneDeep(MEMBER_COLUMNS),
    pastConfiguratorColumns: cloneDeep(MEMBER_COLUMNS),
    page: pag,
    rowsPerPage: rowPerPag,
    totalTableRowsCount: 0,
    totalPageCount: 0,
    showActive: storedActive,
    searchInput: "",
    showOptions: false,
    showCancelIcon: false,
    isFilterApplied: false,
    selectedFilterId: "",
    bulkMember: "",
    bulkMemberFile: null,
    filters: {},
    filtersList: {},
    exportLoading: false,
    currentScrollPosition: 0,
  });

  const {
    isOpenConfigurator,
    configuratorColumns,
    pastConfiguratorColumns,
    page,
    rowsPerPage,
    showActive,
    searchInput,
    showOptions,
    showCancelIcon,
    bulkMember,
    bulkMemberFile,
    filters,
    isFilterApplied,
    selectedFilterId,
    tableData,
    searchTableData,
    filtersList,
    exportLoading,
    currentScrollPosition,
  } = localState;

  const { storedFilterId } = useSelector(
    (state) => ({
      storedFilterId: get(state, "SavedFilterSlice.storedFilterId", ""),
    }),
    shallowEqual,
  );

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "MemberFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const { storedSearchInput } = useSelector(
    (state) => ({
      storedSearchInput: get(state, "MemberSearchSlice.storedSearchInput", ""),
    }),
    shallowEqual,
  );

  const isFilterStored = JSON.stringify(storedFilters) !== JSON.stringify(defaultStoredFilters);
  const isFilterStoredEmpty = isEqual(storedFilters, defaultFiltervalues) || isEqual(defaultStoredFilters, storedFilters);

  const findFiltersByMemberId = () => {
    setTimeout(() => {
      getFiltersByMemberId(memberId)
        .unwrap()
        .then((res) => {
          setLocalState({
            filtersList: res,
          });
        })
        .catch(handleError);
    }, RENDER_TABLE_DATA_TIME);
  };

  const refreshMemberTable = () => {
    setTimeout(() => {
      getMemberTableData(page, rowsPerPage);
    }, RENDER_TABLE_DATA_TIME);
  };

  const refreshUserAction = () => {
    if (searchInput) {
      getMemberSearchTableData(page, rowsPerPage, showActive, searchInput);
    } else if (Object.keys(filters).length > 0) {
      getMemberFilterTableData(page, rowsPerPage);
    } else {
      getMemberTableData(page, rowsPerPage);
    }
  };

  useEffect(() => {
    if (!storedSearchInput && !storedFilterId && !isFilterStored) {
      setTimeout(() => {
        refreshMemberTable();
      }, RENDER_TABLE_DATA_TIME);
    }
    findFiltersByMemberId();
  }, []);
  // const isFilterNotRendred = isEqual(storedFilters,defaultStoredFilters);

  // useEffect(()=>{
  //   if(!isFilterNotRendred)
  //   {
  //     const renderedFilters =loadFetchedFilters (storedFilters);
  //     setLocalState({filters:renderedFilters})
  //   }
  // },[])

  useEffect(() => {
    if (isFilterStored && filtersList) {
      setTimeout(() => {
        getMemberFilterTableData(page, rowsPerPage);
      }, RENDER_TABLE_DATA_TIME);
    }
  }, [isFilterStored, filtersList]);

  useEffect(() => {
    if (storedFilterId && filtersList) {
      onHandleFilterSelect(storedFilterId);
      setTimeout(() => {
        handleFilterApply(storedFilterId);
      }, RENDER_TABLE_DATA_TIME);
    }
  }, [storedFilterId, filtersList]);

  useEffect(() => {
    if (storedSearchInput && filtersList) {
      setLocalState({
        searchInput: storedSearchInput,
        showCancelIcon: true,
      });
      setTimeout(() => {
        getMemberSearchTableData(page, rowsPerPage, showActive, storedSearchInput);
      }, RENDER_TABLE_DATA_TIME);
    }
  }, [storedSearchInput, filtersList]);

  // useUpdateAccessFetch();

  const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(tableData);

  const getMemberTableData = (page, rowsPerPage, status = showActive) => {
    const params = {
      page,
      size: rowsPerPage,
      status: status || showActive === T.STABLE ? T.ACTIVE : T.INACTIVE,
    };
    getUserList(params)
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: res,
        });
      })
      .catch(handleError);
  };

  const getMemberSearchTableData = (page, rowsPerPage, status = showActive, search = "", type = "") => {
    if (search === "" && storedSearchInput === "") {
      return;
    }
    getUserSearchList({
      page,
      rowsPerPage,
      status: status ? T.ACTIVE : T.INACTIVE,
      search,
    })
      .unwrap()
      .then((res) => {
        dispatch(memberFilterStore({ storedFilters: defaultStoredFilters }));
        dispatch(savedFilterStore({ selectedFilterId: "" }));
        dispatch(memberSearchStore({ storedSearchInput: search }));
        setLocalState({
          page,
          searchTableData: res,
          tableData: res,
          filters: {},
          selectedFilterId: "",
        });
      })
      .catch(handleError);
  };

  // Page change handler
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });

    if (searchInput) {
      getMemberSearchTableData(newPage, rowsPerPage, showActive, searchInput);
    } else if (Object.keys(filters).length > 0) {
      getMemberFilterTableData(newPage, rowsPerPage);
    } else {
      getMemberTableData(newPage, rowsPerPage);
    }

    dispatch(memberStore({ page: newPage, rowsPerPage }));

    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;

    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });

    if (searchInput) {
      getMemberSearchTableData(INITIAL_PAGE, value, showActive, searchInput);
    } else if (Object.keys(filters).length > 0) {
      getMemberFilterTableData(INITIAL_PAGE, value);
    } else {
      getMemberTableData(INITIAL_PAGE, value);
    }

    dispatch(memberStore({ page: INITIAL_PAGE, rowsPerPage: value }));
  };

  const handleStatusSwitch = () => {
    navigate("/app/members", { state: { showActive: !showActive } });
    setLocalState({
      showActive: !showActive,
      page: INITIAL_PAGE,
      rowsPerPage: ROWS_PER_PAGE,
      filters: {},
      searchInput: "",
      selectedFilterId: "",
    });

    getMemberTableData(INITIAL_PAGE, ROWS_PER_PAGE, !showActive);
    dispatch(memberActiveStore({ storedActive: !showActive }));
    dispatch(memberFilterStore({ storedFilters: defaultStoredFilters }));
    dispatch(savedFilterStore({ selectedFilterId: "" }));
    dispatch(memberFilterStore({ storedFilters: {} }));
    dispatch(savedFilterStore({ selectedFilterId: "" }));
  };

  const renderTabContent = () => ({
    columns: pastConfiguratorColumns.filter((column) => column.checked),
    lockedColumns: pastConfiguratorColumns.filter((column) => column.locked && column.checked),
    page,
    rowsPerPage,
    totalTableRowsCount,
    totalPageCount,
    refreshMemberTable,
    refreshUserAction,
    onPageChange: handlePageChange,
    onRowsPerPageChange: handleRowsPerPageChange,
    // handleScroll,
    // tableData,
    storedScrollPosition,
    currentScrollPosition,
    allTableRows,
  });

  const getBinaryFile = async (event) => {
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const formData = new FormData();
    formData.append("file", file);
    return { fileName, formData };
  };

  const handleBulkFileUpload = async (event) => {
    const { name } = event.target;
    const { fileName, formData } = await getBinaryFile(event);
    const parts = fileName.split(".");
    const extension = parts[parts.length - 1];
    if (extension !== "XLSX" && extension !== "xlsx") {
      toast.error("Please upload a XLSX file.");
      event.target.value = null;
      return;
    }
    if (!formData) {
      toast.error("File is empty");
      event.target.value = null;
      return;
    }
    setLocalState({ [name]: fileName, bulkMemberFile: formData });
  };

  const handleDeleteFile = (event, type) => {
    setLocalState({ [type]: "", bulkMemberFile: "" });
  };

  const handleBulkUploadClose = (type) => {
    setLocalState({ [type]: "", bulkMemberFile: "" });
  };

  const handleSubmitUpload = (fileType) => {
    const modFileType = ["ADD", "EDIT", "RELIEVE"].includes(fileType) ? "ADD" : fileType;
    bulkUpload({ bulkMemberFile, modFileType })
      .unwrap()
      .then((res) => {
        toast.success("Bulk File Uploaded");
        refreshUserAction();
      })
      .catch(handleError);
  };

  const handleConfigurator = () => {
    setLocalState({ isOpenConfigurator: !isOpenConfigurator });
  };

  const handleSearchClick = (value) => {
    dispatch(memberStore({ page: INITIAL_PAGE, rowsPerPage }));
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      searchInput: value,
      page: INITIAL_PAGE,
    });
    getMemberSearchTableData(INITIAL_PAGE, rowsPerPage, showActive, value);
  };

  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;

    const searchValue = value || get(dataset, "val", "");

    // if (searchValue === "") {
    //   dispatch(memberSearchStore({ storedSearchInput: searchValue }));
    //   refreshMemberTable();
    // }
    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });

    if (event.currentTarget.nodeName === "svg" && storedSearchInput) {
      dispatch(memberStore({ page: INITIAL_PAGE, rowsPerPage }));
      setLocalState({ page: INITIAL_PAGE });
      getMemberSearchTableData(INITIAL_PAGE, rowsPerPage, showActive, "");
    }
  };

  const handleSearchKeyChange = () => {
    dispatch(memberSearchStore({ storedSearchInput: searchInput }));
    dispatch(memberStore({ page: INITIAL_PAGE, rowsPerPage }));
    setLocalState({ page: INITIAL_PAGE });
    getMemberSearchTableData(INITIAL_PAGE, rowsPerPage, showActive, searchInput);
  };

  const resetSearch = () => {
    setLocalState({
      showOptions: false,
      searchInput: "",
      showCancelIcon: false,
    });
    dispatch(memberSearchStore({ storedSearchInput: "" }));
    refreshMemberTable();
  };

  const handleClickOutside = () => {
    setLocalState({
      showOptions: false,
    });
  };

  const onhandleFilterChange = (newValue, item) => {
    dispatch(memberFilterStore({ storedFilters: defaultStoredFilters }));
    dispatch(savedFilterStore({ selectedFilterId: "" }));
    filters[item] = newValue;
    setLocalState({ filters });
  };

  const getAvailabilityVal = (availability) => {
    if (availability === T.FULL) {
      return 1;
    }
    if (availability === T.NO) {
      return 0;
    }
    if (availability === T.PARTIAL) {
      return 2;
    }
    return "";
  };

  const getAvailabilityValById = (availability) => {
    switch (availability) {
      case 1:
        return T.FULL;
      case 0:
        return T.NO;
      case 2:
        return T.PARTIAL;
      default:
        return "";
    }
  };

  const getFilterPayload = (page = INITIAL_PAGE, rowsPerPage) => {
    if (isFilterStored) {
      const payload = { ...storedFilters };
      payload.page = page;
      payload.size = rowsPerPage;
      return payload;
    }
    const minExp = get(filters, "minExp", "");
    const maxExp = get(filters, "maxExp", "");

    const availability = get(filters, T.AVAILABILITY, "");

    const payload = {
      primarySkillName: get(filters, `[${T.PRIMARY_SKILL}]`, []).map((data) => data.skillName),
      secondarySkillName: get(filters, `[${T.SECONDARY_SKILL}]`, []).map((data) => data.skillName),
      workLocationName: get(filters, `[${T.WORK_LOCATION}]`, []).map((data) => data.workLocationName),
      departmentName: get(filters, `[${T.DEPARTMENT}]`, []).map((data) => data.departmentName),
      empMode: get(filters, T.EMP_MODE, ""),
      empStatus: get(filters, T.STATUS, ""),
      projectName: get(filters, `[${T.PROJECT}]`, []).map((data) => data.name),
      projectMode: get(filters, T.PROJECT_MODE, ""),
      availability: getAvailabilityVal(availability),
      projectManagerName: get(filters, `[${T.PROJECT_MANAGER}]`, []).map((data) => data.name),
      totalExp: minExp && maxExp ? `${get(filters, "minExp", "")}-${get(filters, "maxExp", "")}` : "",
      status: showActive ? T.ACTIVE : T.INACTIVE,
      page,
      size: rowsPerPage,
    };
    return payload;
  };

  const getMemberFilterTableData = (page, rowsPerPage) => {
    const payload = getFilterPayload(page, rowsPerPage);
    dispatch(memberFilterStore({ storedFilters: payload }));
    applyFilters(payload)
      .unwrap()
      .then((res) => {
        if (isFilterStored) {
          setLocalState({ filters: loadFetchedFilters(storedFilters) });
        }
        setLocalState({
          tableData: res,
          searchInput: "",
          page,
        });
      })
      .catch(handleError);
  };

  const handleFilterSubmit = () => {
    dispatch(memberSearchStore({ storedSearchInput: "" }));
    setLocalState({ selectedFilterId: "", page: INITIAL_PAGE });
    dispatch(memberStore({ page: INITIAL_PAGE, rowsPerPage }));
    getMemberFilterTableData(INITIAL_PAGE, rowsPerPage);
  };

  const handleFilterClose = () => {
    setLocalState({ filters: {}, selectedFilterId: "" });
    dispatch(memberFilterStore({ storedFilters: defaultStoredFilters }));
    refreshMemberTable();
  };

  const handleFilterSave = (filterName) => {
    if (Object.keys(filters).length === 0) {
      toast.error(T.NO_FILTER_SELECTED);
      return;
    }

    if (filterName === "") {
      toast.error(T.PLEASE_ADD_FILTER_NAME);
      return;
    }

    if (
      get(filtersList, "results", [])
        .map((data) => data.name)
        .includes(filterName)
    ) {
      toast.error(T.FILTER_NAME_ALREADY_EXISTS);
      return;
    }

    const payload = {
      filter: getFilterPayload(),
      memberId,
      name: filterName,
    };

    payload.filter.page = page;
    payload.filter.size = rowsPerPage;

    saveFilters(payload)
      .unwrap()
      .then(() => {
        toast.success(T.FILTER_ADDED_SUCCESSFULLY);
        findFiltersByMemberId();
      })
      .catch(handleError);
  };

  const loadFetchedFilters = (records) => {
    const totalExp = get(records, "totalExp", "");
    const exp = totalExp.split("-");
    filters[T.PRIMARY_SKILL] = get(skillList, "results", []).filter((rec) =>
      get(records, "primarySkillName", []).includes(rec.skillName),
    );

    filters[T.SECONDARY_SKILL] = get(skillList, "results", []).filter((rec) =>
      get(records, "secondarySkillName", []).includes(rec.skillName),
    );

    filters[T.WORK_LOCATION] = get(workLocationList, "results", []).filter((rec) =>
      get(records, "workLocationName", []).includes(rec.workLocationName),
    );
    filters[T.EMP_MODE] = get(records, "empMode", "");
    filters[T.STATUS] = get(records, "empStatus", "");
    filters[T.DEPARTMENT] = get(departmentList, "results", []).filter((rec) =>
      get(records, "departmentName", []).includes(rec.departmentName),
    );
    filters[T.PROJECT] = get(projectList, "results", []).filter((rec) => get(records, "projectName", []).includes(rec.name));
    filters[T.PROJECT_MODE] = get(records, "projectMode", "");
    filters[T.AVAILABILITY] = records?.availability === 0 ? T.NO : getAvailabilityValById(get(records, "availability", ""));
    filters[T.PROJECT_MANAGER] = get(projectManagers, "results", []).filter((rec) =>
      get(records, "projectManagerName", []).includes(rec.name),
    );
    filters.minExp = exp[0] || "";
    filters.maxExp = exp[1] || "";

    return filters;
  };

  const onHandleFilterSelect = (id) => {
    setLocalState({
      selectedFilterId: id,
    });
  };
  const toastFilterAppliedMessage = useCallback(
    debounce(() => {
      toast.success(T.FILTER_APPLIED_SUCCESSFULLY);
    }, DEBOUNCE_TIME),
    [],
  );

  const handleFilterApply = (storedFilterId = "") => {
    const storedId = storedFilterId || selectedFilterId;
    dispatch(memberSearchStore({ storedSearchInput: "" }));
    dispatch(memberFilterStore({ storedFilters: defaultStoredFilters }));
    setLocalState({ searchInput: "" });
    getFilterById({
      id: storedId,
    })
      .unwrap()
      .then((res) => {
        const filterListResults = get(filtersList, "results", []);
        if (filterListResults.length > 0) {
          const selectedFilterResults = filterListResults.find((result) => result.id === storedId);
          const parsedRecords = JSON.parse(get(selectedFilterResults, "filters", ""));
          setLocalState({
            filters: loadFetchedFilters(parsedRecords),
          });
        }
        setLocalState({
          tableData: res,
        });
        !storedFilterId && toastFilterAppliedMessage();
      })
      .catch(handleError);
  };

  const handleFilterDelete = (id) => {
    deleteFilter({
      id,
    })
      .unwrap()
      .then(() => {
        findFiltersByMemberId();
        toast.success(T.FILTER_DELETED_SUCCESSFULLY);
      })
      .catch(handleError);
  };
  // const handleScrollChange= (e) =>{
  //   const scrollPosition = e.target.scrollTop;
  //   setLocalState({currentScrollPosition:scrollPosition})
  //   dispatch(memberStore({storedScrollPosition:scrollPosition}))
  // }

  // const handleScroll = useCallback(debounce(handleScrollChange,SCROLL_DEBOUNCE_TIME),[]);

  const { sessionToken } = MISCurrentUser();
  const exportFilterInitial = {
    availability: "",
    departmentName: [],
    empMode: "",
    empStatus: "",
    projectManagerName: [],
    projectMode: "",
    projectName: [],
    primarySkillName: [],
    secondarySkillName: [],
    status: showActive ? T.ACTIVE : T.INACTIVE,
    workLocationName: [],
    totalExp: `-`,
  };
  const handleExport = async (type, exportFilter) => {
    setLocalState({ exportLoading: true });
    const filterRequestDto = { ...exportFilter };
    if (JSON.stringify(exportFilter) === JSON.stringify(exportFilterInitial)) {
      fetch(`${SERVER_URL}/user/export?type=${type}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      })
        .then((res) => res.blob())
        .then((response) => {
          downloadFile(response, type);

          setLocalState({ exportLoading: false });
        })
        .catch(handleError);
    } else {
      fetch(`${SERVER_URL}/user/filter/export?type=${type}`, {
        method: "POST",
        body: JSON.stringify(filterRequestDto),
        headers: {
          Authorization: `Bearer ${sessionToken}`,
          "content-type": "application/json",
        },
      })
        .then((res) => res.blob())
        .then((response) => {
          downloadFile(response, type);

          setLocalState({ exportLoading: false });
        })
        .catch(handleError);
    }
  };

  const renderSearchFilterContent = () => ({
    searchInput,
    showOptions,
    showCancelIcon,
    searchTableData,
    filters,
    showActive,
    projectManagers,
    workLocationList,
    skillList,
    departmentList,
    projectList,
    filtersList,
    selectedFilterId,
    isFilterStoredEmpty,
    isFilterApplied,
    bulkMember,
    handleBulkFileUpload,
    handleDeleteFile,
    handleSubmitUpload,
    handleBulkUploadClose,
    handleFilterApply,
    handleFilterDelete,
    onHandleFilterSelect,
    handleFilterSave,
    handleFilterSubmit,
    handleFilterClose,
    handleExport,
    onhandleFilterChange,
    onClick: handleSearchClick,
    handleChange: handleSearchChange,
    handleKeyChange: handleSearchKeyChange,
    reset: resetSearch,
    onClickOutside: handleClickOutside,
    handleConfigurator,
  });

  return (
    <Paper sx={{ p: 2 }}>
      {(isFetching ||
        isSearchFetching ||
        isApplyfetching ||
        isSavefetching ||
        isFilterFetching ||
        exportLoading ||
        isFileUploading ||
        isDeleteFetching) && <MISLoader />}
      <Typography variant="h5">
        {showActive ? T.MEMBERS : `${T.INACTIVE} ${T.MEMBERS}`}

        <Tooltip placement="top" title={showActive ? T.TOGGLE_TO_SHOW_INACTIVE_MEMBERS : T.TOGGLE_TO_SHOW_ACTIVE_MEMBERS}>
          <Switch name="show_active" color="warning" checked={showActive} onChange={handleStatusSwitch} />
        </Tooltip>
      </Typography>

      <TopBar {...renderSearchFilterContent()} />
      <Table {...renderTabContent()} />

      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={() => setLocalState({ isOpenConfigurator: null })}
        open={Boolean(isOpenConfigurator)}
        anchorEl={isOpenConfigurator}
        PaperProps={{
          style: {
            left: "50%",
            transform: "translateX(65%) translateY(-15%)",
          },
        }}
      >
        <MISTableConfigurator
          configData={configuratorColumns}
          handleOnDragEnd={(result) =>
            setLocalState({
              configuratorColumns: handleConfiguratorDragEnd({
                result,
                columns: configuratorColumns,
              }),
            })
          }
          handleCancel={() =>
            setLocalState({
              isOpenConfigurator: null,
              configuratorColumns: pastConfiguratorColumns,
            })
          }
          handleConfChange={(index, type, confData) =>
            setLocalState({
              configuratorColumns: handleConfigurationChange({
                index,
                type,
                confData,
              }),
            })
          }
          saveConfigSettings={() =>
            setLocalState({
              isOpenConfigurator: null,
              pastConfiguratorColumns: configuratorColumns,
            })
          }
        />
      </Menu>
    </Paper>
  );
};

export default Members;
