import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import { noop } from "lodash";
import MISNoData from "components/common/MISNoData";
import SIRTableHeader from "./TableHeader";
import { SIR_TABLE_HEADER } from "settings/constants/members";
import SIRTableBody from "./TableBody";

const SIRTable = ({ tableRecord, handleDeleteSIR = noop, handleEditSIRDialog = noop }) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.5,
      backgroundColor: "background.card",
      "& .add-shadow": {
        boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
      },
    }}
  >
    <Box overflow="hidden">
      <TableContainer
        sx={{
          height: "calc(100vh - 200px)",
          overflow: "auto",
        }}
      >
        {tableRecord?.length > 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <SIRTableHeader columns={SIR_TABLE_HEADER} />
            <SIRTableBody
              allTableRows={tableRecord}
              handleDeleteSIR={handleDeleteSIR}
              handleEditSIRDialog={handleEditSIRDialog}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Box>
  </Card>
);

SIRTable.propTypes = {
  tableRecord: PropTypes.array,
  handleEditSIRDialog: PropTypes.func,
  handleDeleteSIR: PropTypes.func,
};
export default SIRTable;
