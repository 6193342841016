import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled, Box, Button } from "@mui/material";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import T from "T";
import { useNavigate } from "react-router-dom";
import { canAddTechAssignment } from "utils/permissions";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 17px",
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: BACKGROUND.white,
  },
  "&:nth-of-type(even)": {
    backgroundColor: BACKGROUND.cardDefault,
  },
}));

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  width: 22,
  height: 22,
  cursor: "pointer",
}));

const MemberAssignmentTableBody = ({ allTableRows = [] }) => {
  const navigate = useNavigate();

  const handleAssignedTopicDetails = (record) => {
    navigate("/app/assigned-topics", { state: record });
  };

  return (
    <TableBody>
      {Children.toArray(
        allTableRows.map((record, index) => {
          const assignmentDate = get(record, "assignedDate", "-");
          const submissionDate = get(record, "submissionDate", "-");
          const timeTaken = get(record, "timeTaken", "");
          const timeTakenInMinutes = timeTaken ? `${timeTaken} minutes` : "-";
          const technology = get(record, "technology", "");
          const durationAssigned = get(record, "assignedDuration", "");
          const durationAssignedInMinutes = durationAssigned ? `${durationAssigned} minutes` : "-";
          const status = get(record, "status", "");
          return (
            <StyledTableRow sx={{ background: BACKGROUND.white }} /* key={index} */>
              <StyledTableCell>{index + 1}.</StyledTableCell>
              <StyledTableCell>{assignmentDate}</StyledTableCell>
              <StyledTableCell>{submissionDate}</StyledTableCell>
              <StyledTableCell>{timeTakenInMinutes}</StyledTableCell>
              <StyledTableCell>topicAssigned</StyledTableCell>
              <StyledTableCell>{technology}</StyledTableCell>
              {canAddTechAssignment() && <StyledTableCell>{durationAssignedInMinutes}</StyledTableCell>}
              <StyledTableCell>{status}</StyledTableCell>
              <StyledTableCell>
                <Button
                  size="small"
                  sx={{
                    bgcolor: NETSMARTZ_THEME_COLOR,
                    whiteSpace: "nowrap",
                    color: BACKGROUND.white,

                    "& .MuiButton-startIcon": {
                      mr: 0,
                    },
                    "&:hover": {
                      bgcolor: NETSMARTZ_THEME_COLOR,
                      color: BACKGROUND.white,
                    },
                  }}
                  onClick={() => handleAssignedTopicDetails(record)}
                >
                  {T.VIEW_DETAILS}
                </Button>
              </StyledTableCell>
            </StyledTableRow>
          );
        }),
      )}
    </TableBody>
  );
};

MemberAssignmentTableBody.propTypes = {
  allTableRows: PropTypes.array,
  handleViewClientDialog: PropTypes.func,
  openDialog: PropTypes.bool,
};

export default memo(MemberAssignmentTableBody);
