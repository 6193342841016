import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";
import { Box } from "@mui/system";
import T from "T";

const FeedbackHistoryHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow>
      <TableCell
        sx={{
          border: "none",
          backgroundColor: BACKGROUND.cardDefault,
          paddingY: "5px ",
        }}
      >
        <Typography fontSize={13.5} noWrap fontWeight={600}>
          {T.S_NO}
        </Typography>
      </TableCell>

      {Children.toArray(
        columns.map((column) => (
          <>
            <TableCell
              align={get(column, "align", "")}
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.cardDefault,
                paddingY: "5px ",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography fontSize={13.5} noWrap fontWeight={600}>
                  {column.label}
                </Typography>
              </Box>
            </TableCell>
          </>
        )),
      )}
    </TableRow>
  </TableHead>
);

FeedbackHistoryHeader.propTypes = {
  columns: PropTypes.array,
};

export default memo(FeedbackHistoryHeader);
