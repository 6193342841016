import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";

import { MEMBER_FEEDBACK_TABLE } from "settings/constants/members";
import FeedbackTableHeader from "./FeedbackTableHeader";
import FeedbackTableBody from "./FeedbackTableBody";

const FeedbackListTable = ({ tableRecord = [] }) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.5,
      backgroundColor: "background.card",
      "& .add-shadow": {
        boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
      },
    }}
  >
    <Box overflow="hidden">
      <TableContainer
        sx={{
          height: "calc(100vh - 261px)",
          overflow: "auto",
        }}
      >
        <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
          <FeedbackTableHeader columns={MEMBER_FEEDBACK_TABLE} />
          <FeedbackTableBody tableRecord={tableRecord} />
        </TableView>
      </TableContainer>
    </Box>
  </Card>
);

FeedbackListTable.propTypes = {
  tableRecord: PropTypes.array,
};
export default FeedbackListTable;
