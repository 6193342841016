import T from "T";

const {
  PRIMARY_SKILL,
  SECONDARY_SKILL,
  WORK_LOCATION,
  EMP_MODE,
  STATUS,
  PROJECT,
  PROJECT_MODE,
  AVAILABILITY,
  PROJECT_MANAGER,
  EXPERIENCE,
  DEPARTMENT,
  TECHNOLOGY,
  OS_MANAGER,
  ASSIGNED_TO,
  REPORTING_MANAGER,
  COMPANY_NAME,
  ASSIGNED_BY, // Used fot Training Module
  EMP_STATUS,
  BYT_MANAGER,
  TENURE_WITH_NETSMARTZ,
  TECH_STACK,
  CLIENT_NAME,
  PROFIT_CENTER,
  COST_CENTER,
  AUTO_RENEWAL,
  TENURE,
  TOOL_TYPE,
  PURCHASE_START_DATE,
  PURCHASE_END_DATE,
} = T;

export const FILTER_OPTIONS = [
  PRIMARY_SKILL,
  SECONDARY_SKILL,
  WORK_LOCATION,
  DEPARTMENT,
  EMP_MODE,
  STATUS,
  PROJECT,
  PROJECT_MODE,
  AVAILABILITY,
  PROJECT_MANAGER,
  `${EXPERIENCE} (In Years)`,
];
export const GROUP_FILTER_OPTIONS = [
  PRIMARY_SKILL,
  SECONDARY_SKILL,
  WORK_LOCATION,
  DEPARTMENT,
  EMP_MODE,
  EMP_STATUS,
  PROJECT,
  PROJECT_MODE,
  AVAILABILITY,
  PROJECT_MANAGER,
  `${EXPERIENCE} (In Years)`,
  TENURE_WITH_NETSMARTZ,
];
export const PRE_SALES_FILTER_OPTIONS = [TECHNOLOGY, OS_MANAGER, ASSIGNED_TO, STATUS];
export const INTERVIEW_TRACKER_FILTER_OPTIONS = [COMPANY_NAME, REPORTING_MANAGER, BYT_MANAGER, STATUS];
export const REPORT_CARD_FILTER_OPTIONS = [CLIENT_NAME, BYT_MANAGER, TECH_STACK, STATUS];
// Used for Training Module
export const TRAINING_FILTER_OPTIONS = [STATUS, ASSIGNED_BY, DEPARTMENT, REPORTING_MANAGER];
export const FEEDBACK_FILTER_OPTIONS = [PROJECT_MANAGER];

// Used for Inside Sales Module
export const INSIDE_SALES_FILTER_OPTIONS = [TECHNOLOGY, `${EXPERIENCE} (In Years)`];

// Author: Sanjana // Case-Study Filter Options
export const CASE_STUDY_FILTER_OPTIONS = [PROJECT_MANAGER, STATUS];
export const TESTIMONIAL_FILTER_OPTIONS = [PROJECT_MANAGER, STATUS];

// Used for IS-Tools Module
export const IS_TOOLS_FILTER_OPTIONS = [COST_CENTER, AUTO_RENEWAL, TENURE, TOOL_TYPE, DEPARTMENT, PURCHASE_START_DATE, PURCHASE_END_DATE];
