import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    revokeCourse: build.mutation({
      query: ({ trainingAssignmentId }) => ({
        url: `/training/revoke/assignTraining?trainingAssignmentId=${trainingAssignmentId}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useRevokeCourseMutation } = extendedApi;
