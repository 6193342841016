import { useReducer } from "react";
import PropTypes from "prop-types";
import { noop } from "lodash";
import { Grid, Typography, Box } from "@mui/material";

import { isUrl } from "utils/validations";
import MISFooterButton from "components/common/MISFooterButton";
import MISTextField from "components/common/MISTextField";
import MISDialog from "components/common/MISDialog";
import T from "T";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useUploadCaseStudyLinkMutation } from "api/CaseStudy/UpdateCaseStudyLink";
import { BACKGROUND } from "theme/colors";

const CaseStudyUploadLinkModal = ({
  openCaseStudyLink = false,
  handleCaseStudyUploadLinkDialog = noop,
  refreshTable = noop,
  pName = "",
  pId = "",
}) => {
  const [UpdateCaseStudyLink] = useUploadCaseStudyLinkMutation();

  const defaultLocalState = {
    caseStudyLink: "",
    isUrlValid: false,
    project: {},
  };

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), defaultLocalState);

  const { caseStudyLink, isUrlValid } = localState;

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    if (name === "caseStudyLink") {
      setLocalState({ [name]: value, isUrlValid: isUrl(value) });
    } else {
      setLocalState({ [name]: value });
    }
  };

  const resetState = () => {
    setLocalState(defaultLocalState);
  };

  const handleClose = () => {
    resetState();
    handleCaseStudyUploadLinkDialog();
    refreshTable();
  };

  const handleCaseStudyUploadLinkModal = (caseStudyLink) => {
    if (!isUrlValid || !caseStudyLink) {
      toast.error(T.INVALID_URL_FORMAT);
      return;
    }
    const payload = {
      caseStudyLink,
    };
    UpdateCaseStudyLink({ payload, pId })
      .unwrap()
      .then((res) => {
        handleClose();
        toast.success(T.LINK_ADDED_SUCCESSFULLY);
      })
      .catch(handleError);
  };

  return (
    <MISDialog open={openCaseStudyLink}>
      <Box display="flex" alignItems="center" mb={1} pl={1} pt={2} sx={{ backgroundColor: BACKGROUND.cardDefault }}>
        <Typography variant="h6" p="8px 90px" fontWeight={600} ml={19}>
          {T.ADD_CASE_STUDY_LINK.toUpperCase()}
        </Typography>
      </Box>

      <Grid
        container
        spacing={2}
        sx={{
          p: 7,
          "& .MuiFormLabel-root": {
            "& .MuiTypography-root": {
              color: "text.popupLabel",
              fontSize: 14,
              fontWeight: 600,
            },
          },
          width: "50vw",
        }}
      >
        <Grid item xs={6}>
          <MISTextField
            fullWidth
            label={T.PROJECT_NAME.toUpperCase()}
            placeholder={T.PROJECT_NAME}
            size="small"
            variant="outlined"
            name="projectName"
            value={pName}
            required
            onChange={onHandleChange}
          />
        </Grid>

        <Grid item xs={6}>
          <MISTextField
            fullWidth
            label={T.CASE_STUDY_LINK.toUpperCase()}
            placeholder={T.CASE_STUDY_LINK}
            autoComplete="on"
            size="small"
            required
            variant="outlined"
            name="caseStudyLink"
            // value={localState.caseStudyLink}
            value={caseStudyLink}
            onChange={onHandleChange}
            // error={!isUrlValid}
          />
        </Grid>
      </Grid>

      <MISFooterButton
        proceedButtonText={T.SAVE}
        justify="center"
        size="medium"
        disableProceed={!isUrlValid}
        sx={{ p: 1, m: 1 }}
        handleClose={() => {
          handleCaseStudyUploadLinkDialog();
          resetState();
        }}
        handleSubmit={() => handleCaseStudyUploadLinkModal(caseStudyLink)}
      />
    </MISDialog>
  );
};

CaseStudyUploadLinkModal.propTypes = {
  openCaseStudyUploadLinkModal: PropTypes.bool,
  editId: PropTypes.string,
  handleCaseStudyUploadLinkModalDialog: PropTypes.func,
  refreshTable: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  handleCaseStudyUploadLinkDialog: PropTypes.func,
  openCaseStudyLink: PropTypes.bool,
  pName: PropTypes.string,
  pId: PropTypes.string,
};

export default CaseStudyUploadLinkModal;
