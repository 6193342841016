import { useEffect, useReducer, useState } from "react";
import { Grid, Paper, Box, DialogTitle, Typography } from "@mui/material";
import MISTextField from "components/common/MISTextField";
import { useNavigate } from "react-router-dom";
import MISDatePicker from "components/common/MISDatePicker";
import { toast } from "react-toastify";
import MISDialog from "components/common/MISDialog";
import MISFooterButton from "components/common/MISFooterButton";
import { format, isValid } from "date-fns";
import ConfirmCancel from "components/Training/ConfirmCancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetPitchedUserStatusMutation } from "api/preSales/interviewTracker/getPitchedUserStatus";
import { useEditDetailsMutation } from "api/preSales/interviewTracker/editDetails";
import { useLocation } from "react-router-dom";
import T from "T";
import { handleError } from "utils/error";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import MISAutocomplete from "components/common/MISAutocomplete";
import { useGetRejectionReasonsMutation } from "api/preSales/interviewTracker/getRejectionReasons";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { sortBy } from "lodash";

const EditInterviewTrackerPage = () => {
  const navigate = useNavigate();
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [openConfirmSubmit, setOpenConfirmSubmit] = useState(false);
  const [pitchedUserStatus] = useGetPitchedUserStatusMutation();
  const [editInterviewTracker] = useEditDetailsMutation();
  const [getRejectionReasons] = useGetRejectionReasonsMutation();
  const location = useLocation();
  const jdId = location.state ? location.state.jdId : null;
  const id = location.state ? location.state.id : null;

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    endDate: location.state ? location.state.endDate : null,
    selectedStatus: location.state ? location.state.status : null,
    feedback: location.state ? location.state.feedback : "",
    rejectionReason: location.state ? location.state.reasonForRejection : null,
    openModal: false,
  });

  const { endDate, status, selectedStatus, reasonsForRejection, rejectionReason, feedback, openModal } = localState;

  // Fetch status and rejection reasons options when the component mounts
  useEffect(() => {
    pitchedUserStatus()
      .unwrap()
      .then((response) => {
        getRejectionReasons()
          .unwrap()
          .then((res) => {
            setLocalState({
              status: response,
              reasonsForRejection: res,
            });
          })
          .catch((error) => {
            handleError(error);
          });
      })
      .catch((error) => {
        handleError(error);
      });
  }, []);

  const getFormattedDate = (val) => {
    if (!val) {
      return null;
    }

    const formattedDate = new Date(val);

    if (isValid(formattedDate)) {
      return format(formattedDate, BACKEND_DATE_FORMAT);
    }

    return null;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "endDate") {
      setLocalState({ [name]: getFormattedDate(value) });
    } else if (name === "selectedStatus") {
      setLocalState({ selectedStatus: value });
    } else if (name === "rejectionReason") {
      setLocalState({ rejectionReason: value });
    } else {
      setLocalState({ [name]: value });
    }
  };

  const handleCloseConfirmCancel = (proceed) => {
    setOpenConfirmCancel(false);
    if (proceed) {
      handleBack();
    }
  };
  const handleCancel = () => {
    setOpenConfirmCancel(true);
  };

  const handleConfirmSubmit = () => {
    if (selectedStatus && feedback && endDate) {
      if (selectedStatus === "Rejected" && !rejectionReason) {
        toast.error("Please provide a reason for rejection.");
      } else {
        setOpenConfirmSubmit(true);
      }
    } else {
      toast.error("Please fill all the Required Fields");
    }
  };
  const handleCloseConfirmSubmit = () => {
    setOpenConfirmSubmit(false);
  };

  const handleSubmit = async () => {
    const formData = {
      endDate: endDate ? getFormattedDate(endDate) : null,
      status: selectedStatus,
      feedback,
      reasonForRejection: selectedStatus === "Rejected" ? rejectionReason : null,
    };

    editInterviewTracker({
      userId: id,
      preSalesMappingId: jdId,
      formData,
    })
      .then((res) => {
        if (res.error) {
          handleError(res.error);
        } else {
          toast.success("Details edited successfully");
          setTimeout(() => {
            navigate(-1);
          }, 300);
        }
      })
      .catch((err) => {
        handleError(err);
      });
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Box display="flex" alignItems="center" mb={2}>
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer", mr: 1 }} onClick={handleBack} />
        <Typography variant="h5" fontWeight={600}>
          {T.EDIT_ALL_PITCHED}
        </Typography>
      </Box>

      <MISDialog open={openModal}>
        <DialogTitle sx={{ p: "20px 40px" }}>{T.CONFIRM_SUBMIT}</DialogTitle>
      </MISDialog>
      <Grid container spacing={2}>
        <Grid item md={4} xs={12}>
          <MISDatePicker
            required
            placeholder="Select End Date"
            inputFormat="MM/DD/YYYY"
            value={endDate ? new Date(endDate) : null}
            label="End Date"
            name="endDate"
            shouldDisableDate={(date) => {
              const twoDaysAgo = new Date();
              twoDaysAgo.setDate(twoDaysAgo.getDate() - 3);
              return date < twoDaysAgo;
            }}
            handleChange={(newValue) => handleChange({ target: { name: "endDate", value: newValue } })}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <MISAutocomplete
            label="Status"
            options={
              sortBy(status)
                ? sortBy(status).filter((option) =>
                    ["Selected", "Rejected", "Result Awaited", "On Hold", "Got Better Option", "Requirement Changed"].includes(
                      option,
                    ),
                  )
                : []
            }
            value={selectedStatus}
            required
            placeholder={T.SELECT_OPTION}
            getByLabelText={(option) => option || ""}
            onChange={(event, newValue) =>
              handleChange({
                target: { name: "selectedStatus", value: newValue },
              })
            }
          />
        </Grid>
        {selectedStatus === "Rejected" && (
          <Grid item md={4} xs={12}>
            <MISAutocomplete
              label="Reason for rejection"
              options={reasonsForRejection ? reasonsForRejection : []}
              value={rejectionReason}
              required
              placeholder={T.SELECT_OPTION}
              getByLabelText={(option) => option || ""}
              onChange={(event, newValue) =>
                handleChange({
                  target: { name: "rejectionReason", value: newValue },
                })
              }
            />
          </Grid>
        )}

        <Grid item md={12} xs={12}>
          <MISTextField
            label="Feedback/Comments"
            fullWidth
            multiline
            rows={8}
            size="small"
            required
            variant="outlined"
            name="feedback"
            value={feedback}
            onChange={handleChange}
          />
        </Grid>
        <Grid display="flex" justifyContent="flex-end" item md={12} xs={12}>
          <MISFooterButton handleSubmit={handleConfirmSubmit} handleClose={handleCancel} />
        </Grid>
        <ConfirmCancel
          openConfirmCancel={openConfirmCancel}
          confrmCancelModal={() => handleCloseConfirmCancel(false)}
          handleClose={() => handleCloseConfirmCancel(true)}
        />
        <ConfirmSubmit
          cancelButtonText={T.NO}
          proceedButtonText={T.YES}
          dialogMessageText={T.CONFIRM_SUBMIT}
          openConfirmSubmit={openConfirmSubmit}
          handleClose={handleCloseConfirmSubmit}
          handleSubmit={handleSubmit}
        />
      </Grid>
    </Paper>
  );
};

export default EditInterviewTrackerPage;
