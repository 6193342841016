import { Grid } from "@mui/material";
import { Children } from "react";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import usePMFetch from "hooks/usePMFetch";
import { get, noop } from "lodash";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
const AssignmentForm = ({
  skills = [],
  assignedDuration = "",
  experience = "",
  topicsList = "",
  onHandleChange = noop,
  onHandleTopicsChange = noop,
  onHandleTopicsAddMore = noop,
  onHandleTopicsRemove = noop,
  onHandleAutoCompleteChange = noop,
}) => {
  const { isXs } = GET_SIZE();
  const { skillList } = usePMFetch();
  const skillListResults = get(skillList, "results", []);
  return (
    <Grid container spacing={3}>
      <Grid item xs={4}>
        <MISAutocomplete
          label={T.TECHNOLOGY}
          listDetails={skillListResults}
          value={skills}
          required
          getByLabelText={(option) => get(option, "skillName", "")}
          onHandleChange={(event, newValue) => onHandleAutoCompleteChange("skills", get(newValue, "id", ""))}
        />
      </Grid>
      <Grid item xs={4}>
        <MISTextField
          label={T.DURATION_IN_MINUTES}
          //   required
          type="number"
          fullWidth
          placeholder={T.DURATION}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="assignedDuration"
          value={assignedDuration}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item xs={4}>
        <MISTextField
          label={`${T.EXPERIENCE} (In ${T.YEARS})`}
          //   required
          type="number"
          fullWidth
          placeholder={`${T.EXPERIENCE} (In ${T.YEARS})`}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="experience"
          value={experience}
          onChange={onHandleChange}
        />
      </Grid>
      <Grid item xs={12}>
        {Children.toArray(
          topicsList.map((data, index) => (
            <Grid container spacing={2} key={index} pt={index === 0 ? 0 : 1}>
              <Grid item md={4} xs={12}>
                <MISTextField
                  label={`${T.TOPIC} ${T.NAME} ${topicsList.length > 1 ? index + 1 : ""}`}
                  required
                  fullWidth
                  placeholder={`${T.TOPIC} ${T.NAME} ${topicsList.length > 1 ? index + 1 : ""}`}
                  autoComplete="on"
                  size="small"
                  variant="outlined"
                  name="topicName"
                  value={topicsList[index].topicName}
                  onChange={(e) => onHandleTopicsChange(e, index)}
                />
              </Grid>
              <Grid item md={4} xs={12} mt={isXs ? 1 : 4.25}>
                {topicsList.length === 1 ? (
                  <AddCircleOutlineIcon
                    onClick={onHandleTopicsAddMore}
                    fontSize="small"
                    sx={{
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                ) : topicsList.length - 1 === index ? (
                  <>
                    <RemoveCircleOutlineIcon
                      onClick={() => onHandleTopicsRemove(index)}
                      fontSize="small"
                      sx={{
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                    <AddCircleOutlineIcon
                      onClick={onHandleTopicsAddMore}
                      fontSize="small"
                      sx={{
                        cursor: "pointer",
                        color: NETSMARTZ_THEME_COLOR,
                      }}
                    />
                  </>
                ) : (
                  <RemoveCircleOutlineIcon
                    onClick={() => onHandleTopicsRemove(index)}
                    fontSize="small"
                    sx={{
                      cursor: "pointer",
                      color: NETSMARTZ_THEME_COLOR,
                    }}
                  />
                )}
              </Grid>
            </Grid>
          )),
        )}
      </Grid>
    </Grid>
  );
};

AssignmentForm.propTypes = {
  skills: PropTypes.array,
  assignedDuration: PropTypes.string,
  experience: PropTypes.string,
  topicsList: PropTypes.array,
  onHandleChange: PropTypes.func,
  onHandleTopicsChange: PropTypes.func,
  onHandleTopicsAddMore: PropTypes.func,
  onHandleTopicsRemove: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
};

export default AssignmentForm;
