import api from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitMockVideoResponse: build.mutation({
      query: (formData) => ({
        url: `/mockVideoInterview/save/videoInterviewOneQA`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

export const { useSubmitMockVideoResponseMutation } = extendedApi;
