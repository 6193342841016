import { ExpandRounded } from "@mui/icons-material";

const { createSlice } = require("@reduxjs/toolkit");

const taskboxSlice = createSlice({
  name: "taskbox",
  initialState: {
    badgeCount: 0,
  },
  reducers: {
    setBadgeCount: (state, action) => {
      state.badgeCount = action.payload.badgeCount;
    },
  },
});

export const { setBadgeCount } = taskboxSlice.actions;
export default taskboxSlice.reducer;
