import PropTypes from "prop-types";
import { Avatar, Button, CircularProgress, styled } from "@mui/material";
import { useLazyUploadProfilePicQuery } from "api/EmployeeProfile/uploadProfilePic";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import AddIcon from "@mui/icons-material/Add";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

const ImageInputButton = ({ ...props }) => {
  return <Button {...props} component="label" />;
};

const ImageButton = styled(ImageInputButton)(({ theme }) => ({
  position: "relative",
  height: 150,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 100,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.4,
    },
    "& .MuiImageMarked-root": {
      opacity: 0.8,
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "50px",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const PencilIconWrapper = styled(EditIcon)({
  position: "absolute",
  bottom: 0,
  right: 0,
  padding: 2,
  color: "white",
  backgroundColor: NETSMARTZ_THEME_COLOR,
  borderRadius: "50%",
  fontSize: 10,
  zIndex: 999,
});

const AddIconWrapper = styled(AddIcon)({
  position: "absolute",
  bottom: 0,
  color: "white",
  right: 0,
  padding: 2,
  backgroundColor: NETSMARTZ_THEME_COLOR,
  borderRadius: "50%",
  fontSize: 10,
  zIndex: 999,
});
const UploadAvatar = ({ image, alt, userId, refreshView }) => {
  const [uploadProfilePic, { isLoading }] = useLazyUploadProfilePicQuery();
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      return;
    }
    // Check file type
    if (!file.type.startsWith("image/")) {
      toast.error("Please upload an image file.");
      return;
    }
    // Check file size (maximum size in bytes)
    const maxSizeBytes = 1 * 1024 * 1024;
    if (file.size > maxSizeBytes) {
      toast.error("File size exceeds 1MB. Please upload a smaller image.");
      return;
    }
    const base64File = await convertBase64(file);
    uploadProfilePic({
      userId,
      fileType: file.name,
      file: base64File,
    })
      .unwrap()
      .then(() => {
        toast.success("Profile picture updated!");
        refreshView();
      })
      .catch(handleError);
  };

  const initials = alt
    .split(" ")
    .map((word) => word[0])
    .join("")
    .toUpperCase();

  return (
    <Box sx={{ position: "relative" }}>
      <Avatar aria-label={alt} sx={{ border: `1px solid ${NETSMARTZ_THEME_COLOR}`, height: 50, width: 50 }}>
        {isLoading ? (
          <CircularProgress size={15} sx={{ color: "gray" }} />
        ) : (
          <ImageButton
            focusRipple
            key={"random"}
            style={{
              width: "100%",
            }}
          >
            {image ? (
              <>
                <ImageSrc style={{ backgroundImage: `url(${image})` }} />
              </>
            ) : (
              <Image>
                <span>{initials}</span>
              </Image>
            )}
            <input type="file" accept="image/*" hidden onChange={handleImageUpload} />
          </ImageButton>
        )}
      </Avatar>
      {image ? <PencilIconWrapper /> : <AddIconWrapper />}
    </Box>
  );
};

UploadAvatar.propTypes = {
  image: PropTypes.string,
  alt: PropTypes.string,
  userId: PropTypes.number,
  submitLogo: PropTypes.func.isRequired,
  refreshView: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
};

export default UploadAvatar;
