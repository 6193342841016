import { api } from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBYTManager: builder.query({
      query: () => ({
        url: `/projects/project/bytmanager`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetBYTManagerQuery } = extendedApi;
