import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Typography, Card, Grid } from "@mui/material";
import T from "T";
import InterviewTable from "./InterviewTable";
import { BACKGROUND } from "theme/colors";

const InterviewHistory = ({ allTableRows = [], handleAddFeedback = noop }) => (
  <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12} display="flex" alignItems="center" pb={1}>
        <Typography fontSize={17} fontWeight={600}>
          {`${T.INTERVIEW_HISTORY}`}
        </Typography>
      </Grid>
    </Grid>

    <InterviewTable allTableRows={allTableRows} handleAddFeedback={handleAddFeedback} />
  </Card>
);

InterviewHistory.propTypes = {
  allTableRows: PropTypes.array,
  handleAddFeedback: PropTypes.func,
};

export default InterviewHistory;
