import { useState } from "react";
import noop from "lodash/noop";
import PropTypes from "prop-types";
import { Card, Typography } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltOffOutlinedIcon from "@mui/icons-material/FilterAltOffOutlined";

import T from "T";
import { Box } from "@mui/system";
import { useDispatch } from "react-redux";
import { savedFilterStore } from "slices/savedFilterSlice";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import TrainingFilterOptions from "./TrainingFilterOptions";

const TrainingFilters = ({
  isFilterEmpty = false,
  filters = {},
  projectManagers = {},
  projectList = {},
  projectManagerList = [],
  courseList = [],
  subCourseList = [],
  userList = [],
  departmentList = [],
  handleFilterClose = noop,
  handleFilterSubmit = noop,
  onhandleFilterChange = noop,
  setTrainingState = noop,
}) => {
  const dispatch = useDispatch();
  const [filterOpen, setFilterOpen] = useState(false);

  const handleFilterClick = () => {
    setFilterOpen(!filterOpen);
  };

  const onFilterClose = () => {
    dispatch(savedFilterStore({ selectedFilterId: "" }));
    setFilterOpen(false);
    handleFilterClose();
  };

  const onFilterSubmit = () => {
    handleFilterSubmit();
    setFilterOpen(false);
  };

  return (
    <Box>
      <Card
        elevation={0}
        variant="outlined"
        onClick={handleFilterClick}
        sx={{
          p: 1,
          backgroundColor: "background.white",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {isFilterEmpty ? (
          <>
            <Typography variant="body1" fontSize={14}>{`${T.FILTER}s`}</Typography>
            <FilterAltOutlinedIcon fontSize="small" />
          </>
        ) : (
          <>
            <Typography
              variant="body1"
              fontWeight={600}
              sx={{ color: NETSMARTZ_THEME_COLOR }}
              fontSize={14}
            >{`${T.FILTER}s`}</Typography>
            <FilterAltOffOutlinedIcon fontSize="small" />
          </>
        )}
      </Card>

      {filterOpen && (
        <TrainingFilterOptions
          filters={filters}
          projectManagers={projectManagers}
          projectManagerList={projectManagerList}
          courseList={courseList}
          subCourseList={subCourseList}
          userList={userList}
          projectList={projectList}
          departmentList={departmentList}
          handleFilterClose={onFilterClose}
          handleFilterSubmit={onFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleClose={() => setFilterOpen(false)}
          setTrainingState={setTrainingState}
        />
      )}
    </Box>
  );
};

TrainingFilters.propTypes = {
  isFilterEmpty: PropTypes.bool,
  filters: PropTypes.object,
  projectManagers: PropTypes.object,
  projectList: PropTypes.object,
  projectManagerList: PropTypes.array,
  courseList: PropTypes.array,
  subCourseList: PropTypes.array,
  userList: PropTypes.array,
  departmentList: PropTypes.array,
  handleFilterClose: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  onhandleFilterChange: PropTypes.func,
  setTrainingState: PropTypes.func,
};

export default TrainingFilters;
