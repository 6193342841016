import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import noop from "lodash/noop";
import { get, orderBy } from "lodash";
import { Grid, Box, Button, styled, Tooltip } from "@mui/material";
import Constants from "Constants";
import AddIcon from "@mui/icons-material/Add";
import XLS from "assets/XLS.png";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import T from "T";
import { canAddUser } from "utils/permissions";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import MISAutocomplete from "components/common/MISAutocomplete";
import { toast } from "react-toastify";

export const StyledBox = styled(Box)(() => ({
  margin: "auto",
  maxWidth: "inherit",
  width: 45,
  height: 45,
  cursor: "pointer",
}));

const TopBar = ({
  showActive = true,
  fullName = "",
  id = null,
  selectedPM = {},
  pMResults = [],
  onHandlePMChange = noop,
  tableRecord,
}) => {
  const navigate = useNavigate();
  const { sessionToken, user } = MISCurrentUser();
  const { SERVER_URL } = Constants;
  const userName = user.user.userName;

  const handleAddFeedback = () => {
    // Check if the signed-in user has already created feedback within the last 24 hours
    const userFeedback = tableRecord.find(
      (feedback) => feedback.createdBy === userName && new Date(feedback.createdAt).getTime() >= Date.now() - 24 * 60 * 60 * 1000,
    );
    if (userFeedback) {
      toast.error("Feedback for this user already submitted by you within last 24 hours.", { autoClose: 5000 });
    } else {
      navigate(`/app/member/feedback/form/${id}`, { state: { previousLocation: window.location.pathname } });
    }
  };
  const handleExport = async () => {
    try {
      const response = await fetch(`${SERVER_URL}/feedback/employee/feedback/export?id=${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${sessionToken}`,
        },
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const blob = await response.blob();
      const file = new Blob([blob], { type: "application/vnd.ms-excel" });

      const url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = url;
      link.download = `${fullName} Feedback.xls`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      // justifyContent={isLg ? "space-between" : "center"}
      sx={{ mt: 1, p: 1, backgroundColor: "background.card" }}
    >
      {showActive && (
        <Grid item md={3} xs={12} ml={1}>
          <MISAutocomplete
            listDetails={orderBy(pMResults, ["name"], ["asc"])}
            value={selectedPM}
            placeholder={T.REPORTING_MANAGER}
            getByLabelText={(option) => get(option, "name", "")}
            onHandleChange={(event, newValue) => onHandlePMChange("projectManager", get(newValue, "id", ""))}
            size="small"
            sx={{
              backgroundColor: "background.white",
              "& .MuiOutlinedInput-input": {
                fontSize: 14,
              },
            }}
          />
        </Grid>
      )}
      <Grid item md={7} xs={12} />
      <Grid item>
        <Box display="flex" alignItems="center">
          {showActive && (
            <Tooltip title={T.XLS} placement="top">
              <StyledBox pr={2} component="img" src={XLS} onClick={handleExport} />
            </Tooltip>
          )}
        </Box>
      </Grid>
      {showActive && (
        <Grid>
          <Button
            size="medium"
            sx={{
              bgcolor: NETSMARTZ_THEME_COLOR,
              whiteSpace: "nowrap",
              color: BACKGROUND.white,

              "& .MuiButton-startIcon": {
                mr: 0,
              },
              "&:hover": {
                bgcolor: NETSMARTZ_THEME_COLOR,
                color: BACKGROUND.white,
              },
            }}
            disabled={!canAddUser()}
            startIcon={<AddIcon sx={{ width: 19 }} />}
            onClick={handleAddFeedback}
          >
            {T.POST_FEEDBACK}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

TopBar.propTypes = {
  showActive: PropTypes.bool,
  fullName: PropTypes.string,
  id: PropTypes.number,
  selectedPM: PropTypes.object,
  pMResults: PropTypes.array,
  tableRecord: PropTypes.array,
  onHandlePMChange: PropTypes.func,
};

export default TopBar;
