import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    savePitchedCandidates: build.mutation({
      query: ({ preSalesMappingId }) => ({
        url: `/selected/user/savePitched?preSalesMappingId=${preSalesMappingId}`,
        method: "POST",
      }),
    }),
  }),
});

export const { useSavePitchedCandidatesMutation } = extendedApi;
