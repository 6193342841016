import { Typography, Box, DialogActions, DialogContent, IconButton, Dialog, Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import PropTypes from "prop-types";
import { useEffect, useReducer } from "react";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { get, noop } from "lodash";
import MISAutocomplete from "components/common/MISAutocomplete";
import { useLazyGetCompanyByIdQuery } from "api/Referrals/getCompanyById";
import { PAGINATION } from "settings/constants/pagination";
import { useEditBasicInfoMutation } from "api/Referrals/editBasicInfo";
import { isEmail, isUrl, MISCurrentUser } from "utils/validations";
import { useNavigate } from "react-router-dom";
import { useLazyGetFunctionalManagerQuery } from "api/members/getFunctionalHead";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const EditDialog = ({ openEditDialog, handleOpenEditDialog = noop, record1, record2 }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    name: "",
    openConfirmModal: false,
    skillList: [],
    skills: [],
    linkedInUrl: "",
    phoneNumber: "",
    experience: "",
    email: "",
    comment: "",
    ta: null,
    vpList: [],
    taComment: null,
    vpComment: null,
    cooComment: null,
  });

  const { ta, vpList, taComment, vpComment, cooComment } = localState;

  const handleCommentChange = (role, event) => {
    switch (role) {
      case "TA":
        setLocalState({ taComment: event.target.value });
        break;
      case "VP":
        setLocalState({ vpComment: event.target.value });
        break;
      case "COO":
        setLocalState({ cooComment: event.target.value });
        break;
      case "Dynamics VP":
        setLocalState({ vpComment: event.target.value });
        break;
      default:
        break;
    }
  };

  const [getVpList] = useLazyGetFunctionalManagerQuery();

  const getVpListDropDown = () => {
    getVpList({
      page: 0,
      rowPerPage: 10000,
    })
      .unwrap()
      .then((res) => {
        setLocalState({ vpList: get(res, "results", []) });
      })
      .catch(handleError);
  };
  useEffect(() => {
    getVpListDropDown();
  }, []);

  const { user } = MISCurrentUser();

  const role = get(user, "role", null);

  const { name, skillList, skills, linkedInUrl, phoneNumber, experience, email, comment } = localState;
  const [getPreviousCompany] = useLazyGetCompanyByIdQuery();
  const [editBasicInfo] = useEditBasicInfoMutation();
  const companyId = get(record1, "company.id", null);
  const navigate = useNavigate();

  useEffect(() => {
    getPrevComp();
    populateInitialData();
  }, []);

  useEffect(() => {
    handleValidation();
  }, []);

  const handleValidation = () => {
    return true;
  };
  const getPrevComp = () => {
    getPreviousCompany({ page: INITIAL_PAGE, SIZE: ROWS_PER_PAGE, id: companyId })
      .unwrap()
      .then((res) => {
        setLocalState({
          skillList: res.techStack,
        });
      })
      .catch(handleError);
  };

  const populateInitialData = () => {
    const record = record2 || record1;
    setLocalState({
      name: get(record, "name", null),
      linkedInUrl: get(record, "linkedinUrl", null),
      phoneNumber: get(record, "phoneNumber", null),
      experience: get(record, "experience", null),
      email: get(record, "email", null),
      skills: get(record, "skills", null),
      comment: get(record, "comment", null),
      ta: record?.functionalHead ? record?.functionalHead : null,
      taComment: get(record, "taComment", null),
      vpComment: get(record, "vpComment", null),
      cooComment: get(record, "cooComment", null),
    });
  };

  const handleChange = (name, value) => {
    setLocalState({ [name]: value });
  };

  const handleSubmit = () => {
    if (phoneNumber !== null && phoneNumber !== "" && phoneNumber.length !== 10) {
      toast.error(T.INVALID_PHONE_NUMBER);
      return false;
    }
    if (linkedInUrl !== null && linkedInUrl !== "" && !isUrl(linkedInUrl)) {
      toast.error(T.INVALID_LINKEDIN_URL);
      return false;
    }
    if (email !== null && email !== "" && !isEmail(email)) {
      toast.error(T.INVALID_EMAIL);
      return false;
    }

    if ((role === "TA" && !taComment) || (role === "VP" && !vpComment) || (role === "Dynamics VP" && !vpComment)) {
      toast.error("Please fill in all the required fields.");
      return;
    }

    const payload = {
      referralIds: record2 ? [record1.id, record2.id] : [record1.id],
      referralName: name,
      phoneNumber: phoneNumber,
      referralEmail: email,
      linkedinUrl: linkedInUrl,
      skills: skills.map((skill) => skill.id),
      experience: experience,

      assignedByVp: ta ? ta?.emailId : null,
      taComment,
      vpComment,
      cooComment,
      comment: comment,
    };
    editBasicInfo({ payload })
      .unwrap()
      .then(() => {
        setTimeout(() => {
          toast.success("Details updated scuessfully!");
        }, 200);
        handleClose();
        navigate(-1);
      })
      .catch(handleError);
  };

  const handleClose = () => {
    handleOpenEditDialog();
    setLocalState({
      name: "",
      openConfirmModal: false,
      skillList: [],
      skills: [],
      linkedInUrl: "",
      phoneNumber: "",
      experience: "",
      email: "",

      assignedByVp: "",

      comment: "",
      taComment: null,
      vpComment: null,
      cooComment: null,
    });
    populateInitialData();
  };

  const onHandleAutoCompleteChange = (newValue, item) => {
    setLocalState({ [item]: newValue });
  };

  return (
    <Dialog open={openEditDialog} maxWidth="md" fullWidth>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            backgroundColor: "white",
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            pl={1}
            sx={{
              backgroundColor: "background.card",
            }}
            p={1.5}
            pb={1}
          >
            <IconButton onClick={handleClose}>
              <ArrowBackIcon fontSize="small" />
            </IconButton>

            <Typography variant="h6" fontWeight={700}>
              Edit Recommendation Details
            </Typography>
          </Box>
        </Box>
        <DialogContent
          sx={{
            pt: 3,
            pb: 1,
            px: 3.5,
            boxShadow: "none",
            flexGrow: 1,
            borderRadius: 1,
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              <MISTextField
                label="Name"
                fullWidth
                size="small"
                variant="outlined"
                name="name"
                value={name}
                onChange={(event) => handleChange("name", event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MISTextField
                label="LinkedIn URL"
                fullWidth
                size="small"
                variant="outlined"
                name="linkedInUrl"
                value={linkedInUrl}
                onChange={(event) => handleChange("linkedInUrl", event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MISAutocomplete
                label={T.SKILLS}
                multiple
                listDetails={skillList}
                value={skills}
                getByLabelText={(option) => get(option, "skillName", "")}
                onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "skills")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MISTextField
                label="Phone Number"
                fullWidth
                size="small"
                variant="outlined"
                InputProps={{ type: "number" }}
                name="phoneNumber"
                value={phoneNumber}
                onChange={(event) => handleChange("phoneNumber", event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MISTextField
                label="Experience"
                fullWidth
                InputProps={{ type: "number" }}
                size="small"
                variant="outlined"
                name="experience"
                value={experience}
                onChange={(event) => handleChange("experience", event.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <MISTextField
                label="Email"
                fullWidth
                size="small"
                variant="outlined"
                name="email"
                value={email}
                onChange={(event) => handleChange("email", event.target.value)}
              />
            </Grid>

            {(role === "VP" || role === "Dynamics VP") && (
              <Grid item xs={12} sm={6}>
                <MISAutocomplete
                  label={T.ASSIGN_TO}
                  required
                  multiple={false}
                  listDetails={vpList}
                  value={ta}
                  getByLabelText={(option) => get(option, "name", "")}
                  onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "ta")}
                />
              </Grid>
            )}
          </Grid>
          {/* <Grid item xs={12} md={6.3}>
            <MISTextField
              label={T.COMMENTS}
              fullWidth
              required={role !== "COO"}
              placeholder={T.COMMENTS}
              size="small"
              rows={4}
              multiline
              variant="outlined"
              value={comment}
              name="comment"
              onChange={(e) => handleChange("comment", e.target.value)}
            />
          </Grid> */}

          {role === "TA" && (
            <Grid item xs={12} md={6.3}>
              <MISTextField
                label={T.COMMENTS}
                fullWidth
                required={role !== "COO"}
                placeholder={T.COMMENTS}
                size="small"
                rows={4}
                multiline
                value={taComment}
                variant="outlined"
                name="experience"
                onChange={(e) => handleCommentChange("TA", e)}
              />
            </Grid>
          )}
          {(role === "VP" || role === "Dynamics VP") && (
            <Grid item xs={12} md={6.3}>
              <MISTextField
                label={T.COMMENTS}
                fullWidth
                required={role !== "COO"}
                placeholder={T.COMMENTS}
                size="small"
                rows={4}
                value={vpComment}
                multiline
                variant="outlined"
                name="experience"
                onChange={(e) => handleCommentChange("VP", e)}
              />
            </Grid>
          )}
          {role === "COO" && (
            <Grid item xs={12} md={6.3}>
              <MISTextField
                label={T.COMMENTS}
                fullWidth
                required={role !== "COO"}
                placeholder={T.COMMENTS}
                size="small"
                rows={4}
                multiline
                value={cooComment}
                variant="outlined"
                name="experience"
                onChange={(e) => handleCommentChange("COO", e)}
              />
            </Grid>
          )}
        </DialogContent>
        <DialogActions sx={{ px: 3.5, my: 1 }}>
          <MISFooterButton handleSubmit={handleSubmit} handleClose={handleClose} />
        </DialogActions>
      </Box>
    </Dialog>
  );
};

EditDialog.propTypes = {
  openEditDialog: PropTypes.bool,
  handleOpenEditDialog: PropTypes.func,
  record1: PropTypes.object,
  record2: PropTypes.object,
};

export default EditDialog;
