
import { useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { Typography, Paper, Box, TextField,Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useSaveSubRoleMutation } from "api/roles/saveSubrole";
import { ROLES_MAPPING } from "settings/constants/roles";
import { handleError } from "utils/error";

import T from "T";
import Form from "./Form";
import MISTextField from "components/common/MISTextField";
import MISFooterButton from "components/common/MISFooterButton";

const AddSubRole = () => {
    const [saveSubRole] = useSaveSubRoleMutation();
  const navigate = useNavigate();
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    subRoleName: "",
    description: "",

  });
  const { subRoleName, description } = localState;

  const onHandleChange = (e) => {
    const { name, value } = e.target;

    setLocalState({ [name]: value });
  };
  const handleAddSubRole = () => {
    const payload = {
      subRoleName,
      description,
    };

    saveSubRole(payload)
      .unwrap()
      .then(() => {
        toast.success(T.SUBROLE_CREATED_SUCCESSFULLY);
        handleBack();
      })
      .catch(handleError);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Paper
      sx={{
        p: "8px 16px 20px",
        maxHeight: "calc(100vh - 120px)",
        overflowY: "auto",
      }}
    >
      <Box display="flex" alignItems="center">
        <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} />
        <Typography variant="h6" fontWeight={700} ml={1}>
          {T.ADD_KRA_ROLE}
        </Typography>
      </Box>
      <Grid>
        <Grid item xs={12}>
          <MISTextField
            label={T.KRA_ROLE}
            fullWidth
            placeholder={T.KRA_ROLE}
            size="small"
            variant="outlined"
            name="subRoleName"
            multiline
            rows={1}
            value={subRoleName}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MISTextField
            label={T.DESCRIPTION}
            fullWidth
            placeholder={T.DESCRIPTION}
            size="small"
            variant="outlined"
            name="description"
            multiline
            rows={6}
            value={description}
            onChange={onHandleChange}
          />
        </Grid>
        <Grid pt={2}>
          <MISFooterButton
            proceedButtonText={T.SAVE_AND_CONTINUE}
              disableProceed={!subRoleName && !description}
            justify="end"
            handleClose={handleBack}
              handleSubmit={handleAddSubRole}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddSubRole;
