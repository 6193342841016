import PropTypes from "prop-types";
import { Card, Typography, Grid } from "@mui/material";
import T from "T";
import TrainingTable from "./TrainingTable";
import { BACKGROUND } from "theme/colors";

const TrainingHistory = ({ tableRecord = [] }) => {
  return (
    <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12} display="flex" alignItems="center" pb={1}>
          <Typography fontSize={17} fontWeight={600}>
            {T.TRAINING_HISTORY}
          </Typography>
        </Grid>
      </Grid>
      <TrainingTable tableRecord={tableRecord} />
    </Card>
  );
};

TrainingHistory.propTypes = {
  tableRecord: PropTypes.array,
};

export default TrainingHistory;
