import { Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import MISNoData from "components/common/MISNoData";
import MISTableLoader from "components/common/MISTableLoader";

import PropTypes from "prop-types";

const UserTable = ({
  columns,
  memberList,
  allSelected,
  handleSelectAll,
  selectedMembers,
  handleSelectMember,
  isUserFetching,
}) => {
  return (
    <Paper sx={{ minWidth: "95%", maxHeight: "445px", overflow: "auto", margin: "auto" }}>
      <Table aria-label="user table" stickyHeader>
        <TableHead>
          <TableRow>
            {columns &&
              columns.length &&
              columns.map((column, index) => {
                return column.key === "checkBox" ? (
                  <TableCell key={column.key}>
                    <Checkbox checked={allSelected} onClick={(e) => handleSelectAll(e, "Dialog")} />
                  </TableCell>
                ) : (
                  <TableCell key={column.key}>{column.label}</TableCell>
                );
              })}
          </TableRow>
        </TableHead>
        <TableBody sx={{ overflow: "auto" }}>
          {isUserFetching ? (
            <MISTableLoader rowsNum={5} colsNum={columns.length - 2} hasCheckList={true} />
          ) : memberList.length ? (
            memberList.map((member, memberIndex) => (
              <TableRow key={member.id}>
                {columns.map((column) => {
                  return column.key === "checkBox" ? (
                    <TableCell key={`${member.id}-${column.key}`}>
                      <Checkbox
                        checked={selectedMembers.includes(member.id)}
                        onChange={(e) => handleSelectMember(e, member.id, "Dialog")}
                        name={`${member.id}-checkbox`}
                        id={`${member.id}`}
                      />
                    </TableCell>
                  ) : (
                    <TableCell key={`${member.id}-${column.key}`}>{member[column.key]} </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length + 1}>
                <MISNoData />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </Paper>
  );
};

UserTable.propTypes = {
  columns: PropTypes.array.isRequired,
  memberList: PropTypes.array.isRequired,
  allSelected: PropTypes.bool.isRequired,
  handleSelectAll: PropTypes.func.isRequired,
  selectedMembers: PropTypes.array.isRequired,
  handleSelectMember: PropTypes.func.isRequired,
  isUserFetching: PropTypes.bool,
};

export default UserTable;
