import { Children } from "react";
import PropTypes from "prop-types";
import { TableRow, TableHead, TableCell, Typography, FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import memo from "utils/react";
import { get } from "utils/lodash";
import { handleHeaderClass } from "utils/members";
import { BACKGROUND } from "theme/colors";
import T from "T";
import { noop } from "lodash";

const TableHeader = ({
  columns = [],
  lockedColumns = [],
  selectAllCheck = false,
  handleShortListCheckBoxChange = noop,
  records,
}) => {
  const disableSelectAllCheck = records.every((item) => item.shortlistedchecks);

  return (
    <TableHead>
      <TableRow>
        <TableCell
          className="sticky-col"
          sx={{
            border: "none",
            backgroundColor: BACKGROUND.header,
            // padding: "0px 8px 8px 20px",
            p: 0,
          }}
        >
          <FormGroup>
            <FormControlLabel
              sx={{ ml: 1.7 }}
              control={
                <Checkbox
                  checked={selectAllCheck}
                  disabled={disableSelectAllCheck}
                  sx={{ opacity: disableSelectAllCheck ? 0.5 : 1 }}
                  onChange={handleShortListCheckBoxChange}
                  name={T.SELECT_ALL}
                />
              }
            />
          </FormGroup>
          {/* <Typography variant="body1" fontSize={14} fontWeight={600} sx={{ ml: -0.5 }}>
          {T.SELECT_ALL}
        </Typography> */}
        </TableCell>
        {Children.toArray(
          columns.map((column, ind) => {
            const isLocked = get(column, "locked", false);
            const columnName = get(column, "label", "").toUpperCase();
            return (
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: BACKGROUND.header,
                  padding: "0px 8px 0px 24px",
                  position: column === "Personal Info" ? "sticky" : "none",
                  left: "71px",
                  zIndex: column.label === "Personal Info" ? 2 : 0,
                }}
                className={handleHeaderClass(ind, isLocked, lockedColumns)}
              >
                <Typography variant="body1" fontSize={14} noWrap fontWeight={600}>
                  {columnName}
                </Typography>
              </TableCell>
            );
          }),
        )}
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  records: PropTypes.array,
  selectAllCheck: PropTypes.bool,
  handleShortListCheckBoxChange: PropTypes.func,
};

export default memo(TableHeader);
