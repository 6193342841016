import { Box, Button, Card, Grid, IconButton, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import T from "T";
import { NETSMARTZ_THEME_COLOR, TEXT } from "theme/colors";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { GET_SIZE } from "utils/responsive";
import { handleError } from "utils/error";
import { useLazyGetQuestionQuery } from "api/MockInterview/getQuestion";
import { useSubmitAudioMutation } from "api/MockInterview/submitAudio";
import { shallowEqual, useDispatch } from "react-redux";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import MicIcon from "@mui/icons-material/Mic";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import html2canvas from "html2canvas";
import {
  setStartTime,
  updateTimeLeft,
  videoInterviewStore1,
  videoInterviewTechQuestionStore,
  videoInterviewTechSet1Recieved,
  videoInterviewTechSet2Recieved,
} from "slices/videoInterviewSlice";
import {
  mockSetStartTime,
  mockUpdateTimeLeft,
  mockVideoInterviewStore1,
  mockVideoInterviewTechQuestionStore,
  mockVideoInterviewTechSet1Recieved,
  mockManageVideoInterviewUserLogin,
  mockVideoInterviewFormDataStore,
  mockVideoInterviewInstructionModal,
} from "slices/mockVideoInterviewSlice";
import { useSelector } from "react-redux";
import { get } from "lodash";
import { usePostUserInfoMutation } from "api/MockInterview/postMockInterviewUserInfo";
import { MISCurrentUser } from "utils/validations";
// import QuestionsBoxButtons from "./QuestionsBoxButtons";
import MISLoader from "components/common/MISLoader";
import InstructionsModal from "./InstructionsModal";
// import { useLazyGetExistingResponsesQuery } from "api/MockInterview/getExistingResponses";
import { ReactMediaRecorder, useReactMediaRecorder } from "react-media-recorder";

import {
  manageVideoInterviewUserLogin,
  videoInterviewFormDataStore,
  videoInterviewInstructionModal,
} from "slices/videoInterviewSlice";
import { useSubmitVideoResponseMutation } from "api/VideoInterview/postInterviewResponses";
import VideoInterviewHeader from "../VideoInterviewHeader";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";
import { useGetInterviewQuestionsMutation } from "api/TechnicalInterview/getQuestions";
import { usePostAiUserInfoMutation } from "api/MockVideoInterview/postVideoInterviewAiUserInfo";
import { useLazyGetMockVideoInterviewQuestionQuery } from "api/MockVideoInterview/getVideoInterviewQuestion";
import PropTypes from "prop-types";
import { useLazyFinalMockSubmissionQuery } from "api/MockVideoInterview/finalInterviewSubmission";
import { useSubmitMockVideoResponseScreenshotMutation } from "api/MockVideoInterview/postInterviewScreenShots";
import { useSubmitMockVideoResponseMutation } from "api/MockVideoInterview/postInterviewResponses";
const APP_PREFIX = "/mock-interview";
export const convertBase64ToBlob = (base64String) => {
  const binaryData = atob(base64String);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);

  for (let i = 0; i < binaryData.length; i++) {
    uint8Array[i] = binaryData.charCodeAt(i);
  }

  // Create a Blob object from the binary data
  const blob = new Blob([uint8Array], { type: "application/octet-stream" });
  return blob;
};

// Helper function to convert FormData to a simple object
export const convertFormDataToObject = (formData) => {
  const obj = {};
  formData.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

// Helper function to convert object back to FormData
const convertObjectToFormData = (obj) => {
  const formData = new FormData();
  Object.entries(obj).forEach(([key, value]) => {
    formData.append(key, value);
  });
  return formData;
};
const InterviewQuestions = ({ boxHeight }) => {
  const videoRef = useRef(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const notifyError = (message) => toast.error(message);
  const getvideo = () => {
    navigator.mediaDevices.getUserMedia({ video: { width: "inherit", height: "inherit" } }).then((stream) => {
      let video = videoRef?.current;
      if (video) {
        video.srcObject = stream;
        video.play().catch((error) => {
          console.error(error);
        });
      }
    });
  };

  const dispatch = useDispatch();
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition, isMicrophoneAvailable } =
    useSpeechRecognition();
  // const location = useLocation();

  // const { user } = MISCurrentUser();
  // const userId = get(user, "user.id", "");
  // const userName = get(user, "user.userName", "");
  // const empCode = get(user, "user.employeeCode", "");
  let videoResponsesInSession = sessionStorage.getItem("videoResponsesCount");
  useEffect(() => {
    if (!videoResponsesInSession || videoResponsesInSession === 0 || videoResponsesInSession === undefined) {
      dispatch(mockVideoInterviewFormDataStore({ videoResponsesFormData: {} }));
    }
  }, [videoResponsesInSession]);
  const {
    formSubmitted,
    receivedTechSet1,
    receivedTechSet2,
    videoResponses,
    videoResponsesFormData,
    participantDetails,
    videoInterviewUserRegistered,
    responseArr,
    startTime,
    timeLeft,
    openInstructionModal,
    techQuestions,
  } = useSelector(
    (state) => ({
      formSubmitted: get(state, "MockVideoInterviewSlice.formSubmitted", false),
      receivedTechSet1: get(state, "MockVideoInterviewSlice.receivedTechSet1", false),
      receivedTechSet2: get(state, "MockVideoInterviewSlice.receivedTechSet2", false),
      techQuestions: get(state, "MockVideoInterviewSlice.techQuestions", []),
      videoResponses: get(state, "MockVideoInterviewSlice.videoResponses", []),
      videoResponsesFormData: get(state, "MockVideoInterviewSlice.videoResponsesFormData", {}),
      participantDetails: get(state, "MockVideoInterviewSlice.participantDetails", {}),
      videoInterviewUserRegistered: get(state, "MockVideoInterviewSlice.videoInterviewUserRegistered", false),
      responseArr: get(state, "MockVideoInterviewSlice.responseArr", []),
      startTime: get(state, "MockVideoInterviewSlice.startTime", null),
      openInstructionModal: get(state, "MockVideoInterviewSlice.openInstructionModal", null),
      timeLeft: get(state, "MockVideoInterviewSlice.timeLeft", null),
    }),
    shallowEqual,
  );

  const storedVideoResponsesCount = Object.keys(videoResponsesFormData).length;
  const { isXs, isMd, isLg } = GET_SIZE();
  const [getVideoInterviewQuestion, { data: question, isFetching }] = useLazyGetMockVideoInterviewQuestionQuery();
  const [finalSubmission] = useLazyFinalMockSubmissionQuery();
  const [getInterviewQuestions, { isLoading: isLoadingTechQuestions }] = useGetInterviewQuestionsMutation();
  // const [submitResponses, { isFetching: submitResponseFetching }] = useSubmitResponsesMutation();
  const [submitMockVideoResponse, { isFetching: submitResponseFetching }] = useSubmitMockVideoResponseMutation();
  const [submitVideoResponseScreenshot, { isFetching: submitResponseScreenshotFetching }] =
  useSubmitMockVideoResponseScreenshotMutation();
  // const [getExistingResponses] = useLazyGetExistingResponsesQuery();
  const [postAiUserInfo] = usePostAiUserInfoMutation();
  const [submitAudio, audioData] = useSubmitAudioMutation();
  const { isLoading: submitIsLoading } = audioData;
  const navigate = useNavigate();
  const mimeType = "audio/webm";
  const [permission, setPermission] = useState(false);
  const mediaRecorder = useRef(null);
  const [recordingStatus, setRecordingStatus] = useState("inactive");
  const [stream, setStream] = useState(null);
  const [audioChunks, setAudioChunks] = useState([]);
  const [videoFormData, setVideoFormData] = useState(new FormData());
  // const { status, startRecording, stopRecording, mediaBlobUrl } = useReactMediaRecorder({ video: true, audio: true });

  // const [recordedVideoList,setRecordedVideoList] = useState([])
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    questionsList: [],
    answersList: [],
    questionStep: 0,
    recordedVideoList: [],
    isLoading: false,
    // isVideoInterviewAssigned: location?.state?.isVideoInterviewAssigned ? location?.state?.isVideoInterviewAssigned : null,
    startTimer: false,
    submitLoading: false,
    videoResponsesSubmitted: false,
    transcriptedText: "",
  });
  const {
    questionsList,
    isLoading,
    questionStep,
    recordedVideoList,
    isVideoInterviewAssigned,
    startTimer,
    answersList,
    videoResponsesSubmitted,
    transcriptedText,
    submitLoading,
  } = localState;

  const startAudioRecording = async () => {
    setRecordingStatus("recording");
    // const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: { facingMode: "user" } });
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });

    // getUserMedia({
    //   audio: true,
    //   video: { facingMode: "user" },
    // });
    //create new Media recorder instance using the stream
    const media = new MediaRecorder(stream, { type: mimeType });
    //set the MediaRecorder instance to the mediaRecorder ref
    mediaRecorder.current = media;
    //invokes the start method to start the recording process
    mediaRecorder.current.start();
    let localAudioChunks = [];
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === "undefined") {
        return;
      }
      if (event.data.size === 0) {
        return;
      }
      localAudioChunks.push(event.data);
    };
    setAudioChunks(localAudioChunks);
  };

  const stopAudioRecording = () => {
    setRecordingStatus("inactive");
    //stops the recording instance
    mediaRecorder.current.stop();
    mediaRecorder.current.onstop = async () => {
      //creates a blob file from the audiochunks data
      const audioBlob = new Blob(audioChunks, { type: mimeType });
      const audioUrl = URL.createObjectURL(audioBlob);
      const blobBase64 = await convertBase64(audioBlob);
      // let newAudioFormData = new FormData();
      // newAudioFormData.append(`audio_${questionStep}`, audioBlob, "recording.wav");
      let storedVideoResponsesFormData = convertObjectToFormData(videoResponsesFormData);
      // videoFormData.append(`video${questionStep}`, blobStr, `video-recording-${questionStep}.mp4`);
      storedVideoResponsesFormData.append(`video-${quesStep}`, audioBlob, `video-recording-${quesStep}.mp4`);
      let newFormDataResponse = new FormData();
      newFormDataResponse.append("file", audioBlob, `"recording-${quesStep}.wav`);
      newFormDataResponse.append("participantId", get(participantDetails, "id", null));

      // newFormDataResponse.append("question", `question-${quesStep + 1}`);
      if (quesStep < 5) {
        newFormDataResponse.append("question", get(question, "question", `question-${quesStep + 1}`));
      } else if (quesStep > 4) {
        newFormDataResponse.append("question", techQuestions[quesStep - 5]);
      }
      newFormDataResponse.append("transcriptedText", "");
      newFormDataResponse.append("type", quesStep < 5 ? "M" : "T");

      let answersListCopy = [...videoResponses];
      answersListCopy.push(btoa(blobBase64));

      setVideoFormData(videoFormData);
      setLocalState({ answersList: audioUrl });
      dispatch(
        mockVideoInterviewFormDataStore({
          // videoResponses: answersListCopy,
          videoResponsesFormData: convertFormDataToObject(storedVideoResponsesFormData),
        }),
      );
      sessionStorage.setItem("videoResponsesCount", `${quesStep + 1}`);
      // const finalPayload = newFormDataResponse;
      submitMockVideoResponse(newFormDataResponse)
        .unwrap()
        .then(() => console.log(`Response ${quesStep + 1} Submitted`))
        .catch(handleError);

      // let answersListCopy = [...mockResponses];
      // answersListCopy.push(blobBase64);
      // setAudioFormData(audioFormData);
      setLocalState({ answersList: answersListCopy });
      // dispatch(mockInterviewStore({ mockResponses: answersListCopy }));
      setAudioChunks([]);
      setLocalState({
        recordedVideoList: [...recordedVideoList, audioUrl],
      });
    };
  };
  // Function to convert mediaBlobUrl to Blob
  const convertUrlToBlob = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return blob;
    // setMediaBlob(blob); // Set Blob to state
  };
  // useEffect(() => {
  //   setLocalState({ isLoading: true });
  //   if (responseArr.length === 0) {
  //     getExistingResponses({ candidateName: "Nishant" })
  //       .unwrap()
  //       .then((res) => {
  //         // const isVideoInterviewAssigned = get(res, "isVideoInterviewAssigned", null);
  //         setLocalState({ isVideoInterviewAssigned: true });
  //         const responseArr = get(res, "mockInterviewLevels", []);
  //         const createdAt = get(res, "createdAt", []);
  //         const resLength = responseArr.length;
  //         if (resLength > 0) {
  //           navigate(`/mock-interview-responses`, {
  //             state: { responseArr: responseArr, createdAt: createdAt },
  //           });
  //         }
  //       })
  //       .catch(handleError)
  //       .finally(() => {
  //         setLocalState({ isLoading: false });
  //       });
  //   } else {
  //     setLocalState({ isLoading: false });
  //   }
  // }, [responseArr.length]);

  useEffect(() => {
    if (timeLeft === 0 && !formSubmitted) {
      handleSubmit();
    }
  }, [timeLeft]);

  // open the info dialog
  useEffect(() => {
    if (timeLeft === 20 * 60) {
      dispatch(mockVideoInterviewInstructionModal({ openInstructionModal: true }));
    } else {
      dispatch(mockVideoInterviewInstructionModal({ openInstructionModal: false }));
      setLocalState({ openInitialDialog: true });
    }
  }, []);

  useEffect(() => {
    if (!startTime && startTimer) {
      const now = new Date().getTime();
      dispatch(mockSetStartTime({ startTime: now }));
    }
  }, [startTime, dispatch, startTimer]);
  useEffect(() => {
    if ((!openInstructionModal || openInstructionModal !== null) && !startTime && startTimer) {
      const now = new Date().getTime();
      dispatch(mockSetStartTime({ startTime: now }));
    }
  }, [openInstructionModal, startTime, dispatch, startTimer]);
  useEffect(() => {
    if (!openInstructionModal && startTime) {
      const interval = setInterval(() => {
        const now = new Date().getTime();
        const elapsedTime = Math.floor((now - startTime) / 1000);
        const remainingTime = Math.max(0, 20 * 60 - elapsedTime);
        dispatch(mockUpdateTimeLeft({ timeLeft: remainingTime }));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [openInstructionModal, startTime, dispatch]);

  function stripBase64Metadata(base64String) {
    const prefix = "data:image/png;base64,";
    if (base64String.startsWith(prefix)) {
      return base64String.slice(prefix.length);
    }
    return base64String; // Return the original string if it doesn't have the prefix
  }
  useEffect(() => {
    if (!formSubmitted) {
      const takeScreenshot = () => {
        // Capture the screenshot using html2canvas
        html2canvas(document.body)
          .then((canvas) => {
            const screenshotUrl = canvas.toDataURL("image/png");
            let screenShotDetails = {
              file: stripBase64Metadata(screenshotUrl),
              fileName: `Candidate-${get(participantDetails, "id", null)}-Screen Capture.png`,
              participantId: get(participantDetails, "id", null),
            };
            // let newScreenshotFormDataResponse = new FormData();
            // newScreenshotFormDataResponse.append("participantId", get(participantDetails, "id", null));
            // // newScreenshotFormDataResponse.append("videoInterviewScreenshotRequestDto", screenShotDetails);
            // newScreenshotFormDataResponse.append("file", screenshotUrl);
            // newScreenshotFormDataResponse.append("filename", `Candidate-${get(participantDetails, "id", null)}-Screen Capture`);
            submitVideoResponseScreenshot(screenShotDetails)
              .unwrap()
              .then((res) => console.log("screen shot captured"))
              .catch(handleError);
            // const newTab = window.open();
            // newTab.document.body.innerHTML = `<img src="${screenshotUrl}" alt="Screenshot" style="max-width:100%; height:auto;" />`;

            // You can add logic here to upload the screenshot or save it as needed
            // e.g., dispatch or API call to send the screenshot to the server
          })
          .catch((err) => {
            console.error("Error capturing screenshot:", err);
          });
      };

      // Set an interval to capture screenshots every 4 minutes (240000 milliseconds)
      const intervalId = setInterval(takeScreenshot, 120000);

      // Clean up the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [formSubmitted]);

  // useEffect(() => {
  //   if (!openInstructionModal && startTime) {
  //     const interval = setInterval(() => {
  //       const now = new Date().getTime();
  //       const elapsedTime = Math.floor((now - startTime) / 1000);
  //       const remainingTime = Math.max(0, 20 * 60 - elapsedTime);
  //       dispatch(updateTimeLeft({ timeLeft: remainingTime }));
  //     }, 1000);
  //     return () => clearInterval(interval);
  //   }
  // }, [openInstructionModal, startTime, dispatch]);

  // useEffect(() => {
  //   dispatch(videoInterviewFormDataStore({ videoResponsesFormData: {} }));
  // }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };
  const [searchParams] = useSearchParams();
  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  const quesStep = +searchParams.get("ques");

  useEffect(() => {
    if (quesStep === 0 && quesStep !== null && !videoInterviewUserRegistered) {
      const userInfoPayload = {
        user_name: get(participantDetails, "name", ""),
        employee_code: `UID${get(participantDetails, "id", "")}`,
      };
      postAiUserInfo(userInfoPayload)
        .unwrap()
        .then(() => {
          dispatch(mockManageVideoInterviewUserLogin({ videoInterviewUserRegistered: true }));
          getVideoInterviewQuestion(quesStep)
            .unwrap()
            .then((res) => {
              const questions = [...questionsList];
              questions.push(res?.question);
              setLocalState({ questionsList: questions });
            })
            .catch(handleError);
        })
        .catch(handleError);
    }
  }, []);

  const getTechQuestionsList = (obj) => {
    return Object.values(obj);
  };

  useEffect(() => {
    if (!receivedTechSet1 && (quesStep < 1 || (quesStep > 1 && quesStep < 5))) {
      const payload = {
        emp_id: `UID${get(participantDetails, "id", "")}`,
        YOE: "0.5",
        tech_stack: "OOPS,MySQL",
        topics: [
          "Abstraction,Encapsulation,Inheritance,Polymorphism,Classes,Constructors,Overriding,Aggregation,Coupling,Interfaces,Indexes,Joins,Transactions,Normalization,Replication,Security,Backup,Queries,Schemas,Performance",
        ],
      };
      getInterviewQuestions({ payload })
        .unwrap()
        .then((res) => {
          const { "Question Dict": questions, Experience, employee_id } = res;
          let newTechQuestions = getTechQuestionsList(questions);
          // newTechQuestions.push(getTechQuestionsList(questions));
          dispatch(mockVideoInterviewTechQuestionStore({ techQuestions: newTechQuestions }));
          dispatch(mockVideoInterviewTechSet1Recieved({ receivedTechSet1: true }));
        })
        .catch(handleError);
    }

    // if (!receivedTechSet2 && quesStep > 4) {
    //   console.log("2nd");

    //   const newPayload = {
    //     emp_id: `UID${get(participantDetails, "id", "")}`,
    //     YOE: "0.5",
    //     tech_stack: "MySQL",
    //     topics: ["Indexes,Joins,Transactions,Normalization,Replication,Security,Backup,Queries,Schemas,Performance"],
    //   };
    //   getInterviewQuestions({ newPayload })
    //     .unwrap()
    //     .then((res) => {
    //       const { "Question Dict": questions, Experience, employee_id } = res;
    //       let newTechQuestions = [...techQuestions];
    //       newTechQuestions.push(getTechQuestionsList(questions));
    //       dispatch(videoInterviewTechQuestionStore({ techQuestions: newTechQuestions }));
    //       dispatch(videoInterviewTechSet2Recieved({ receivedTechSet2: true }));

    //       // const questions = [...questionsList];
    //       // questions.push(res?.question);
    //       // setLocalState({ questionsList: questions });
    //     })
    //     .catch(handleError);
    // }
  }, [quesStep]);
  useEffect(() => {
    if (quesStep === 0 || videoResponsesSubmitted) {
      window.history.pushState(null, "", window.location.href);
      window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
      };
      // window.onpopstate = function () {
      //   window.history.go(1);
      // };
    }
    // if (quesStep > 0 && quesStep < 5 || videoInterviewUserRegistered) {
    if (quesStep >= 0 && quesStep < 5) {
      if (quesStep === null) {
        return;
      }
      getVideoInterviewQuestion(quesStep)
        .unwrap()
        .then((res) => {
          const questions = [...questionsList];
          questions.push(res?.question);
          setLocalState({ questionsList: questions });
        })
        .catch(handleError);
    }
    // if (quesStep > 4 && quesStep < 15) {
    //   if (quesStep === null) {
    //     return;
    //   }
    //   const payload =
    //     quesStep > 4 && quesStep < 10
    //       ? {
    //           emp_id: `UID${get(participantDetails, "id", "")}`,
    //           YOE: "0.5",
    //           tech_stack: "OOPS",
    //           topics: ["OOPS"],
    //         }
    //       : quesStep > 9 &&
    //         quesStep < 15 && {
    //           emp_id: `UID${get(participantDetails, "id", "")}`,
    //           YOE: "0.5",
    //           tech_stack: "MySQL",
    //           topics: ["Indexes,Joins,Transactions,Normalization,Replication,Security,Backup,Queries,Schemas,Performance"],
    //         };
    //   getInterviewQuestions({ payload })
    //     .unwrap()
    //     .then((res) => {
    //       const { "Question Dict": questions, Experience, employee_id } = res;
    //       console.log(res);
    //       // const questions = [...questionsList];
    //       // questions.push(res?.question);
    //       // setLocalState({ questionsList: questions });
    //     })
    //     .catch(handleError);
    // }
  }, [quesStep]);

  const getCameraMicrophonePermission = async () => {
    if ("MediaRecorder" in window) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: true,
        });
        const videoElement = document.querySelector("video");
        videoElement.srcObject = stream;
        videoElement.play();
        setPermission(true);
        setStream(streamData);
      } catch (err) {
        handleError(err);
        alert("Camera and microphone access permission required !");
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  useEffect(() => {
    if (!isLoading) {
      getCameraMicrophonePermission();
    }
  }, [isLoading]);
  useEffect(() => {
    getvideo();
  }, [videoRef, quesStep]);

  const handleNext = () => {
    setLocalState({ questionStep: quesStep + 1 });
    navigate(`/app/mock-video-screen-test?ques=${quesStep + 1}`);
  };

  const handleSubmit = () => {
    // setLocalState({ submitLoading: true });
    dispatch(mockVideoInterviewStore1({ formSubmitted: true }));
    setLocalState({ videoResponsesSubmitted: true });

    finalSubmission(get(participantDetails, "id", null))
      .unwrap()
      .then((res) => {
        // setLocalState({ submitLoading: false });
        // setLocalState({ videoResponsesSubmitted: true });

        toast.success(T.RESPONSE_SUBMISSION_STATEMENT);
      })
      .catch(handleError);
    // let finalVideoFormData = new FormData();
    // videoResponses.map((data, index) => finalVideoFormData.append(`audio_${index}`, convertBase64ToBlob(data), "recording.wav"));
    // submitAudio(finalVideoFormData)
    //   .unwrap()
    //   .then((res) => {
    //     let audioFiles = [];
    //     videoResponses.map((data, index) =>
    //       audioFiles.push({
    //         file: data,
    //         fileName: `audio${index}.wav`,
    //       }),
    //     );
    //     const saveDataPayload = {
    //       mockInterviewLevelDto: res,
    //       audioFiles: audioFiles,
    //     };
    //     const finalPayload = {
    //       candidateName: "Nishant",
    //       saveDataPayload,
    //     };
    //   })
    //   .catch(handleError);
  };

  const handleInstructionModal = () => {
    dispatch(mockVideoInterviewInstructionModal({ openInstructionModal: !openInstructionModal }));
    setLocalState({ startTimer: true });
  };

  return submitIsLoading || submitResponseFetching || isLoading || submitLoading ? (
    <MISLoader />
  ) : (
    <Grid container>
      {/* <Grid item xs={12}>
        <VideoInterviewHeader />
      </Grid> */}
      {!formSubmitted && (
        <Grid item display={"flex"} justifyContent={"flex-end"} width={"100%"} sx={{ zIndex: 99 }}>
          {timeLeft < 60 ? (
            <Card
              variant="outlined"
              sx={{
                // margin: "0px -20px 0px 0px",
                marginTop: `${boxHeight - 60}px`,
                marginRight: "-20px",
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                bgcolor: "red",
                color: "white",
              }}
            >
              <TimerOutlinedIcon fontSize="small" sx={{ pr: 0.5 }} />
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                Your assessment will be submitted in {formatTime(timeLeft)?.split(":")[1]} seconds.
              </Typography>
            </Card>
          ) : (
            <Card
              variant="outlined"
              sx={{
                // margin: "0px -20px 0px 0px",
                marginTop: `${boxHeight - 60}px`,
                marginRight: "-20px",
                width: 150,
                p: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <TimerOutlinedIcon fontSize="small" sx={{ pr: 0.5 }} />
              <Typography variant="body1" sx={{ textAlign: "center" }}>
                {`Time Left: ${formatTime(timeLeft)}`}
              </Typography>
            </Card>
          )}
        </Grid>
      )}

      <Grid container>
        <Grid item display={"flex"} justifyContent={"flex-end"} width={"100%"} sx={{ zIndex: 99 }}></Grid>
        <Grid item xs={isMd ? 2 : 3.25} />
        <Grid
          item
          xs={isMd ? 8 : 5.5}
          className="questionDiv"
          sx={{
            position: "absolute",
            top: "40%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "270px",
            width: "inherit",
            minWidth: "300px",
            height: "auto",
            // maxHeight: "450px",
            backgroundColor: "background.white",
            borderRadius: "8px",
            padding: "5px 15px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            boxShadow: "3px 3px 30px -10px rgba(0,0,0,0.3)",
            // overflowY: "auto",
            // marginTop: `15px`,
            marginTop: isMd ? `${boxHeight + 60}px` : isLg ? `${boxHeight + 10}px` : isXs && `${boxHeight + 10}px`,
          }}
        >
          {videoResponsesSubmitted || formSubmitted ? (
            <Box sx={{ display: "block", justifyContent: "center", alignItems: "center", margin: "auto" }}>
              <Typography variant="h6" align="center" color={NETSMARTZ_THEME_COLOR}>
                Thank You.
              </Typography>
              <Typography variant="h6" align="center">
                Your responses are submitted successfully.
              </Typography>
            </Box>
          ) : (
            <>
              {quesStep < 5 ? (
                <Stack>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600, color: NETSMARTZ_THEME_COLOR }}>
                      {quesStep < 5 ? "Set 1" : "Set 2"}
                    </Typography>
                    <Typography
                      variant="subtitle-2"
                      sx={{
                        flexWrap: "wrap",
                        textAlign: "start",
                        color: TEXT.grayBlue,
                        // marginBottom: 1,
                      }}
                    >
                      {quesStep < 5 ? `Question ${quesStep + 1}/5` : quesStep > 4 && `Question ${quesStep - 4}/10`}
                    </Typography>

                    <Tooltip title={T.INSTRUCTIONS} placement="top">
                      <IconButton aria-label="info" onClick={handleInstructionModal}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>

                  <Typography
                    variant="h6"
                    sx={{
                      // flexWrap: "wrap",
                      textAlign: "start",
                      fontSize: "1rem",
                      // fontSize: isXs ? "1.3rem" : "1.6rem",
                      // lineHeight: isXs ? "1.3" : "1.6",
                      fontWeight: "bold",
                      mb: 1,
                    }}
                  >
                    {quesStep < 5 && !isFetching ? (
                      question?.question
                    ) : quesStep > 4 && !isLoadingTechQuestions ? (
                      techQuestions[quesStep - 5]
                    ) : (
                      <Skeleton variant="rectangular" width={"inherit"} height={30} />
                    )}
                  </Typography>
                </Stack>
              ) : (
                <Typography
                  variant="h2"
                  sx={{
                    flexWrap: "wrap",
                    textAlign: "center",
                  }}
                >
                  Thank You!
                </Typography>
              )}

              <Grid
                sx={{ marginBottom: 1 }}
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                // }}
              >
                <ReactMediaRecorder
                  video
                  render={({ status, startRecording, stopRecording, mediaBlobUrl }) => {
                    useEffect(() => {
                      if (mediaBlobUrl) {
                        const fetchBlob = async () => {
                          const blob = await convertUrlToBlob(mediaBlobUrl); // Await the blob
                          return blob; // Set Blob to state
                        };
                        // const blobStr= fetchBlob()
                        fetchBlob()
                          .then(async (blobStr) => {
                            setRecordedChunks([blobStr]);
                            const blobBase64 = await convertBase64(blobStr);
                            const videoUrl = URL.createObjectURL(blobStr);
                            let storedVideoResponsesFormData = convertObjectToFormData(videoResponsesFormData);
                            // videoFormData.append(`video${questionStep}`, blobStr, `video-recording-${questionStep}.mp4`);
                            storedVideoResponsesFormData.append(`video-${quesStep}`, blobStr, `video-recording-${quesStep}.mp4`);

                            let newFormDataResponse = new FormData();
                            newFormDataResponse.append("file", blobStr, `video-recording-${quesStep}.mp4`);
                            newFormDataResponse.append("participantId", get(participantDetails, "id", null));

                            // newFormDataResponse.append("question", `question-${quesStep + 1}`);
                            newFormDataResponse.append("question", get(question, "question", `question-${quesStep + 1}`));
                            newFormDataResponse.append("transcriptedText", transcriptedText);
                            let answersListCopy = [...videoResponses];
                            answersListCopy.push(btoa(blobBase64));

                            setVideoFormData(videoFormData);
                            setLocalState({ answersList: videoUrl });
                            dispatch(
                              mockVideoInterviewFormDataStore({
                                // videoResponses: answersListCopy,
                                videoResponsesFormData: convertFormDataToObject(storedVideoResponsesFormData),
                              }),
                            );
                            sessionStorage.setItem("videoResponsesCount", `${quesStep + 1}`);
                            // const finalPayload = newFormDataResponse;
                            submitMockVideoResponse(newFormDataResponse)
                              .unwrap()
                              .then(() => console.log("response Submitted"))
                              .catch(handleError);
                            // dispatch(mockInterviewStore({ videoResponses: [] }));
                            setAudioChunks([]);
                          })
                          .catch((error) => {
                            console.error("Error fetching Blob:", error);
                          });
                      }
                    }, [mediaBlobUrl]);
                    if (quesStep + 1 <= storedVideoResponsesCount) {
                      return (
                        <>
                          {storedVideoResponsesCount === 5 ? (
                            <Box
                              sx={{
                                display: "block",
                                alignItems: "center",
                                marginBottom: "16px",
                              }}
                            >
                              <Typography color={NETSMARTZ_THEME_COLOR} align="center">
                                Thank you, All your answers have been recorded.
                              </Typography>
                              <Typography align="center">Please click on submit button to finish.</Typography>
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: "block",
                                alignItems: "center",
                                marginBottom: "16px",
                              }}
                            >
                              <Typography color={NETSMARTZ_THEME_COLOR} align="center">
                                Thank you, your answer has been recorded.
                              </Typography>
                              <Typography align="center">Please click on next to continue.</Typography>
                            </Box>
                          )}

                          <Box
                            sx={{
                              display: isXs ? "block" : "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {permission && recordingStatus === "inactive" ? (
                              <Button
                                variant="contained"
                                disabled={storedVideoResponsesCount > +quesStep || formSubmitted}
                                sx={{
                                  fontSize: 16,
                                  px: 3,
                                  width: isXs ? "100%" : 125,
                                  minWidth: "90px",
                                  py: 1.3,
                                  mb: 2,
                                  backgroundColor: "themeColor",
                                  borderRadius: 2.1,
                                  "&:hover": {
                                    backgroundColor: "themeColor", // Change to your theme color
                                  },
                                }}
                                startIcon={
                                  <MicIcon
                                    sx={{
                                      fontSize: "16px !important",
                                      borderRadius: 50,
                                      p: 0.2,
                                      backgroundColor: "white",
                                      color: NETSMARTZ_THEME_COLOR,
                                    }}
                                  />
                                }
                                onClick={() => {
                                  resetTranscript();
                                  startAudioRecording();
                                  SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
                                  if (!isMicrophoneAvailable) {
                                    notifyError("Microphone is not available.");
                                    return;
                                  }
                                  if (!browserSupportsSpeechRecognition) {
                                    notifyError("Feature not supported in this browser.");
                                    return;
                                  }
                                  // setLocalState({ openRecordingDialog: true });
                                  setRecordingStatus("recording");
                                }}
                              >
                                Answer
                              </Button>
                            ) : null}
                            {recordingStatus === "recording" ? (
                              <Button
                                variant="contained"
                                startIcon={
                                  <StopCircleIcon
                                    sx={{
                                      fontSize: "16px !important",
                                      borderRadius: 50,
                                      p: 0.2,
                                      backgroundColor: "white",
                                      color: NETSMARTZ_THEME_COLOR,
                                    }}
                                  />
                                }
                                sx={{
                                  fontSize: 16,
                                  px: 3,
                                  width: isXs ? "100%" : 125,
                                  minWidth: "90px",
                                  maxHeight: "48px",
                                  py: 1.3,
                                  mb: 2,
                                  backgroundColor: "themeColor",
                                  borderRadius: 2.1,
                                  "&:hover": {
                                    backgroundColor: "themeColor", // Change to your theme color
                                  },
                                }}
                                disabled={storedVideoResponsesCount < questionStep || formSubmitted}
                                onClick={() => {
                                  stopAudioRecording();

                                  SpeechRecognition.stopListening();
                                  setLocalState({ transcriptedText: transcript });
                                  resetTranscript();
                                  setRecordingStatus("inactive");
                                  // setLocalState({
                                  //   recordedVideoList: [...recordedVideoList, mediaBlobUrl],
                                  // });
                                }}
                              >
                                Stop
                              </Button>
                            ) : null}
                            {quesStep < 4 ? (
                              <Button
                                variant="outlined"
                                onClick={handleNext}
                                endIcon={<ArrowForwardIcon />}
                                disabled={storedVideoResponsesCount === quesStep || formSubmitted}
                                sx={{
                                  borderColor: "themeColor",
                                  color: "black",
                                  fontWeight: "bold",
                                  borderRadius: 2,
                                  width: isXs ? "100%" : 100,
                                  fontSize: "15px",
                                  px: 1.4,
                                  py: 1.3,
                                  mb: 2,
                                  "&:hover": {
                                    borderColor: "themeColor", // Change to your theme color
                                  },
                                }}
                              >
                                {T.NEXT}
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={handleSubmit}
                                disabled={storedVideoResponsesCount < 5 || audioData?.status === "pending" || formSubmitted}
                                sx={{
                                  borderColor: "themeColor",
                                  color: "black",
                                  fontWeight: "bold",
                                  borderRadius: 2,
                                  width: isXs ? "100%" : 100,
                                  fontSize: "15px",
                                  px: 1.4,
                                  py: 1.3,
                                  mb: 2,
                                  "&:hover": {
                                    backgroundColor: "themeColor", // Change to your theme color
                                    borderColor: NETSMARTZ_THEME_COLOR,
                                    color: "white",
                                  },
                                }}
                              >
                                {T.SUBMIT}
                              </Button>
                            )}
                          </Box>
                        </>
                      );
                    } else {
                      return (
                        <Grid>
                          <Box
                            marginBottom={2}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <video
                              ref={videoRef}
                              style={{
                                width: isLg ? "" : isMd ? "100%" : "",
                                // width: "inherit",
                                // height: "inherit",
                                height: isLg ? "" : isMd ? "inherit" : "",
                                maxHeight: isLg && "280px",
                                borderRadius: "10px",
                                transform: "scaleX(-1)",
                                objectFit: "fill",
                              }} // This flips the video horizontally
                              //  width={"100%"}
                              //  height={"300px"}
                              //  autoPlay
                              playsInline
                            />
                          </Box>
                          {/* <p>{status}</p> */}
                          <Box
                            sx={{
                              display: isXs ? "block" : "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {permission && recordingStatus === "inactive" ? (
                              <Button
                                variant="contained"
                                disabled={storedVideoResponsesCount > +quesStep || formSubmitted}
                                sx={{
                                  fontSize: 16,
                                  display: "flex",
                                  px: 3,
                                  width: isXs ? "100%" : 125,
                                  minWidth: "90px",
                                  py: 1.3,
                                  mb: isXs ? 2 : isMd ? 0 : 0,
                                  height: "48px",
                                  backgroundColor: "themeColor",
                                  borderRadius: 2.1,
                                  "&:hover": {
                                    backgroundColor: "themeColor", // Change to your theme color
                                  },
                                }}
                                startIcon={
                                  <MicIcon
                                    sx={{
                                      fontSize: "16px !important",
                                      borderRadius: 50,
                                      p: 0.2,
                                      backgroundColor: "white",
                                      color: NETSMARTZ_THEME_COLOR,
                                    }}
                                  />
                                }
                                onClick={() => {
                                  resetTranscript();
                                  startAudioRecording();
                                  SpeechRecognition.startListening({ continuous: true, language: "en-IN" });
                                  if (!isMicrophoneAvailable) {
                                    notifyError("Microphone is not available.");
                                    return;
                                  }
                                  if (!browserSupportsSpeechRecognition) {
                                    notifyError("Feature not supported in this browser.");
                                    return;
                                  }
                                  // setLocalState({ openRecordingDialog: true });
                                  setRecordingStatus("recording");
                                }}
                              >
                                Answer
                              </Button>
                            ) : null}
                            {recordingStatus === "recording" ? (
                              <Button
                                variant="contained"
                                startIcon={
                                  <StopCircleIcon
                                    sx={{
                                      fontSize: "16px !important",
                                      borderRadius: 50,
                                      p: 0.2,
                                      backgroundColor: "white",
                                      color: NETSMARTZ_THEME_COLOR,
                                    }}
                                  />
                                }
                                sx={{
                                  fontSize: 16,
                                  px: 3,
                                  width: isXs ? "100%" : 125,
                                  minWidth: "90px",
                                  maxHeight: "48px",
                                  py: 1.3,
                                  // mb: 2,
                                  backgroundColor: "themeColor",
                                  borderRadius: 2.1,
                                  "&:hover": {
                                    backgroundColor: "themeColor", // Change to your theme color
                                  },
                                }}
                                disabled={storedVideoResponsesCount < questionStep || formSubmitted}
                                onClick={() => {
                                  stopAudioRecording();
                                  SpeechRecognition.stopListening();
                                  resetTranscript();
                                  setLocalState({ transcriptedText: transcript });
                                  setRecordingStatus("inactive");
                                  // setLocalState({
                                  //   recordedVideoList: [...recordedVideoList, mediaBlobUrl],
                                  // });
                                }}
                              >
                                Stop
                              </Button>
                            ) : null}
                            {quesStep < 4 ? (
                              <Button
                                variant="outlined"
                                onClick={handleNext}
                                endIcon={<ArrowForwardIcon />}
                                disabled={storedVideoResponsesCount === quesStep || formSubmitted}
                                sx={{
                                  borderColor: "themeColor",
                                  color: "black",
                                  fontWeight: "bold",
                                  borderRadius: 2,
                                  width: isXs ? "100%" : 100,
                                  fontSize: "15px",
                                  px: 1.4,
                                  py: 1.3,
                                  // mb: 2,
                                  "&:hover": {
                                    borderColor: "themeColor", // Change to your theme color
                                  },
                                }}
                              >
                                {T.NEXT}
                              </Button>
                            ) : (
                              <Button
                                variant="outlined"
                                onClick={handleSubmit}
                                disabled={storedVideoResponsesCount < 5 || audioData?.status === "pending" || formSubmitted}
                                sx={{
                                  borderColor: "themeColor",
                                  color: "black",
                                  fontWeight: "bold",
                                  borderRadius: 2,
                                  width: isXs ? "100%" : 100,
                                  fontSize: "15px",
                                  px: 1.4,
                                  py: 1.3,
                                  mb: 2,
                                  "&:hover": {
                                    backgroundColor: "themeColor", // Change to your theme color
                                    borderColor: NETSMARTZ_THEME_COLOR,
                                    color: "white",
                                  },
                                }}
                              >
                                {T.SUBMIT}
                              </Button>
                            )}
                          </Box>
                        </Grid>
                      );
                    }
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={isMd ? 2 : 3.25} />
        {openInstructionModal && quesStep === 0 && (
          <InstructionsModal
            openInstructionModal={openInstructionModal}
            micPermission={permission}
            handleInstructionModal={handleInstructionModal}
          />
        )}
      </Grid>
    </Grid>
  );
};

InterviewQuestions.propTypes = {
  boxHeight: PropTypes.number,
};
export default InterviewQuestions;
