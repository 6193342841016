import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    preInterviewNotify: build.mutation({
      query: ({ userId, presalesMappingId, formData }) => ({
        url: `/selected/user/PitchedUser/InterviewIntimation`,
        method: "POST",
        body: formData,
        params: { userId, presalesMappingId },
      }),
    }),
  }),
});

export const { usePreInterviewNotifyMutation } = extendedApi;
