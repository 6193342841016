import { api } from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getInsideSalesByFilter: build.mutation({
      query: ({ page, rowsPerPage, data }) => ({
        url: `insideSales/filter?page=${page}&size=${rowsPerPage}`,
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useGetInsideSalesByFilterMutation } = extendedApi;
