// rough component of casestudy
import { useEffect, useReducer } from "react";
import { Paper, Box, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TopBar from "components/CaseStudy/TopBar";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash.js";
import { isEqual } from "lodash";
import { useUpdateAcceptRejectCaseStudyMutation } from "api/CaseStudy/UpdateAcceptRejectCaseStudy";
import { caseStudyFilterStore } from "slices/caseStudyFilterSlice.js";
import { toast } from "react-toastify";
import T from "T.js";
import { useLazyGetReminderQuery } from "api/CaseStudy/ReminderButtonAPI.js";
import { useGetCaseStudyByFilterMutation } from "api/CaseStudy/getCaseStudyByFilter.js";
import AcceptRejectCaseStudy from "./CaseStudyProjects/AcceptRejectCaseStudy.js";
import CaseStudyUploadLinkModal from "./CaseStudyProjects/CaseStudyUploadLinkModal.js";
import AddEditCaseStudy from "./CaseStudyProjects/AddEditCaseStudy.js";
import CaseStudyProjects from "./CaseStudyProjects/index.js";
import CaseStudyUploadFileModal from "./CaseStudyProjects/CaseStudyUploadFileModal.js";
import usePMFetch from "hooks/usePMFetch.js";

const FULL_ROWS_PER_PAGE = 10000;

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const CaseStudy = () => {
  const { user } = MISCurrentUser();
  const dispatch = useDispatch();
  const { projectManagers } = usePMFetch();
  const userRole = get(user, "role", "");
  const userId = get(user, "id", "");

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    pId: "",
    filters: { projectManager: null, status: null },
    editId: "",
    skillEditId: "",
    locationEditId: "",
    projectId: "",
    skillId: "",
    comments: "",
    locationId: "",
    startDate: null,
    endDate: null,
    searchInput: "",
    pName: "",
    openAddEditCaseStudy: false,
    openCaseStudyLink: false,
    openCaseStudyUpload: false,
    role: "",
    openRejectCaseStudy: false,
    exportLoading: false,
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    CaseStudyTableData: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
  });

  const {
    pId,
    filters,
    editId,
    startDate,
    endDate,
    searchInput,
    pName,
    allTableRows,
    openRejectCaseStudy,
    openAddEditCaseStudy,
    openCaseStudyLink,
    page,
    rowsPerPage,
    CaseStudyTableData,
    openCaseStudyUpload,
    role,
  } = localState;

  const [UpdateAcceptRejectCaseStudy] = useUpdateAcceptRejectCaseStudyMutation();
  const [getReminder] = useLazyGetReminderQuery();
  const [getCaseStudyByFilter, { isFetching: isCaseStudyFetching }] = useGetCaseStudyByFilterMutation();

  const emptyFilters = { projectManager: null, status: null };

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "CaseStudyFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const { storedSearch } = useSelector(
    (state) => ({
      storedSearch: get(state, "CaseStudyFilterSlice.storedSearch", ""),
    }),
    shallowEqual,
  );

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);

  const projectManagersList = get(projectManagers, "results", []);

  useEffect(() => {
    setLocalState({
      filters: storedFilters,
      searchInput: storedSearch,
    });
    refreshTable(page, rowsPerPage, storedSearch, storedFilters);
  }, [page, rowsPerPage]);

  const refreshTable = (page, rowsPerPage, search, filters) => {
    getCaseStudyTableData(page, rowsPerPage, search, filters);
  };

  const getCaseStudyTableData = (page, rowsPerPage, search, filters) => {
    const caseStudyFilterRequestDto = {
      projectManagerId: get(filters, "projectManager.id", 0),
      caseStudyStatus: get(filters, "status", null),
      text: search,
    };
    getCaseStudyByFilter({ page, rowsPerPage, caseStudyFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({ CaseStudyTableData: { allTableRows, totalTableRowsCount, totalPageCount } });
      })
      .catch(handleError);
  };

  // ############################ Pagination Things ##############################
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      searchInput: searchValue,
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      dispatch(caseStudyFilterStore({ storedSearch: "", storedFilters: storedFilters }));
      refreshTable(page, rowsPerPage, searchValue, storedFilters);
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    dispatch(caseStudyFilterStore({ storedSearch: searchInput, storedFilters: storedFilters }));
    refreshTable(INITIAL_PAGE, rowsPerPage, searchInput, filters);
  };

  // ############################ Filter Things ###################################
  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };

  const handleFilterSubmit = () => {
    dispatch(caseStudyFilterStore({ storedSearch: storedSearch, storedFilters: filters }));
    refreshTable(page, rowsPerPage, storedSearch, filters);
  };

  const handleFilterClose = () => {
    dispatch(caseStudyFilterStore({ storedSearch: storedSearch, storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters });
    refreshTable(page, rowsPerPage, storedSearch, emptyFilters);
  };

  // ##################################################################################################
  const refreshOnChange = () => {
    refreshTable(page, rowsPerPage, storedSearch, storedFilters);
  };

  const handleAddEditCaseStudyDialog = (id = "") => {
    if (openAddEditCaseStudy) {
      refreshTable(page, rowsPerPage, storedSearch, storedFilters);
    }
    setLocalState({
      openAddEditCaseStudy: !openAddEditCaseStudy,
      editId: id,
    });
  };

  const handleCaseStudyUploadLinkDialog = (id = "", pName) => {
    setLocalState({
      openCaseStudyLink: !openCaseStudyLink,
      pName,
      pId: id,
    });
  };

  const handleOpenCaseStudyUpload = (id, publish) => {
    setLocalState({ openCaseStudyUpload: true, pId: id, role: publish ? T.KRA : userRole });
  };

  const handleCloseCaseStudyUpload = () => {
    setLocalState({ openCaseStudyUpload: false, pId: "", role: "" });
  };

  // const handleStatus = (pId = "") => {
  //   const payload = {
  //     caseStudyApproval: true,
  //   };
  //   UpdateAcceptRejectCaseStudy({ payload, pId })
  //     .unwrap()
  //     .then(() => {
  //       refreshTable(page, rowsPerPage, storedSearch, storedFilters);
  //       toast.success(T.CASE_STUDY_ACCEPTED);
  //     })
  //     .catch(handleError);
  // };

  const handleRejectCaseStudyDialog = (pId = "", type) => {
    if (type === "accept") {
      const payload = {
        caseStudyApproval: true,
      };
      UpdateAcceptRejectCaseStudy({ payload, pId })
        .unwrap()
        .then(() => {
          refreshTable(page, rowsPerPage, storedSearch, storedFilters);
          toast.success(T.CASE_STUDY_ACCEPTED);
        })
        .catch(handleError);
    }
  };

  const onClickReminder = (caseStudyId) => {
    getReminder(caseStudyId)
      .unwrap()
      .then((res) => {
        toast.success(T.REMINDER_SENT);
      })
      .catch(handleError);
  };

  return (
    <Paper display="block" justifyContent="flex-start" sx={{ borderRadius: 2 }}>
      {isCaseStudyFetching && <MISLoader />}
      <Typography variant="h5" fontWeight={600} mb={1} marginLeft={2} sx={{ pt: 2 }}>
        {T.CASE_STUDY_LIST_VIEW}
      </Typography>

      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
            padding: "4px",
          },
        }}
      >
        <TopBar
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          startDate={startDate}
          endDate={endDate}
          searchInput={searchInput}
          projectManagers={projectManagersList}
          handleKeyChange={handleSearchKeyChange}
          handleChange={handleSearchChange}
          handleFilterClose={handleFilterClose}
          handleFilterApply={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
          handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
        />

        <CaseStudyProjects
          allTableRows={CaseStudyTableData.allTableRows}
          totalTableRowsCount={CaseStudyTableData.totalTableRowsCount}
          totalPageCount={CaseStudyTableData.totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          pName={pName}
          refreshTable={refreshOnChange}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
          handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
          handleOpenCaseStudyUpload={handleOpenCaseStudyUpload}
          handleRejectCaseStudyDialog={handleRejectCaseStudyDialog}
          onClickReminder={onClickReminder}
        />
      </Box>

      <AddEditCaseStudy
        allTableRows={CaseStudyTableData.allTableRows}
        editId={editId}
        refreshTable={refreshOnChange}
        openAddEditCaseStudy={openAddEditCaseStudy}
        handleAddEditCaseStudyDialog={handleAddEditCaseStudyDialog}
      />

      {/* <AcceptRejectCaseStudy
        pId={pId}
        openRejectCaseStudy={openRejectCaseStudy}
        refreshView={refreshOnChange}
        refreshTable={refreshOnChange}
        handleRejectCaseStudyDialog={handleRejectCaseStudyDialog}
      /> */}

      <CaseStudyUploadLinkModal
        pId={pId}
        refreshTable={refreshOnChange}
        openCaseStudyLink={openCaseStudyLink}
        pName={pName}
        handleCaseStudyUploadLinkDialog={handleCaseStudyUploadLinkDialog}
      />
      <CaseStudyUploadFileModal
        pId={pId}
        userRole={role}
        refreshTable={refreshOnChange}
        openCaseStudyUpload={openCaseStudyUpload}
        handleCloseCaseStudyUpload={handleCloseCaseStudyUpload}
      />
    </Paper>
  );
};

export default CaseStudy;
