import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import SwotTableBody from "./SwotTableBody";
import { noop } from "lodash";
import MISNoData from "components/common/MISNoData";

const SwotListTable = ({
  tableRecord = null,
  handleEditSWOT = noop,
  // handleDeleteSWOT = noop,
  // handleDeleteModal = noop,
  handleCloseModal = noop,
  handleAssignedCourseDialog = noop,
}) => (
  <Card
    elevation={0}
    sx={{
      p: "0px 8px",
      mt: 1.5,
      backgroundColor: "background.card",
      "& .add-shadow": {
        boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
      },
    }}
  >
    <Box overflow="hidden">
      <TableContainer
        sx={{
          height: "calc(100vh - 200px)",
          overflow: "auto",
        }}
      >
        {tableRecord?.length > 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            {/* <SwotTableHeader columns={MEMBER_SWOT_TABLE} /> */}
            <SwotTableBody
              tableRecord={tableRecord}
              handleEditSWOT={handleEditSWOT}
              // handleDeleteSWOT={handleDeleteSWOT}
              // handleDeleteModal={handleDeleteModal}
              handleCloseModal={handleCloseModal}
              handleAssignedCourseDialog={handleAssignedCourseDialog}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Box>
  </Card>
);

SwotListTable.propTypes = {
  tableRecord: PropTypes.object,
  handleEditSWOT: PropTypes.func,
  handleDeleteModal: PropTypes.func,
  handleCloseModal: PropTypes.func,
  handleDeleteSWOT: PropTypes.func,
  handleAssignedCourseDialog: PropTypes.func,
};
export default SwotListTable;
