import PropTypes from "prop-types";
import { Table as TableView, TableContainer, Box, Card, Typography } from "@mui/material";

import { MEMBER_FEEDBACK_TABLE } from "settings/constants/members";

import T from "T";
import FeedbackHistoryBody from "./FeedbackHistoryTablebody";
import FeedbackHistoryHeader from "./FeedbackHistoryHeader";
import { BACKGROUND } from "theme/colors";
import MISNoData from "components/common/MISNoData";

const FeedbackHistory = ({ tableRecord = [] }) => (
  <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
    <Typography fontSize={17} fontWeight={600} pb={1}>
      {T.FEEDBACK_HISTORY}
    </Typography>
    <Box overflow="hidden">
      <TableContainer
        sx={{
          overflowY: "auto",
          height: 220,
        }}
      >
        {tableRecord.length !== 0 ? (
          <TableView stickyHeader>
            <FeedbackHistoryHeader columns={MEMBER_FEEDBACK_TABLE} />
            <FeedbackHistoryBody tableRecord={tableRecord} />
          </TableView>
        ) : (
          <Box>
            <MISNoData />
          </Box>
        )}
      </TableContainer>
    </Box>
  </Card>
);

FeedbackHistory.propTypes = {
  tableRecord: PropTypes.array,
};
export default FeedbackHistory;
