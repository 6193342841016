import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    deleteShortlistedCandidate: build.mutation({
      query: ({ presaleMapping_id, id }) => ({
        url: `/selected/user/delete?presaleMapping_id=${presaleMapping_id}&userId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const { useDeleteShortlistedCandidateMutation } = extendedApi;
