import { useEffect, useReducer } from "react";
import { Box, Tab, Paper, styled, Typography } from "@mui/material";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { PAGINATION } from "settings/constants/pagination";
import Constants from "Constants";
import T from "T";
import { handleError } from "utils/error";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { get, isEqual, orderBy } from "lodash";
import { useLazyGetSkillListQuery } from "api/skills/getSkillList";
import { useLazyGetPreSalesManagersQuery } from "api/preSales/getPreSalesManagers";
import { useLazyGetProjectManagerQuery } from "api/projects/getProjectManager";
import { preSalesFilterStore } from "slices/preSalesFilterSlice";
import { format, isValid } from "date-fns";
import { BACKEND_DATE_FORMAT } from "settings/constants/date";
import { useGetPreSalesByFilterMutation } from "api/preSales/getPreSalesByFilter";
import { preSalesSearchStore } from "slices/preSalesSearchSlice";
import { MISCurrentUser } from "utils/validations";
import { downloadFile } from "utils/file";
import TopBar from "./Topbar";
import PreSalesTab from "./PresSalesTab";
import DeletePreSalesProject from "./DeletePreSalesProject";
import EditPreSalesProjectInfo from "./EditPreSalesProjectInfo";
import ViewClientDialog from "./ViewClient/ViewClientDialog";
import { psCardStore } from "slices/preSalesCardSlice";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;
const FULL_ROWS_PER_PAGE = 10000;
const emptyFilters = {
  preSalesStatus: "",
  projectManagerName: "",
  salesManagerName: "",
  skills: [],
};
export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.background.black,
  minHeight: "unset",
  border: `1px solid ${theme.palette.border.tabsGrey}`,
}));

const PreSales = () => {
  const getBEDateFormat = (val) => format(val, BACKEND_DATE_FORMAT);
  const { storedScrollPosition } = useSelector(
    (state) => ({
      storedScrollPosition: get(state, "PSCardSlice.scrollPosition", 0),
    }),
    shallowEqual,
  );
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    openEditProjectDialog: false,
    openDeletePreSalesProject: false,
    tableData: [],
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    openJd: false,
    searchInput: "",
    jobDescription: {},
    filters: {},
    showCancelIcon: false,
    startDate: null,
    endDate: null,
    openDialog: false,
    scrollPosition: storedScrollPosition,
  });

  const {
    openEditProjectDialog,
    openDeletePreSalesProject,
    tableData,
    page,
    rowsPerPage,
    openJd,
    searchInput,
    openDialog,
    filters,
    showCancelIcon,
    startDate,
    endDate,
    scrollPosition,
  } = localState;

  const { SERVER_URL } = Constants;
  const { sessionToken } = MISCurrentUser();
  const dispatch = useDispatch();
  const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(tableData);
  const [getSkillList, { data: skillList }] = useLazyGetSkillListQuery();
  const [getPreSalesManagers, { data: preSalesManagersList }] = useLazyGetPreSalesManagersQuery();
  const [getProjectManager, { data: projectManagers }] = useLazyGetProjectManagerQuery();
  const [getPreSalesByFilter] = useGetPreSalesByFilterMutation();
  const projectManagersList = get(projectManagers, "results", []);

  useEffect(() => {
    getSkillList({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE });
    getProjectManager({ page: INITIAL_PAGE, rowPerPage: FULL_ROWS_PER_PAGE });
    getPreSalesManagers();
  }, []);

  useEffect(() => {
    if (!isFilterEmpty && preSalesManagersList && projectManagersList) {
      setLocalState({ filters: loadFetchedFilters(storedFilters) });
    }
    if (storedSearchInput) {
      setLocalState({ searchInput: storedSearchInput });
    }
  }, [tableData]);
  useEffect(() => {
    if (searchInput === "" && storedSearchInput === "") {
      setTimeout(() => {
        getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage, storedFilters);
      }, 100);
    }
  }, [searchInput]);
  useEffect(() => {
    if (startDate || endDate) {
      getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    if (storedSearchInput) {
      getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage, storedFilters);
    }
  }, []);

  const companyNamesSet = new Set(allTableRows.map((item) => item.companyName));
  const companyNames = [...companyNamesSet];

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "PreSalesFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  const { storedSearchInput } = useSelector(
    (state) => ({
      storedSearchInput: get(state, "PreSalesSearchSlice.storedSearchInput", ""),
    }),
    shallowEqual,
  );

  const isFilterEmpty = isEqual(storedFilters, {}) || isEqual(storedFilters, emptyFilters);
  // Edit requirement handler
  const handleEditProjectInfoDialog = () => {
    setLocalState({ openEditProjectDialog: !openEditProjectDialog });
  };

  // Delete requirement handler
  const handleDeleteProjectDialog = () => {
    setLocalState({ openDeletePreSalesProject: !openDeletePreSalesProject });
  };

  // Page change handler
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });

    getPreSalesFilterTableData(newPage, rowsPerPage, storedFilters);
    dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));

    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
    getPreSalesFilterTableData(INITIAL_PAGE, value, storedFilters);
    dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
  };
  const handleJdDialog = (jdData) => {
    setLocalState({ openJd: !openJd, jobDescription: jdData });
  };

  const handleDaysSorting = () => {
    // orderBy()
  };

  // Search Enter button handler
  const handleSearchKeyChange = () => {
    dispatch(preSalesSearchStore({ storedSearchInput: searchInput }));
    dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
    getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage, storedFilters);
  };

  // Search Input handler(Icon click handler)
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");

    if (searchValue === "") {
      dispatch(preSalesSearchStore({ storedSearchInput: searchValue }));
      dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));

      getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage, storedFilters);
    }

    setLocalState({
      showOptions: event.key !== "Enter",
      showCancelIcon: searchValue !== "",
      searchInput: searchValue,
    });

    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      dispatch(preSalesSearchStore({ storedSearchInput: searchValue }));
      getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage, storedFilters);
    }
  };

  // Date change handler
  const onHandleDateChange = (newValue, type) => {
    const validDate = newValue ? new Date(newValue) : null;
    setLocalState({
      [type]: validDate && isValid(validDate) ? getBEDateFormat(validDate) : null,
    });
    dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
  };

  // Filter submit handler
  const handleFilterSubmit = () => {
    dispatch(preSalesFilterStore({ storedFilters: {} }));
    dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
    setLocalState({ scrollPosition: 0 });
    getPreSalesFilterTableData(INITIAL_PAGE, rowsPerPage, storedFilters);
  };
  // Filter reset handler
  const handleFilterClose = () => {
    dispatch(preSalesFilterStore({ storedFilters: {} }));
    setLocalState({
      filters: {},
      searchInput: "",
      startDate: null,
      endDate: null,
    });
    dispatch(preSalesSearchStore({ storedSearchInput: "" }));
    dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
    setLocalState({ scrollPosition: 0 });
    getPreSalesFilterTableData(page, rowsPerPage, emptyFilters);
  };

  // Filter change handler
  const onhandleFilterChange = (newValue, item) => {
    if (newValue === null) {
      newValue = "";
    }
    dispatch(preSalesFilterStore({ storedFilters: {} }));
    filters[item] = newValue;
    setLocalState({ filters });
  };

  // get Filtered data
  const getPreSalesFilterTableData = (page, rowsPerPage, appliedFilters) => {
    const payload = getFilterPayload(appliedFilters);
    const filtersCopy = {
      preSalesStatus: get(payload, "preSalesStatus", ""),
      projectManagerName: get(payload, "projectManagerName", ""),
      salesManagerName: get(payload, "salesManagerName", ""),
      skills: get(payload, "skills", []),
    };
    dispatch(preSalesFilterStore({ storedFilters: filtersCopy }));
    getPreSalesByFilter({
      page,
      rowPerPage: rowsPerPage,
      preSalesFilterRequestDto: payload,
    })
      .unwrap()
      .then((res) => {
        setLocalState({
          tableData: res,
          page,
        });
      })
      .catch(handleError);
  };

  // Filter payload
  const getFilterPayload = (appliedFilters) => {
    if (!isFilterEmpty && storedSearchInput) {
      const payload = {
        ...appliedFilters,
        startDate,
        endDate,
        text: storedSearchInput,
      };
      return payload;
    }
    if (isFilterEmpty && storedSearchInput) {
      const payload = {
        startDate,
        endDate,
        skills: get(filters, `[${T.TECHNOLOGY}]`, []).map((data) => data.skillName),
        salesManagerName: get(get(filters, T.OS_MANAGER, {}), "name", ""),
        projectManagerName: get(get(filters, T.ASSIGNED_TO, {}), "name", ""),
        preSalesStatus: get(filters, T.STATUS, ""),
        text: storedSearchInput,
      };
      return payload;
    }
    if (!isFilterEmpty) {
      const payload = {
        ...appliedFilters,
        startDate,
        endDate,
        text: searchInput,
      };
      return payload;
    }
    const payload = {
      startDate,
      endDate,
      skills: get(filters, `[${T.TECHNOLOGY}]`, []).map((data) => data.skillName),
      salesManagerName: get(get(filters, T.OS_MANAGER, {}), "name", ""),
      projectManagerName: get(get(filters, T.ASSIGNED_TO, {}), "name", ""),
      preSalesStatus: get(filters, T.STATUS, ""),
      text: searchInput,
    };
    return payload;
  };

  // Fetch filters from store
  const loadFetchedFilters = (records) => {
    filters[T.TECHNOLOGY] = get(skillList, "results", []).filter((rec) => get(records, "skills", []).includes(rec.skillName));

    filters[T.OS_MANAGER] = preSalesManagersList.find((rec) => get(records, "salesManagerName", "") === rec.name);

    filters[T.ASSIGNED_TO] = get(projectManagers, "results", []).find(
      (rec) => get(records, "projectManagerName", "") === rec.name,
    );

    filters[T.STATUS] = get(records, "preSalesStatus", "");

    return filters;
  };

  const handleExport = async (type) => {
    const url = `${SERVER_URL}/preSales/export`;

    setLocalState({ exportLoading: true });

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${sessionToken}`,
      },
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, type);
        setLocalState({ exportLoading: false });
      })
      .catch(handleError);
  };
  const handleViewClientDialog = (record) => {
    setLocalState({ openDialog: !openDialog, record: record });
  };
  const handleSetScrollPosition = (updatedScrollPosition) => {
    setLocalState({ scrollPosition: updatedScrollPosition });
  };

  return (
    <Paper display="block" justifycontent="flex-start" sx={{ borderRadius: 2, p: 2 }}>
      <Typography variant="h5" fontWeight={600} mb={1}>
        {T.PRE_SALES_PROJECT_LIST_VIEW}
      </Typography>
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      >
        <TopBar
          startDate={startDate}
          endDate={endDate}
          filters={filters}
          searchInput={searchInput}
          skillList={skillList}
          preSalesManagersList={preSalesManagersList}
          projectManagers={projectManagers}
          companyNames={companyNames}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClose={handleFilterClose}
          showCancelIcon={showCancelIcon}
          isFilterEmpty={isFilterEmpty}
          handleExport={handleExport}
          handleKeyChange={handleSearchKeyChange}
          handleChange={handleSearchChange}
          onHandleDateChange={onHandleDateChange}
        />

        <PreSalesTab
          allTableRows={allTableRows}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          handleJdDialog={handleJdDialog}
          handleDaysSorting={handleDaysSorting}
          openDialog={openDialog}
          handleViewClientDialog={handleViewClientDialog}
          handleDeleteProjectDialog={handleDeleteProjectDialog}
          scrollPosition={scrollPosition}
          handleSetScrollPosition={handleSetScrollPosition}
        />

        <EditPreSalesProjectInfo
          openEditProjectDialog={openEditProjectDialog}
          handleEditProjectInfoDialog={handleEditProjectInfoDialog}
        />

        <DeletePreSalesProject
          openDeletePreSalesProject={openDeletePreSalesProject}
          handleDeleteProjectDialog={handleDeleteProjectDialog}
        />
        <ViewClientDialog record={localState.record} handleViewClientDialog={handleViewClientDialog} openDialog={openDialog} />
      </Box>
    </Paper>
  );
};

export default PreSales;
