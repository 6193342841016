import { Typography, Box } from "@mui/material";
import T from "T";

const ProfilePage = () => (
  <Box display="flex" justifyContent="space-between">
    <Typography variant="h4">{`${T.PROFILE} ${T.PAGE}`}</Typography>
  </Box>
);

export default ProfilePage;
