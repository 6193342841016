import PropTypes from "prop-types";
import { noop } from "lodash";
import { Table as TableView, TableContainer, Box, Card } from "@mui/material";
import MISNoData from "components/common/MISNoData";
import T from "T";
import DashboardTableHeader from "./DashboardTableHeader";
import DashboardTableBody from "./DashboardTableBody";
const DashboardTable = ({ dashboardData = [], profitCenterList = [], toolsList = [], handleLicenseDetail = noop }) => {
  const getProfitCenterLabel = (data) => {
    return { label: data.profitCenterName.trim() };
  };
  const TOOLS_DASHBOARD_TABLE_HEADER = [
    { label: T.TOOLS_NAME },
    { label: T.TOTAL },
    ...profitCenterList.map(getProfitCenterLabel),
    { label: T.MONTHLY_COST },
    { label: T.QUARTERLY_COST },
    { label: T.BI_ANNUALLY_COST },
    { label: T.YEARLY_COST },
  ];

  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <TableContainer
        sx={{
          height: "calc(100vh - 243px)",
          overflow: "auto",
        }}
      >
        {dashboardData.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <DashboardTableHeader columns={TOOLS_DASHBOARD_TABLE_HEADER} />
            <DashboardTableBody
              dashboardData={dashboardData}
              profitCenterList={profitCenterList}
              toolsList={toolsList}
              handleLicenseDetail={handleLicenseDetail}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>
    </Card>
  );
};
DashboardTable.propTypes = {
  dashboardData: PropTypes.array,
  profitCenterList: PropTypes.array,
};

export default DashboardTable;
