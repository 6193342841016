import api from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitVideoResponseScreenshot: build.mutation({
      query: (screenShotDetails) => ({
        url: `/videoInterview/save/videoInterviewScreenshot`,
        method: "POST",
        body: screenShotDetails,
      }),
    }),
  }),
});

export const { useSubmitVideoResponseScreenshotMutation } = extendedApi;
