import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";
const CostCenterMutation = ({
  addEditCostCenter = {},
  isAddEditCostCenterOpen = bool,
  onHandleChange = noop,
  handleClose = noop,
  handleSubmitCostCenter = noop,
}) => {
  return (
    <Dialog onClose={handleClose} open={isAddEditCostCenterOpen}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {get(addEditCostCenter, "costCenterHeadName", "")
              ? `${T.EDIT} ${get(addEditCostCenter, "costCenterHeadName", "")}`
              : `${T.ADD} ${T.NEW} ${T.COST_CENTER}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <MISTextField
          label={T.COST_CENTER}
          required
          placeholder={T.COST_CENTER}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="costCenterName"
          value={get(addEditCostCenter, "costCenterName", "")}
          onChange={(e) => onHandleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={addEditCostCenter.costCenterId ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={handleClose}
          handleSubmit={
            addEditCostCenter.costCenterId
              ? () => handleSubmitCostCenter(addEditCostCenter.costCenterId)
              : () => handleSubmitCostCenter()
          }
        />
      </DialogActions>
    </Dialog>
  );
};

CostCenterMutation.propTypes = {
  addEditCostCenter: PropTypes.object,
  isAddEditCostCenterOpen: PropTypes.bool,
  onHandleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmitCostCenter: PropTypes.func,
};

export default CostCenterMutation;
