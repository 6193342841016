import { ArrowBack, Download } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Paper,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@mui/material";
import T from "T";
import MISButton from "components/common/MISButton";
import PropTypes from "prop-types";
import { GET_SIZE } from "utils/responsive";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import { get, noop } from "lodash";
import UploadIcon from "@mui/icons-material/Upload";
import MISNoData from "components/common/MISNoData";
import { FRONTEND_DATE_FORMAT } from "settings/constants/date";
import { format, isValid, parse } from "date-fns";
import { downloadContractFile } from "components/ISTools/toolsModel";
import Constants from "Constants";
import { MISCurrentUser } from "utils/validations";
import { handleError } from "utils/error";
const { SERVER_URL } = Constants;

const ContractHistoryTable = ({
  columns = [],
  licenseData = {},
  contractHistoryData = [],
  isOpenContractHistory = bool,
  handleClose = noop,
  handleUploadContract = noop,
  handleOpenConfirmDelete = noop,
}) => {
  const { sessionToken } = MISCurrentUser();
  const { isMd, isLg } = GET_SIZE();
  const extractFilename = (path) => {
    const regex = /([^/\\]+)_[\da-f]{8}-[\da-f]{4}-[\da-f]{4}-[\da-f]{4}-[\da-f]{12}\.(doc|pdf|docx)$/;
    const match = path.match(regex);
    return match ? match[1] + "." + match[2] : null;
  };

  const formatDate = (val) => {
    const [datePart] = val.split(" ");
    const [day, month, year] = datePart.split("-");
    const formattedDateStr = `${year}-${month}-${day}`;
    const parsedDate = parse(formattedDateStr, "yyyy-MM-dd", new Date());
    return format(parsedDate, FRONTEND_DATE_FORMAT);
  };

  const handleDownloadContract = async (id) => {
    const url = `${SERVER_URL}/tools/license/download/history?contractId=${id}`;
    const headers = {
      Authorization: `Bearer ${sessionToken}`,
      "Content-Type": "application/json",
    };
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: headers,
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      downloadContractFile(response);
      toast.success(T.FILE_DOWNLOADED_SUCCESSFULLY);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Dialog onClose={handleClose} open={isOpenContractHistory} maxWidth={isLg ? "lg" : isMd ? "md" : "sm"}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {`${get(licenseData, "toolsId.toolsName", "")} ${T.CONTRACT_HISTORY}`}
          </Box>
          <input
            name="contractFileName"
            style={{
              display: "none",
            }}
            type="file"
            // key={contractFileName}
            onChange={(e) => handleUploadContract(e)}
            id="contractFileInput"
          />
          <label htmlFor="contractFileInput">
            <Button
              variant="contained"
              component="span"
              style={{
                backgroundColor: NETSMARTZ_THEME_COLOR,
                color: "white",
                marginTop: 10,
                textTransform: "none",
                borderRadius: "5px",
              }}
              startIcon={<UploadIcon />}
            >
              {T.UPLOAD_FILE}
            </Button>
          </label>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <TableContainer component={Paper} sx={{ maxHeight: "calc(100svh - 350px)", overflow: "auto" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.length &&
                  columns.map((column) => {
                    return <TableCell key={column.key}>{column.label}</TableCell>;
                  })}
              </TableRow>
            </TableHead>
            {contractHistoryData.length !== 0 ? (
              <TableBody>
                {contractHistoryData.map((contract, index) => {
                  return (
                    <TableRow key={contract.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      {columns.map((column) => (
                        <TableCell key={`${contract.id}-${column.key}`}>
                          {column.key === "actions" ? (
                            <>
                              <IconButton
                                sx={{ color: NETSMARTZ_THEME_COLOR }}
                                onClick={() => {
                                  handleDownloadContract(contract.id);
                                }}
                              >
                                <Download />
                              </IconButton>
                              <IconButton
                                sx={{ color: NETSMARTZ_THEME_COLOR }}
                                onClick={() => {
                                  handleOpenConfirmDelete(contract.id);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </>
                          ) : column.key === "id" ? (
                            index + 1
                          ) : column.key === "contractPath" ? (
                            extractFilename(contract[column.key])
                          ) : column.key === "createdAt" ? (
                            formatDate(contract[column.key])
                          ) : (
                            contract[column.key]
                          )}
                        </TableCell>
                      ))}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : (
              <MISNoData />
            )}
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

ContractHistoryTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  contractHistoryData: PropTypes.arrayOf(PropTypes.object),
  isOpenContractHistory: PropTypes.bool,
  handleClose: PropTypes.func,
  handleOpenConfirmDelete: PropTypes.func,
};

export default ContractHistoryTable;
