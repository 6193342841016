import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { IconButton, DialogActions } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import T from "T";
import { noop } from "lodash";
import PropTypes from "prop-types";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import { Box } from "@mui/system";
import WithInputLabel from "components/common/WithInputLabel";
import MISFooterButton from "components/common/MISFooterButton";

const Form = ({
  handleToggleDialog = noop,
  openModal,
  companyName,
  onHandleChange = noop,
  skillList,
  skills,
  formData,
  location,
  onHandleAutoCompleteChange = noop,
  handleSubmit = noop,
  minEmp,
  maxEmp,
  tableData,
}) => {
  return (
    <>
      <Dialog open={openModal} fullWidth>
        <DialogTitle
          sx={{
            fontFamily: "Arial, sans-serif",
            fontWeight: 600,
            backgroundColor: BACKGROUND.cardDefault,
            display: "flex ",
            alignItems: "center",
            pt: 2,
            pb: 1,
          }}
        >
          <IconButton fontSize="small" sx={{ cursor: "pointer" }} onClick={handleToggleDialog}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" fontWeight={600}>
            {formData ? `${T.EDIT} ${T.COMPANY_DETAILS}` : `${T.ADD} ${T.COMPANY_DETAILS}`}
          </Typography>
        </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <MISTextField
            label={T.COMPANY_NAME}
            required
            fullWidth
            placeholder={T.COMPANY_NAME}
            autoComplete="on"
            size="small"
            variant="outlined"
            name="companyName"
            value={companyName}
            onChange={(e) => onHandleChange(e)}
            helperText={
              formData ? (
                ""
              ) : tableData.some((it) => it.companyName.toLowerCase() === companyName.toLowerCase()) ? (
                <span style={{ color: "red" }}>{T.COMPANY_ALREADY_EXISTS}</span>
              ) : (
                ""
              )
            }
          />
          <Box mt={2}>
            <MISAutocomplete
              label={T.TECH_STACK}
              required
              multiple
              listDetails={skillList}
              value={skills}
              getByLabelText={(option) => get(option, "skillName", "")}
              onHandleChange={(event, newValue) => onHandleAutoCompleteChange(newValue, "skills")}
            />
          </Box>

          <Box display="flex" mt={2}>
            <Box pt={1} mr={2}>
              <WithInputLabel required label={T.NO_OF_EMPLOYEES} />
            </Box>
            <Box display={"flex"} justifyContent={"space-around"}>
              <MISTextField
                required
                placeholder={T.MIN}
                size="small"
                variant="outlined"
                name="minEmp"
                InputProps={{ type: "number" }}
                value={minEmp}
                sx={{ width: 60 }}
                onChange={(e) => onHandleChange(e)}
              />
              <MISTextField
                required
                placeholder={T.MAX}
                size="small"
                variant="outlined"
                name="maxEmp"
                InputProps={{ type: "number" }}
                value={maxEmp}
                sx={{ ml: 2, width: 60 }}
                onChange={(e) => onHandleChange(e)}
              />
            </Box>
          </Box>

          <Box mt={1}>
            <MISTextField
              required
              label={"Location"}
              fullWidth
              placeholder={"Location"}
              autoComplete="on"
              size="small"
              variant="outlined"
              name="location"
              value={location}
              onChange={(e) => onHandleChange(e)}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 2.8, pb: 2 }}>
          <MISFooterButton proceedButtonText="Submit" handleClose={handleToggleDialog} handleSubmit={handleSubmit} />
        </DialogActions>
      </Dialog>
    </>
  );
};

Form.propTypes = {
  handleToggleDialog: PropTypes.func,
  openModal: PropTypes.bool,
  skillList: PropTypes.any,
  skills: PropTypes.array,
  location: PropTypes.string,
  companyName: PropTypes.string,
  onHandleChange: PropTypes.func,
  onHandleAutoCompleteChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  formData: PropTypes.any,
  minEmp: PropTypes.any,
  maxEmp: PropTypes.any,
  tableData: PropTypes.array,
};

export default Form;
