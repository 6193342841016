import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    scheduleMockInterview: build.mutation({
      query: ({ userId, presalesMappingId, formData }) => ({
        url: `/selected/user/PitchedUser/scheduleMockInterview`,
        method: "POST",
        body: formData,
        params: { userId, presalesMappingId },
      }),
    }),
  }),
});

export const { useScheduleMockInterviewMutation } = extendedApi;
