import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";

const ToolsMutation = ({
  addEditTools = {},
  isAddEditToolsOpen = bool,
  onHandleChange = noop,
  handleClose = noop,
  handleSubmitTools = noop,
}) => {
  return (
    <Dialog onClose={handleClose} open={isAddEditToolsOpen}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {get(addEditTools, "toolsHeadName", "")
              ? `${T.EDIT} ${get(addEditTools, "toolsHeadName", "")}`
              : `${T.ADD} ${T.NEW} ${T.TOOLS_NAME}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <MISTextField
          label={T.TOOLS_NAME}
          required
          placeholder={T.TOOLS_NAME}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="toolsName"
          value={get(addEditTools, "toolsName", "")}
          onChange={(e) => onHandleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={addEditTools.toolsId ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={handleClose}
          handleSubmit={addEditTools.toolsId ? () => handleSubmitTools(addEditTools.toolsId) : () => handleSubmitTools()}
        />
      </DialogActions>
    </Dialog>
  );
};

ToolsMutation.propTypes = {
  addEditTools: PropTypes.object,
  isAddEditToolsOpen: PropTypes.bool,
  onHandleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmitTools: PropTypes.func,
};

export default ToolsMutation;
