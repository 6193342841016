import api from "api";

const extendedApi = api.injectEndpoints({
	endpoints: (builder) => ({
		deleteToolType: builder.mutation({
			query: (id) => ({
				url: `/toolType/delete?toolTypeId=${id}`,
				method: "DELETE",
			}),
		}),
	}),
});

export const { useDeleteToolTypeMutation } = extendedApi;
