import { memo, useEffect, useReducer } from "react";
import cloneDeep from "lodash/cloneDeep";
import { RECOMMENDED_COLUMNS } from "settings/constants/members";
import T from "T";
import Table from "components/PreSales/RecommendedMembers/Table";
import PropTypes from "prop-types";
import { noop } from "lodash";
const INITIAL_PAGE = 0;
const ROWS_PER_PAGE = 10000;

const RecommendedMembers = ({
  jdId,
  recommendedUserIds,
  allTableRows,
  getRecommendedMembers = noop,
  getSelectedMembers = noop,
}) => {
  // useUpdateAccessFetch(); //setting access permissions in state

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    isOpenConfigurator: false,
    configuratorColumns: cloneDeep(RECOMMENDED_COLUMNS),
    pastConfiguratorColumns: cloneDeep(RECOMMENDED_COLUMNS),
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    tableData: [],
    shortListedChecks: [],
    selectedMembers: [],
    selectAllCheck: false,
    recommendedUsers: [],
    updatedAllTableRows: [],
  });

  const {
    pastConfiguratorColumns,
    page,
    rowsPerPage,
    shortListedChecks,
    selectedMembers,
    selectAllCheck,
    recommendedUsers,
    totalTableRowsCount,
    totalPageCount,
    updatedAllTableRows,
  } = localState;

  useEffect(() => {
    const updatedArr = allTableRows.map((row) => ({
      ...row,
      shortlistedchecks: recommendedUserIds.includes(row.id) ? true : false,
    }));

    const shortListedChecksArr = updatedArr.map((row) => ({
      label: row.id,
      isChecked: recommendedUserIds.includes(row.id),
    }));

    const selectAllChecked = shortListedChecksArr.every((record) => record.isChecked);

    setLocalState({
      updatedAllTableRows: updatedArr,
      recommendedUsers: recommendedUserIds,
      shortListedChecks: shortListedChecksArr,
      selectedMembers: recommendedUserIds,
      selectAllCheck: selectAllChecked,
    });
  }, [jdId, recommendedUserIds]);

  const handleShortListCheckBoxChange = (event, index) => {
    const { name, checked } = event.target;
    if (name === T.SELECT_ALL) {
      const updatedShortListedChecks = [...shortListedChecks].map((record) => {
        return recommendedUsers.includes(record.label)
          ? { ...record }
          : {
              ...record,
              isChecked: checked,
            };
      });
      setLocalState({
        shortListedChecks: updatedShortListedChecks,
        selectAllCheck: checked,
        selectedMembers: updatedShortListedChecks.filter((item) => item.isChecked).map((data) => data.label),
      });
    } else {
      const updatedShortListedChecks = [...shortListedChecks];
      updatedShortListedChecks[index].isChecked = checked;
      const selectAllChecked = updatedShortListedChecks.every((record) => record.isChecked);
      setLocalState({
        shortListedChecks: updatedShortListedChecks,
        selectAllCheck: selectAllChecked,
        selectedMembers: updatedShortListedChecks.filter((item) => item.isChecked).map((data) => data.label),
      });
    }
  };

  return (
    <Table
      columns={pastConfiguratorColumns.filter((column) => column.checked)}
      lockedColumns={pastConfiguratorColumns.filter((column) => column.locked && column.checked)}
      jdId={jdId}
      page={page}
      rowsPerPage={rowsPerPage}
      totalTableRowsCount={totalTableRowsCount}
      shortListedChecks={shortListedChecks}
      totalPageCount={totalPageCount}
      selectAllCheck={selectAllCheck}
      selectedMembers={selectedMembers}
      recommendedUsers={recommendedUsers}
      handleShortListCheckBoxChange={handleShortListCheckBoxChange}
      allTableRows={updatedAllTableRows}
      getRecommendedMembers={getRecommendedMembers}
      getSelectedMembers={getSelectedMembers}
    />
  );
};

RecommendedMembers.propTypes = {
  recommendedUserIds: PropTypes.array,
  jdId: PropTypes.number,
  allTableRows: PropTypes.array,
  getRecommendedMembers: PropTypes.func,
  getSelectedMembers: PropTypes.func,
};

export default memo(RecommendedMembers);
