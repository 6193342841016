import { Children } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { TableRow, TableCell, TableBody, styled } from "@mui/material";
import { BACKGROUND } from "theme/colors";
import { get } from "utils/lodash";
import memo from "utils/react";
import { GET_SIZE } from "utils/responsive";
import { getMemberColumnData, handleHeaderClass } from "utils/shortlistedMembers";
import MISNoData from "components/common/MISNoData";
import Constants from "Constants";
import { handleError } from "utils/error";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 0px 8px 24px",
}));

const MISTableBody = ({
  columns = [],
  lockedColumns = [],
  records = [],
  handleUnshortlist = noop,
  handleFileUpload = noop,
  handlePitchCandidate = noop,
}) => {
  const { isXs } = GET_SIZE();
  const { SERVER_URL } = Constants;

  const handleExport = (email) => {
    const url = `${SERVER_URL}/user/download/profileDocFile?userEmail=${email}`;
    try {
      window.location.href = url;
    } catch (error) {
      handleError(error);
    }
  };
  return (
    <TableBody>
      {Children.toArray(
        records.map((record) => (
          <TableRow sx={{ background: BACKGROUND.white }}>
            {Children.toArray(
              columns.map((column, index) => {
                const colKey = get(column, "columnKey", "");

                const { columnData } = getMemberColumnData(
                  record,
                  isXs,
                  colKey,
                  handleUnshortlist,
                  handleFileUpload,
                  handleExport,
                );
                const isRowLocked = get(column, "locked", false);

                const isSticky = index < 2;
                return (
                  <StyledTableCell
                    sx={{
                      position: isSticky ? "sticky" : "inherit",
                      left: isSticky ? `${index * 72}px` : "inherit",
                      zIndex: isSticky ? 2 : "inherit",
                      backgroundColor: "white",
                    }}
                    className={handleHeaderClass(index, isRowLocked, lockedColumns)}
                  >
                    {columnData}
                  </StyledTableCell>
                );
              }),
            )}
          </TableRow>
        )),
      )}
      {records && records.length === 0 && (
        <TableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </TableRow>
      )}
    </TableBody>
  );
};

MISTableBody.propTypes = {
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
  records: PropTypes.array,
  handleUnshortlist: PropTypes.func,
  handleFileUpload: PropTypes.func,
  handleExport: PropTypes.func,
};

export default memo(MISTableBody);
