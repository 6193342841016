import api from "api";
const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    submitMockVideoResponseScreenshot: build.mutation({
      query: (screenShotDetails) => ({
        url: `/mockVideoInterview/save/videoInterviewScreenshot`,
        method: "POST",
        body: screenShotDetails,
      }),
    }),
  }),
});

export const { useSubmitMockVideoResponseScreenshotMutation } = extendedApi;
