// rough component of casestudy
import { useEffect, useReducer } from "react";
import { Paper, Box, Typography } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
// import TopBar from "components/CaseStudy/TopBar";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { PAGINATION } from "settings/constants/pagination";
import { get } from "utils/lodash.js";
import { isEqual } from "lodash";
import { toast } from "react-toastify";
import T from "T.js";
import usePMFetch from "hooks/usePMFetch.js";
import { useLazyGetCandidateRecordListQuery } from "api/CampusPlacement/GetCandidateRecords";
import CampusPlacementTable from "./CampusPlacementTable";
import MISLoader from "components/common/MISLoader";
import RecordingListDialog from "./Dialogs/RecordingListDialog";
import TopBar from "./Topbar";
import Constants from "Constants";
import { downloadFile } from "utils/file";
import ScreenshotListDialog from "./Dialogs/ScreenshotListDialog";
import { campusPlacementFilterStore } from "slices/campusPlacementFilterSlice";

const FULL_ROWS_PER_PAGE = 10000;

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const CampusPlacement = () => {
  const { user, sessionToken } = MISCurrentUser();
  const dispatch = useDispatch();
  const { projectManagers } = usePMFetch();
  const userRole = get(user, "role", "");
  const userId = get(user, "id", "");

  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    exportLoading: false,
    filters: {
      minMockAverage: null,
      maxMockAverage: null,
      minQuestionEngagementScore: null,
      maxQuestionEngagementScore: null,
      minLogicalLevelScore: null,
      maxLogicalLevelScore: null,
      text: "",
      isNaUser: null,
    },
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    CandidateRecordList: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    openRecordingDialog: false,
    openScreenshotDialog: false,
    showOptions: false,
    showCancelIcon: false,
    // searchInput: "",
    searchTableData: {},
    recordingData: [],
    screenShotData: [],
    recordingUser: null,
  });

  const {
    exportLoading,
    filters,
    CandidateRecordList,
    page,
    rowsPerPage,
    openRecordingDialog,
    openScreenshotDialog,
    showOptions,
    showCancelIcon,
    // searchInput,
    searchTableData,
    recordingData,
    screenShotData,
    recordingUser,
  } = localState;

  const { SERVER_URL } = Constants;

  const [getCandidateRecordList, { isFetching: isRecordLoading }] = useLazyGetCandidateRecordListQuery();

  const emptyFilters = {
    minMockAverage: null,
    maxMockAverage: null,
    minQuestionEngagementScore: null,
    maxQuestionEngagementScore: null,
    minLogicalLevelScore: null,
    maxLogicalLevelScore: null,
    text: "",
    isNaUser: null,
  };

  const { storedFilters } = useSelector(
    (state) => ({
      storedFilters: get(state, "CampusPlacementFilterSlice.storedFilters", {}),
    }),
    shallowEqual,
  );
  // const { storedSearch } = useSelector(
  //   (state) => ({
  //     storedSearch: get(state, "CaseStudyFilterSlice.storedSearch", ""),
  //   }),
  //   shallowEqual,
  // );

  const isFilterEmpty = isEqual(storedFilters, emptyFilters);

  useEffect(() => {
    setLocalState({
      filters: storedFilters,
      // searchInput: storedSearch,
    });
    refreshTable(page, rowsPerPage, storedFilters);
  }, [page, rowsPerPage]);

  const refreshTable = (page, rowsPerPage, filters) => {
    getCandidateRecordTableData(page, rowsPerPage, filters);
  };

  const getCandidateRecordTableData = (page, rowsPerPage, filters) => {
    const interviewFilterRequestDto = {
      mockAverageMin:
        filters?.minMockAverage === null
          ? null
          : filters?.minMockAverage !== null && filters?.minMockAverage !== undefined && filters?.minMockAverage,
      mockAverageMax:
        filters?.maxMockAverage === null
          ? null
          : filters?.maxMockAverage !== null && filters?.maxMockAverage !== undefined && filters?.maxMockAverage,
      questionEngagementScoreMin:
        filters?.minQuestionEngagementScore === null
          ? null
          : filters?.minQuestionEngagementScore !== null &&
            filters?.minQuestionEngagementScore !== undefined &&
            filters?.minQuestionEngagementScore,

      questionEngagementScoreMax:
        filters?.maxQuestionEngagementScore === null
          ? null
          : filters?.maxQuestionEngagementScore !== null &&
            filters?.maxQuestionEngagementScore !== undefined &&
            filters?.maxQuestionEngagementScore,
      logicalLevelScoreMin:
        filters?.minLogicalLevelScore === null
          ? null
          : filters?.minLogicalLevelScore !== null &&
            filters?.minLogicalLevelScore !== undefined &&
            filters?.minLogicalLevelScore,
      logicalLevelScoreMax:
        filters?.maxLogicalLevelScore === null
          ? null
          : filters?.maxLogicalLevelScore !== null &&
            filters?.maxLogicalLevelScore !== undefined &&
            filters?.maxLogicalLevelScore,
      text: filters?.text,
      isNaUser: filters?.isNaUser,
    };

    getCandidateRecordList({ page, rowsPerPage, interviewFilterRequestDto })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);
        setLocalState({ CandidateRecordList: { allTableRows, totalTableRowsCount, totalPageCount } });
      })
      .catch(handleError);
  };

  // ############################ Pagination Things ##############################
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  // // ############################ Search Things ###################################
  const handleSearchChange = (event) => {
    const { value, dataset } = event.currentTarget;
    const searchValue = value || get(dataset, "val", "");
    setLocalState({
      filters: { ...filters, text: searchValue },
    });
    if (event.key === "Enter" || event.currentTarget.nodeName === "svg" || searchValue === "") {
      // dispatch(caseStudyFilterStore({ storedSearch: "", storedFilters: storedFilters }));
      dispatch(campusPlacementFilterStore({ storedFilters: { ...storedFilters, text: searchValue } }));
      refreshTable(page, rowsPerPage, { ...storedFilters, text: searchValue });
    }
  };

  const handleSearchKeyChange = () => {
    setLocalState({
      page: INITIAL_PAGE,
    });
    dispatch(campusPlacementFilterStore({ storedFilters: { ...storedFilters, text: filters?.text } }));

    // dispatch(caseStudyFilterStore({ storedSearch: searchInput, storedFilters: storedFilters }));
    refreshTable(INITIAL_PAGE, rowsPerPage, filters);
  };

  // // ############################ Filter Things ###################################
  const onhandleFilterChange = (newValue, item) => {
    setLocalState({ filters: { ...filters, [item]: newValue } });
  };
  const handleFilterSubmit = () => {
    setLocalState({ page: INITIAL_PAGE });
    dispatch(campusPlacementFilterStore({ storedFilters: filters }));
    refreshTable(INITIAL_PAGE, rowsPerPage, filters);
  };

  const handleFilterClose = () => {
    dispatch(campusPlacementFilterStore({ storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters });
    refreshTable(page, rowsPerPage, emptyFilters);
  };

  // ##################################################################################################
  const refreshOnChange = () => {
    refreshTable(page, rowsPerPage, storedFilters);
  };
  // ################################### Handle Recording Dialog ######################################
  const handleRecordingDialog = (id, recordingData) => {
    setLocalState({
      openRecordingDialog: !openRecordingDialog,
      recordingUser: id ? id : null,
      recordingData: recordingData ? recordingData : [],
    });
  };
  const handleScreenshotDialog = (id, screenShotData) => {
    setLocalState({
      openScreenshotDialog: !openScreenshotDialog,
      recordingUser: id ? id : null,
      screenShotData: screenShotData ? screenShotData : [],
    });
  };

  const handleExport = async (type) => {
    const videoInterviewRequestFilterDto = {
      mockAverageMin: filters?.minMockAverage === null ? null : Number(get(filters, "minMockAverage", null)),
      mockAverageMax: filters?.maxMockAverage === null ? null : get(filters, "maxMockAverage", null),
      questionEngagementScoreMin:
        filters?.minQuestionEngagementScore === null ? null : get(filters, "minQuestionEngagementScore", null),
      questionEngagementScoreMax:
        filters?.maxQuestionEngagementScore === null ? null : get(filters, "maxQuestionEngagementScore", null),
      logicalLevelScoreMin: filters?.minLogicalLevelScore === null ? null : get(filters, "minLogicalLevelScore", null),
      logicalLevelScoreMax: filters?.maxLogicalLevelScore === null ? null : get(filters, "maxLogicalLevelScore", null),
      text: filters?.text,
    };

    setLocalState({ exportLoading: true });
    fetch(`${SERVER_URL}/videoInterview/download/csv`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${sessionToken}`,
        "content-type": "application/json",
      },
      body: JSON.stringify(videoInterviewRequestFilterDto),
    })
      .then((res) => res.blob())
      .then((response) => {
        downloadFile(response, type);
        setLocalState({ exportLoading: false });
      })
      .catch(handleError);
  };

  const handleSearchClick = (value) => {
    setLocalState({
      showOptions: false,
      showCancelIcon: false,
      filters: { ...filters, text: value },
    });
  };

  const handleClickOutside = () => {
    setLocalState({
      showOptions: false,
    });
  };
  const handleCheckboxChange = (checked, type) => {
    if (checked) {
      setLocalState({ filters: { ...filters, [type]: checked } });
      dispatch(campusPlacementFilterStore({ storedFilters: { ...storedFilters, [type]: checked } }));
    } else {
      setLocalState({ filters: { ...filters, [type]: null } });
      dispatch(campusPlacementFilterStore({ storedFilters: { ...storedFilters, [type]: null } }));
    }
  };

  return (
    <Paper display="block" justifyContent="flex-start" sx={{ borderRadius: 2 }}>
      {(isRecordLoading || exportLoading) && <MISLoader />}
      <Typography variant="h5" fontWeight={600} mb={1} marginLeft={2} sx={{ pt: 2 }}>
        {T.CAMPUS_PLACEMENT_LIST_VIEW}
      </Typography>

      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
            padding: "4px",
          },
        }}
      >
        <TopBar
          handleExport={handleExport}
          isFilterEmpty={isFilterEmpty}
          filters={filters}
          storedFilters={storedFilters}
          searchTableData={searchTableData}
          onClick={handleSearchClick}
          handleKeyChange={handleSearchKeyChange}
          handleChange={handleSearchChange}
          handleClickOutside={handleClickOutside}
          handleFilterClose={handleFilterClose}
          handleFilterSubmit={handleFilterSubmit}
          onhandleFilterChange={onhandleFilterChange}
          handleCheckboxChange={handleCheckboxChange}
        />

        <CampusPlacementTable
          allTableRows={CandidateRecordList.allTableRows}
          totalTableRowsCount={CandidateRecordList.totalTableRowsCount}
          totalPageCount={CandidateRecordList.totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          handleRecordingDialog={handleRecordingDialog}
          handleScreenshotDialog={handleScreenshotDialog}
        />
      </Box>
      <RecordingListDialog
        handleRecordingDialog={handleRecordingDialog}
        openRecordingDialog={openRecordingDialog}
        recordingData={recordingData}
        recordingUser={recordingUser}
      />
      <ScreenshotListDialog
        handleScreenshotDialog={handleScreenshotDialog}
        openScreenshotDialog={openScreenshotDialog}
        screenShotData={screenShotData}
        recordingUser={recordingUser}
      />
    </Paper>
  );
};

export default CampusPlacement;
