import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled, Tooltip, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import T from "T";
import MISNoData from "components/common/MISNoData";

const StyledTableCell = styled(TableCell)(({ theme, styles }) => ({
  borderTop: "inherit",
  overflow: "hidden",
  padding: "8px 10px 8px 5px",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  maxWidth: 100,
  verticalAlign: styles?.verticalAlign,
  ...theme.typography.subtitle1,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "white",
  },
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.cardDefault,
  },
}));

const FeedbackHistoryTableBody = ({ tableRecord = [] }) => {
  const reversedTableRecord = [...tableRecord].reverse();
  const getLabelText = (value) => `${value}`;
  return (
    <TableBody>
      {reversedTableRecord.length === 0 ? (
        <StyledTableRow>
          <StyledTableCell colSpan="10%" sx={{ border: "none" }}>
            <MISNoData />
          </StyledTableCell>
        </StyledTableRow>
      ) : (
        reversedTableRecord.map((entry) => (
          <StyledTableRow
            key={entry.id}
            // style={{ height: "100px", justifyContent: "center" }}
          >
            <StyledTableCell>
              <Tooltip title={entry.createdBy} placement="top-start">
                <Typography>{entry.createdBy}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell>
              <Rating
                name={`${"technicalFeedback"}-${entry.id}`}
                precision={0.5}
                value={entry.technicalFeedback}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.techComment} placement="top-start">
                <Typography>{entry.techComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Tooltip title={entry.clientComment} placement="top-start">
                <Typography>{entry.clientComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"communicationSkill"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.communicationSkill}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.communicationComment} placement="top-start">
                <Typography>{entry.communicationComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"availability"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.availability}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"teamPlayer"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.teamPlayer}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"enhancingSkill"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.enhancingSkill}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.enhancingSkillComment} placement="top-start">
                <Typography>{entry.enhancingSkillComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"takingInitiative"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.takingInitiative}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.takingInitiativeComment} placement="top-start">
                <Typography>{entry.takingInitiativeComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              {entry.employeeOnEDP === true && (
                <>
                  <Typography>From: {entry.startDate}</Typography>
                  <Typography>To: {entry.endDate}</Typography>
                </>
              )}
              {entry.employeeOnEDP === false && <Typography>No</Typography>}
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"jiraFilling"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.jiraFilling}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.jiraFillingComment} placement="top-start">
                <Typography>{entry.jiraFillingComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Rating
                name={`${"discipline"}-${entry.id}`}
                precision={0.5}
                defaultValue={0}
                value={entry.discipline}
                getLabelText={getLabelText}
                readOnly
                size="small"
              />
              <Tooltip title={entry.disciplineComment} placement="top-start">
                <Typography>{entry.disciplineComment}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Tooltip title={entry.comments} placement="top-start">
                <Typography>{entry.comments}</Typography>
              </Tooltip>
            </StyledTableCell>
            <StyledTableCell styles={{ verticalAlign: "top" }}>
              <Tooltip title={entry.createdAt} placement="top-start">
                <Typography>{entry.createdAt}</Typography>
              </Tooltip>
            </StyledTableCell>
          </StyledTableRow>
        ))
      )}
    </TableBody>
  );
};

FeedbackHistoryTableBody.propTypes = {
  tableRecord: PropTypes.array,
};

export default FeedbackHistoryTableBody;
