import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCandidateChecklist: builder.query({
      query: ({ userId, presalesMappingId }) => ({
        url: `/pitchedUser/candidateInterviewChecklist/get`,
        method: "GET",
        params: { userId, presalesMappingId },
      }),
    }),
  }),
});

export const { useLazyGetCandidateChecklistQuery } = extendedApi;
