import PropTypes from "prop-types";
import { TableRow, TableCell, TableBody, styled, Tooltip, Typography } from "@mui/material";
import Rating from "@mui/material/Rating";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  borderBottom: "none",
  overflow: "hidden",
  fontSize: 13,
  paddingBottom: 0,
}));

const FeedbackHistoryTableBody = ({ tableRecord = [] }) => {
  const reversedTableRecord = [...tableRecord].reverse();
  const getLabelText = (value) => `${value}`;
  return (
    <TableBody>
      {reversedTableRecord.map((entry, index) => (
        <TableRow key={entry.id}>
          <StyledTableCell>{index + 1}.</StyledTableCell>

          <StyledTableCell>
            <Tooltip title={entry.createdBy} placement="top-start">
              <>{entry.createdBy}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <Rating
              name={`${"technicalFeedback"}-${entry.id}`}
              precision={0.5}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              value={entry.technicalFeedback}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />{" "}
            <br />
            <Tooltip title={entry.techComment} placement="top-start">
              <>{entry.techComment}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Tooltip title={entry.clientComment} placement="top-start">
              <>{entry.clientComment}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"communicationSkill"}-${entry.id}`}
              precision={0.5}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              defaultValue={0}
              value={entry.communicationSkill}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
            <br />
            <Tooltip title={entry.communicationComment} placement="top-start">
              <>{entry.communicationComment}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"availability"}-${entry.id}`}
              precision={0.5}
              defaultValue={0}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              value={entry.availability}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"teamPlayer"}-${entry.id}`}
              precision={0.5}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              defaultValue={0}
              value={entry.teamPlayer}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"enhancingSkill"}-${entry.id}`}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              precision={0.5}
              defaultValue={0}
              value={entry.enhancingSkill}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
            <br />
            <Tooltip title={entry.enhancingSkillComment} placement="top-start">
              <>{entry.enhancingSkillComment}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"takingInitiative"}-${entry.id}`}
              precision={0.5}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              defaultValue={0}
              value={entry.takingInitiative}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
            <br />
            <Tooltip title={entry.takingInitiativeComment} placement="top-start">
              <>{entry.takingInitiativeComment}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            {entry.employeeOnEDP === true && (
              <>
                <>From: {entry.startDate}</>
                <>To: {entry.endDate}</>
              </>
            )}
            {entry.employeeOnEDP === false && <>No</>}
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"jiraFilling"}-${entry.id}`}
              precision={0.5}
              defaultValue={0}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              value={entry.jiraFilling}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
            <br />
            <Tooltip title={entry.jiraFillingComment} placement="top-start">
              <Typography>{entry.jiraFillingComment}</Typography>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Rating
              name={`${"discipline"}-${entry.id}`}
              precision={0.5}
              defaultValue={0}
              sx={{
                fontSize: 18,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              value={entry.discipline}
              getLabelText={getLabelText}
              readOnly
              size="small"
            />
            <br />
            <Tooltip title={entry.disciplineComment} placement="top-start">
              <>{entry.disciplineComment}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Tooltip title={entry.comments} placement="top-start">
              <>{entry.comments}</>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell styles={{ verticalAlign: "top" }}>
            <Tooltip title={entry.createdAt} placement="top-start">
              <>{entry.createdAt}</>
            </Tooltip>
          </StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

FeedbackHistoryTableBody.propTypes = {
  tableRecord: PropTypes.array,
};

export default FeedbackHistoryTableBody;
