import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Typography, Card, Grid } from "@mui/material";
import T from "T";
import HistoryTable from "./HistoryTable";
import { BACKGROUND } from "theme/colors";

const ProjectHistory = ({ allTableRows = [], handleAddFeedback = noop }) => (
  <Card sx={{ mt: 3, p: 2, height: 240, backgroundColor: BACKGROUND.card }} elevation={2}>
    <Grid container spacing={2}>
      <Grid item sm={6} xs={12} display="flex" alignItems="center" pb={1}>
        <Typography fontSize={17} fontWeight={600}>
          {`${T.PROJECT_HISTORY}`}
        </Typography>
      </Grid>
    </Grid>

    <HistoryTable allTableRows={allTableRows} handleAddFeedback={handleAddFeedback} />
  </Card>
);

ProjectHistory.propTypes = {
  allTableRows: PropTypes.array,
  handleAddFeedback: PropTypes.func,
  handleExport: PropTypes.func,
};

export default ProjectHistory;
