import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getTestimonialSearchList: builder.query({
      query: ({ search }) => {
        const params = {
          text: search,
        };

        return {
          url: "/testimonial/search",
          params,
        };
      },
    }),
  }),
});

export const { useLazyGetTestimonialSearchListQuery } = extendedApi;
