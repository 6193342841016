import DashboardTable from "./DashboardTable";
import { noop } from "lodash";

const ToolsDashboardTab = ({ dashboardData = [], toolsList = [], profitCenterList = [], handleLicenseDetail = noop }) => {
  return (
    <DashboardTable
      dashboardData={dashboardData}
      profitCenterList={profitCenterList}
      toolsList={toolsList}
      handleLicenseDetail={handleLicenseDetail}
    />
  );
};

export default ToolsDashboardTab;
