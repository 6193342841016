import PropTypes from "prop-types";
import { Box, Grid, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import T from "T";
import { TEXT } from "theme/colors";
import InfoIcon from "@mui/icons-material/Info";
import { GET_SIZE } from "utils/responsive";
import { noop } from "lodash";
// import CloseIcon from "@mui/icons-material/Close";
import QuestionsBoxButtons from "./QuestionsBoxButtons";

const InterviewQuestions = ({
  // handleInterviewStarted = noop,
  handleStopListening = noop,
  handleStartListening = noop,
  handleNextClick = noop,
  handleSubmit = noop,
  quesStep,
  questionDict,
  query,
}) => {
  const { isXs, isMd } = GET_SIZE();

  // Get the current question based on the quesStep
  const currentQuestion = questionDict ? questionDict[`Q${quesStep}`] : "";

  return (
    <Grid container>
      <Grid item display={"flex"} justifyContent={"flex-end"} width={"100%"} sx={{ zIndex: 99 }}></Grid>
      {/* <IconButton
        aria-label="close"
        onClick={handleInterviewStarted}
        sx={{
          position: "absolute",
          right: 20,
        }}
      >
        <CloseIcon />
      </IconButton> */}
      <Grid item xs={isMd ? 2 : 3.25} />
      <Grid
        item
        xs={isMd ? 8 : 5.5}
        className="questionDiv"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          minHeight: "270px",
          width: "inherit",
          minWidth: "300px",
          maxHeight: "650px",
          backgroundColor: "background.white",
          borderRadius: "8px",
          padding: "50px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          boxShadow: "3px 3px 30px -10px rgba(0,0,0,0.3)",
          overflowY: "auto",
        }}
      >
        {quesStep <= 8 && (
          <Stack>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Typography
                variant="subtitle-2"
                sx={{
                  flexWrap: "wrap",
                  textAlign: "start",
                  color: TEXT.grayBlue,
                  marginBottom: 1,
                }}
              >
                {`Question ${quesStep}/8`}
              </Typography>
              <Tooltip title={T.INSTRUCTIONS} placement="top">
                <IconButton aria-label="info">
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Box>

            <Typography
              variant="h6"
              sx={{
                flexWrap: "wrap",
                textAlign: "start",
                fontSize: isXs ? "1.3rem" : "1.6rem",
                lineHeight: isXs ? "1.3" : "1.6",
                fontWeight: "bold",
                mb: 1.5,
              }}
            >
              {currentQuestion}
            </Typography>
          </Stack>
        )}

        <QuestionsBoxButtons
          handleNextClick={handleNextClick}
          quesStep={quesStep}
          handleStartListening={handleStartListening}
          handleStopListening={handleStopListening}
          handleSubmit={handleSubmit}
          query={query}
        />
      </Grid>
    </Grid>
  );
};

InterviewQuestions.propTypes = {
  handleInterviewStarted: PropTypes.func,
  handleStopListening: PropTypes.func,
  handleStartListening: PropTypes.func,
  handleNextClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  quesStep: PropTypes.number.isRequired,
  questionDict: PropTypes.object.isRequired,
  query: PropTypes.object.isRequired,
};

export default InterviewQuestions;
