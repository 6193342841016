import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getNewJoiners: builder.query({
      query: ({ page, size }) => ({
        url: `/user/fetch/newJoiners`,
        method: "GET",
        params: { page, size },
      }),
    }),
  }),
});

export const { useLazyGetNewJoinersQuery } = extendedApi;
