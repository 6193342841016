import { Children } from "react";
import { Card, Grid, Rating, Typography } from "@mui/material";
import { get } from "lodash";
import T from "T";
import { BACKGROUND, NETSMARTZ_THEME_COLOR } from "theme/colors";
import PropTypes from "prop-types";

const TechnicalHistory = ({ results }) => (
  <Card sx={{ p: 3, width: "300px", backgroundColor: BACKGROUND.card }} elevation={3}>
    <Typography fontSize={16} fontWeight={600} mb={1.5}>
      {T.TECHNICAL_HISTORY}
    </Typography>
    {Children.toArray(
      results.map((skill) => (
        <Grid container>
          <Grid item md={7}>
            <Typography fontSize={13.5} variant="body" component="legend" color="text.label" noWrap>
              {get(skill, "skillName", "")}
            </Typography>
          </Grid>
          <Grid item md={5}>
            <Rating
              sx={{
                fontSize: 21,
                "& .MuiRating-iconFilled": {
                  color: NETSMARTZ_THEME_COLOR,
                },
              }}
              name="skillRating"
              precision={0.5}
              value={get(skill, "rating", "")}
            />
          </Grid>
        </Grid>
      )),
    )}

    {results?.length < 1 && <Typography variant="body1">{T.NOT_AVAILABLE}</Typography>}
  </Card>
);
TechnicalHistory.propTypes = {
  results: PropTypes.array,
};
export default TechnicalHistory;
