import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Typography, Card, Grid } from "@mui/material";
import MISDatePicker from "components/common/MISDatePicker";
import MISTextField from "components/common/MISTextField";
import T from "T";
import NCTable from "./NCTable";
const NCHistory = ({
  startDate = null,
  endDate = null,
  allTableRows = [],
  refreshNCTable = noop,
  handleActiveNcCountDialog = noop,
  handleDeleteNcCountDialog = noop,
  onHandleDateChange = noop,
}) => {
  // const relativeMonths=[...MONTH_LIST];

  return (
    <Card sx={{ mt: 3, p: 2 }} elevation={2}>
      <Grid container spacing={2}>
        <Grid item sm={8} xs={12} display="flex" alignItems="center">
          <Typography fontSize={18} fontWeight={600}>
            {`${T.NC_HISTORY}`}
          </Typography>
        </Grid>

        <Grid item sm={4} xs={12} display="flex" columnGap={2} alignItems="center">
          <MISDatePicker
            bel={T.START_DATE}
            placeholder={T.START_DATE}
            inputFormat="MM/DD/YYYY"
            value={startDate}
            maxDate={new Date()}
            name="startDate"
            handleChange={onHandleDateChange}
            renderInput={(params) => <MISTextField {...params} />}
          />

          <MISDatePicker
            bel={T.END_DATE}
            placeholder={T.END_DATE}
            inputFormat="MM/DD/YYYY"
            value={endDate}
            name="endDate"
            minDate={startDate}
            handleChange={onHandleDateChange}
            renderInput={(params) => <MISTextField {...params} />}
          />
        </Grid>
      </Grid>

      <NCTable
        allTableRows={allTableRows}
        refreshTable={refreshNCTable}
        handleActiveNcCountDialog={handleActiveNcCountDialog}
        handleDeleteNcCountDialog={handleDeleteNcCountDialog}
      />
    </Card>
  );
};

NCHistory.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  allTableRows: PropTypes.array,
  refreshNCTable: PropTypes.func,
  handleActiveNcCountDialog: PropTypes.func,
  handleDeleteNcCountDialog: PropTypes.func,
  handleNCDelete: PropTypes.func,
  onHandleDateChange: PropTypes.func,
  handleExport: PropTypes.func,
};

export default NCHistory;
