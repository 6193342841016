import PropTypes from "prop-types";
import { TableCell, TableBody, styled, TableRow, IconButton, Tooltip } from "@mui/material";
import { Edit, Delete, ThumbUp, ThumbDown } from "@mui/icons-material";
import memo from "utils/react";
import get from "lodash/get";
import { ERROR, NETSMARTZ_THEME_COLOR, SUCCESS } from "theme/colors";
import { noop } from "lodash";

const StyledTableCell = styled(TableCell)(() => ({
  borderTop: "inherit",
  overflow: "hidden",
  backgroundColor: "white",
  padding: "7px 8px 7px 24px",
  maxWidth: 130,
  whiteSpace: "nowrap",
  fontSize: 13.5,
}));

const PositiveIndicator = styled(ThumbUp)({
  color: SUCCESS.main,
});

const NegativeIndicator = styled(ThumbDown)({
  color: ERROR.main,
});

const SIRTableBody = ({ allTableRows, handleDeleteSIR = noop, handleEditSIRDialog = noop }) => {
  const reversedTableRecord = [...allTableRows].reverse();

  return (
    <TableBody>
      {reversedTableRecord.map((record, index) => (
        <TableRow key={index}>
          <StyledTableCell sx={{ width: 0 }}>
            {get(record, "sirCategories.groupName", null) === "Positive" ? <PositiveIndicator /> : <NegativeIndicator />}
          </StyledTableCell>
          <StyledTableCell sx={{ pl: 4.5 }}>{index + 1}.</StyledTableCell>
          <StyledTableCell>{get(record, "createdBy.name", "-")}</StyledTableCell>
          <StyledTableCell>{get(record, "createdAt", "-").split("T")[0]}</StyledTableCell>
          <StyledTableCell>{get(record, "sirCategories.categoryName", "-")}</StyledTableCell>
          <Tooltip placement="top" title={get(record, "description", "-")}>
            <StyledTableCell>{get(record, "description", "-")}</StyledTableCell>
          </Tooltip>
          <StyledTableCell sx={{ textAlign: "center" }}>
            <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleEditSIRDialog(record)}>
              <Edit />
            </IconButton>
            <IconButton sx={{ color: NETSMARTZ_THEME_COLOR }} onClick={() => handleDeleteSIR(record.id)}>
              <Delete />
            </IconButton>
          </StyledTableCell>
        </TableRow>
      ))}
    </TableBody>
  );
};

SIRTableBody.propTypes = {
  allTableRows: PropTypes.array,
  handleDeleteSIR: PropTypes.func,
  handleEditSIRDialog: PropTypes.func,
};

export default memo(SIRTableBody);
