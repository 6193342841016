import { api3 } from "api";

const extendedApi = api3.injectEndpoints({
  endpoints: (build) => ({
    submitSolution: build.mutation({
      query: (formData) => ({
        url: `/upload_answer/`,
        method: "POST",
        body: formData,
        // prepareHeaders: (headers) => {
        //   headers.set("Content-Type", "multipart/form-data");
        //   return headers;
        // },
      }),
    }),
  }),
});

export const { useSubmitSolutionMutation } = extendedApi;
