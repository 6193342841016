import { api2 } from "api";

const extendedApi = api2.injectEndpoints({
  endpoints: (builder) => ({
    getVideoInterviewQuestion: builder.query({
      query: (qIndex) => ({
        url: `/new_questions?index=${qIndex}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyGetVideoInterviewQuestionQuery } = extendedApi;
