import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    saveRecommendationDetails: build.mutation({
      query: ({ payload, user_id }) => ({
        url: "/talentAcquisition/save",
        method: "POST",
        params: { user_id },
        body: payload,
      }),
    }),
  }),
});

export const { useSaveRecommendationDetailsMutation } = extendedApi;
