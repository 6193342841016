import { Box } from "@mui/system";
import { get } from "lodash";
import { useEffect, useReducer } from "react";
import T from "T";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import CreateReferral from "./CreateReferral";
import usePMFetch from "hooks/usePMFetch";
import MISButton from "components/common/MISButton";
import MISLoader from "components/common/MISLoader";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useLazyGetCompanyListQuery } from "api/members/getCompanyList";
import { referralAnswers, referralQuestions } from "./referralModel";
import { useSaveRecommendationDetailsMutation } from "api/Referrals/saveRecommendationDetails";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { useLazyGetCompanyByIdQuery } from "api/Referrals/getCompanyById";
import { useLazyGetUserListQuery } from "api/members/getUserList";
import { PAGINATION } from "settings/constants/pagination";
import { isEmail, isMobileNo, isUrl } from "utils/validations";
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Form = ({ departmentList, isSecondLevel, user_id, showRecommendedBy, record }) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    referralName: "",
    phoneNumber: "",
    referralEmail: "",
    skills: [],
    companyList: [],
    experience: "",
    comment: "",
    selectedDept: {},
    // companyName: "",
    linkedInURL: "",
    previousCompany: "",
    feedbackType: null,
    taRecommended: null,
    vpRecommended: null,
    cooRecommended: null,
    taComment: null,
    vpComment: null,
    memberList: [],
    recommendedBy: "",
    selectedReferralAnswers: Array(referralQuestions.length).fill([]),
    files: [],
    skillList: [],
    fileType1: null,
    fileType2: null,
    file1: null,
    file2: null,
  });
  const {
    referralName,
    phoneNumber,
    referralEmail,
    skills,
    experience,
    comment,
    // companyName,
    companyList,
    linkedInURL,
    previousCompany,
    selectedReferralAnswers,
    selectedDept,
    feedbackType,
    taRecommended,
    vpRecommended,
    cooRecommended,
    taComment,
    vpComment,
    recommendedBy,
    memberList,
    skillList,
    files,
    fileType1,
    fileType2,
    file1,
    file2,
  } = localState;

  const [saveRecommendationDetails, { isLoading }] = useSaveRecommendationDetailsMutation();
  const [getUserList, { isFetching: isLoadingUsers }] = useLazyGetUserListQuery();

  const [getCompanyList] = useLazyGetCompanyListQuery();
  const navigate = useNavigate();

  const [getPreviousCompany] = useLazyGetCompanyByIdQuery();

  const getPrevComp = () => {
    getPreviousCompany({ page: INITIAL_PAGE, SIZE: ROWS_PER_PAGE, id: previousCompany.id })
      .unwrap()
      .then((res) => {
        setLocalState({
          skillList: res.techStack,
        });
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (previousCompany) {
      getPrevComp();
    } else {
      setLocalState({
        skillList: [],
      });
    }
  }, [previousCompany]);

  useEffect(() => {
    getCompanyList()
      .unwrap()
      .then((res) => {
        setLocalState({ companyList: res });
      })
      .catch(handleError);
    getUserList({ page: 0, size: 10000, status: T.ACTIVE })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        setLocalState({
          memberList: allTableRows,
        });
      })
      .catch(handleError);
  }, []);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const onHandleAutoCompleteChange = (newValue, item) => {
    setLocalState({ [item]: newValue });
  };

  const validation = () => {
    if (
      !referralName ||
      !experience ||
      !previousCompany ||
      (showRecommendedBy && !recommendedBy) ||
      // (previousCompany === "Other" && !companyName) ||
      selectedReferralAnswers.some((answer) => !answer.length) ||
      !(skills && skills.length > 0)
    ) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return false;
    }
    if (!(phoneNumber || referralEmail || linkedInURL)) {
      toast.error(T.CONTACT_DETAIL_ERROR_MESSAGE);
      return false;
    }
    if (phoneNumber && !isMobileNo(phoneNumber)) {
      toast.error(T.INVALID_PHONE_NUMBER);
      return false;
    }

    if (referralEmail && !isEmail(referralEmail)) {
      toast.error(T.INVALID_EMAIL);
      return false;
    }

    if (linkedInURL && !isUrl(linkedInURL)) {
      toast.error(T.INVALID_LINKEDIN_URL);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (!validation()) {
      return;
    }
    const formattedReferralAnswers = {};
    referralQuestions.forEach((question, index) => {
      formattedReferralAnswers[`firstMcq`] = selectedReferralAnswers[0];
      formattedReferralAnswers[`secondMcq`] = selectedReferralAnswers[1];
      formattedReferralAnswers[`thirdMcq`] = selectedReferralAnswers[2];
      formattedReferralAnswers[`fourthMcq`] = selectedReferralAnswers[3];
      formattedReferralAnswers[`fifthMcq`] = selectedReferralAnswers[4];
    });
    const recommendedById = get(recommendedBy, "id", null);
    const payload = {
      referralName: referralName,
      phoneNumber: phoneNumber,
      referralEmail: referralEmail,
      skills: skills?.map((skill) => {
        return get(skill, "id", "");
      }),
      experience: parseFloat(experience),
      comment: comment,
      linkedinUrl: linkedInURL,
      // companyName,
      // previousCompanyId: !companyName ? get(previousCompany, "id", null) : null,
      previousCompanyId: get(previousCompany, "id", null),
      ...formattedReferralAnswers,
      departmentId: get(selectedDept, "id", null),
      taRecommended,
      vpRecommended,
      cooRecommended,
      taComment,
      vpComment,
      feedback: feedbackType,
      fileType1,
      file1,
      fileType2,
      file2,
    };
    saveRecommendationDetails({ payload, user_id: recommendedById ? recommendedById : user_id })
      .unwrap()
      .then(() => {
        setLocalState({
          referralName: "",
          phoneNumber: "",
          referralEmail: "",
          skills: [],
          companyList: [],
          experience: "",
          comment: "",
          selectedDept: {},
          // companyName: "",
          linkedInURL: "",
          previousCompany: "",
          feedbackType: "",
          taRecommended: null,
          vpRecommended: null,
          cooRecommended: null,
          taComment: null,
          vpComment: null,
          recommendedBy: "",
          selectedReferralAnswers: Array(referralQuestions.length).fill([]),
        });
        toast.success(T.RECOMMENDATION_ADDED);
        setTimeout(() => {
          navigate(-1);
        }, 500);
      })
      .catch(handleError);
  };
  const onHandleCompanyChange = (newValue) => {
    setLocalState({ previousCompany: newValue });
  };

  const handleReferralAnswersChange = (index, newValue) => {
    const updatedSelectedReferralAnswers = [...selectedReferralAnswers];
    updatedSelectedReferralAnswers[index] = newValue;
    setLocalState({ selectedReferralAnswers: updatedSelectedReferralAnswers });
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileUpload = async (updatedFiles) => {
    setLocalState({ files: updatedFiles });
    const base64Files = [];
    for (let i = 0; i < Math.min(updatedFiles.length, 2); i++) {
      const file = updatedFiles[i];
      const base64 = await convertBase64(file);
      base64Files.push({
        fileName: file.name,
        fileBase64: base64,
      });

      if (i === 0) {
        setLocalState({
          fileType1: file.name,
          file1: base64,
        });
      }
      if (i === 1) {
        setLocalState({
          fileType2: file.name,
          file2: base64,
        });
      }
    }
  };
  return (
    <>
      <Box
        sx={{
          height: "calc(100vh - 265px)",
          overflowY: "auto",
          px: 1,
        }}
      >
        {isLoading && <MISLoader />}
        <CreateReferral
          referralName={referralName}
          phoneNumber={phoneNumber}
          referralEmail={referralEmail}
          skills={skills}
          experience={experience}
          linkedInURL={linkedInURL}
          comment={comment}
          // companyName={companyName}
          selectedDept={selectedDept}
          departmentList={departmentList}
          previousCompany={previousCompany}
          skillList={skillList}
          companyList={companyList}
          referralQuestions={referralQuestions}
          memberList={memberList}
          recommendedBy={recommendedBy}
          showRecommendedBy={showRecommendedBy}
          referralAnswers={referralAnswers}
          selectedReferralAnswers={selectedReferralAnswers}
          feedbackType={feedbackType}
          onHandleChange={onHandleChange}
          onHandleAutoCompleteChange={onHandleAutoCompleteChange}
          onHandleCompanyChange={onHandleCompanyChange}
          handleReferralAnswersChange={handleReferralAnswersChange}
          isSecondLevel={isSecondLevel}
          record={record}
          handleFileUpload={handleFileUpload}
          files={files}
          isLoadingUsers={isLoadingUsers}
        />
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"} mr={2} mt={1}>
        <MISButton sx={{ fontSize: 14, px: 2 }} onClick={handleSubmit}>
          {T.SUBMIT}
        </MISButton>
      </Box>
    </>
  );
};
Form.propTypes = {
  departmentList: PropTypes.object,
  isSecondLevel: PropTypes.bool,
  user_id: PropTypes.number,
  showRecommendedBy: PropTypes.bool,
  record: PropTypes.object,
};
export default Form;
