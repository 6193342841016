import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    assignTechnicalInterview: build.mutation({
      query: ({ userId, payload }) => ({
        url: `technicalAssignment/save`,
        method: "POST",
        body: payload,
        params: { userId },
      }),
    }),
  }),
});

export const { useAssignTechnicalInterviewMutation } = extendedApi;
