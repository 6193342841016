import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Table as TableView, TableContainer, Paper } from "@mui/material";

import { TABLE_HEADER_VALUES } from "settings/constants/roles";
import TableHeader from "./TableHeader";
import TableBody from "./TableBody";

const Permissions = ({ access = [], handleRole = noop }) => (
  <Paper>
    <TableContainer>
      <TableView stickyHeader>
        <TableHeader values={TABLE_HEADER_VALUES} />
        <TableBody access={access} handleRole={handleRole} />
      </TableView>
    </TableContainer>
  </Paper>
);

Permissions.propTypes = {
  access: PropTypes.array,
  handleRole: PropTypes.func,
};

export default Permissions;
