import { Children } from "react";
import PropTypes from "prop-types";

import { TableRow, TableHead, TableCell, Typography } from "@mui/material";

import memo from "utils/react";
import { get } from "utils/lodash";
import { BACKGROUND } from "theme/colors";

const TableHeader = ({ columns = [] }) => (
  <TableHead>
    <TableRow sx={{ height: 20 }}>
      {Children.toArray(
        columns.map((column, ind) => {
          const columnName = get(column, "label", "").toUpperCase();
          return (
            <TableCell
              sx={{
                border: "none",
                backgroundColor: BACKGROUND.header,
                padding: "0px 8px 0px 24px",
                position: column.label === "Client Name" ? "sticky" : "none",
                left: column.label === "Client Name" ? 0 : "auto",
                zIndex: column.label === "Client Name" ? 99 : "auto",
              }}
            >
              <Typography variant="body1" fontSize={13} noWrap fontWeight={600}>
                {columnName}
              </Typography>
            </TableCell>
          );
        }),
      )}
    </TableRow>
  </TableHead>
);

TableHeader.propTypes = {
  columns: PropTypes.array,
  lockedColumns: PropTypes.array,
};

export default memo(TableHeader);
