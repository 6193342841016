import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getVersionHistory: build.mutation({
      query: ({ id, page, rowPerPage, caseStudyVersionDto }) => ({
        url: `/caseStudy/get/all/version?caseStudyId=${id}&page=${page}&size=${rowPerPage}`,
        method: "POST",
        body: caseStudyVersionDto,
      }),
    }),
  }),
});

export const { useGetVersionHistoryMutation } = extendedApi;
