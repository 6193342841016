import styled from "@emotion/styled";
import { Card, TableBody, TableHead as MuiTableHead } from "@mui/material";
import { Box } from "@mui/system";
import MISNoData from "components/common/MISNoData";
import { BACKGROUND } from "theme/colors";
import PropTypes from "prop-types";

const CardTitle = styled.h1`
  font-size: 16px;
  padding-top: 0;
  padding-bottom: 1;
`;

const TableContainer = styled.div`
  overflow-x: auto;
  height: 200px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
`;

const Th = styled.th`
  padding: 5px;
  font-size: 13px;
  text-align: left;
`;

const Td = styled.td`
  padding-right: 10px;
  padding-left: 10px;
  vertical-align: top;
`;

const List = styled.ul`
  list-style-type: none;
  padding: 0;
  font-size: 13px;
`;

const ListItem = styled.li`
  margin-bottom: 5px;
  &:before {
    content: "\\2022";
    color: #f58220;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }
`;

const TableHead = styled(MuiTableHead)`
  position: sticky;
  top: 0;
  background-color: #eef0f7;
`;

const SWOT = ({ swotData }) => {
  const renderList = (type) => {
    return swotData
      .filter((item) => item.type === type)
      .map((item) => <ListItem key={item.swotAnalysisId}>{item.description}</ListItem>);
  };

  return (
    <Card sx={{ mt: 3, p: 2, pt: 1, backgroundColor: BACKGROUND.card }} elevation={2}>
      <CardTitle>SWOT Analysis</CardTitle>
      {swotData.length > 0 ? (
        <TableContainer>
          <Table>
            <TableHead>
              <tr>
                <Th>Strengths (S)</Th>
                <Th>Weaknesses (W)</Th>
                <Th>Opportunities (O)</Th>
                {/* <Th>Threats (T)</Th> */}
              </tr>
            </TableHead>
            <TableBody>
              <tr>
                <Td>
                  <List>{renderList("S")}</List>
                </Td>
                <Td>
                  <List>{renderList("W")}</List>
                </Td>
                <Td>
                  <List>{renderList("O")}</List>
                </Td>
                {/* <Td>
                  <List>{renderList("T")}</List>
                </Td> */}
              </tr>
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box pb={3.8}>
          <MISNoData />
        </Box>
      )}
    </Card>
  );
};

SWOT.propTypes = {
  swotData: PropTypes.array,
};
export default SWOT;
