import { useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import noop from "lodash/noop";
import { Card, Grid } from "@mui/material";
import { PAGINATION } from "settings/constants/pagination";
import PreSalesTableCards from "./PreSalesTableCards";
import PreSalesTableData from "./PreSalesTableData";
import MISNoData from "components/common/MISNoData";
import { shallowEqual, useDispatch } from "react-redux";
import { psCardStore } from "slices/preSalesCardSlice";
import MasterPagination from "components/MasterSetting/MasterPagination";
import { useSelector } from "react-redux";
import { get } from "lodash";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const PreSalesTable = ({
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
  scrollPosition,
  handleJdDialog = noop,
  handleDeleteProjectDialog = noop,
  handleViewClientDialog = noop,
  handleSetScrollPosition = noop,
  page = INITIAL_PAGE,
  openDialog,
}) => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    renderedRow: [],
    psTableData: [],
  });
  const dispatch = useDispatch();
  const { psTableData } = localState;
  const { selectedCardId } = useSelector(
    (state) => ({
      selectedCardId: get(state, "PSCardSlice.selectedCardId", null),
    }),
    shallowEqual,
  );
  useEffect(() => {
    setLocalState({
      renderedRow: allTableRows,
      psTableData: allTableRows.length > 0 ? [allTableRows[0]] : [],
    });
  }, [allTableRows]);
  const { renderedRow } = localState;
  // useEffect(() => {
  //   dispatch(psCardStore({ selectedCard: psTableData, selectedCardId: selectedCardId, scrollPosition: scrollPosition }));
  // }, [psTableData, selectedCardId, scrollPosition, dispatch]);

  const handleShowDetails = (recordId, scrollPosition) => {
    const psTableData = allTableRows.filter((row) => row.id === recordId);
    dispatch(psCardStore({ selectedCard: psTableData, selectedCardId: recordId, scrollPosition: scrollPosition }));
    setLocalState({ psTableData: psTableData, recordId: recordId });
  };
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px",
        mt: 1.5,
        backgroundColor: "background.card",
        "& .add-shadow": {
          boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)",
        },
      }}
    >
      <Grid
        container
        sx={{
          height: "calc(100vh - 325px)",
        }}
      >
        {psTableData.length !== 0 ? (
          <>
            <Grid item xs={12} md={3}>
              <PreSalesTableCards
                allTableRows={renderedRow}
                handleShowDetails={handleShowDetails}
                scrollPosition={scrollPosition}
                handleSetScrollPosition={handleSetScrollPosition}
                psTableData={psTableData}
              />
            </Grid>
            <Grid item xs={12} md={9}>
              <PreSalesTableData
                handleJdDialog={handleJdDialog}
                openDialog={openDialog}
                psTableData={psTableData}
                handleViewClientDialog={handleViewClientDialog}
                handleDeleteProjectDialog={handleDeleteProjectDialog}
                scrollPosition={scrollPosition}
                handleSetScrollPosition={handleSetScrollPosition}
              />
            </Grid>
          </>
        ) : (
          <MISNoData />
        )}
      </Grid>
      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

PreSalesTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalPageCount: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  scrollPosition: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
  handleJdDialog: PropTypes.func,
  handleDeleteProjectDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  handleViewClientDialog: PropTypes.func,
  handleSetScrollPosition: PropTypes.func,
};
export default PreSalesTable;
