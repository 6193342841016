import { Stepper, Step, StepLabel, styled } from "@mui/material";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { NETSMARTZ_THEME_COLOR } from "theme/colors";

const ThemedStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.themeColor,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.themeColor,
    },
  },
}));

const MISStepper = ({ steps, activeStep }) => {
  // Create a function to map the status to the step index
  const getStepIndex = (status) => {
    return steps.indexOf(status);
  };

  // Use the getStepIndex function to get the active step index
  const activeStepIndex = getStepIndex(activeStep);

  return (
    <div>
      <Stepper
        activeStep={activeStepIndex}
        alternativeLabel
        sx={{ padding: "8px", margin: 0 }}
        connector={<ThemedStepConnector />}
      >
        {steps.map((label, index) => (
          <Step key={`${label}_${index}`}>
            <StepLabel
              sx={{
                color: NETSMARTZ_THEME_COLOR,
                "& .MuiStepIcon-root.Mui-active": { color: NETSMARTZ_THEME_COLOR },
                "& .MuiStepIcon-root.Mui-completed": { color: NETSMARTZ_THEME_COLOR, opacity: 0.5 },
                [`&.${stepConnectorClasses.active}`]: {
                  [`& .${stepConnectorClasses.line}`]: {
                    borderColor: "#784af4",
                  },
                },
                [`&.${stepConnectorClasses.completed}`]: {
                  [`& .${stepConnectorClasses.line}`]: {
                    borderColor: "#784af4",
                  },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default MISStepper;
