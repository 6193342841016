import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton } from "@mui/material";
import T from "T";
import { ArrowBack } from "@mui/icons-material";
import MISTextField from "components/common/MISTextField";
import MISAutocomplete from "components/common/MISAutocomplete";
import { get } from "utils/lodash";
import MISFooterButton from "components/common/MISFooterButton";
import { noop } from "lodash";
import PropTypes from "prop-types";

const ProfitCenterMutation = ({
  addEditProfitCenter = {},
  isAddEditProfitCenterOpen = bool,
  onHandleChange = noop,
  handleClose = noop,
  handleSubmitProfitCenter = noop,
}) => {
  return (
    <Dialog onClose={handleClose} open={isAddEditProfitCenterOpen}>
      <DialogTitle display={"inline-flex"} alignItems={"center"}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Box display="inline-flex" alignItems="center">
            <IconButton onClick={handleClose}>
              <ArrowBack />
            </IconButton>
            {get(addEditProfitCenter, "profitCenterHeadName", "")
              ? `${T.EDIT} ${get(addEditProfitCenter, "profitCenterHeadName", "")}`
              : `${T.ADD} ${T.NEW} ${T.PROFIT_CENTER}`}
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ height: "inherit" }}>
        <MISTextField
          label={T.PROFIT_CENTER}
          required
          placeholder={T.PROFIT_CENTER}
          autoComplete="on"
          size="small"
          variant="outlined"
          name="profitCenterName"
          value={get(addEditProfitCenter, "profitCenterName", "")}
          onChange={(e) => onHandleChange(e)}
        />
      </DialogContent>
      <DialogActions>
        <MISFooterButton
          proceedButtonText={addEditProfitCenter.profitCenterId ? T.UPDATE : T.SUBMIT}
          justify="end"
          sx={{ pb: 0.5 }}
          handleClose={handleClose}
          handleSubmit={
            addEditProfitCenter.profitCenterId
              ? () => handleSubmitProfitCenter(addEditProfitCenter.profitCenterId)
              : () => handleSubmitProfitCenter()
          }
        />
      </DialogActions>
    </Dialog>
  );
};

ProfitCenterMutation.propTypes = {
  addEditProfitCenter: PropTypes.object,
  isAddEditProfitCenterOpen: PropTypes.bool,
  onHandleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmitProfitCenter: PropTypes.func,
};

export default ProfitCenterMutation;
