import { useEffect, useReducer } from "react";
import { Box, Tab, Paper, styled, Typography } from "@mui/material";
import { PAGINATION } from "settings/constants/pagination";
import T from "T";
import { handleError } from "utils/error";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
import { get } from "lodash";
import TopBar from "./Topbar";
import MemberAssignmentTable from "./MemberAssignmentTable";
import { useLazyGetUserViewByIdQuery } from "api/members/getUserViewById";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyGetAssignmentListQuery } from "api/Assignment/getAssignmentList";
import { MISCurrentUser } from "utils/validations";
import { canAddTechAssignment } from "utils/permissions";
import MISLoader from "components/common/MISLoader";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

export const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: "capitalize",
  fontWeight: 700,
  fontSize: "14px",
  alignItems: "center",
  justifyContent: "space-between",
  color: theme.palette.background.black,
  minHeight: "unset",
  border: `1px solid ${theme.palette.border.tabsGrey}`,
}));

const MemberAssignment = () => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    tableData: [],
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
  });

  const { tableData, page, rowsPerPage } = localState;
  const navigate = useNavigate();
  const location = useLocation();
  const url = location.pathname;
  const id = Number(url.replace(/\D+/g, ""));

  const { user } = MISCurrentUser();
  const loggedUserId = get(user, "user.id", "");
  const [getUserViewById, { data: viewData }] = useLazyGetUserViewByIdQuery();
  const [getAssignmentList, { isLoading }] = useLazyGetAssignmentListQuery();

  const refreshView = () => {
    getUserViewById({ id }).unwrap().catch(handleError);
  };
  useEffect(() => {
    const userId = canAddTechAssignment() ? id : loggedUserId;
    getAssignmentList(userId)
      .unwrap()
      .then((res) => {
        setLocalState({ tableData: res });
      })
      .catch(handleError);
  }, []);

  useEffect(() => {
    if (id) {
      refreshView();
    }
  }, [id]);
  const record = get(viewData, "results", []);
  const fullName = get(record, "userName", "");
  const { totalTableRowsCount, totalPageCount } = getCurrentTableParams(tableData);

  // Page change handler
  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
  };

  // Rows per page change handler
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handleCreateAssignment = () => {
    navigate(`/app/add-assignment/${id}`);
  };

  return (
    <Paper display="block" justifycontent="flex-start" sx={{ borderRadius: 2, p: 2 }}>
      {isLoading && <MISLoader />}
      <Box display="flex" alignItems="center" mb={1}>
        {/* <ArrowBackIcon fontSize="small" sx={{ cursor: "pointer" }} onClick={handleBack} /> */}
        <Typography variant="h5" fontWeight={600} ml={1}>
          {`${fullName} ${T.ASSINMENTS_LIST}`}
        </Typography>
      </Box>
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      >
        {canAddTechAssignment() && <TopBar handleCreateAssignment={handleCreateAssignment} />}

        <MemberAssignmentTable
          allTableRows={tableData}
          totalTableRowsCount={totalTableRowsCount}
          totalPageCount={totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Paper>
  );
};

export default MemberAssignment;
