import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getUserInterviewHistory: builder.query({
      query: ({ email }) => ({
        url: `/pitchedUser/interviewHistory/fetchHistory`,
        method: "GET",
        params: { emailId: email },
      }),
    }),
  }),
});

export const { useLazyGetUserInterviewHistoryQuery } = extendedApi;
