import { Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { get } from "lodash";
import PropTypes from "prop-types";

const SecondaryGrid = ({ record }) => {
  const successRate = isNaN(record.successRate) || record.successRate === 0 ? "0" : `${record.successRate.toFixed(0)}`;

  return (
    <Grid container justifyContent={"flex-end"}>
      {[
        { text: "Pitched Candidates", number: get(record, "totalPitchedCandidates", 0), color: "#d3a82c" },
        { text: "Selected", number: get(record, "totalSelectedEmployees", 0), color: "#d3a82c" },
        { text: "Rejected", number: get(record, "totalRejectedEmployees", 0), color: "#d44c4b" },
        {
          text: "Success Rate",
          number: (
            <span>
              {successRate}
              <span style={{ fontSize: 20 }}>%</span>
            </span>
          ),
          color: "#40c55e",
        },
      ].map((box, index) => (
        <Grid
          item
          xs={12}
          md={2.5}
          key={index}
          sx={{
            display: "flex",
            justifyContent: "space-around",
            height: "60px",
            px: "25px",
            alignItems: "center",
            // backgroundColor: box.color,
            border: "1px solid #ccc",
            borderRadius: 2,
            "&:not(:last-child)": {
              marginRight: { md: 1.5 },
            },
          }}
        >
          <Typography fontSize={14} color={"black"} mr={2}>
            {box.text}
          </Typography>
          <Typography fontSize={25} sx={{ fontWeight: "bold", color: "#33333" }}>
            {box.number}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
SecondaryGrid.propTypes = {
  record: PropTypes.object,
};
export default SecondaryGrid;
