import PropTypes from "prop-types";
import noop from "lodash/noop";

import { Table as TableView, Paper, TableContainer, Box, Card } from "@mui/material";

import {
  TIME_SHEET_TABLE_HEADER,
  TIME_SHEET_TABLE_HEADER_FM,
  TIME_SHEET_TABLE_HEADER_PM,
  TIME_SHEET_TABLE_HEADER_PMO,
} from "settings/constants/members";

import { PAGINATION } from "settings/constants/pagination";

import { MISCurrentUser } from "utils/validations";
import { get } from "lodash";
import T from "T";
import { canViewPmFilter } from "utils/permissions";
import NCTableBody from "./NCTableBody";
import MasterPagination from "../MasterPagination";
import TableHeader from "../TableHeader";
import MISNoData from "components/common/MISNoData";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const NCListTable = ({
  page = INITIAL_PAGE,
  onPageChange,
  rowsPerPage = ROWS_PER_PAGE,
  onRowsPerPageChange,
  totalTableRowsCount = INITIAL_PAGE,
  totalPageCount = INITIAL_PAGE,
  allTableRows = [],
  handleDeleteNCDialog = noop,
  handleByDateUserNCDialog = noop,
}) => {
  const { user } = MISCurrentUser();
  const userRole = get(user, "role", "");
  return (
    <Card
      elevation={0}
      sx={{
        p: "0px 8px 0px 0px",
        mt: 1.5,
      }}
    >
      <TableContainer component={Paper} sx={{ height: "calc(100vh - 290px)", width: "inherit", overflow: "auto" }}>
        {allTableRows.length !== 0 ? (
          <TableView stickyHeader sx={{ borderSpacing: "0 8px" }}>
            <TableHeader
              columns={!canViewPmFilter() ? TIME_SHEET_TABLE_HEADER_PM : canViewPmFilter() && TIME_SHEET_TABLE_HEADER_PMO}
              // columns={TIME_SHEET_TABLE_HEADER}
            />
            <NCTableBody
              handleDeleteNCDialog={handleDeleteNCDialog}
              handleByDateUserNCDialog={handleByDateUserNCDialog}
              records={allTableRows}
            />
          </TableView>
        ) : (
          <MISNoData />
        )}
      </TableContainer>

      <MasterPagination
        page={page}
        onPageChange={onPageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={onRowsPerPageChange}
        totalTableRowsCount={totalTableRowsCount}
        totalPageCount={totalPageCount}
      />
    </Card>
  );
};

NCListTable.propTypes = {
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
  totalDataCount: PropTypes.number,
  totalPageCount: PropTypes.number,
  totalTableRowsCount: PropTypes.number,
  handleDeleteNCDialog: PropTypes.func,
  handleByDateUserNCDialog: PropTypes.func,
  onPageChange: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  allTableRows: PropTypes.array,
};
export default NCListTable;
