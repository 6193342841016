import api from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    postIntervieweeInfo: build.mutation({
      query: (body) => ({
        url: "/videoInterview/save",
        method: "POST",
        body,
      }),
    }),
  }),
});

export const { usePostIntervieweeInfoMutation } = extendedApi;
