import { Grid, Paper, Typography, Box, Card } from "@mui/material";
import FeedbackForm from "components/Training/Feedback/Form";
import React, { useEffect, useReducer, useState } from "react";
import TaskboxTable from "./TaskboxTable";
import T from "T";
import { PAGINATION } from "settings/constants/pagination";
import { useLazyGetAllTasksQuery } from "api/taskbox/getAllTasks";
import { handleError } from "utils/error";
import { MISCurrentUser } from "utils/validations";
import { useDispatch } from "react-redux";
import { setBadgeCount } from "slices/taskboxSlice";
import { groupBy, orderBy } from "lodash";
import { get } from "utils/lodash";
import TaskboxLabels from "./TaskboxLabels";
import MISNoData from "components/common/MISNoData";
import { getCurrentTableParams } from "data/members/memberTableSelectors";

const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const Taskbox = () => {
  const { user } = MISCurrentUser();
  const dispatch = useDispatch();
  //API Endpoints
  const [getAllTasks] = useLazyGetAllTasksQuery();
  //Local State
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    labelId: 0,
    tasks: [],
    labeledTasks: [],
  });
  const { labelId, tasks, labeledTasks } = localState;

  useEffect(() => {
    getAllTaskbox();
  }, []);

  // ################################ All Tasks API ############################
  const getAllTaskbox = () => {
    const payload = {
      isReportingManger: null,
      text: null,
      userEmail: user?.user?.userEmailId ?? null,
    };

    getAllTasks({ page: 0, rowPerPage: 10000, payload })
      .unwrap()
      .then((res) => {
        const { allTableRows } = getCurrentTableParams(res);
        const sortedRows = orderBy(allTableRows, ["taskType"], ["asc"]);
        const countsArray = Object.entries(groupBy(sortedRows, (item) => get(item, "taskType", ""))).map(
          ([labelType, items], index) => ({
            id: index,
            labelType,
            count: items.length,
            data: items,
          }),
        );
        if (labelId !== 0 && labelId > countsArray.length - 1) {
          // logic to check if the labelId is greater than the length of the countsArray
          setLocalState({ labelId: countsArray.length - 1 });
        }
        setLocalState({ tasks: sortedRows, labeledTasks: countsArray });
        dispatch(setBadgeCount({ badgeCount: get(res, "numberOfRecords", 0) }));
      })
      .catch(handleError);
  };

  // ################################ Helper Functions ############################
  const handleLabelClick = (labelId) => {
    setLocalState({ labelId: labelId });
  };

  return (
    <Paper display="block" sx={{ borderRadius: 2, p: 2, height: "79vh" }}>
      <Typography variant="h5" fontWeight={600}>
        {"Taskbox"}
      </Typography>
      {/* <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      > */}
      <Card
        elevation={0}
        sx={{
          mt: 1.5,
          backgroundColor: "background.card",
          "& .add-shadow": { boxShadow: "inset -6px 0px 5px -5px rgb(0 0 0 / 15%)" },
          height: "calc(100vh - 195px)",
        }}
      >
        <Grid container>
          {labeledTasks.length !== 0 ? (
            <>
              <Grid item xs={12} md={3}>
                <TaskboxLabels allTableRows={labeledTasks} handleLabelClick={handleLabelClick} labelId={labelId} />
              </Grid>
              <Grid item xs={12} md={9}>
                <TaskboxTable allTableRows={labeledTasks[labelId].data} refreshTable={getAllTaskbox} />
              </Grid>
            </>
          ) : (
            <MISNoData />
          )}
        </Grid>
      </Card>
      {/* </Box> */}
    </Paper>
  );
};

export default Taskbox;

export const TASKBOX_HEADER_COLUMNS = [
  { label: T.S_NO },
  { label: "EMPLOYEE NAME" },
  { label: "TASK NAME" },
  { label: "TASK" },
  { label: "Trigger Date" },
  { label: "Due Date" },
  { label: "Actions" },
];
