import { api } from "api";

const extendedApi = api.injectEndpoints({
  endpoints: (builder) => ({
    sendEmailToPartnerById: builder.query({
      query: ({ id }) => ({
        url: `preSalesMapping/get/presalesJD/?preSalesMappingId=${id}`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazySendEmailToPartnerByIdQuery } = extendedApi;
