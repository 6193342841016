import { Children } from "react";
import PropTypes from "prop-types";
import { noop, isEmpty, orderBy, camelCase } from "lodash";

import { Typography, Divider, Card, Box, CardActions, CardContent } from "@mui/material";
import MISAutocomplete from "components/common/MISAutocomplete";
import { ClickAwayListener } from "@mui/material";
import { GROUP_FILTER_OPTIONS } from "settings/constants/filter";
import { EMP_STATUS, PROJECT_MODE, AVAILABILITY, EMP_MODE_LIST } from "components/Members/Member/memberModel";

import { GET_SIZE } from "utils/responsive";
import { isNumber } from "utils/validations";

import T from "T";

import { get } from "utils/lodash";
import MISTextField from "components/common/MISTextField";
import { toast } from "react-toastify";
import MISButton from "components/common/MISButton";

const FilterOptions = ({
  filters = {},
  projectManagers = {},
  workLocationList = {},
  skillList = {},
  departmentList = {},
  projectList = {},
  handleFilterReset = noop,
  handleFilterSubmit = noop,
  handleFilterChange = noop,
  handleClose = noop,
}) => {
  const technologyList = get(skillList, "results", []);
  const locationList = get(workLocationList, "results", []);
  const projManagers = get(projectManagers, "results", []);
  const projects = get(projectList, "results", []);
  const departments = get(departmentList, "results", []);
  const { isXs } = GET_SIZE();
  const occurrenceCount = {};
  for (let proj of projects) {
    if (occurrenceCount[proj.name]) {
      occurrenceCount[proj.name]++;
    } else {
      occurrenceCount[proj.name] = 1;
    }
  }
  const getFilterRecords = (val) => {
    switch (val) {
      case T.PRIMARY_SKILL:
        return technologyList;
      case T.SECONDARY_SKILL:
        return technologyList;
      case T.WORK_LOCATION:
        return locationList;
      case T.DEPARTMENT:
        return departments;
      case T.EMP_MODE:
        return EMP_MODE_LIST;
      case T.EMP_STATUS:
        return EMP_STATUS;
      case T.PROJECT:
        return projects;
      case T.PROJECT_MODE:
        return PROJECT_MODE;
      case T.PROJECT_MANAGER:
        return orderBy(projManagers, ["name"], ["asc"]);
      case T.AVAILABILITY:
        return AVAILABILITY;
      case T.EXPERIENCE:
        return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      default:
    }
  };

  const getLabelData = (option, index) => {
    const optionVal = isEmpty(option) ? "" : option;

    switch (index) {
      case 0:
        return get(option, "skillName", "");
      case 1:
        return get(option, "skillName", "");
      case 2:
        return get(option, "workLocationName", "");
      case 3:
        return get(option, "departmentName", "");
      case 4:
        return optionVal;
      case 5:
        return optionVal;
      case 6:
        return occurrenceCount[option.name] === 1
          ? `${option.name}`
          : `${option.name} | ${option.department} | ${option.projectType}`;
      case 7:
        return optionVal;
      case 8:
        return optionVal;
      case 9:
        return get(option, "name", "");
      default:
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Card
        sx={{
          position: "absolute",
          right: "0px",
          width: isXs ? "100%" : "350px",
          zIndex: 400,
        }}
      >
        <CardContent
          sx={{
            maxHeight: "calc(100vh - 420px)",
            overflow: "auto",
          }}
        >
          {Children.toArray(
            GROUP_FILTER_OPTIONS.map((item, index) => {
              const lists = getFilterRecords(item);

              const multiple = [
                T.PRIMARY_SKILL,
                T.SECONDARY_SKILL,
                T.WORK_LOCATION,
                T.DEPARTMENT,
                T.PROJECT,
                T.PROJECT_MANAGER,
              ].includes(item);

              const isYearRange = [`${T.EXPERIENCE} (In Years)`, T.TENURE_WITH_NETSMARTZ].includes(item);

              return (
                <Box display="flex" p="5px 10px" justifyContent="space-between" alignItems={"center"}>
                  <Typography variant="subtitle1" noWrap>
                    {item}
                  </Typography>

                  {isYearRange ? (
                    <Box display="flex">
                      <MISTextField
                        required
                        placeholder={T.MIN}
                        size="small"
                        variant="outlined"
                        name={camelCase(`${item} minExp`)}
                        sx={{ width: 75 }}
                        value={filters[camelCase(`${item} minExp`)] || ""}
                        onChange={(event) => {
                          const { value } = event.target;

                          if (value && !isNumber(value)) {
                            toast.error(T.ONLY_NUMBERS_ALLOWED);
                            return;
                          }

                          handleFilterChange(value, camelCase(`${item} minExp`));
                        }}
                      />

                      <MISTextField
                        required
                        placeholder={T.MAX}
                        size="small"
                        variant="outlined"
                        name={camelCase(`${item} maxExp`)}
                        sx={{ ml: 2, width: 75 }}
                        value={filters[camelCase(`${item} maxExp`)] || ""}
                        onChange={(event) => {
                          const { value } = event.target;
                          if (value && !isNumber(value)) {
                            toast.error(T.ONLY_NUMBERS_ALLOWED);
                            return;
                          }

                          handleFilterChange(value, camelCase(`${item} maxExp`));
                        }}
                      />
                    </Box>
                  ) : (
                    <MISAutocomplete
                      multiple={multiple}
                      listDetails={lists}
                      size="small"
                      placeholder={T.SELECT}
                      sx={{
                        width: 170,
                      }}
                      value={filters[camelCase(item)] || []}
                      getByLabelText={(option) => getLabelData(option, index)}
                      onHandleChange={(event, newValue) => {
                        handleFilterChange(newValue, item);
                      }}
                    />
                  )}
                </Box>
              );
            }),
          )}
        </CardContent>
        <Divider />
        <CardActions>
          <MISButton variant="outlined" onClick={handleFilterReset}>
            {T.RESET}
          </MISButton>
          <MISButton variant="contained" onClick={handleFilterSubmit}>
            {T.APPLY}
          </MISButton>
        </CardActions>
      </Card>
    </ClickAwayListener>
  );
};

FilterOptions.propTypes = {
  projectManagers: PropTypes.object,
  workLocationList: PropTypes.object,
  skillList: PropTypes.object,
  departmentList: PropTypes.object,
  projectList: PropTypes.object,
  filters: PropTypes.object,
  handleFilterChange: PropTypes.func,
  handleFilterSubmit: PropTypes.func,
  handleFilterReset: PropTypes.func,
  handleClose: PropTypes.func,
};

export default FilterOptions;
