import { Box } from "@mui/system";
import { useLazyGetProjectListQuery } from "api/projects/getProjectList";
import { useLazyGetSkillListQuery } from "api/skills/getSkillList";
import MISFooterButton from "components/common/MISFooterButton";
import { get } from "lodash";
import { useEffect, useReducer } from "react";
import { PAGINATION } from "settings/constants/pagination";
import T from "T";
import { useLazyGetAllSelectionProcessQuery } from "api/preSales/getAllSelectionProcess";
import { useLazyGetExpListQuery } from "api/preSales/getExpList";
import { useLazyGetPreSalesRegionQuery } from "api/preSales/getPreSalesRegions";
import { useLazyGetPreSalesSourceQuery } from "api/preSales/getPreSalesSource";
import { useLazyGetShiftTimingsListQuery } from "api/preSales/getShiftTimingsList";
import { useLazyGetPreSalesManagersQuery } from "api/preSales/getPreSalesManagers";
import { useSavePreSalesProjectMutation } from "api/preSales/addPreSalesProject";
import { toast } from "react-toastify";
import { handleError } from "utils/error";
import { useLocation, useNavigate } from "react-router-dom";
import { useLazyFindPreSalesByIdQuery } from "api/preSales/findPreSalesById";
import { useUpdatePreSalesProjectMutation } from "api/preSales/updatePreSalesProject";
import { useLazyGetProjectManagerQuery } from "api/projects/getProjectManager";
import { useLazyGetAllJobTitleQuery } from "api/preSales/getPreSalesJobTitle";
import CreateProject from "./CreateProject";
import ConfirmCancel from "./ConfirmCancel";
import MISLoader from "components/common/MISLoader";
import ConfirmSubmit from "components/Members/Member/ConfirmSubmit";
import { psCardStore } from "slices/preSalesCardSlice";
import { useDispatch } from "react-redux";

const { INITIAL_PAGE } = PAGINATION;
const ROWS_PER_PAGE = 10000;

const Form = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const url = location.pathname;
  const id = url.includes("edit") && Number(url.replace(/\D+/g, ""));
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    leadName: "",
    companyName: "",
    companyWebsiteLink: "",
    sourceType: "",
    startDate: null,
    endData: null,
    isExistingClient: false,
    clientsPreviousProjectId: "",
    clientRegion: "",
    priorContactWithNetsmartz: false,
    desiredSelectionProcess: "",
    isTrialOffered: false,
    shiftTiming: "",
    clientRemarks: "",
    requirementStatus: "",
    requirementFor: "BYT",
    requirementType: "New",
    preferredTech: [],
    file: [],
    clientFileLink: null,
    base64File: "",
    assignedTo: "",
    isRequirementStatusChanged: false,
    openModal: false,
    preSalesRequirements: [
      {
        file: "",
        fileName: "",
        preSalesJobTitleId: "",
        budget: "",
        requiredExperienceId: "",
        goodToHaveSkills: [],
        mustToHaveSkills: [],
        numberOfPosts: "",
        designation: "",
        preRequisites: "",
      },
    ],
    updatePreSalesRequirements: [
      {
        id: "",
        preSalesJobTitleId: "",
        budget: "",
        requiredExperienceId: "",
        goodToHaveSkills: [],
        mustToHaveSkills: [],
        primaryskillsIds: [],
        secondaryskillsIds: [],
        numberOfPosts: "",
        designation: "",
        preRequisites: "",
        jobStatus: "",
        openConfirmCancel: false,
        file: "",
        fileName: "",
      },
    ],
    savedPreSalesRequirements: [],
  });

  const {
    leadName,
    companyName,
    companyWebsiteLink,
    sourceType,
    startDate,
    endDate,
    isExistingClient,
    clientsPreviousProjectId,
    clientRegion,
    priorContactWithNetsmartz,
    desiredSelectionProcess,
    isTrialOffered,
    shiftTiming,
    clientRemarks,
    assignedTo,
    requirementStatus,
    requirementFor,
    requirementType,
    file,
    clientFileLink,
    base64File,
    preferredTech,
    isRequirementStatusChanged,
    preSalesRequirements,
    updatePreSalesRequirements,
    savedPreSalesRequirements,
    openConfirmCancel,
    openModal,
  } = localState;

  const [savePreSalesProject, { isLoading: isSavingPresales }] = useSavePreSalesProjectMutation();
  const [updatePreSalesProject, { isLoading: isUpdatingPresales }] = useUpdatePreSalesProjectMutation();
  const [getProjectList, { data: projectList }] = useLazyGetProjectListQuery();
  const [getProjectManager, { data: projectManagerList }] = useLazyGetProjectManagerQuery();
  const [getSkillList, { data: skillList }] = useLazyGetSkillListQuery();
  const [getExpList, { data: expList }] = useLazyGetExpListQuery();
  const [getAllJobTitle, { data: jobTitleList }] = useLazyGetAllJobTitleQuery();
  const [getPreSalesRegion, { data: preSalesRegionList }] = useLazyGetPreSalesRegionQuery();
  const [getPreSalesSource, { data: preSalesSourceList }] = useLazyGetPreSalesSourceQuery();
  const [getShiftTimingsList, { data: shiftTimingsList }] = useLazyGetShiftTimingsListQuery();
  const [getPreSalesManagers, { data: preSalesManagersList }] = useLazyGetPreSalesManagersQuery();
  const [getAllSelectionProcess, { data: selectionProcessList }] = useLazyGetAllSelectionProcessQuery();
  const [findPreSalesById, { isLoading: isPreSalesLoading }] = useLazyFindPreSalesByIdQuery();

  useEffect(() => {
    getExpList();
    getAllJobTitle();
    getPreSalesRegion();
    getPreSalesSource();
    getShiftTimingsList();
    getAllSelectionProcess();
    getPreSalesManagers();
    getProjectManager({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE });
    getProjectList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE });
    getSkillList({ page: INITIAL_PAGE, rowPerPage: ROWS_PER_PAGE });
  }, []);
  useEffect(() => {
    if (!isExistingClient) {
      setLocalState({ clientsPreviousProjectId: "" });
    }
  }, [isExistingClient]);
  useEffect(() => {
    if (id) {
      findPreSalesById({ id })
        .unwrap()
        .then((res) => {
          const requirements = get(res, "preSalesMapping", []);
          const prevRequirements = {
            leadName: get(res, "salesManagers.id", ""),
            companyName: get(res, "companyName", ""),
            startDate: get(res, "startDate", null),
            endDate: get(res, "endDate", null),
            companyWebsiteLink: get(res, "companyLink", ""),
            sourceType: get(res, "source.id", ""),
            isExistingClient: get(res, "isExistingClient", false),
            clientsPreviousProjectId: get(res, "existingProject.id", ""),
            clientRegion: get(res, "clientRegion.id", ""),
            priorContactWithNetsmartz: get(res, "hasContactedNtzBefore", false),
            desiredSelectionProcess: get(res, "selectionProcess.id", ""),
            isTrialOffered: get(res, "isTrialPeriodOffered", false),
            shiftTiming: get(res, "shiftTimings.id", ""),
            clientRemarks: get(res, "remarks", ""),
            requirementStatus: get(res, "preSalesStatus", ""),
            requirementFor: get(res, "requirementFor", ""),
            requirementType: get(res, "requirementType", ""),
            preferredTech: get(res, "preferredTech", []),
            file: get(res, "file", []),
            clientFileLink: get(res, "clientFileLink", null),
            assignedTo: get(res, "projectManager.id", ""),
            updatePreSalesRequirements:
              requirements.length > 0
                ? requirements.map((data) => {
                    const primarySkills = get(data, "preSalesSkillsMapping", [])
                      .filter((rec) => rec.isPrimary === true)
                      .map((item) => item.tech);
                    const secondarySkills = get(data, "preSalesSkillsMapping", [])
                      .filter((rec) => rec.isPrimary === false)
                      .map((item) => item.tech);
                    const primaryskillsIds = get(data, "preSalesSkillsMapping", [])
                      .filter((rec) => rec.isPrimary === true)
                      .map((item) => item.id);
                    const secondaryskillsIds = get(data, "preSalesSkillsMapping", [])
                      .filter((rec) => rec.isPrimary === false)
                      .map((item) => item.id);
                    return {
                      id,
                      preSalesJobTitleId: get(data, "preSalesJobTitleId", ""),
                      preSalesId: get(data, "id", ""),
                      budget: get(data, "budget", ""),
                      requiredExperienceId: get(data, "requiredExperience.id", ""),
                      goodToHaveSkills: secondarySkills,
                      mustToHaveSkills: primarySkills,
                      primaryskillsIds,
                      secondaryskillsIds,
                      numberOfPosts: get(data, "numberOfPosts", ""),
                      designation: get(data, "designation", ""),
                      preRequisites: get(data, "preRequisites", ""),
                      jobStatus: get(data, "preSalesMappingStatus", ""),
                      file: get(data, "file", ""),
                      fileName: get(data, "fileName", ""),
                    };
                  })
                : [emptyPreSalesRequirements],
            savedPreSalesRequirements:
              requirements.length > 0
                ? requirements.map((data) => {
                    const primarySkills = get(data, "preSalesSkillsMapping", [])
                      .filter((rec) => rec.isPrimary === true)
                      .map((item) => item.tech);
                    const secondarySkills = get(data, "preSalesSkillsMapping", [])
                      .filter((rec) => rec.isPrimary === false)
                      .map((item) => item.tech);
                    return {
                      id: get(data, "id", ""),
                      budget: get(data, "budget", ""),
                      preSalesJobTitleId: get(data, "preSalesJobTitleId", ""),
                      requiredExperienceId: get(data, "requiredExperience.id", ""),
                      goodToHaveSkills: secondarySkills,
                      mustToHaveSkills: primarySkills,
                      numberOfPosts: get(data, "numberOfPosts", ""),
                      designation: get(data, "designation", ""),
                      preRequisites: get(data, "preRequisites", ""),
                      jobStatus: get(data, "preSalesMappingStatus", ""),
                      file: get(data, "file", ""),
                      fileName: get(data, "fileName", ""),
                    };
                  })
                : [emptyPreSalesRequirements],
          };
          setLocalState(prevRequirements);
        })
        .catch(handleError);
    }
  }, [id]);

  const emptyPreSalesRequirements = {
    preSalesJobTitleId: "",
    budget: "",
    requiredExperienceId: "",
    goodToHaveSkills: [],
    mustToHaveSkills: [],
    numberOfPosts: "",
    designation: "",
    preRequisites: "",
    file: "",
    fileName: "",
  };
  const projectListDetails = get(projectList, "results", []);
  const projectManagerDetails = get(projectManagerList, "results", []);
  const skillListDetails = get(skillList, "results", []);

  const onHandleChange = (event) => {
    const { name, value } = event.target;
    setLocalState({ [name]: value });
  };

  const convertBase64 = (file) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result.split(",")[1]);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  const handleFileUpload = async (event, index) => {
    const { name } = event.target;
    const file = event.target.files[0];
    const fileName = event.target.files[0].name;
    const base64 = await convertBase64(file);
    if (id) {
      updatePreSalesRequirements[index][name] = fileName;
      updatePreSalesRequirements[index].file = base64;
    } else {
      preSalesRequirements[index][name] = fileName;
      preSalesRequirements[index].file = base64;
    }
    setLocalState({ preSalesRequirements });
  };
  const onHandleRequirementsChange = (event, index) => {
    const { name, value } = event.target;
    if (id) {
      updatePreSalesRequirements[index][name] = value;
      setLocalState({ updatePreSalesRequirements });
    } else {
      preSalesRequirements[index][name] = value;
      setLocalState({ preSalesRequirements });
    }
  };
  const onHandleAutoCompleteChange = (type, value) => {
    if (id && type === "requirementStatus") {
      setLocalState({ [type]: value });
      if (value !== T.PARTIALLY_CLOSED) {
        preSalesRequirements.map((item) => (item.jobStatus = value));
        setLocalState({ preSalesRequirements });
        setLocalState({ isRequirementStatusChanged: true });
      } else {
        setLocalState({ isRequirementStatusChanged: false });
      }
    }
    setLocalState({ [type]: value });
  };
  const onHandleRequirementsAutoCompleteChange = (index, type, value) => {
    if (id) {
      updatePreSalesRequirements[index][type] = value;
      setLocalState({ updatePreSalesRequirements });
    } else {
      preSalesRequirements[index][type] = value;
      setLocalState({ preSalesRequirements });
    }
  };
  const onHandleTechAutoCompleteChange = (type, value) => {
    setLocalState({ [type]: value });
  };

  const onhandleSkillChange = (type, value) => {
    setLocalState({ [type]: value });
  };

  const onHandleReqAddMore = () => {
    if (id) {
      updatePreSalesRequirements.push(emptyPreSalesRequirements);
      setLocalState({ updatePreSalesRequirements });
    } else {
      preSalesRequirements.push(emptyPreSalesRequirements);
      setLocalState({ preSalesRequirements });
    }
  };
  const onHandleReqRemove = (index) => {
    if (id) {
      updatePreSalesRequirements.splice(index, 1);
      setLocalState({ updatePreSalesRequirements });
    }
    preSalesRequirements.splice(index, 1);
    setLocalState({ preSalesRequirements });
  };
  const handleClose = () => {
    navigate("/app/pre-sales");
  };
  const renderNewProjectDetails = () => ({
    id,
    leadName,
    companyName,
    companyWebsiteLink,
    sourceType,
    isExistingClient,
    clientsPreviousProjectId,
    assignedTo,
    clientRegion,
    priorContactWithNetsmartz,
    desiredSelectionProcess,
    isTrialOffered,
    shiftTiming,
    clientRemarks,
    requirementStatus,
    requirementFor,
    requirementType,
    file,
    clientFileLink,
    preferredTech,
    projectListDetails,
    projectManagerDetails,
    skillListDetails,
    isRequirementStatusChanged,
    preSalesRequirements: id ? updatePreSalesRequirements : preSalesRequirements,
    savedPreSalesRequirements,
    expList,
    jobTitleList,
    preSalesRegionList,
    preSalesSourceList,
    shiftTimingsList,
    selectionProcessList,
    preSalesManagersList,
    handleFileUpload,
    getPreSalesManagers,
    onHandleReqAddMore,
    onHandleReqRemove,
    onHandleRequirementsAutoCompleteChange,
    onHandleTechAutoCompleteChange,
    onHandleChange,
    onHandleRequirementsChange,
    onhandleSkillChange,
    onHandleAutoCompleteChange,
    handleFilesChange,
    extractFileName,
  });
  const getPreSalesRequirements = () => {
    const requirements = [];
    preSalesRequirements.map((req) => {
      const technologyDetails = [];
      const primarySkills = get(req, "mustToHaveSkills", []);
      const secondarySkills = get(req, "goodToHaveSkills", []);
      primarySkills.map((skills) => technologyDetails.push({ isPrimary: true, techId: get(skills, "id", "") }));
      secondarySkills.map((skills) => technologyDetails.push({ isPrimary: false, techId: get(skills, "id", "") }));
      requirements.push({
        budget: get(req, "budget", ""),
        requiredExperienceId: get(req, "requiredExperienceId", ""),
        preSalesJobTitleId: get(req, "preSalesJobTitleId", ""),
        numberOfPosts: get(req, "numberOfPosts", ""),
        designation: get(req, "designation", ""),
        preRequisites: get(req, "preRequisites", ""),
        preSalesSkillsMappingRequestDto: technologyDetails,
        file: get(req, "file", ""),
        fileName: get(req, "fileName", ""),
      });
      return requirements;
    });
    return requirements;
  };

  const getPreSalesUpdateRequirements = () => {
    const requirements = [];
    updatePreSalesRequirements.map((req) => {
      const technologyDetails = [];
      const primarySkills = get(req, "mustToHaveSkills", []);
      const secondarySkills = get(req, "goodToHaveSkills", []);
      const primaryskillsIds = get(req, "primaryskillsIds", []);
      const secondaryskillsIds = get(req, "secondaryskillsIds", []);
      primarySkills.map((skills, index) =>
        technologyDetails.push({ id: primaryskillsIds[index] || "", isPrimary: true, techId: get(skills, "id", "") }),
      );
      secondarySkills.map((skills, index) =>
        technologyDetails.push({ id: secondaryskillsIds[index] || "", isPrimary: false, techId: get(skills, "id", "") }),
      );
      requirements.push({
        budget: get(req, "budget", ""),
        requiredExperienceId: get(req, "requiredExperienceId", ""),
        preSalesJobTitleId: get(req, "preSalesJobTitleId", ""),
        numberOfPosts: get(req, "numberOfPosts", ""),
        designation: get(req, "designation", ""),
        preSalesId: id,
        id: get(req, "preSalesId", ""),
        preRequisites: get(req, "preRequisites", ""),
        preSalesMappingStatus: get(req, "jobStatus", ""),
        preSalesSkillsMappingCommonUpdateRequestDto: technologyDetails,
        file: get(req, "file", ""),
        fileType: get(req, "fileName", ""),
      });
      return requirements;
    });
    return requirements;
  };

  const extractFileName = (clientFileLink) => {
    if (clientFileLink !== null) {
      const parts = clientFileLink.split("/").pop().split("_");
      const slicedParts = parts.slice(1);
      const fileName = slicedParts.join("_");
      return fileName;
    } else {
      return clientFileLink;
    }
  };

  const handleSubmit = () => {
    const isBytRequirementEmpty =
      (requirementFor === "BYT" || !requirementFor) &&
      (!leadName ||
        requirementFor === null ||
        !companyName ||
        !sourceType ||
        !clientRegion ||
        (isExistingClient && !requirementType) ||
        !desiredSelectionProcess ||
        !shiftTiming ||
        (isExistingClient && !clientsPreviousProjectId) ||
        preSalesRequirements.some(
          (item) =>
            !item.budget ||
            !item.preSalesJobTitleId ||
            !item.requiredExperienceId ||
            !item.goodToHaveSkills ||
            !item.mustToHaveSkills ||
            !item.numberOfPosts ||
            !item.designation ||
            !item.preRequisites,
        ));

    const isTMRequirementEmpty =
      (requirementFor === "T&M" || requirementFor === "Fixed Cost") &&
      (!leadName ||
        requirementFor === null ||
        !companyName ||
        !sourceType ||
        !clientRegion ||
        (isExistingClient && !clientsPreviousProjectId));

    if (isBytRequirementEmpty || isTMRequirementEmpty) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return;
    }
    const hasPostsMoreThan9 = preSalesRequirements.some((item) => item.numberOfPosts > 9);
    if (hasPostsMoreThan9) {
      toast.error(T.POSTS_MORE_THAN_9);
      return;
    }

    const psRequirements = getPreSalesRequirements();
    const payload = {
      clientRegionId: clientRegion,
      companyLink: companyWebsiteLink,
      companyName,
      isExistingClient,
      existingProjectId: clientsPreviousProjectId,
      hasContactedNtzBefore: priorContactWithNetsmartz,
      isTrialPeriodOffered: isTrialOffered,
      preSalesMappingRequests: psRequirements,
      remarks: clientRemarks,
      salesManagerId: leadName,
      selectionProcessId: desiredSelectionProcess,
      shiftTimingsId: shiftTiming,
      sourceId: sourceType,
      requirementFor: requirementFor,
      requirementType: requirementType,
      preferredTech: preferredTech,
      file: base64File,
      fileType: file.length > 0 ? file[0].name : null,
    };

    savePreSalesProject(payload)
      .unwrap()
      .then((res) => {
        toast.success(T.REQUIREMENT_ADDED_SUCCESSFULLY);
        handleClose();
        dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
      })
      .catch(handleError);
  };

  const handleUpdate = () => {
    const isBytRequirementEmpty =
      requirementFor === "BYT" &&
      (!leadName ||
        requirementFor === null ||
        !companyName ||
        !sourceType ||
        !clientRegion ||
        !assignedTo ||
        (isExistingClient && !requirementType) ||
        !desiredSelectionProcess ||
        !shiftTiming ||
        (isExistingClient && !clientsPreviousProjectId) ||
        updatePreSalesRequirements.find(
          (item) =>
            !item.budget ||
            !item.preSalesJobTitleId ||
            !item.requiredExperienceId ||
            !item.goodToHaveSkills ||
            !item.mustToHaveSkills ||
            !item.numberOfPosts ||
            !item.designation ||
            !item.preRequisites,
        ));
    const isTMRequirementEmpty =
      (requirementFor === "T&M" || requirementFor === "Fixed Cost") &&
      (!leadName ||
        requirementFor === null ||
        !companyName ||
        !sourceType ||
        !clientRegion ||
        (isExistingClient && !clientsPreviousProjectId));

    if (isBytRequirementEmpty || isTMRequirementEmpty) {
      toast.error(T.REQUIRED_FIELDS_EMPTY);
      return;
    }
    const hasPostsMoreThan9 = preSalesRequirements.some((item) => item.numberOfPosts > 9);
    if (hasPostsMoreThan9) {
      toast.error(T.POSTS_MORE_THAN_9);
      return;
    }
    const psRequirements = getPreSalesUpdateRequirements();

    const payload = {
      clientRegionId: clientRegion,
      companyLink: companyWebsiteLink,
      companyName,
      startDate: startDate ? startDate.split(" ")[0] : null,
      endDate: endDate ? endDate.split(" ")[0] : null,
      isExistingClient,
      existingProjectId: clientsPreviousProjectId,
      hasContactedNtzBefore: priorContactWithNetsmartz,
      id,
      isTrialPeriodOffered: isTrialOffered,
      preSalesMappingCommonUpdateRequestDto: psRequirements,
      remarks: clientRemarks,
      salesManagerId: leadName,
      selectionProcessId: desiredSelectionProcess,
      shiftTimingsId: shiftTiming,
      sourceId: sourceType,
      preSalesStatus: requirementStatus,
      assignedTo,
      requirementFor: requirementFor,
      requirementType: requirementType,
      preferredTech: preferredTech,
      file: base64File,
      fileType: file.length > 0 ? file[0].name : null,
    };

    updatePreSalesProject({ id, preSalesUpdateRequestDto: payload })
      .unwrap()
      .then((res) => {
        toast.success(T.REQUIREMENT_UPDATED_SUCCESSFULLY);
        handleClose();
        dispatch(psCardStore({ selectedCardId: null, selectedCard: [], scrollPosition: 0 }));
      })
      .catch(handleError);
  };
  const confrmCancelModal = () => {
    setLocalState({ openConfirmCancel: !openConfirmCancel });
  };

  const handleFilesChange = async (updatedFiles) => {
    const file = updatedFiles[0];
    const base64 = await convertBase64(file);
    setLocalState({ base64File: base64, file: updatedFiles });
  };

  const handleCloseModal = () => {
    setLocalState({ openModal: false });
  };

  const handleSubmitModal = () => {
    setLocalState({ openModal: true });
  };
  return (
    <>
      {isPreSalesLoading && <MISLoader />}

      <Box
        // mb={2}
        sx={{
          height: "calc(100vh - 205px)",
          overflowY: "auto",
        }}
      >
        <CreateProject {...renderNewProjectDetails()} />
      </Box>
      <MISFooterButton
        proceedButtonText={id ? T.UPDATE : T.SUBMIT}
        justify="end"
        sx={{ pb: 0.5 }}
        handleClose={confrmCancelModal}
        handleSubmit={handleSubmitModal}
      />
      <ConfirmCancel openConfirmCancel={openConfirmCancel} confrmCancelModal={confrmCancelModal} handleClose={handleClose} />
      <ConfirmSubmit
        cancelButtonText={T.NO}
        proceedButtonText={T.YES}
        dialogMessageText={T.CONFIRM_SUBMIT}
        openConfirmSubmit={openModal}
        handleClose={handleCloseModal}
        handleSubmit={id ? handleUpdate : handleSubmit}
        disableSubmit={isSavingPresales || isUpdatingPresales}
        showLoader={isSavingPresales || isUpdatingPresales}
      />
    </>
  );
};

export default Form;
