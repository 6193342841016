import { Box, Paper, Typography } from "@mui/material";
import T from "T";
import TopBar from "./Topbar";
import InsideSalesTab from "./InsideSalesTab";
import { useGetInsideSalesByFilterMutation } from "api/InsideSales/filter";
import { useEffect, useReducer } from "react";
import { handleError } from "utils/error";
import MISLoader from "components/common/MISLoader";
import usePMFetch from "hooks/usePMFetch";
import { insideSalesFilterStore } from "slices/insideSalesFilterSlice";
import { useDispatch } from "react-redux";
import { isEqual, orderBy } from "lodash";
import Constants from "Constants";
import { PAGINATION } from "settings/constants/pagination";
import { getCurrentTableParams } from "data/members/memberTableSelectors";
const { SERVER_URL } = Constants;
const { INITIAL_PAGE, ROWS_PER_PAGE } = PAGINATION;

const InsideSales = () => {
  const [localState, setLocalState] = useReducer((prevState, newState) => ({ ...prevState, ...newState }), {
    filters: {},
    filteredData: { allTableRows: [], totalTableRowsCount: null, totalPageCount: null },
    departmentName: ["BYT"],
    empStatus: "Stable",
    empMode: "In-House",
    page: INITIAL_PAGE,
    rowsPerPage: ROWS_PER_PAGE,
    totalTableRowsCount: INITIAL_PAGE,
    totalPageCount: INITIAL_PAGE,
    isFilterEmpty: true,
  });
  const dispatch = useDispatch();
  const { filters, filteredData, departmentName, empStatus, empMode, page, rowsPerPage, isFilterEmpty } = localState;

  const [insideSalesByFilter, { isLoading }] = useGetInsideSalesByFilterMutation();
  const { skillList } = usePMFetch();
  const { minExp, maxExp, Technology } = localState.filters;
  const technologies = Technology?.map((item) => item.skillName);

  useEffect(() => {
    getInsideSalesByFilter();
    handleFilterSubmit();
  }, [page, rowsPerPage, filters]);

  const emptyFilters = {};

  const getInsideSalesByFilter = () => {
    const data = {
      departmentName,
      totalExp: minExp && maxExp ? `${minExp}-${maxExp}` : "",
      empMode,
      technologies: technologies ? technologies : [],
      empStatus,
    };
    insideSalesByFilter({ page, rowsPerPage, data })
      .unwrap()
      .then((res) => {
        const { allTableRows, totalTableRowsCount, totalPageCount } = getCurrentTableParams(res);

        let sortedTableRows;

        if (technologies && technologies.length > 0) {
          sortedTableRows = orderBy(
            allTableRows,
            [
              (user) => {
                const matchingSkills = user.userSkills.filter((skill) => technologies.includes(skill.skills.skillName));

                // First, put users with same skills as in technologies irrespective of primary true or false. If there are multiple users with the same skills, it puts them first (returning 0)
                if (matchingSkills.length > 1) {
                  return 0;
                }

                // Then, it puts users with a primary skill matching the technologies (returning 1).
                const primarySkill = user.userSkills.find(
                  (skill) => skill.primary && technologies.includes(skill.skills.skillName),
                );
                return primarySkill ? 1 : 2;
              },

              // Finally, it sorts the remaining users based on the order of their skills in technologies.
              (user) => user.userSkills.map((skill) => skill.skills.skillName).join(","),
            ],
            ["asc", "asc"],
          );
        } else {
          sortedTableRows = allTableRows;
        }

        setLocalState({
          filteredData: { allTableRows: sortedTableRows, totalTableRowsCount, totalPageCount },
        });
      })
      .catch(handleError);
  };

  const onhandleFilterChange = (newValue, item) => {
    dispatch(insideSalesFilterStore({ storedFilters: {} }));
    filters[item] = newValue;
    setLocalState({ filters });
  };

  const handleExport = (email) => {
    const url = `${SERVER_URL}/user/download/profileDocFile?userEmail=${email}`;
    try {
      window.location.href = url;
    } catch (error) {
      handleError(error);
    }
  };
  const handleRowsPerPageChange = (event) => {
    const { value } = event.target;
    setLocalState({ page: INITIAL_PAGE, rowsPerPage: value });
  };

  const handlePageChange = (newPage) => {
    setLocalState({ page: newPage });
    document.getElementsByClassName("MuiTableContainer-root")[0].scrollTop = 0;
  };

  const handleFilterSubmit = () => {
    dispatch(insideSalesFilterStore({ storedFilters: filters }));
    getInsideSalesByFilter();
    const isFilterEmpty = isEqual(filters, emptyFilters);
    setLocalState({ isFilterEmpty: isFilterEmpty });
  };

  const handleFilterClose = () => {
    dispatch(insideSalesFilterStore({ storedFilters: emptyFilters }));
    setLocalState({ filters: emptyFilters });
    getInsideSalesByFilter();
  };

  return (
    <Paper display="block" justifycontent="flex-start" sx={{ borderRadius: 2, p: 2 }}>
      {isLoading && <MISLoader />}
      <Typography variant="h5">{T.SEARCH_PROFILES}</Typography>
      <Box
        sx={{
          "& .MuiTabPanel-root": {
            p: 2,
            pt: 0,
          },
        }}
      >
        <TopBar
          filters={filters}
          skillList={skillList}
          onhandleFilterChange={onhandleFilterChange}
          handleFilterSubmit={handleFilterSubmit}
          handleFilterClose={handleFilterClose}
          isFilterEmpty={isFilterEmpty}
        />
        <InsideSalesTab
          allTableRows={filteredData.allTableRows}
          handleExport={handleExport}
          totalTableRowsCount={filteredData.totalTableRowsCount}
          totalPageCount={filteredData.totalPageCount}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Box>
    </Paper>
  );
};

export default InsideSales;
