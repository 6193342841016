import PropTypes from "prop-types";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";

import T from "T";
import MISButton from "components/common/MISButton";
import MISDialog from "components/common/MISDialog";
import MISTextField from "components/common/MISTextField";

const AddSingleUser = ({ isDialogOpen, groupName, userEmailId, handleChange, handleClose, handleSubmit }) => {
  return (
    <MISDialog open={isDialogOpen} handleClose={handleClose}>
      <DialogTitle>
        <IconButton onClick={handleClose}>
          <ArrowBack />
        </IconButton>
        {`Add User To Group "${groupName}"`}
      </DialogTitle>
      <DialogContent>
        <MISTextField
          label={T.EMAIL}
          required
          fullWidth
          placeholder={T.EMAIL_ID}
          size="small"
          variant="outlined"
          name="userEmailId"
          value={userEmailId}
          onChange={handleChange}
        ></MISTextField>
      </DialogContent>
      <DialogActions>
        <MISButton variant={"outlined"} onClick={handleClose}>
          {T.CANCEL}
        </MISButton>
        <MISButton variant={"contained"} onClick={handleSubmit}>
          {T.CONFIRM}
        </MISButton>
      </DialogActions>
    </MISDialog>
  );
};

AddSingleUser.propTypes = {
  isDialogOpen: PropTypes.bool.isRequired,
  groupName: PropTypes.string.isRequired,
  userEmailId: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

export default AddSingleUser;
